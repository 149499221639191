// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import { OrgImages } from "pages/organization/org-images/org-images";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
// import Billing from "pages/organization/organization_details/Billing";
import Dashboards from "pages/organization/organization_details/Dashboards";
import NLP from "pages/organization/organization_details/NLP";
import EmailPref from "pages/organization/organization_details/EmailPref";
import CRM from "pages/organization/organization_details/CRM";
import SalesforceSettings from "pages/organization/organization_details/SalesforceSettings";
import { Calculations } from "pages/organization/calculations/Calculations";
import ConnectObjects from "pages/organization/organization_details/ConnectObjects";
import CustomUpdates from "pages/organization/organization_details/CustomUpdates";
import { GetOrganizationDetails } from "pages/organization/organization_details/OrganizationDetails";

export const OrganizationRoutes = ({ updateRoute }) => {
  // console.log(role)
  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();

  return (
    <>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="org-details" />} />
          {getCurrRole.data.role.isAdmin && (
            <>
              <Route
                path="org-details/*"
                element={
                  <GetOrganizationDetails
                    role={getCurrRole.data.role}
                    updateRoute={updateRoute}
                  />
                }
              />

              <Route path="org-images/*" element={<OrgImages updateRoute={updateRoute}/>} />

              {/* <Route
                path="billing-product"
                element={
                  <Billing
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                  />
                }
              /> */}

              <Route
                path="email-preferences"
                element={
                  <EmailPref
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    updateRoute={updateRoute}
                  />
                }
              />

              <Route
                path="dashboards"
                element={<Dashboards roles={getCurrRole.data.role} updateRoute={updateRoute}/>}
              />

              <Route
                path="nlp"
                element={<NLP roles={getCurrRole.data.role} updateRoute={updateRoute}/>}
              />

              <Route
                path="contact-management"
                element={
                  <CRM
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    updateRoute={updateRoute}
                  />
                }
              />
              <Route
                path="Salesforce"
                element={
                  <SalesforceSettings
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    updateRoute={updateRoute}
                  />
                }
              />

              <Route
                path="calculations"
                element={
                  <Calculations
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    updateRoute={updateRoute}
                    />
                }/>

              <Route
                path="Salesforce/ConnectObjects/:id"
                element={
                  <ConnectObjects
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    updateRoute={updateRoute}
                  />
                }
              />
              <Route
                path="Salesforce/CustomUpdates/:id"
                element={
                  <CustomUpdates
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    updateRoute={updateRoute}
                  />
                }
              />
            </>
          )}
        </Routes>
      )}
    </>
  );
};
