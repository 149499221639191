// External
import React, { useEffect, useRef, useState } from "react";
import * as htmlToImage from "html-to-image";
import QRCode from "react-qr-code";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  FacebookIcon,
  XIcon,
  LinkedinIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  RedditIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";

//Internal
import styles from "./AnonLinks.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { TextFieldSimple } from "components/inputs";
import { useParams } from "react-router-dom";
import { useUpdateProjectDashProperties, useUpdateProjectStatus } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { getAnonLink } from "assets/functions/SurveyHealth";

export const AnonLinks = ({ roles, userId, survey, justLink, style }) => {
  const { id } = useParams();
  const [enable, setEnable] = useState(survey.activeLink);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [link] = useState(getAnonLink(survey));
  const updateProject = useUpdateProjectDashProperties();
  const updateStatus = useUpdateProjectStatus();

  function handleSave(val) {
    setLoading(true);
    setPrompt(false);
    setEnable(val);
    if (survey.status === "Draft") {
      updateStatus.mutate(
        {
          projectId: id,
          status: survey.status === "Draft" ? "Open" : survey.status,
        },
        {
          onSuccess: (data) => {
            updateProject.mutate(
              {
                updateProjectId: survey ? survey.id : id,
                data: {
                  activeLink: val,
                  // status: survey.status === "Draft" ? "Open" : survey.status,
                },
              },
              {
                onSuccess: () => {
                  setLoading(false);
                  location.reload();
                },
              }
            );
          },
          onError: (data) => {
            console.log(data);
          },
        }
      );
    } else {
      updateProject.mutate(
        {
          updateProjectId: survey ? survey.id : id,
          data: {
            activeLink: val,
            // status: survey.status === "Draft" ? "Open" : survey.status,
          },
        },
        {
          onSuccess: () => {
            setLoading(false);
          },
        }
      );
    }
  }

  function handleToggle(val) {
    if (survey.status === "Draft") {
      setPrompt(true);
    } else {
      handleSave(val);
    }
  }

  const ref = useRef(null);

  useEffect(() => {
    if (!loading) {
      ref.current?.play();
    }
  }, [loading]);

  const qrCodeRef = useRef(null);
  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  return (
    <div className={styles.container} style={style}>
      <div className={styles.container2}>
        <div
          className={styles.text}
          style={{ fontSize: ".9em", padding: "0em 1em" }}
        >
          Copy and paste this URL link to share your survey anywhere. This link
          works as long as you have it enabled and the survey status is open.
        </div>
        <div className={styles.links}>
          <TextFieldSimple
            value={getAnonLink(survey)}
            // disable
            copyToClipboard
            label={
              <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
                Survey Link{" "}
                <div
                  className={`${styles.status} ${
                    enable ? styles.active : styles.inactive
                  }`}
                >
                  {enable ? "enabled" : "disabled"}
                </div>
              </div>
            }
            placeholder="Survey Link..."
          />
        </div>
        {!justLink && (
          <>
            <div className={styles.setting}>
              <ToggleSwitch
                startChecked={enable}
                handleCheck={handleToggle}
                removeState
              />
              <div className={styles.text}>
                Enable Survey Link
              </div>
              {loading && <Loading height={20} width={20} />}
              <Player
                loop={false}
                ref={ref}
                src={require("assets/animations/checkmarkgreen.json")}
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
            {enable && (
              <div className={styles.bottomContent}>
                <div className={styles.qr}>
                  <Label
                    style={{
                      width: "fit-content",
                      margin: "0em",
                      padding: "0em",
                    }}
                  >
                    Share Link
                  </Label>
                  <div className={styles.icons}>
                    <EmailShareButton
                      url={link}
                      title={"Please Take this survey"}
                      quote={"Please Take this survey"}
                      picture={
                        "https://pixabay.com/photos/football-sport-play-competition-4455306/"
                      }
                    >
                      <div className={styles.icon}>
                        {" "}
                        <EmailIcon size={32} round />
                      </div>
                    </EmailShareButton>
                    <FacebookShareButton
                      url={link}
                      quote={"Please Take this survey"}
                      picture={
                        "https://pixabay.com/photos/football-sport-play-competition-4455306/"
                      }
                      // className="Demo__some-network__share-button"
                    >
                      <div className={styles.icon}>
                        <FacebookIcon size={32} round />
                      </div>
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={link}
                      title={"Please Take this survey"}
                    >
                      <div className={styles.icon}>
                        {" "}
                        <XIcon size={32} round />
                      </div>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={link}
                      title={"Please Take this survey"}
                      quote={"Please Take this survey"}
                      picture={
                        "https://pixabay.com/photos/football-sport-play-competition-4455306/"
                      }
                    >
                      <div className={styles.icon}>
                        <LinkedinIcon size={32} round />
                      </div>
                    </LinkedinShareButton>
                    <RedditShareButton
                      url={link}
                      title={"Please Take this survey"}
                      quote={"Please Take this survey"}
                      picture={
                        "https://pixabay.com/photos/football-sport-play-competition-4455306/"
                      }
                    >
                      <div className={styles.icon}>
                        <RedditIcon size={32} round />
                      </div>
                    </RedditShareButton>
                  </div>
                </div>
                <div className={styles.qr}>
                  <Label
                    style={{
                      width: "fit-content",
                      margin: "0em",
                      padding: "0em",
                    }}
                  >
                    Survey Link QR Code
                  </Label>
                  <div
                    style={{
                      opacity: "0",
                      position: "absolute",
                      pointerEvents: "none",
                    }}
                  >
                    <QRCode
                      ref={qrCodeRef}
                      value={getAnonLink(survey)}
                      size={500}
                    />
                  </div>
                  <QRCode value={getAnonLink(survey)} size={100} />
                  <Button
                    onClick={downloadQRCode}
                    shadow
                    style={{ fontSize: ".7em", padding: "0px 10px" }}
                  >
                    Download QR Code
                  </Button>
                </div>
              </div>
            )}
            <ReactModal
              show={prompt}
              // onClose={() => setPrompt(false)}
              modalStyle={{
                borderRadius: "1em",
                height: "fit-content",
                padding: "1em",
                maxWidth: "500px",
                gap: "1em",
              }}
            >
              <div className={styles.text} style={{ fontSize: ".9em" }}>
                Are you sure you want to enable this link? Doing so will open
                the survey and allow anyone with this url link to take the
                survey.
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  justifyContent: "center",
                }}
              >
                <Button shadow onClick={() => setPrompt(false)}>
                  No
                </Button>
                <Button shadow onClick={() => handleSave(true)}>
                  Yes
                </Button>
              </div>
            </ReactModal>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export function LinkEditor() {}
