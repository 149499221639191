/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
// External
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation, useGqlInfiniteQuery } from "api/Api";

export const useSearchDeliveries = (
  search,
  perPage,
  sort,
  projectId,
  distId,
  send
) => {
  const query = gql`
    query SearchDeliveries(
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortSelectionDelivery!
      $projectId: String!
      $distId: String!
      $send: Float!
    ) {
      response: searchDeliveries(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        projectId: $projectId
        distId: $distId
        send: $send
      ) {
        deliveries {
          id
          status
          email
          date
          error
          opens
          clicks
          contactId
          message
        }
        count
        hasMore
      }
    }
  `;

  return useGqlInfiniteQuery(
    ["Get Deliveries:" + distId],
    query,
    {
      search,
      skip: 0,
      perPage,
      sort,
      projectId,
      distId,
      send,
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentCount = allPages.flatMap(
          (page) => page?.response?.deliveries
        ).length;
        return lastPage?.response?.hasMore ? currentCount : undefined; // Use `currentCount` as `skip` for the next page
      },
    }
  );
};

export const useGetDeliveryStats = (projectId, distId, search) => {
  const query = gql`
    query Query($projectId: String!, $distId: String!, $search: String!) {
      stats: deliveryStats(
        projectId: $projectId
        distId: $distId
        search: $search
      ) {
        delivered
        opens
        clicks
        dropped
        bounced
        deferred
        spam
        blocked
      }
    }
  `;

  return useGqlQuery(["getdleliverystats:" + distId + projectId], query, {
    projectId: projectId,
    distId: distId,
    search: search,
  });
};

export const useExportDeliveries = () => {
  const mutation = gql`
    mutation Mutation(
      $search: String!
      $sort: SortSelectionDelivery!
      $projectId: String!
      $distId: String!
      $send: Float!
      $settings: exportDeliverySettings!
      $headers: DeliveryHeaders!
    ) {
      response: exportDeliveries(
        search: $search
        sort: $sort
        projectId: $projectId
        distId: $distId
        send: $send
        settings: $settings
        headers: $headers
      ) {
        zippedBase64
        csvString
        error
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useCancelSelectedSends = () => {
  const mutation = gql`
    mutation Mutation($deliveries: String!, $distId: String!) {
      cancelDeliveries(deliveries: $deliveries, distId: $distId)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useGetDeliveries = (id) => {
  console.log(id);
  const query = gql`
    query {
      count: getEmailMessages(
        id: "${id}"
      ) {
        id
      }
    }
  `;

  return useGqlQuery(["getEmailMessages:" + id], query);
};

export const useUnsubFromDelivery = () => {
  const mutation = gql`
    mutation UnsubFromDelivery($val: Boolean!, $id: String!) {
      unsubFromDelivery(val: $val, id: $id)
    }
  `;

  return useGqlMutation(mutation);
};
