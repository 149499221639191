import { useState, useEffect, useRef } from "react";
import styles from "./QuestionChart.module.scss";
import {
  useGetQChart,
  useUpdateVisualization,
} from "api/resources/projects/visualizations";
import { Loading } from "components/Loading/Loading";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import Chart from "components/Charts/Chart";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchColorPaletteById } from "api/resources/organization/colorpalettes";
import {
  getColorsFromGradient,
  getColorsFromPalette,
  getComparisonColors,
} from "assets/functions/ColorFunctions";
import { combinedProjIds, combinedQs } from "../Visualization";

import {
  LineType,
  Ranking,
  NoSort,
  High2Low,
  Low2High,
  AtoZ,
  ZtoA,
  ReverseSort,
  defaultColors,
  splitSomehow,
  NpsScore,
  Flywheel,
} from "./QuestionChart";
import { BackendDataTable } from "../Tables/DataTable/BackendDataTable";
import { BackendDrillTable } from "../Tables/DrillTable/BackendDrillTable";

const fakeData = {
  labels: ["", "", ""],
  // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  datasets: [
    {
      // label: "Amount",
      data: [125, 55, 82],
      // you can set indiviual colors for each bar
      // backgroundColor: ["#edb57e", "#FF8878", "#C1E08D"],
      backgroundColor: ["#15bcc7", "#dbdbdb", "#ed9146"],
      borderWidth: 0,
    },
  ],
};

export default function BackendQChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  setVisibleItems,
  editing,
  visible,
  active,
  setCurrViz,
  setShowDrill,
}) {
  const colorPalette = useFetchColorPaletteById(
    viz.designSettings.paletteId ? viz.designSettings.paletteId : ""
  );
  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  function getTitle() {
    if (!viz.designSettings.hasTitle) {
      return "";
    }

    return viz.title;
  }

  function titleChange(e) {
    let val = e.target.textContent;
    let newViz = { ...viz };
    newViz.title = val;
    newViz.titleValue = val;
    newViz.designSettings.artificialTitle = false;
    setCurrViz(newViz);
  }

  const editableTitle = active && visible === "Title";

  return (
    <>
      <div className={styles.chartWithTitle} id={viz.id + idAddOn}>
        {viz.designSettings.hasTitle && (
          <div
            className={styles.titleContainer}
            id={"title for " + viz.id + idAddOn}
            style={{
              alignItems: viz.designSettings.titleAlignment,
              backgroundColor: viz.designSettings.titleBackgroundColor,
              borderRadius: viz.designSettings.titleBorderRadius,
              paddingTop: viz.designSettings.paddingTopTitle,
              paddingBottom: viz.designSettings.paddingBottomTitle,
              paddingLeft: viz.designSettings.paddingLeftTitle,
              paddingRight: viz.designSettings.paddingRightTitle,
              border: editableTitle ? "2px solid #2a627c" : "",
              outline: "none",
              color: viz.designSettings.titleColor,
              fontSize: viz.designSettings.valueTitleSize,
              fontFamily: viz.designSettings?.titleFont
                ? viz.designSettings?.titleFont
                : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (editing) {
                setVisibleItems("Title");
                if (!active) {
                  setCurrViz(viz);
                }
              }
            }}
          >
            <div
              className={styles.title}
              style={{
                textAlign:
                  viz.designSettings.titleAlignment == "start"
                    ? "left"
                    : viz.designSettings.titleAlignment == "end"
                    ? "right"
                    : "center",
              }}
              contentEditable={editableTitle}
              onBlur={editableTitle ? titleChange : undefined}
              suppressContentEditableWarning={true}
            >
              {getTitle()}
            </div>

            {filterSubtitle && viz.designSettings.hasSubtitle && (
              <div className={styles.subtitle}>{filterSubtitle}</div>
            )}
          </div>
        )}

        {(colorPalette.isLoading || getSurveyTags.isLoading) && (
          <Loading height={height} />
        )}
        {colorPalette.isSuccess && getSurveyTags.isSuccess && (
          <QChart
            viz={viz}
            setOutsideData={setOutsideData}
            setUpOutsideDataCounter={setUpOutsideDataCounter}
            setLabelInfo={setLabelInfo}
            custom_fields={custom_fields}
            update={update}
            height={height}
            reDraw={reDraw}
            role={role}
            seeData={seeData}
            closeSeeData={closeSeeData}
            filters={filters}
            idAddOn={idAddOn}
            setVisibleItems={setVisibleItems}
            editing={editing}
            visible={visible}
            active={active}
            setCurrViz={setCurrViz}
            setShowDrill={setShowDrill}
            palette={colorPalette.data.palette}
            surveyTags={getSurveyTags.data.tags}
          />
        )}
      </div>
    </>
  );
}

function QChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  idAddOn,
  setVisibleItems,
  editing,
  visible,
  active,
  setCurrViz,
  setShowDrill,
  palette,
  surveyTags,
}) {
  const vizQs = combinedQs(viz);
  const vizProjIds = combinedProjIds(viz);

  const [data, setData] = useState(null);
  const [N, setN] = useState(0);

  const fetchQChart = useGetQChart();

  function onSuccess(data) {
    let compiledData = data.chart.split
      ? compileSplitData(data.chart)
      : compileData(data.chart);

    if (viz.type === LineType) {
      getLineData(compiledData);
    }

    setData(compiledData);
    setN(data.chart.N);
  }

  function fetchChart() {
    fetchQChart.mutate(
      {
        qIds: {
          ids: vizQs.map((q) => q.id),
        },
        filters: filters,
        projectIds: {
          ids: vizProjIds,
        },
        designSettings: JSON.stringify(viz.designSettings),
        tagIds: {
          ids: viz.tagIdsArray,
        },
        pivot: viz.pivotString,
        compQIds: {
          ids: viz.comparisonQs ? viz.comparisonQs.map((q) => q.id) : [],
        },
      },
      {
        onSuccess: onSuccess,
        onError: (err) => {
          setData(null);
          console.log(err);
        },
      }
    );
  }

  const settingsRef = useRef();
  const qIdsRef = useRef();
  const compQIdsRef = useRef();
  const pivotRef = useRef();
  const filtersRef = useRef();

  useEffect(() => {
    if (!data) {
      fetchChart();
    } else if (filtersRef.current && filtersRef.current !== filters) {
      fetchChart();
    } else if (
      qIdsRef.current &&
      qIdsRef.current.toString() !== vizQs.map((q) => q.id).toString()
    ) {
      fetchChart();
      setData(null);
    } else if (settingsRef.current && !sameSettings()) {
      fetchChart();
    } else if (pivotRef.current !== viz.pivotString) {
      fetchChart();
    } else if (
      compQIdsRef.current &&
      (!viz.comparisonQs ||
        compQIdsRef.current.toString() !==
          viz.comparisonQs.map((q) => q.id).toString())
    ) {
      fetchChart();
      setData(null);
    } else if (
      fetchQChart.isSuccess &&
      settingsRef.current &&
      !sameFrontEndSettings()
    ) {
      recompile();
    }

    filtersRef.current = filters;
    qIdsRef.current = vizQs.map((q) => q.id);
    settingsRef.current = JSON.parse(JSON.stringify(viz.designSettings));
    pivotRef.current = viz.pivotString;
    compQIdsRef.current = viz.comparisonQs
      ? viz.comparisonQs.map((q) => q.id)
      : null;
  }, [viz, filters]);

  function sameSettings() {
    const list = [
      "split",
      "useComparison",
      "byPercent",
      "byPercentOfTotal",
      "showUndefined",
      "undefinedLabel",
      "includeOverall",
      "showNonParticipating",
      "answerType",
      "chosenLabel",
      "comparisonLabel",
      "hasDataLabels",
      "asRanking",
      "scaleByRank",
      "option",
      "flip",
      "matrixScaleByRank",
      "dynamic",
      "sigFigs", // for flywheel
      "hideNonChosen",
      'mAvg',
    ];

    for (let item of list) {
      if (settingsRef.current[item] !== viz.designSettings[item]) {
        // console.log("on " + item);
        return false;
      }
    }

    const nestedList = [{ first: "dataLabelValue", second: "sigFigs" }];

    for (let item of nestedList) {
      if (
        settingsRef.current[item.first][item.second] !==
        viz.designSettings[item.first][item.second]
      ) {
        // console.log("on " + item);
        return false;
      }
    }

    return true;
  }

  function sameFrontEndSettings() {
    const list = [
      "paletteId",
      "useTagColor",
      "sortData",
      "stacked",
      "useTagColor",
    ];

    for (let item of list) {
      if (settingsRef.current[item] !== viz.designSettings[item]) {
        // console.log("on " + item);
        return false;
      }
    }

    const objList = [
      "segLabelReplacements",
      "axisLabelReplacements",
      "colors",
      "gradient",
    ];

    for (let item of objList) {
      let old = settingsRef.current[item];
      let update = viz.designSettings[item];
      if (JSON.stringify(old) !== JSON.stringify(update)) {
        // console.log("on " + item);
        return false;
      }
    }

    return true;
  }

  function recompile() {
    // console.log("re-compilation");
    onSuccess(fetchQChart.data);
  }

  useEffect(() => {
    if (active && data && fetchQChart.isSuccess) {
      recompile();
    }
  }, [active]);

  const updateViz = useUpdateVisualization();

  const [drill, setDrill] = useState();

  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    if (setUpOutsideDataCounter) {
      if (setOutsideData && data) {
        setOutsideData({ ...data });
      }
    }
  }, [setUpOutsideDataCounter]);

  function comparing() {
    return (
      viz.designSettings.useComparison &&
      viz.comparisonQs &&
      viz.comparisonQs.length
    );
  }

  function sortData(labels, answerTallies, colors) {
    let labelTallyMap = [];
    forEach(labels, (label, ind) =>
      labelTallyMap.push({
        label: label,
        tally: answerTallies[ind],
        color: colors[ind],
        orig: ind,
        sorted: ind,
      })
    );

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      forEach(labelTallyMap, (l) => {
        if (l.orig in viz.designSettings?.segLabelReplacements) {
          l.label = viz.designSettings.segLabelReplacements[l.orig];
          l.origLabel = labels[l.orig]; // So you can know what it was originally
          labels[l.orig] = l.label;
        }
      });
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        labelTallyMap.reverse();
      } else {
        labelTallyMap.sort((a, b) => {
          if (viz.designSettings.sortData === High2Low) {
            return b.tally - a.tally;
          }
          if (viz.designSettings.sortData === Low2High) {
            return a.tally - b.tally;
          }

          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return a.label - b.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return b.label - a.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
        });
      }

      forEach(labelTallyMap, (choice, ind) => {
        labels[ind] = choice.label;
        answerTallies[ind] = choice.tally;

        // if (viz.designSettings?.afterSort) {
        //   choice.color = colors[ind];
        // } else {
        //   colors[ind] = choice.color;
        // }

        if (viz.designSettings.colors && !viz.designSettings?.afterSort) {
          colors[ind] = choice.color;
        } else {
          choice.color = colors[ind];
        }

        choice.sorted = ind; // For Chart Settings
      });
    }

    if (setLabelInfo) {
      let labelInfo = {
        segLabels: labelTallyMap,
        axisLabels: labelTallyMap,
      };
      setLabelInfo(labelInfo);
    }
  }

  function getDefaultColors(length) {
    let colors = [];
    let c = 0;
    for (let i = 0; i < length; i++) {
      colors[i] = defaultColors[c];
      c < defaultColors.length - 1 ? c++ : (c = 0);
    }
    return colors;
  }

  function getColors(num, data) {
    let compSimple = false;

    let colors = [];
    if (viz.designSettings.colors) {
      let c = 0;
      let copy = viz.designSettings.colors;
      for (let i = 0; i < num; i++) {
        colors[i] = copy[c];
        c < copy.length - 1 ? c++ : (c = 0);
      }
    } else if (viz.designSettings.gradient) {
      let gradient = viz.designSettings.gradient;
      colors = getColorsFromGradient(gradient.anchors, gradient.blended, num);
    } else if (palette) {
      if (comparing() && !(num % 2)) {
        if (splitSomehow(viz)) {
          let half = num / 2;
          let firstSet = getColorsFromPalette(palette, half);
          colors = getComparisonColors(firstSet);
        } else {
          // compSimple = true;
          // let actual = data.datasets[0].data.length;
          // let origSet = getColorsFromPalette(palette, actual);
          // colors = getComparisonColors(origSet);
          colors = getColorsFromPalette(palette, num);
        }
      } else {
        colors = getColorsFromPalette(palette, num);
      }
    } else {
      colors = getDefaultColors(num);
    }
    return [colors, compSimple];
  }

  function compileData(chartData) {
    let labels = [...chartData.axisLabels];
    let tallies = chartData.datasets.length ? chartData.datasets[0].data : [];

    let [colors] = getColors(labels.length);

    //Survey Tag Colors Display
    if (viz.pivotString === "survey tag" && viz.designSettings?.useTagColor) {
      for (let i = 0; i < labels.length; i++) {
        let tag = surveyTags.find((t) => t.label === labels[i]);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    sortData(labels, tallies, colors);

    let data = {
      labels: labels,
      datasets: [
        {
          data: tallies,
          backgroundColor: colors,
          borderRadius: viz.designSettings.borderRadius
            ? viz.designSettings.borderRadius
            : 0,
          borderSkipped: viz.designSettings.borderSkipped,
        },
      ],
    };

    return data;
  }

  function sortSplitData(data) {
    let segLabels = [];
    forEach(data.datasets, (dataset, i) =>
      segLabels.push({
        label: dataset.label,
        orig: i,
        sorted: i,
        color: dataset.backgroundColor[0],
      })
    );

    // add origLabel
    for (let l of segLabels) {
      if (
        viz.designSettings?.segLabelReplacements &&
        l.orig in viz.designSettings?.segLabelReplacements
      ) {
        l.label = viz.designSettings.segLabelReplacements[l.orig];
        l.origLabel = data.datasets[l.orig].label; // So you can know what it was originally
        data.datasets[l.orig].label = l.label; // Actually change it
      }
    }

    let axisLabels = [];
    if (data.labels) {
      forEach(data.labels, (label, i) =>
        axisLabels.push({
          label: label,
          orig: i,
          sorted: i,
        })
      );

      for (let l of axisLabels) {
        if (
          viz.designSettings?.axisLabelReplacements &&
          l.orig in viz.designSettings?.axisLabelReplacements
        ) {
          l.label = viz.designSettings.axisLabelReplacements[l.orig];
          l.origLabel = data.labels[l.orig]; // So you can know what it was originally
          data.labels[l.orig] = l.label; // Actually change it
        }
      }
    }

    if (setLabelInfo) {
      let labelsInfo = {
        segLabels: segLabels,
        axisLabels: axisLabels,
      };
      setLabelInfo(labelsInfo);
    }

    let labelMap = {};
    for (let i = 0; i < data.labels.length; i++) {
      labelMap[data.labels[i]] = i;
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        data.labels.reverse();
      } else {
        data.labels.sort((a, b) => {
          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a === "number" && typeof b === "number") {
              return a - b;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a === "number" && typeof b === "number") {
              return b - a;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return 1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          return 0;
        });
      }

      // axisLabels.forEach((one, i) => (one.sorted = i));

      let order = data.labels.map((label) => labelMap[label]);

      for (let set of data.datasets) {
        let copy = [];
        forEach(order, (num, ind) => {
          copy[ind] = set.data[num];
        });
        set.data = copy;
      }
      // data.labels = axisLabels.map(l => l.label);
    }
  }

  function changeToPercentOnSplitData(data, axisLabels) {
    // if (viz.designSettings.byPercentOfTotal) {
    //   for (let dataset of data.datasets) {
    //     let sum = 0;
    //     let tallyArray = dataset.data;
    //     forEach(tallyArray, (tally) => (sum += tally));
    //     for (let i = 0; i < tallyArray.length; i++) {
    //       tallyArray[i] = ((tallyArray[i] * 100) / sum).toFixed(
    //         viz.designSettings.dataLabelValue.sigFigs
    //       );
    //     }
    //   }
    // }

    for (let i = 0; i < axisLabels.length; i++) {
      let partCount = 0;
      for (let dataset of data.datasets) {
        partCount += dataset.data[i];
      }

      if (!partCount) {
        continue;
      }

      for (let dataset of data.datasets) {
        let portion = (dataset.data[i] / partCount) * 100;
        dataset.data[i] = portion.toFixed(
          viz.designSettings.dataLabelValue.sigFigs
        );
      }
    }
  }

  function bindColorsOnSplitData(data) {
    let [colors, compSimple] = getColors(data.datasets.length, data);

    //Survey Tag Colors Display
    if (
      viz.designSettings.split === "survey tag" &&
      viz.designSettings?.useTagColor
    ) {
      for (let i = 0; i < data.datasets.length; i++) {
        let tag = surveyTags.find((t) => t.label === data.datasets[i].label);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    if (compSimple) {
      let normal = [];
      let comp = [];
      for (let i = 0; i < colors.length; i += 2) {
        normal.push(colors[i]);
        comp.push(colors[i + 1]);
      }
      data.datasets[0].backgroundColor = normal;
      data.datasets[1].backgroundColor = comp;
    } else {
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].backgroundColor = [colors[i]];
        // data.datasets[i].borderWidth = 0;
      }
    }
  }

  function compileSplitData(chartData) {
    let copy = []; // So the real thing doesn't get changed.
    for (let set of chartData.datasets) {
      copy.push({
        data: [...set.data],
        label: set.label,
      });
    }

    let data = {
      labels: [...chartData.axisLabels],
      datasets: copy,
      stacked: viz.designSettings.stacked,
    };

    if (viz.designSettings.answerType === NpsScore) {
      for (let dataset of data.datasets) {
        for (let i = 0; i < dataset.data.length; i++) {
          if (dataset.data[i] == -101) {
            dataset.data[i] = null; // Won't pass to the front end as null, so have to decode it
          }
        }
      }
    }

    bindColorsOnSplitData(data);
    sortSplitData(data);
    return data;
  }

  function getLineData(data) {
    let settings = viz.designSettings;
    //so updates to the settings gets recognized by the useEffect in Chart.jsx
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].pointRadius = settings.pointRadius;
      data.datasets[i].borderWidth = settings.lineGraphWidth;
      data.datasets[i].pointBorderWidth = 0;
      data.datasets[i].borderRadius = settings.borderRadius
        ? settings.borderRadius
        : 0;
      data.datasets[i].borderSkipped = settings.borderSkipped;
    }

    if (data.datasets.length > 1 || data.datasets[0]?.label) {
      // split
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].borderColor = data.datasets[i].backgroundColor[0];
      }
    } else if (data.datasets[0]) {
      data.datasets[0].borderColor = settings.lineGraphColor;
      if (settings.hasUniformPointColor) {
        for (let i = 0; i < data.datasets[0].data.length; i++) {
          data.datasets[0].backgroundColor[i] = settings.uniformPointColor;
        }
      }
    }

    return data;
  }

  function saveData(data) {
    updateViz.mutate({
      id: viz.id,
      data: { data: typeof data != "string" ? JSON.stringify(data) : data },
    });
  }

  function closeDrill() {
    setDrill(null);
    setShowDrill(false);
    setChartData(null);
  }

  function onSegClick(segment, dataset, dataIndex, datasetIndex) {
    if (editing) {
      setVisibleItems("ChartSegments");
      if (!active) {
        setCurrViz(viz);
      }
      return;
    }

    if (vizQs.length === 0 || !role.canSeeContactInfo) {
      // is a new chart with no data
      return;
    }

    segment = fetchQChart.data.chart.axisLabels[dataIndex];
    dataset = fetchQChart.data.chart.datasets[datasetIndex].label;

    let otherOption = "";
    // check for other options
    for (let question of vizQs) {
      if (question.choiceQuestion && question.choiceQuestion.hasOtherOption) {
        let lookFor =
          viz.pivotString && viz.designSettings.split === "choices"
            ? dataset
            : segment;
        let otherLabel = question.choiceQuestion.otherOptionLabel;
        if (otherLabel === lookFor) {
          if (viz.pivotString && viz.designSettings.split === "choices") {
            // pivoting while splitting choices
            dataset = otherLabel;
          } else {
            // for split only or for normal
            segment = otherLabel;
          }
          otherOption = otherLabel;
        }
      }
    }

    if (viz.designSettings.answerType === Ranking) {
      let x = segment;
      segment = dataset;
      dataset = x;
    }

    let setup = {
      segment: segment,
      dataset: dataset,
      split: viz.designSettings.split,
      pivot: viz.pivotString,
      otherOption: otherOption,
      asRanking:
        viz.designSettings.asRanking && !viz.designSettings.scaleByRank,
    };

    if (comparing()) {
      let comparedLabel = viz.designSettings.comparisonLabel
        ? viz.designSettings.comparisonLabel
        : "Comparison";

      if (
        viz.designSettings.split ||
        (vizQs[0]?.matrixQuestion &&
          !viz.designSettings.option &&
          (!vizQs[0]?.textQuestion ||
            (vizQs[0]?.textQuestion && viz.pivotString))) ||
        (viz.designSettings.asRanking && !viz.designSettings.scaleByRank)
      ) {
        if (
          typeof dataset === "string" &&
          dataset.includes(comparedLabel + " | ")
        ) {
          setup.useCompared = true;
          let ind = dataset.indexOf("|");
          setup.dataset = dataset.slice(ind + 2);
        }
      } else if (dataset === comparedLabel) {
        setup.useCompared = true;
        setup.split = "";
      }
    }

    if (viz.designSettings.includeOverall) {
      let lookFor = viz.designSettings.overallLabel
        ? viz.designSettings.overallLabel
        : "Overall";
      if (typeof dataset === "string" && dataset.includes(lookFor)) {
        setup.split = "";
      }
    }

    setDrill(setup);
    setShowDrill(true);
  }

  return (
    <>
      {data ? (
        <>
          <Chart
            data={data}
            onSegClick={onSegClick}
            viz={viz}
            idAddOn={idAddOn}
            reDraw={reDraw}
            update={update}
            saveData={saveData}
            setVisibleItems={setVisibleItems}
            editing={editing}
            active={active}
            setCurrViz={setCurrViz}
            visibleItems={visible}
          />
          {viz.designSettings?.showN && (
            <div className={styles.answerCount}>
              {viz.designSettings?.NLabel ? viz.designSettings?.NLabel : "N"} ={" "}
              {N}
            </div>
          )}
        </>
      ) : (
        <Loading height={height} />
      )}

      {drill && (
        <BackendDrillTable
          viz={viz}
          filters={filters}
          drill={drill}
          custom_fields={custom_fields}
          onClose={closeDrill}
        />
      )}
      {seeData && (
        <BackendDataTable
          viz={viz}
          filters={filters}
          custom_fields={custom_fields}
          closeSeeData={closeSeeData}
        />
      )}
    </>
  );
}
