/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useGqlMutation } from "api/Api";
import { gql } from "graphql-request";

export const useRegisterRequest = () => {
  const mutation = gql`
    mutation Register($data: registerInput!) {
      register(data: $data) {
        organizationId
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useCreateUserAccountRequest = () => {
  const mutation = gql`
    mutation Mutation($data: CreateAccountInput!, $captchaToken: String!) {
      response: createAccount(data: $data, captchaToken: $captchaToken) {
        token
        success
        error
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useValidateEmail = () => {
  const mutation = gql`
    mutation ValidateEmail($email: String!) {
      validateEmail(email: $email)
    }
  `;

  return useGqlMutation(mutation);
};

export const useValidateJoinCode = () => {
  const mutation = gql`
    mutation ValidCodeForUser($id: String!) {
      validCodeForUser(id: $id)
    }
  `;

  return useGqlMutation(mutation);
};

export const useValidateJoinCodeOrganization = () => {
  const mutation = gql`
    mutation ValidCodeForOrganization($id: String!) {
      data: validCodeForOrganization(id: $id) {
        organization {
          name
        }
        image {
          imageURL
          isLogo
          scale
          position
          name
          about
        }
        success
        description
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useCheckUserToken = () => {
  const mutation = gql`
    mutation Mutation($token: String!) {
      status: checkUserToken(token: $token) {
        status
        description
        id
        email
        expires
        join
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useCheckPasswordResetToken = () => {
  const mutation = gql`
    mutation Mutation($token: String!) {
      status: validatePasswordReset(token: $token) {
        status
        description
        id
        email
        expires
        join
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useValidateJoinCodeToken = () => {
  const mutation = gql`
    mutation ValidCodeForUser($code: String!, $token: String!) {
      response: validCodeForUserToken(code: $code, token: $token) {
        error
        success
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useResendCreateAccountCode = () => {
  const mutation = gql`
    mutation Mutation($token: String!) {
      response: resendCreateAccountCode(token: $token) {
        error
        success
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useValidCodeReturnOrg = () => {
  const mutation = gql`
    mutation ValidCodeReturnOrg($validCodeForUserId: String!) {
      organization: validCodeReturnOrg(id: $validCodeForUserId) {
        id
        name
        image {
          imageURL
        }
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useCreateUserJoinCode = () => {
  const mutation = gql`
    mutation CreateCode($data: JoinCodeInput!) {
      createCode(data: $data) {
        code
        id
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useUpdatePswd = () => {
  const mutation = gql`
    mutation Mutation($password: String!, $userId: String!) {
      resetPassword(password: $password, id: $userId)
    }
  `;

  return useGqlMutation(mutation);
};