import React from "react";
import styles from "./SurveyProjects.module.scss";
import SurveyProject from "./SurveyProject";
import { Sorting } from "../../InteractiveSurveysTable";
import { Pagination } from "../../Pagination";
import { Loading } from "components/Loading/Loading";

function SurveyProjects({
  surveys,
  onClick,
  roles,
  refetch,
  iterations,
  sort,
  setSort,
  pageNum,
  setPageNum,
  perPage,
  setPerPage,
  setPageSkip,
  count,
  length,
  searchString,
  isCreate,
  onClickChoose,
  loading,
  chosen
}) {
  return (
    <div
      className={styles.surveys}
    
    >
      <div
        className={styles.sortBox}
      >
        <Sorting sort={sort} setSort={setSort}></Sorting>

        <Pagination
          pageNum={pageNum}
          setPageNum={setPageNum}
          maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
          length={length}
          searchString={searchString}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
          dropDown
        ></Pagination>
      </div>
      {loading && <Loading></Loading>}
      {!loading && surveys.map((survey) => (
        <SurveyProject
          key={survey.id}
          survey={survey}
          onClick={onClick}
          roles={roles}
          refetch={refetch}
          iterations={iterations}
          isCreate={isCreate}
          onClickChoose={onClickChoose}
          chosen={chosen}
        />
      ))}{" "}
      <div className={styles.sortBox} style={{justifyContent: "flex-end"}}>
        <Pagination
          pageNum={pageNum}
          setPageNum={setPageNum}
          maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
          length={length}
          searchString={searchString}
          perPage={perPage}
          setPerPage={setPerPage}
          setPageSkip={setPageSkip}
        ></Pagination>
      </div>
    </div>
  );
}

export default SurveyProjects;
