import { useGetFilterableAnswers } from "api/resources/projects/answers";
import { combinedProjIds, combinedQs } from "../../Visualization";
import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import { useState, useEffect } from "react";
import styles from "../AnswerTable.module.scss";
import { TableType } from "../../QChart/QuestionChart";
import ContactDetails from "components/Popout/ContactProfile";
import TableChart from "components/Charts/Table/TableChart";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import { capitalize } from "assets/functions/StringFunctions";
import { getTitleContainerStyle, getTitleStyle } from "../Table";
import { useGetCorrespondingComments } from "api/resources/projects/answers";
import { Loading } from "components/Loading/Loading";
import { trimTimeDay } from "assets/functions/DateFunctions";
import {
  addColAttributes,
  addColumnStrings,
} from "assets/functions/ObjectFunctions";

export function BackendDrillTable({
  viz,
  filters,
  drill,
  custom_fields,
  onClose,
}) {
  // TODO: Put on the backend
  const vizQs = combinedQs(viz);

  const columns = { all: true };

  const fetchAnswers = useGetFilterableAnswers(
    vizQs.map((q) => q.id),
    filters,
    columns,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    null
  );

  const getProjects = useFetchProjectsByIdsGql(
    {
      projectIds: combinedProjIds(viz),
    },
    viz.id
  );

  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  function handleClickOutside(event) {
    if (event.target === document.getElementById("outsideDrill")) {
      document.removeEventListener("click", handleClickOutside, true);
      onClose();
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.popoutBackground} id="outsideDrill">
      <div className={styles.popout}>
        <i
          className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
          onClick={onClose}
        ></i>

        {(fetchAnswers.isLoading ||
          getProjects.isLoading ||
          getSurveyTags.isLoading) && <Loading></Loading>}
        {fetchAnswers.isSuccess &&
          getProjects.isSuccess &&
          getSurveyTags.isSuccess && (
            <>
              <DrillTable
                answers={fetchAnswers.data?.answers}
                projects={getProjects.data?.survey}
                surveyTags={getSurveyTags.data.tags}
                filters={filters}
                viz={viz}
                custom_fields={custom_fields}
                drill={drill}
              />
            </>
          )}
      </div>
    </div>
  );
}

function DrillTable({
  answers,
  filters,
  viz,
  drill,
  projects,
  surveyTags,
  custom_fields,
}) {
  const vizQs =
    drill.useCompared && viz.comparisonQs ? viz.comparisonQs : combinedQs(viz);

  function allNPS() {
    for (let q of vizQs) {
      if (!q.isScore) {
        return false;
      }
    }
    return true;
  }

  const addComment = viz.designSettings.includeComment && allNPS();

  return (
    <>
      {!addComment && (
        <Drill
          answers={answers}
          Qs={vizQs}
          viz={viz}
          drill={drill}
          projects={projects}
          surveyTags={surveyTags}
          custom_fields={custom_fields}
        />
      )}
      {addComment && (
        <TackOnComment
          answers={answers}
          Qs={vizQs}
          filters={filters}
          viz={viz}
          drill={drill}
          projects={projects}
          surveyTags={surveyTags}
          custom_fields={custom_fields}
        />
      )}
    </>
  );
}

function TackOnComment({
  answers,
  Qs,
  filters,
  viz,
  drill,
  projects,
  surveyTags,
  custom_fields,
}) {
  const getCorresponding = useGetCorrespondingComments(
    Qs.map((q) => q.projectId),
    filters,
    viz.designSettings?.dynamic ? viz.designSettings.userConstraints : undefined
  );

  return (
    <>
      {getCorresponding.isLoading && <Loading />}
      {getCorresponding.isSuccess && (
        <Drill
          answers={answers}
          Qs={[...Qs, ...getCorresponding.data.QsAndAnswers.questions]}
          comments={getCorresponding.data.QsAndAnswers.answers}
          viz={viz}
          drill={drill}
          projects={projects}
          surveyTags={surveyTags}
          custom_fields={custom_fields}
        />
      )}
    </>
  );
}

function Drill({
  answers,
  Qs,
  comments,
  viz,
  drill,
  projects,
  surveyTags,
  custom_fields,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [headers, setHeaders] = useState();
  const [contact, setContact] = useState(null);

  function isRanking(qId) {
    let q = Qs.find((q) => q.id === qId);
    return q.choiceQuestion?.isRanking;
  }

  function getMatrixAnswer(answer) {
    let matrixAnswer = JSON.parse(answer.matrixAnswer);
    let q = Qs.find((q) => q.id === answer.questionId);
    let array = [];

    for (let option of q.matrixQuestion.options) {
      if (option in matrixAnswer) {
        let string = "";
        string += option + ": ";
        if (q.choiceQuestion) {
          let choiceAnswer = matrixAnswer[option];
          const ranking = isRanking(answer.questionId);
          for (let i = 0; i < choiceAnswer.length; i++) {
            if (ranking) {
              string += i + 1 + ": ";
            }
            string += choiceAnswer[i];
            if (i < choiceAnswer.length - 1) {
              if (ranking) {
                string += "  ";
              } else {
                string += ", ";
              }
            }
          }
        } else {
          string += matrixAnswer[option];
        }
        let div = <div style={{ whiteSpace: "pre" }}>{string}</div>;
        if (
          option ===
          (viz.designSettings.flip || drill.pivot
            ? drill.dataset
            : drill.segment)
        ) {
          div.props.style.fontStyle = "italic";
        }
        array.push(div);
      }
    }
    return <>{array}</>;
  }

  function getRankingAnswer(answer) {
    let string = "";
    for (let i = 0; i < answer.choiceAnswer.length; i++) {
      if (string) string += " ";
      string += i + 1 + ": ";
      string += answer.choiceAnswer[i];
    }
    return string;
  }

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      if (isRanking(answer.questionId)) {
        return getRankingAnswer(answer);
      }
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (string) string += ", ";
        string += answer.choiceAnswer[i];
      }
      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      return answer.textAnswer;
    }
    if (answer.matrixAnswer) {
      return getMatrixAnswer(answer);
    }
    return "Did not answer";
  }

  function undefinedLabel() {
    return viz.designSettings.undefinedLabel
      ? viz.designSettings.undefinedLabel
      : "Undefined";
  }

  const style = (value) => <span className={styles.text}>{value}</span>;

  const initHeaders = [
    {
      name: "First Name",
      accessor: "firstName",
      cell_style: style,
    },
    {
      name: "Last Name",
      accessor: "lastName",
      cell_style: style,
    },
    {
      name: "Email",
      accessor: "email",
      cell_style: style,
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getMonthTaken(answer) {
    if (!answer.createdAt) {
      return undefinedLabel();
    }
    let date = new Date(answer.createdAt);

    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + year;
  }

  function getHourTaken(answer) {
    if (!answer.createdAt) {
      return "Undefined";
    }
    let date = new Date(answer.createdAt);
    date.setMinutes(0, 0, 0);
    return trimTimeDay(date, true);
  }

  function getSurveyLabel(answer, val) {
    let proj = getProjFromAnswer(answer);
    if (val === "survey") {
      return proj.name;
    }
    if (val === "survey tag") {
      let tag = surveyTags.find((t) => t.project.find((p) => p.id === proj.id));
      if (tag) {
        return tag.label;
      }
      return undefinedLabel();
    }

    if (val === "survey date" || val === "survey quarter") {
      let segLabel = proj.name;
      if (proj.startedAt) {
        let date = new Date(proj.startedAt);
        let month = date.toDateString().substring(4, 7);
        let year = date.toDateString().substring(11, 15);
        segLabel = month + " " + year;
        if (val === "survey quarter") {
          if (month === "Jan" || month === "Feb" || month === "Mar") {
            segLabel = "Q1 " + year;
          } else if (month === "Apr" || month === "May" || month === "Jun") {
            segLabel = "Q2 " + year;
          } else if (month === "Jul" || month === "Aug" || month === "Sep") {
            segLabel = "Q3 " + year;
          } else if (month === "Oct" || month === "Nov" || month === "Dec") {
            segLabel = "Q4 " + year;
          }
        }
      }
      return segLabel;
    }
  }

  function getNPSLabel(answer) {
    if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
      return "Promoters";
    }
    if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
      return "Passives";
    }
    if (answer.scaleAnswer <= 6) {
      return "Detractors";
    }

    return undefinedLabel();
  }

  function passesSplitDrill(contact, answer) {
    if (drill.split.includes("survey")) {
      let label = getSurveyLabel(answer, drill.split);
      return label === drill.dataset;
    }

    if (drill.split === "choices") {
      if (drill.otherOption && drill.otherOption === drill.dataset) {
        return passesAnsweredOther(answer, drill.dataset);
      }
      return answer.choiceAnswer.includes(drill.dataset);
    }

    if (drill.split === "nps") {
      let label = getNPSLabel(answer);
      return label === drill.dataset;
    }

    if (drill.split === "month taken") {
      let label = getMonthTaken(answer);
      return label === drill.dataset;
    }

    if (drill.split === "hour taken") {
      let label = getHourTaken(answer);
      return label === drill.dataset;
    }

    if (viz.designSettings.splittingByBucket) {
      // check buckets
      if (answer.buckets) {
        let buckets = JSON.parse(answer.buckets);
        if (drill.split in buckets) {
          if (
            getAnswerBucketLabels(buckets[drill.split]).includes(drill.dataset)
          ) {
            return true;
          }
        } else {
          if (drill.dataset === undefinedLabel()) {
            return true;
          }
        }
      } else {
        if (drill.dataset === undefinedLabel()) {
          return true;
        }
      }

      return false;
    }

    // else splitting by a contact field
    if (!contact) {
      if (drill.dataset === undefinedLabel()) {
        return true;
      }
      return false;
    }

    if (!contact[drill.split] && drill.dataset === undefinedLabel()) {
      return true;
    }

    if (contact[drill.split] && contact[drill.split].includes(drill.dataset)) {
      return true;
    }

    return false;
  }

  function getProjFromAnswer(answer) {
    let project = projects.find(
      (p) => p.id === answer.participation?.projectId
    );
    return project;
  }

  function passesPivot(contact, answer) {
    if (drill.pivot.includes("survey")) {
      let label = getSurveyLabel(answer, drill.pivot);
      return label === drill.segment;
    }
    if (drill.pivot === "nps") {
      let label = getNPSLabel(answer);
      return label === drill.segment;
    }
    if (drill.pivot === "month taken") {
      let label = getMonthTaken(answer);
      return label === drill.segment;
    }
    if (drill.pivot === "hour taken") {
      let label = getHourTaken(answer);
      return label === drill.segment;
    }

    if (viz.designSettings.pivotingByBucket) {
      // check buckets
      if (answer.buckets) {
        let buckets = JSON.parse(answer.buckets);
        if (drill.pivot in buckets) {
          if (
            getAnswerBucketLabels(buckets[drill.pivot]).includes(drill.segment)
          ) {
            return true;
          }
        } else {
          if (drill.segment === undefinedLabel()) {
            return true;
          }
        }
      } else {
        if (drill.segment === undefinedLabel()) {
          return true;
        }
      }
      return false;
    }

    // else pivoting by a contact Field
    if (!contact) {
      if (drill.segment === undefinedLabel()) {
        return true;
      }
      return false;
    }

    if (!contact[drill.pivot] && drill.segment === undefinedLabel()) {
      return true;
    }

    if (contact[drill.pivot] && contact[drill.pivot].includes(drill.segment)) {
      return true;
    }

    return false;
  }

  function passesRanking(answer) {
    // seg replacement

    let passesChoice = false;
    let choiceToLookFor = drill.segment;

    const lookForOther =
      drill.otherOption && drill.otherOption === choiceToLookFor;

    if (lookForOther) {
      passesChoice = passesAnsweredOther(answer, choiceToLookFor);
    } else if (answer.choiceAnswer.includes(choiceToLookFor)) {
      passesChoice = true;
    }

    if (passesChoice) {
      // passes rank
      let rankToLookFor = drill.dataset;
      if (lookForOther) {
        return passesOtherRank(answer, rankToLookFor);
      } else {
        let indexOfAnswer = answer.choiceAnswer.indexOf(choiceToLookFor);
        if (indexOfAnswer + 1 == rankToLookFor) {
          return true;
        }
      }
    }
    return false;
  }

  function passesOtherRank(answer, rankToLookFor) {
    if (answer.choiceAnswer[rankToLookFor - 1]) {
      let q = Qs.find((q) => q.id === answer.questionId);
      let chosenAt = answer.choiceAnswer[rankToLookFor - 1];
      if (!q.choiceQuestion.choices.includes(chosenAt)) {
        return true;
      }
    }
    return false;
  }

  function passesAnsweredOther(answer, lookingFor) {
    let q = Qs.find((q) => q.id === answer.questionId);
    if (
      q.choiceQuestion.hasOtherOption &&
      q.choiceQuestion.otherOptionLabel === lookingFor
    ) {
      for (let choice of answer.choiceAnswer) {
        if (!q.choiceQuestion.choices.includes(choice)) {
          return true;
        }
      }
    }
    return false;
  }

  function passesDrill(contact, answer) {
    let passesSegment = false;
    let passesSplit = true;

    if (drill.pivot) {
      passesSegment = passesPivot(contact, answer);
    } else {
      if (answer.choiceAnswer) {
        if (drill.asRanking) {
          passesSegment = passesRanking(answer);
        } else if (drill.otherOption && drill.otherOption === drill.segment) {
          passesSegment = passesAnsweredOther(answer, drill.segment);
        } else {
          for (let choice of answer.choiceAnswer) {
            if (choice.toLowerCase() === drill.segment.toLowerCase()) {
              passesSegment = true;
              break;
            }
          }
        }
      } else if (answer.scaleAnswer || answer.scaleAnswer === 0) {
        passesSegment = answer.scaleAnswer == drill.segment;
      } else if (answer.textAnswer) {
        passesSegment = true; //answer.textAnswer === drill.segment;
      } else if (answer.matrixAnswer) {
        let option = viz.designSettings.flip ? drill.dataset : drill.segment;
        let choice = viz.designSettings.flip ? drill.segment : drill.dataset;
        let matrixAnswer = JSON.parse(answer.matrixAnswer);
        if (option in matrixAnswer) {
          if (viz.type === TableType) {
            return true;
          }
          if (Qs[0].choiceQuestion) {
            passesSegment = matrixAnswer[option].includes(choice);
          } else if (Qs[0].textQuestion) {
            passesSegment = true;
          } else if (viz.designSettings.mAvg) {
            if (option == drill.segment) return true;
          } else {
            passesSegment = matrixAnswer[option] == choice;
          }
        }
      }
    }

    if (passesSegment && drill.split && viz.type !== TableType) {
      passesSplit = passesSplitDrill(contact, answer);
    }
    
    if (
      passesSegment &&
      drill.pivot &&
      viz.type !== TableType &&
      Qs[0].matrixQuestion &&
      Qs[0].textQuestion
    ) {
      // Only for pivoting text matrix on a chart
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      passesSplit = drill.dataset in matrixAnswer;
    }

    return passesSplit && passesSegment;
  }

  function getColumns() {
    let columns = [...initHeaders];

    if (projects.length > 1) {
      columns.push({
        name: "Survey",
        accessor: "survey",
        cell_style: style,
      });
    }

    let QColumns = [];
    if (Qs.length > 1) {
      for (let q of Qs) {
        if (!QColumns.some((c) => c.accessor === q.questionText)) {
          QColumns.push({
            name: q.questionText,
            accessor: q.questionText,
            cell_style: (val) => <div style={{ whiteSpace: "pre" }}>{val}</div>,
          });
        }
      }
      QColumns = getSortedArray(QColumns, function (a, b) {
        return (
          new Date(a?.project?.startedAt) - new Date(b?.project?.startedAt)
        );
      });

      columns = [...columns, ...QColumns];
    } else {
      columns.push({
        name: "Answer",
        accessor: "answer",
        cell_style: style,
      });
    }

    for (let q of Qs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        for (let b of q.textQuestion.bucket) {
          if (!columns.some((c) => c.accessor === b.name)) {
            columns.push({
              name: b.name,
              accessor: b.name,
              // cell_style: style,
            });
          }
        }
      }
    }

    for (let field of custom_fields.filter((f) => f.filterable !== false)) {
      if (field.generic) {
        let alreadyIn = columns.find((c) => c.accessor === field.name);
        if (alreadyIn) {
          alreadyIn.name = field.displayName;
        } else {
          columns.push({
            name: field.displayName ? field.displayName : field.name,
            accessor: field.name,
            cell_style: style,
          });
        }
      } else {
        columns.push({
          name: field.displayName ? field.displayName : field.name,
          accessor: field.id,
          cell_style: style,
        });
      }
    }

    return columns;
  }

  function getAnswerBucketLabels(result) {
    if (Array.isArray(result)) {
      return result;
    } else {
      if (viz.designSettings?.includeAll) {
        let keys = [];
        for (let b in result.breakdown) {
          if (result.breakdown[b].length) {
            keys.push(b);
          }
        }
        return keys;
      }
      return [result.overall];
    }
  }

  function putInBuckets(dataField, answer) {
    let buckets = JSON.parse(answer.buckets);
    for (let b in buckets) {
      let string = "";
      for (let prop of getAnswerBucketLabels(buckets[b])) {
        if (string) {
          string += ", ";
        }
        string += prop;
      }

      if (dataField[b]) {
        string = dataField[b] + ", " + string;
      }
      dataField[b] = string;
    }
  }

  function getDataField(answer, contact) {
    let dataField = {};

    if (contact) {
      dataField = { ...contact };
      dataField.anon = false;
    } else {
      dataField = {
        firstName: "Anonymous",
        anon: true,
      };
    }

    if (Qs.length > 1) {
      let q = Qs.find((q) => q.id === answer.questionId);
      dataField[q.questionText] = getAnswer(answer);
    } else {
      dataField.answer = getAnswer(answer);
    }

    if (Qs.length > 1) {
    } else {
      dataField.answer = getAnswer(answer);
    }

    if (projects.length > 1) {
      let proj = projects.find((p) => p.id === answer.participation?.projectId);
      dataField.survey = proj?.name;
    }

    if (answer.buckets) {
      putInBuckets(dataField, answer);
    }

    return dataField;
  }

  function getData(columns) {
    let dataMap = {};
    let anonymous = [];

    let commentMap = {};
    if (comments) {
      for (let c of comments) {
        commentMap[c.participation.id] = c;
      }
    }

    for (let answer of answers) {
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact) {
        addColAttributes(contact, custom_fields);
      }

      if (passesDrill(contact, answer)) {
        addColumnStrings(contact, custom_fields);
        let dataField = getDataField(answer, contact);
        if (comments) {
          let comment = commentMap[answer.participation.id];
          if (comment) {
            let q = Qs.find((q) => q.id === comment.questionId);
            dataField[q.questionText] = getAnswer(comment);
            if (comment.buckets) {
              putInBuckets(dataField, comment);
            }
          }
        }

        if (dataField.anon) {
          anonymous.push(dataField);
        } else {
          let key = answer.participation.id;
          if (!dataMap[key]) {
            dataMap[key] = dataField;
          } else {
            let orig = dataMap[key];
            dataMap[key] = { ...orig, ...dataField };
          }
        }
      }
    }

    let data = [];
    for (let responseId in dataMap) {
      data.push(dataMap[responseId]);
    }

    sortData(data, columns);
    data = [...data, ...anonymous];

    return data;
  }

  function sortData(data, columns) {
    // sort data so contacts are next to each other by last name, then first name, then by surveys
    if (columns.some((c) => c.accessor === "survey")) {
      let copy = [...projects];
      copy.sort((p1, p2) => {
        let a = p1.startedAt ? new Date(p1.startedAt).getTime() : 0;
        let b = p2.startedAt ? new Date(p2.startedAt).getTime() : 0;
        return a - b;
      });

      let projectMap = {};
      for (let i = 0; i < copy.length; i++) {
        projectMap[copy[i].name] = i;
      }

      data.sort((row1, row2) => {
        let a = projectMap[row1.survey];
        let b = projectMap[row2.survey];

        return b - a;
      });
    }
    if (columns.some((c) => c.accessor === "firstName")) {
      sortField(data, "firstName");
    }
    if (columns.some((c) => c.accessor === "lastName")) {
      sortField(data, "lastName");
    }
  }

  function sortField(data, field) {
    data.sort((row1, row2) => {
      let a = row1[field];
      let b = row2[field];
      if (!a) {
        a = "";
      }
      if (!b) {
        b = "";
      }

      if (a.toLowerCase() < b?.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (answers) {
      let columns = getColumns();
      let data = getData(columns);

      let keeping = [];
      for (let col of columns) {
        if (col.accessor === drill.pivot || col.accessor === drill.split) {
          keeping.push(col);
          continue;
        }
        for (let row of data) {
          if (col.accessor in row && row[col.accessor]) {
            keeping.push(col);
            break;
          }
        }
      }
      columns = keeping;

      setHeaders(columns);
      setDataArray(data);
    }
  }, [viz]);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
      }
    }
  }

  function getDrillTitle() {
    if (drill.split && drill.pivot && drill.split === "nps") {
      let pivot = drill.pivot.charAt(0).toUpperCase() + drill.pivot.slice(1);
      let title = drill.dataset + " with " + drill.segment + " as " + pivot;
      return title;
    }

    if (Qs[0].matrixQuestion && !viz.designSettings.option) {
      if (Qs[0].textQuestion) {
        if (drill.pivot) {
          return (
            drill.dataset + " with " + drill.pivot + " as " + drill.segment
          );
        }
        if (drill.split) {
          return (
            drill.segment + " with " + drill.split + " as " + drill.dataset
          );
        }

        return drill.segment;
      }

      return viz.designSettings.flip
        ? drill.segment + " for " + drill.dataset
        : drill.dataset + " for " + drill.segment;
    }

    let string = drill.segment + " ";

    if (drill.asRanking) {
      string += "ranked " + drill.dataset;
      if (viz.designSettings.option) {
        string += " for " + capitalize(viz.designSettings.option);
      }
      return string;
    }

    if (drill.pivot && drill.pivot !== "nps") {
      let pivot = capitalize(drill.pivot);
      string += " as " + pivot;
    }

    if (viz.designSettings.option && !drill.pivot) {
      string += " for " + capitalize(viz.designSettings.option);
    }

    if (drill.split && viz.type !== TableType) {
      if (drill.split === "choices") {
        string += " that chose ";
      } else {
        let split = capitalize(drill.split);
        string += " with " + split + " as ";
      }
      string += drill.dataset;
    }

    if (viz.designSettings.option && drill.split === "choices") {
      string += " for " + capitalize(viz.designSettings.option);
    }

    return string;
  }

  return (
    <>
      {headers && dataArray && (
        <TableChart
          initHeaders={headers}
          data={dataArray}
          threeDots
          onRowClick={handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          subtitle={getDrillTitle()}
          downloadCsv
          toggleSpreadsheet
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          showScroll
        />
      )}
      {contact && (
        <ContactDetails
          onClose={() => setContact(null)}
          contact={contact}
        ></ContactDetails>
      )}
    </>
  );
}
