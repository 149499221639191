import React, { useState, useEffect } from "react";
import styles from "./TextField.module.scss";
import Button from "components/Button/Button";

/**
 * A simple and customized text field
 * @param {String} value value that is passed in as initial value and/or changeable value for the text field
 * @param {String} placeholder a placeholder for the text field
 * @param {Function} onChange called when the text field changes/is typed in and returns the event which contains the value accessed with : (e.target.value)
 * @param {string} concerning used to update an object through it's property or accessor
 * @param {Function} onSave used to reduce the amount of re-renders to update an object;
 * @returns {React.ReactElement} a simple and customized text field
 */
export const TextFieldSimple = ({
  onChange,
  value,
  placeholder,
  type,
  name,
  style,
  copyToClipboard,
  label,
  onSave,
  saveLabel,
  onBlur,
  disable,
  valid,
  validMessage,
  invalidMessage,
  onEnter,
  id,
  autoFocus,
  defaultValue,
  maxLength,
  containerStyle,
  noState,
  icon,
  finalIcon,
  onClickFinalIcon,
  min,
  max,
  validationRules,
  validationRulesTitle,
}) => {
  const [val, setVal] = useState(value ? value : defaultValue);
  const [copied, setCopied] = useState(false);
  const [autoCompleteType, setAutoComplete] = useState(checkType);
  const [showModal, setShowModal] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value != val) {
      setVal(value);
    }
  }, [value]);

  useEffect(() => {
    if (copied) {
      const timeId = setTimeout(() => {
        // After 3 seconds set Copied value to false
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  function handleOnChange(e) {
    if (onChange) {
      onChange(e.target.value);
    }
    if (!disable) {
      setVal(e.target.value);
    }
  }

  function handleFocusOut(e) {
    setFocus(false);
    let value = e.target.value;
    if (type === "currency") {
      value = formatCurrency(e.target.value);
      setVal(value);
      if (onChange) {
        onChange(value);
      }
    }
    if (type === "phone") {
      value = formatPhoneNumber(e.target.value);
      setVal(value);
      if (onChange) {
        onChange(value);
      }
    }
    if (type === "url" || type === "email") {
      value = value.toLowerCase();
      setVal(value);
      if (onChange) {
        onChange(value);
      }
    }

    if (onBlur && !disable) {
      onBlur(value);
    }

    setShowModal(false);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      onEnter(e.target.value);
    }
  }

  // function autoComplete() {
  //   if (type === "email") {
  //     return "email"
  //   }
  //   if (type === "current-")
  // }
  const formatCurrency = (value) => {
    if (!value) return "";
    const numberValue = parseFloat(value.replace(/[^0-9.]/g, ""));
    return numberValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const formatPhoneNumber = (value) => {
    if (!value) return "";

    const phoneNumber = value.replace(/\D/g, ""); // Remove non-numeric characters

    // If the number starts with +, handle international formatting
    if (value.startsWith("+")) {
      // Extract country code and the rest of the number
      const countryCodeMatch = phoneNumber.match(/^(\d{1,3})/);
      if (!countryCodeMatch) return `+${phoneNumber}`;

      const countryCode = countryCodeMatch[0];
      const restOfNumber = phoneNumber.slice(countryCode.length);

      // Handle various country codes and number lengths
      switch (countryCode) {
        case "1": // USA, Canada
          if (restOfNumber.length <= 3) return `+1 (${restOfNumber}`;
          if (restOfNumber.length <= 6)
            return `+1 (${restOfNumber.slice(0, 3)}) ${restOfNumber.slice(3)}`;
          return `+1 (${restOfNumber.slice(0, 3)}) ${restOfNumber.slice(
            3,
            6
          )}-${restOfNumber.slice(6, 10)}`;
        case "44": // UK
          if (restOfNumber.length <= 4) return `+44 ${restOfNumber}`;
          if (restOfNumber.length <= 7)
            return `+44 ${restOfNumber.slice(0, 4)} ${restOfNumber.slice(4)}`;
          return `+44 ${restOfNumber.slice(0, 4)} ${restOfNumber.slice(
            4,
            7
          )} ${restOfNumber.slice(7, 11)}`;
        case "91": // India
          if (restOfNumber.length <= 5) return `+91 ${restOfNumber}`;
          return `+91 ${restOfNumber.slice(0, 5)}-${restOfNumber.slice(5, 10)}`;
        default:
          // Generic international formatting
          if (restOfNumber.length <= 3)
            return `+${countryCode} ${restOfNumber}`;
          if (restOfNumber.length <= 6)
            return `+${countryCode} ${restOfNumber.slice(
              0,
              3
            )} ${restOfNumber.slice(3)}`;
          return `+${countryCode} ${restOfNumber.slice(
            0,
            3
          )} ${restOfNumber.slice(3, 6)}-${restOfNumber.slice(6, 10)}`;
      }
    }

    // If not international, assume local U.S. format
    if (phoneNumber.length <= 3) return phoneNumber;
    if (phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  function checkType() {
    if (type === "currency") {
      return "";
    }
    if (type === "phone") {
      return "tel";
    }

    if (type === "time" || type === "date" || type === "text") {
      return "";
    }
    return type;
  }

  function enforceMinMax(el) {
    if (el.value != "") {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min;
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max;
      }
    }
  }

  return (
    <div
      style={{
        ...{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
        },
        // ...{
        //   backgroundColor: editColor && edit && val != initval ? "#FFF7E4" : "",
        // },
        ...containerStyle,
      }}
    >
      <div className={styles.labelContainer}>
        {label && (
          <div className={styles.label6} style={{ margin: "0" }}>
            {label}
          </div>
        )}
        {valid === true && (
          <div className={styles.validMessage}>
            {validMessage ? validMessage : ""}
          </div>
        )}
        {valid === false && (
          <div className={styles.invalidMessage}>
            {invalidMessage ? invalidMessage : "Invalid"}
          </div>
        )}
        {copied === true && (
          <div className={styles.copied}>Copied To Clipboard!</div>
        )}
      </div>

      <div
        className={`${styles.inputBox} ${focus && styles.focus} ${
          disable && styles.disabled
        } ${valid === false && styles.invalidBorder}`}
      >
        {icon ? <i className={`bi-${icon} ${styles.icon}`} /> : <></>}
        <input
          type={type}
          name={name}
          onChange={handleOnChange}
          onBlur={handleFocusOut}
          onFocus={() => {
            setShowModal(true);
            setFocus(true);
          }}
          value={noState ? value : val ? val : ""}
          placeholder={placeholder}
          disabled={disable}
          onKeyDown={onEnter ? handleKeyDown : null}
          id={id}
          style={style}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          maxLength={maxLength}
          autoComplete={autoCompleteType}
          min={min}
          max={max}
        ></input>
        {finalIcon ? (
          <i
            className={`bi-${finalIcon} ${styles.icon}`}
            style={{
              cursor: onClickFinalIcon ? "pointer" : "",
              marginRight: ".3rem",
            }}
            onClick={onClickFinalIcon}
          />
        ) : (
          <></>
        )}
        {copyToClipboard && (
          <i
            className="bi bi-clipboard"
            onClick={() => {
              navigator.clipboard.writeText(val);
              setCopied(true);
            }}
          ></i>
        )}
        {onSave && (
          <Button onClick={onSave} blue>
            {saveLabel ? saveLabel : "Save"}
          </Button>
        )}{" "}
      </div>

      {showModal && validationRules && validationRules.length > 0 && (
        <div className={styles.modal}>
          <h4>{validationRulesTitle}</h4>
          <ul>
            {validationRules.map((rule, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i
                  className={`bi ${
                    rule.valid
                      ? `bi-check-circle-fill ${styles.textSuccess}`
                      : `bi-x-circle-fill ${styles.textDanger}`
                  }`}
                  style={{ marginRight: "10px" }}
                ></i>
                {rule.rule}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
