import DataTable from "./DataTable/DataTable";
import DrillTable from "./DrillTable/DrillTable";

export function getTitleContainerStyle(viz) {
  return {
    alignItems: viz.designSettings.titleAlignment,
    backgroundColor: viz.designSettings.titleBackgroundColor,
    borderRadius: viz.designSettings.titleBorderRadius,
    paddingTop: viz.designSettings.paddingTopTitle + 10,
    paddingBottom: viz.designSettings.paddingBottomTitle,
    paddingLeft: viz.designSettings.paddingLeftTitle + 10,
    paddingRight: viz.designSettings.paddingRightTitle + 10,
    fontFamily: viz.designSettings.titleFont,
  };
}

export function getTitleStyle(viz) {
  return {
    color: viz.designSettings.titleColor,
    fontSize: viz.designSettings.valueTitleSize,
    textAlign: viz.designSettings.titleAlignment,
  };
}

export default function Table({
  answers,
  filters,
  onClose,
  viz,
  inEdit,
  drill,
  asChart,
  projects,
  surveyTags,
  custom_fields,
  spreadsheetmode,
  togglespreadsheet,
  chartData,
  setChartData,
}) {
  return (
    <>
      {drill && (
        <DrillTable
          answers={answers}
          filters={filters}
          viz={viz}
          custom_fields={custom_fields}
          projects={projects}
          surveyTags={surveyTags}
          drill={drill}
          onClose={onClose}
        />
      )}
      {!asChart && !drill && (
        <DataTable
          answers={answers}
          filters={filters}
          custom_fields={custom_fields}
          onClose={onClose}
          viz={viz}
          inEdit={inEdit}
          projects={projects}
          togglespreadsheet={togglespreadsheet}
          spreadsheetmode={spreadsheetmode}
          setChartData={setChartData}
          chartData={chartData}
        />
      )}
    </>
  );
}
