// External
import React, { useEffect, useState } from "react";

//Internal
import styles from "./Distributions.module.scss";
import {
  useBuildNewEmail,
  useCopyEmail,
  useDeleteDistribution,
  useSearchDistributions,
  useSearchDistributionsCount,
} from "api/resources/projects/distributions";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import {
  useFetchProjectGql,
} from "api/resources/projects/projects";
import InteractiveDistributionTable from "components/tables/BasicTable/InteractiveDistributionTable";

export const Distributions = ({ user, survey, onBuildEmail }) => {
  const { id } = useParams();
  const [copying, setCopying] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const [pageSort, setPageSort] = useState({
    item: "index",
    descend: false,
  });
  const [perPage, setPerpage] = useState(15);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [composing, setComposing] = useState(false);

  const fetchProjectQuery = useFetchProjectGql(id);
  const getDistributions = useSearchDistributions(
    id,
    pageSort,
    pageSkip,
    perPage,
    searchString,
    "email",
    "allemails"
  );
  const getDistributionsCount = useSearchDistributionsCount(
    id,
    pageSort,
    pageSkip,
    perPage,
    searchString,
    "email",
    "count"
  );
  const deleteDist = useDeleteDistribution();
  const composeNew = useBuildNewEmail();
  const copyEmail = useCopyEmail();

  const headers = [
    {
      id: 0,
      index: 0,
      name: "#",
      accessor: "index",
      enabled: true,
      sort: false,
      canSort: true,
    },
    {
      id: 0,
      index: 0,
      name: "Name",
      // accessor: "name",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: (value) => (
        <div style={{ display: "flex", gap: ".5em", alignItems: "center" }}>
          <i className={`bi bi-${value?.icon}`}></i> {value?.name}
        </div>
      ),
    },
    {
      id: 1,
      index: 1,
      name: "Subject",
      accessor: "subject",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      index: 2,
      name: "From",
      accessor: "sender",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 3,
      index: 3,
      name: "Status",
      accessor: "status",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: (value) => (
        <span
          className={`${styles.status} 
          ${value === "Sent" ? `${styles.delivered}` : ""} 
          ${value === "Draft" ? `${styles.draft}` : ""}
          ${value === "Scheduled" ? `${styles.scheduled}` : ""}
          ${value === "Active" ? `${styles.active}` : ""}
          ${value === "Inactive" ? `${styles.inactive}` : ""}`}
        >
          {value}{" "}
        </span>
      ),
    },
    {
      id: 4,
      index: 4,
      name: "Date",
      accessor: "scheduledDate",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 5,
      index: 5,
      name: "Copy",
      // accessor: "id",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (value) => (
        <>
          {value && copying != value?.id && (
            <a className={styles.copySurvey}>
              <i className="bi bi-clipboard" onClick={onCopy}></i>
            </a>
          )}
          {copying === value && <Loading height={60} width={60}></Loading>}
        </>
      ),
    },
  ];

  useEffect(() => {
    getDistributions.refetch();
  }, [pageNumber, pageSort, perPage, searchString]);

  function deleteEmail(email) {
    deleteDist.mutate(
      {
        id: email.id,
      },
      {
        onSuccess: (data) => {
          getDistributions.refetch();
          console.log("deleted");
        },
      }
    );
  }

  function handleRowClick(row, type) {
    if (type && type === "delete") {
      deleteEmail(row);
    } else {
      onBuildEmail(row.id);
    }
  }

  function onComposeNewEmail() {
    composeNew.mutate(
      {
        projectId: survey.id,
      },
      {
        onSuccess: (data) => {
          if (data.email.id) {
            onBuildEmail(data.email.id);
          }
        },
        onError: (data) => {
          console.log(data);
          setComposing(false);
        },
      }
    );
    setComposing(true);
  }

  function onCopy(email) {
    if (!copying) {
      copyEmail.mutate(
        { distId: email.id },
        {
          onSuccess: (data) => {
            getDistributions.refetch();
            setCopying(false);
          },
          onError: (err) => {
            console.log(err);
            setCopying(false);
          },
        }
      );
      setCopying(true);
    }
  }

  return (
    <div className={styles.page}>
      {fetchProjectQuery.isSuccess && (
        <InteractiveDistributionTable
          headers={headers}
          user={user}
          queryData={getDistributions}
          queryDataAccessor={"distributions"}
          maxDataQuery={getDistributionsCount}
          maxDataQueryAccessor={"count"}
          currPage={pageNumber}
          setCurrPage={setPageNumber}
          maxItems={perPage}
          onRowClick={handleRowClick}
          sort={pageSort}
          setSort={setPageSort}
          setPageSkip={setPageSkip}
          perPage={perPage}
          setPerPage={setPerpage}
          searchString={searchString}
          setSearchString={setSearchString}
          actionButtonText={
            composing ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Composing <Loading height={30} width={30} />
              </div>
            ) : (
              <div>
                Compose <i className="bi bi-pencil"></i>
              </div>
            )
          }
          tableTitle={survey.isProgram ? "Program Messages" : ""}
          tableDescription={
            survey.isProgram
              ? "Each program iteration will be initiated with these messages"
              : ""
          }
          widgetType="email"
          searchPlaceholder={"Search Messages..."}
          onClickActionButton={onComposeNewEmail}
          id={id}
          copyClick={onCopy}
          survey={fetchProjectQuery?.data?.survey}
        />
      )}
    </div>
  );
};
