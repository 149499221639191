import Modal from "components/ReactModal/ReactModal";
import styles from "./LoadTest.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { TextEntry } from "components/inputs";
import { useState } from "react";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import Button from "components/Button/Button";
import { isNumber } from "assets/functions/StringFunctions";
import { useRunLoadTest } from "api/resources/projects/distributions";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export function LoadTest({ email, onClose }) {
  const [test, setTest] = useState({
    number: "1,000,000",
    drip: 100,
    cadence: 1,
    concurrency: 100,
    limit: 100,
    duration: 100,
    onCompleteAge: 60,
    onCompleteCount: 500,
    onFailAge: 60,
    onFailCount: 500,
  });
  const [completedAge, setCompletedAge] = useState(true);
  const [failedAge, setFailedAge] = useState(true);
  const [completedCount, setCompletedCount] = useState(true);
  const [failedCount, setFailedCount] = useState(true);

  const runTest = useRunLoadTest();

  function getAllNumbers() {
    let object = { ...test };
    for (let key in object) {
      if (typeof object[key] == "string" && object[key].includes(",")) {
        object[key] = object[key].replaceAll(",", "");
      }
      if (!isNumber(object[key])) {
        object[key] = 0;
      }
      object[key] = parseInt(object[key]);
    }
    return object;
  }

  function onRun() {
    let object = getAllNumbers();

    runTest.mutate({
      distId: email.id,
      number: object.number,
      drip: object.drip,
      cadence: object.cadence,
      concurrency: object.concurrency,
      limit: object.limit,
      duration: object.duration,
      onCompleteAge: completedAge ? object.onCompleteAge : 0,
      onCompleteCount: completedCount ? object.onCompleteCount : 0,
      onFailAge: failedAge ? object.onFailAge : 0,
      onFailCount: failedCount ? object.onFailCount : 0,
    });
  }

  function changeField(field, val) {
    let copy = { ...test };
    copy[field] = val;
    setTest(copy);
  }

  function calculateTime() {
    let val = test.number.replaceAll(",", "");
    if (isNumber(val)) {
      let number = parseInt(val);

      let seconds = Math.ceil(number / test.drip);

      const days = Math.floor(seconds / (24 * 60 * 60));
      seconds %= 24 * 60 * 60;
      const hours = Math.floor(seconds / (60 * 60));
      seconds %= 60 * 60;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;

      return `${days ? days + ` day${days > 1 ? "s" : ""} ` : ""}${
        hours ? hours + " hr " : ""
      }${minutes ? minutes + " min " : ""}${
        seconds ? seconds + " sec" : ""
      }`.trim();
    }
    return <span className={styles.red}>Can't Calculate</span>;
  }

  function allNumbers() {
    for (let val of Object.values(test)) {
      if (typeof val == "string" && val.includes(",")) {
        val = test.number.replaceAll(",", "");
      }
      if (!isNumber(val)) {
        return false;
      }
    }
    return true;
  }

  return (
    <Modal
      show
      onClose={onClose}
      dark
      modalStyle={{ padding: "1rem" }}
      gap={15}
    >
      {runTest.isError && (
        <ErrorBanner error={runTest.error} message="Couldn't run load test" />
      )}
      <FlexCol gap={25} className={styles.container} align="center">
        <FlexCol gap={10} fit align={"center"}>
          <div className={styles.title}>Load Test</div>
          <i className={`bi-cloud-lightning-rain-fill ${styles.thunder}`}></i>
        </FlexCol>

        <FlexRow fit wrap style={{ alignSelf: "flex-start" }}>
          <div className={styles.heading}>This will:</div>
          <div className={styles.info}>
            Generate fake contact data. Not actually send any emails.
          </div>
        </FlexRow>
        <FlexRow wrap gap={20} fit align={"flex-start"}>
          <FlexCol gap={25} fit>
            <div className={styles.subHeader}>Email Send Parameters</div>

            <FlexRow>
              <div className={styles.label}>Number of Emails</div>
              <TextEntry
                value={test.number}
                onChange={(val) => changeField("number", val)}
              />
            </FlexRow>

            <FlexRow>
              <div className={styles.label}>Drip</div>

              <select
                onChange={(e) => changeField("drip", parseInt(e.target.value))}
                value={test.drip}
                className={styles.select}
              >
                {dripOptions.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>

              <div className={styles.smallLabel}>emails</div>
              <div className={styles.smallLabel}>every</div>

              <select
                onChange={(e) =>
                  changeField("cadence", parseInt(e.target.value))
                }
                value={test.cadence}
                className={styles.select}
              >
                {cadenceOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </FlexRow>

            <FlexRow>
              <div className={styles.label}>Estimated Run Time:</div>
              <div className={styles.runTime}>{calculateTime()}</div>
            </FlexRow>

            <div className={styles.subHeader}>Queue Parameters</div>

            <FlexCol gap={10}>
              <FlexRow>
                <div className={styles.label}>Concurrency</div>

                <TextEntry
                  value={test.concurrency}
                  onChange={(val) => changeField("concurrency", val)}
                />
              </FlexRow>

              <div
                className={styles.label}
                style={{ width: "100%", textAlign: "right" }}
              >
                # of jobs that can process at one time
              </div>
            </FlexCol>
          </FlexCol>

          <div className={styles.divider}></div>

          <FlexCol gap={25} fit>
            <FlexCol gap={10}>
              <div className={styles.subSub}>Limiter</div>

              <FlexRow fit>
                <div className={styles.label}>Max # of jobs</div>
                <TextEntry
                  value={test.limit}
                  onChange={(val) => changeField("limit", val)}
                  style={{ width: "100px" }}
                />
              </FlexRow>

              <FlexRow fit>
                <div className={styles.label}>within</div>
                <TextEntry
                  value={test.duration}
                  onChange={(val) => changeField("duration", val)}
                  style={{ width: "100px" }}
                />
                <div className={styles.label}>seconds</div>
              </FlexRow>
            </FlexCol>

            <FlexCol gap={10}>
              <div className={styles.subSub}>Remove On Complete</div>

              <FlexRow fit>
                <div
                  className={`${styles.label} ${
                    completedAge ? "" : styles.disabled
                  }`}
                >
                  After
                </div>
                <TextEntry
                  value={completedAge ? test.onCompleteAge : ""}
                  onChange={(val) => changeField("onCompleteAge", val)}
                  style={{ width: "100px" }}
                  disable={!completedAge}
                />
                <div
                  className={`${styles.label} ${
                    completedAge ? "" : styles.disabled
                  }`}
                >
                  seconds
                </div>

                <HorizontalBar rotate height={"20px"} width={"2px"} />
                <ToggleSwitch
                  startChecked={completedAge}
                  handleCheck={setCompletedAge}
                />
              </FlexRow>

              <FlexRow fit>
                <div
                  className={`${styles.label} ${
                    completedCount ? "" : styles.disabled
                  }`}
                >
                  Keep only
                </div>
                <TextEntry
                  value={completedCount ? test.onCompleteCount : ""}
                  onChange={(val) => changeField("onCompleteCount", val)}
                  style={{ width: "100px" }}
                  disable={!completedCount}
                />
                <div
                  className={`${styles.label} ${
                    completedCount ? "" : styles.disabled
                  }`}
                >
                  completed
                </div>

                <HorizontalBar rotate height={"20px"} width={"2px"} />
                <ToggleSwitch
                  startChecked={completedCount}
                  handleCheck={setCompletedCount}
                />
              </FlexRow>
            </FlexCol>

            <FlexCol gap={10}>
              <div className={styles.subSub}>Remove On Fail</div>

              <FlexRow fit>
                <div
                  className={`${styles.label} ${
                    failedAge ? "" : styles.disabled
                  }`}
                >
                  After
                </div>
                <TextEntry
                  value={failedAge ? test.onFailAge : ""}
                  onChange={(val) => changeField("onFailAge", val)}
                  style={{ width: "100px" }}
                  disable={!failedAge}
                />
                <div
                  className={`${styles.label} ${
                    failedAge ? "" : styles.disabled
                  }`}
                >
                  seconds
                </div>

                <HorizontalBar rotate height={"20px"} width={"2px"} />
                <ToggleSwitch
                  startChecked={failedAge}
                  handleCheck={setFailedAge}
                />
              </FlexRow>

              <FlexRow fit>
                <div
                  className={`${styles.label} ${
                    failedCount ? "" : styles.disabled
                  }`}
                >
                  Keep only
                </div>
                <TextEntry
                  value={failedCount ? test.onFailCount : ""}
                  onChange={(val) => changeField("onFailCount", val)}
                  style={{ width: "100px" }}
                  disable={!failedCount}
                />
                <div
                  className={`${styles.label} ${
                    failedCount ? "" : styles.disabled
                  }`}
                >
                  completed
                </div>

                <HorizontalBar rotate height={"20px"} width={"2px"} />
                <ToggleSwitch
                  startChecked={failedCount}
                  handleCheck={setFailedCount}
                />
              </FlexRow>
            </FlexCol>
          </FlexCol>
        </FlexRow>

        <FlexRow justify={"center"}>
          <Button shadow blue style={{ gap: "10px" }} disable={!allNumbers()} onClick={onRun}>
           <i className={'bi-lightning-fill'} ></i> Run Test
          </Button>
        </FlexRow>
      </FlexCol>
    </Modal>
  );
}

const dripOptions = [1, 5, 25, 50, 100, 500, 1000];

const cadenceOptions = [
  {
    value: 1,
    label: "1 (second)",
  },
  {
    value: 2,
    label: "2 (seconds)",
  },
  {
    value: 3,
    label: "3 (seconds)",
  },
  {
    value: 4,
    label: "4 (seconds)",
  },
  {
    value: 5,
    label: "5 (seconds)",
  },
  {
    value: 10,
    label: "10 (seconds)",
  },
  {
    value: 30,
    label: "30 (seconds)",
  },
  {
    value: 60,
    label: "1 (min)",
  },
  {
    value: 120,
    label: "2 (min)",
  },
  {
    value: 300,
    label: "5 (min)",
  },
  {
    value: 600,
    label: "10 (min)",
  },
];
