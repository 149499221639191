import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  // Project pages
  SurveyBuild,
  Delivery,
  QuestionDesign,
  Distributions,
  SMS,
  Link,
} from "pages";

import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
import { SurveyPreview } from "pages/projects/project/survey_build/preview/SurveyPreview";
import SurveyDetails2 from "pages/projects/project/survey_details/SurveyDetails2";
import SurveyResults from "pages/projects/project/results/SurveyResults";
import { AnonLinks } from "pages/projects/project/delivery/distributions/AnonLinks";
import { PersonalLinks } from "pages/projects/project/delivery/distributions/PersonalLinks";
import EmailBuilder from "pages/projects/project/delivery/distributions/EmailBuilder/Builder";

export const ProjectRoutes = ({
  setHideNav,
  survey,
  direction,
  setShowSettings,
  collapse,
  collapsed,
  onExportDeliveries,
  user,
  roles,
  organization,
  updateRoute,
}) => {
  const location = useLocation();

  const navigate = useNavigate();
  function onBuildEmail(id) {
    navigate("email/" + id);
  }

  function onExitEmail() {
    navigate("delivery/messages");
  }

  return (
    <>
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Navigate to={"survey-build"} />} />
        {roles.canCreateEditOrgSurveys && (
          <Route
            path="survey-build/*"
            element={
              <SurveyBuild
                roles={roles}
                direction={direction}
                collapse={collapse}
                updateRoute={updateRoute}
              />
            }
          />
        )}
        {roles.canViewOrgSurveys && (
          <Route path="preview" element={<SurveyPreview roles={roles} />} />
        )}
        {roles.canViewOrgSurveys && (
          <>
            <Route
              path="delivery/*"
              element={
                <Delivery
                  roles={roles}
                  survey={survey}
                  direction={direction}
                  onBuildEmail={onBuildEmail}
                  collapsed={collapse}
                  collapse={collapse}
                  updateRoute={updateRoute}
                />
              }
            />

            <Route
              path="email/:emailId"
              element={
                <EmailBuilder
                  roles={roles}
                  survey={survey}
                  onBack={onExitEmail}
                  user={user}
                  onExportDeliveries={onExportDeliveries}
                  updateRoute={updateRoute}
                />
              }
            />
          </>
        )}

        {roles.canViewOrgSurveys && (
          <>
            <Route
              path="surveyresults/:reportid"
              element={
                <SurveyResults
                  roles={roles}
                  direction={direction}
                  org={organization}
                  user={user}
                  collapse={collapse}
                  collapsed={collapsed}
                  updateRoute={updateRoute}
                />
              }
            />
          </>
        )}

        <Route
          path="survey-details"
          element={
            <SurveyDetails2
              userRoles={roles}
              currUser={user}
              setHideNav={setHideNav}
              direction={direction}
              setShowSurveySettings={setShowSettings}
              collapsed={collapsed}
              collapse={collapse}
              updateRoute={updateRoute}
            />
          }
        />
      </Routes>
    </>
  );
};

export const SurveyBuildRoutes = (project) => {
  const getCurrRole = useFetchRole();

  return (
    <>
      {getCurrRole.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="questions" />} />
          {/* <Route path="survey-details" element={<SurveyDetails />} /> */}
          <Route
            path="questions"
            element={<SurveyBuild roles={getCurrRole.data.role} />}
          />
          <Route
            path="design"
            element={<QuestionDesign roles={getCurrRole.data.role} />}
          />
        </Routes>
      )}
    </>
  );
};

export const DeliveryRoutes = ({ survey, onBuildEmail }) => {
  const getCurrRole = useFetchRole();
  const getCurrUser = useGetCurrentUser();

  return (
    <>
      {getCurrRole.isSuccess && getCurrUser.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="messages" />} />
          <Route
            path="messages"
            element={
              <Distributions
                roles={getCurrRole.data.role}
                user={getCurrUser.data.me}
                survey={survey}
                onBuildEmail={onBuildEmail}
              />
            }
          />
          <Route
            path="messages/:distId"
            element={
              <Distributions
                roles={getCurrRole.data.role}
                user={getCurrUser.data.me}
                survey={survey}
                onBuildEmail={onBuildEmail}
              />
            }
          />
          <Route
            path="survey-link"
            element={
              <AnonLinks
                roles={getCurrRole.data.role}
                userId={getCurrUser.data.me.id}
                survey={survey}
              />
            }
          />
          <Route
            path="personal-links"
            element={
              <PersonalLinks
                roles={getCurrRole.data.role}
                userId={getCurrUser.data.me.id}
              />
            }
          />
          {/* <Route
            path="stats/*"
            element={<Stats roles={getCurrRole.data.role} />}
          /> */}
          {/* <Route path="distributions/email" element={<Email />} />
      <Route path="distributions/link" element={<Link />} />
      <Route path="distributions/sms" element={<SMS />} /> */}
        </Routes>
      )}
    </>
  );
};

export const DistributionRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="email" />} />
      <Route path="link" element={<Link />} />
      <Route path="sms" element={<SMS />} />
    </Routes>
  );
};

export const SurveysRoutes = () => {
  const getCurrRole = useFetchRole();
  const getCurrUser = useGetCurrentUser();

  return (
    <>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="OrganizationProjects" />} />
        </Routes>
      )}
    </>
  );
};
