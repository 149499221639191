// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import { AllContacts, Manual, Upload, ImportedCleaner, Audiences } from "pages";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
import Audience from "pages/contacts/audiences/Audience";

export const ContactRoutes = ({
  onExport,
  updateRoute,
  user,
  roles,
  organization,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="all-contacts" />} />
      {roles?.canViewContacts && (
        <Route
          path="all-contacts"
          element={
            <AllContacts
              user={user}
              organization={organization}
              roles={roles}
              onExport={onExport}
              updateRoute={updateRoute}
            />
          }
        />
      )}

      {roles?.canViewOrgAudiences && (
        <Route
          path="/audiences/*"
          element={
            <Audiences
              user={user}
              organization={organization}
              roles={roles}
              updateRoute={updateRoute}
            />
          }
        />
      )}
      {roles?.canViewOrgAudiences && (
        <Route
          path="/audiences/:id"
          element={
            <Audience
              user={user}
              organization={organization}
              roles={roles}
              onExport={onExport}
              updateRoute={updateRoute}
            />
          }
        />
      )}
    </Routes>
  );
};

export const AddContactRoutes = () => {
  // const getCurrUser = useGetCurrentUser();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="updloadcontacts" />} />
      <Route path="manual" element={<Manual />} />
      <Route path="uploadcontacts" element={<Upload />} />
      <Route path=":id" element={<ImportedCleaner />} />
    </Routes>
  );
};
