import React, { useEffect, useState } from "react";
import { useFetchSurveyTagsGql } from "api/resources/organization/surveytags";
import {
  useCreateSurveyTemplate,
  useDeleteProjectGql,
  useFetchSharedBySurveyId,
  useUpdateProjectDashProperties,
  useUpdateProjectStatus,
} from "api/resources/projects/projects";
import styles from "./SurveySettings.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import {
  SelectField,
  SelectFieldCustom,
  TextFieldSimple,
} from "components/inputs";
import { SelectFieldCalendar } from "components/inputs/input_fields/SelectFieldCalendar/SelectFieldCalendar";
import Button from "components/Button/Button";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Loading } from "components/Loading/Loading";
import ShareSelect from "components/inputs/input_fields/ShareSelect/ShareSelect";
import {
  useDeleteSharedSurvey,
  useFetchOrgsShareSurvey,
  useFetchUsersShareSurvey,
} from "api/resources/organization/share";
import { ProgressBar } from "components/QuestionConstruction/ProgressBar/ProgressBar";
import { useNavigate } from "react-router-dom";
import CSVReader from "components/DataUploader/uploader";
import { SurveyTypes } from "pages/projects/NewSurveySetUp/CreateSurvey";
import {
  updateQsChangeType,
  useFetchQuestionsGql,
} from "api/resources/projects/questions";
import { shortId } from "components/tables/EditableTable/utils";
import Question from "components/Survey/UXQuestions/Question";

function SurveySettings({ userRoles, survey, show, setShow, user }) {
  let id = survey.id;
  const updateProject = useUpdateProjectDashProperties();
  const removeSharedAccess = useDeleteSharedSurvey();
  const surveyTagsQuery = useFetchSurveyTagsGql();
  const peopleWithoutAccess = useFetchUsersShareSurvey(survey.id);
  const orgsWithoutAccess = useFetchOrgsShareSurvey(survey.id);
  const deleteProject = useDeleteProjectGql();
  const updateStatus = useUpdateProjectStatus();
  const peopleWithAccess = useFetchSharedBySurveyId(survey.id);
  const createTemplate = useCreateSurveyTemplate();
  const [projName, setProjName] = useState(survey.name);
  const [description, setDescription] = useState(survey.description);
  const [scheduledStartDate, setScheduledStartDate] = useState();
  const [tags, setTags] = useState();
  const [showImport, setShowImport] = useState(false);
  const [statusChanging, setStatusChanging] = useState(false);
  const [oldid, setoldid] = useState([]);
  const [savedStatus, setSavedStatus] = useState("");
  const [status, setStatus] = useState(survey.status);
  const [surveyInterval, setInterval] = useState(survey.sendInterval);

  const [templateName, setTemplateName] = useState("");
  const [templateDesc, setTemplateDesc] = useState("");
  const [showTemplate, setShowTemplate] = useState(true);
  const [template, setTemplate] = useState(false);
  const [type, setType] = useState(survey.type);
  const [who, setWho] = useState(survey.audienceType);
  const [checkNPS, setCheckNPS] = useState(false);
  const [useGPS, setUseGPS] = useState(survey.useGPS);
  const [creatingTemplate, setCreatingTemplate] = useState(false);

  const [toDelete, setToDelete] = useState([]);

  const ConactTypes = ["Customer", "Patient", "Employee", "Market", "Other"];

  function handleSave() {
    setSavedStatus(
      <div>
        <i className="bi bi-check-lg"></i>Saving...
      </div>
    );

    let tagArray = null;
    if (tags) {
      tagArray = [];
      for (let tag of tags) {
        if (tag.__isNew__) {
          tagArray.push(tag?.value);
        } else {
          tagArray.push(tag?.id);
        }
      }
    }

    // let usersWithAccess = [...withAccess];
    // let organizationsWithAccess = [...withAccessOrgs];
    // let finalUsersWithAccess = [];
    // for (let userWithAccess of usersWithAccess) {
    //   if (userWithAccess.id != user.id) {
    //     let newUser = {
    //       id: userWithAccess.id,
    //       projectId: survey.id,
    //       userId: userWithAccess.value,
    //       status: userWithAccess.status,
    //     };
    //     finalUsersWithAccess.push(newUser);
    //   }
    // }

    // for (let WithAccess of organizationsWithAccess) {
    //   let newOrg = {
    //     id: WithAccess.id,
    //     projectId: survey.id,
    //     organizationId: WithAccess.value,
    //     status: WithAccess.status,
    //   };
    //   finalUsersWithAccess.push(newOrg);
    // }

    if (toDelete?.length > 0) {
      for (let sharedSurvey of toDelete) {
        removeSharedAccess.mutate({
          sharedUserId: sharedSurvey.id,
        });
      }
    }
    updateProject.mutate(
      {
        updateProjectId: id,
        data: {
          name: projName,
          description: description,
          startedAt: scheduledStartDate ? scheduledStartDate : undefined,
          tags: tagArray,
          type: type,
          sendInterval: surveyInterval,
          useGPS: useGPS,
          audienceType: who,
          // sharedSurveys: finalUsersWithAccess,
        },
      },
      {
        onSuccess: (data) => {
          setSavedStatus(
            <div>
              <i className="bi bi-check-lg"></i> Saved
            </div>
          );
        },
      }
    );

    // setTimeout(() => {
    //     setSavedStatus("Save Changes");
    //   }, 5000);
  }

  function handleStartDateChange(date) {
    let newDate = new Date(date.fullText);
    setScheduledStartDate(newDate);
  }

  function handleTagChange(chosenTags) {
    setTags(chosenTags);
  }

  function onUploadInternal() {
    let oldIds = oldid.map((val) => val.value);
    oldIds = JSON.stringify(oldIds);
    uploadInternalResults.mutate({
      newsurveyId: id,
      oldsurveyId: oldIds,
    });
    setShowImport(false);
    setShow(true);
    // fetchProjectQuery.refetch();
  }

  function changeStatus(newStatus) {
    if (window.confirm("Confirm a Project Status update to: " + newStatus)) {
      updateStatus.mutate(
        {
          projectId: id,
          status: newStatus,
        },
        {
          onSuccess: (data) => {
            setStatusChanging(false);

            if (data?.changeProjectStatus?.status) {
              setStatus(data.changeProjectStatus.status);
            }
            // fetchProjectQuery.refetch();
            // reportInfo.refetch();
          },
          onError: (data) => {
            console.log(data);
          },
        }
      );
      setStatusChanging(true);
    }
  }

  function createAsTemplate() {
    if (
      window.confirm(
        "Creating a template out of this survey means changes to this survey will be reflected in any new surveys created from the template from then on."
      )
    ) {
      createTemplate.mutate(
        {
          data: {
            name: templateName,
            description: templateDesc,
            projectId: survey.id,
          },
        },
        {
          onSuccess: (data) => {
            setShowTemplate(false);
          },
        }
      );
    }
  }

  let navigate = useNavigate();

  function handleDelete() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this survey?")) {
      deleteProject.mutate(
        {
          projectId: id,
        },
        {
          onSuccess: () => {
            navigate("/surveys");
            // refetchN();
          },
        }
      );
    }
  }

  function handleChangeType(chosen) {
    if (chosen.value === "NPS" || chosen.value === "nps") {
      setCheckNPS(true);
    } else {
      setType(chosen ? chosen.value : undefined);
    }
  }

  // function checkOwner() {
  //   if (survey.userId === user.id) {
  //     return {
  //       ...user,
  //       status: "owner",
  //       name: user.firstName + " " + user.lastName + " (you)",
  //     };
  //   }
  //   return null;
  // }

  // function giveAccess(users) {
  //   createShareSurvey.mutate(
  //     {
  //       data: {
  //         users: users,
  //         surveyId: survey.id,
  //       },
  //     },
  //     {
  //       onSuccess: (data) => {
  //         console.log(data?.sharedSurvey);
  //       },
  //     }
  //   );
  // }

  // function handleDeleteAccess(item) {
  //   let temp = [...toDelete];
  //   temp.push(item);
  //   setToDelete(temp);
  // }

  return (
    <>
      {checkNPS && (
        <CheckForNPS
          checkNPS={checkNPS}
          survey={survey}
          setCheckNPS={setCheckNPS}
          setType={setType}
        ></CheckForNPS>
      )}
      <ReactModal
        show={show && !showImport && !checkNPS}
        modalStyle={{
          height: "100%",
          width: "100%",
          maxWidth: "900px",
          // padding: "1em",
        }}
        onClose={() => setShow(false)}
        dark
        // rightStyle
      >
        {" "}
        {surveyTagsQuery.isSuccess &&
          peopleWithAccess.isSuccess &&
          peopleWithoutAccess.isSuccess &&
          orgsWithoutAccess.isSuccess && (
            <>
              <div className={styles.settingContainer}>
                <div className={styles.settingHeader}>
                  <div className={styles.title}>
                    Survey Settings:{" "}
                    <span className={styles.surveyName}>"{survey.name}"</span>
                  </div>
                </div>

                <div className={styles.boxesContainer}>
                  <div className={styles.header_4} style={{}}>Survey Details</div>
                  <div className={styles.boxes} style={{flex: 1, minWidth: "300px"}}>
                    <div className={styles.setting}>
                      <TextFieldSimple
                        label="Survey Name"
                        value={projName}
                        placeholder="Survey Name"
                        onChange={(val) => setProjName(val)}
                        disable={userRoles?.canCreateEditOrgSurveys ? false : true}
                      ></TextFieldSimple>
                    </div>
                    <div className={styles.setting}>
                      <TextFieldSimple
                        label="Survey Description"
                        value={description}
                        // placeholder="Description..."
                        onChange={(val) => setDescription(val)}
                        disable={userRoles?.canCreateEditOrgSurveys ? false : true}
                      ></TextFieldSimple>
                    </div>
                    <div className={styles.setting}>
                      <SelectFieldCustom
                        selectMultiple
                        label="Survey Tags"
                        onChange={handleTagChange}
                        options={surveyTagsQuery.data?.surveyTagByOrgId}
                        values={survey?.surveytag}
                        placeholder="Select tags or start typing to create one..."
                        disable={userRoles?.canCreateEditOrgSurveys ? false : true}
                      ></SelectFieldCustom>
                    </div>
                    <div className={styles.setting}>
                      {userRoles.canCreateEditOrgSurveys && (
                        <SelectFieldCalendar
                          // icon={calenderIcon}
                          label="Started On"
                          startDate={survey?.startedAt}
                          onChange={handleStartDateChange}
                          placeholder="Launched Date..."
                        ></SelectFieldCalendar>
                      )}
                    </div>
                    <div className={styles.setting}>
                      <SelectFieldCustom
                        label="Survey Type"
                        onChange={(chosen) => handleChangeType(chosen)}
                        options={SurveyTypes.map((val) => {
                          return { value: val, label: val };
                        })}
                        value={type ? { value: type, label: type } : undefined}
                        isClearable
                        placeholder="Choose a survey type..."
                      ></SelectFieldCustom>
                    </div>
                    <div className={styles.setting}>
                      <SelectFieldCustom
                        label="Audience Type"
                        onChange={(chosen) =>
                          setWho(chosen ? chosen.value : undefined)
                        }
                        options={ConactTypes.map((val) => {
                          return { value: val, label: val };
                        })}
                        value={who ? { value: who, label: who } : undefined}
                        isClearable
                        placeholder="Who will you be sending to..."
                      ></SelectFieldCustom>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        gap: ".3em",
                        alignItems: "center",
                      }}
                    >
                      <Label
                        style={{ alignItems: "center", paddingTop: "0em" }}
                        className={styles.label}
                      >
                        View Survey Health
              
                        :
                      </Label>
                      <ToggleSwitch
                        startChecked={useGPS}
                        handleCheck={setUseGPS}
                      ></ToggleSwitch>
                    </div> */}
                  </div>
                </div>
                <div className={styles.boxes} style={{ padding: "1rem" }}>
                  {/* {survey.isProgram && (
                    <>
                      <HorizontalBar height={2}></HorizontalBar>
                      <div className={styles.setting}>
                        <Label
                          tooltipText={
                            "This determines how often you will be sending your survey out. This can be changed later."
                          }
                          labelIcon={<i className="bi-info-circle"></i>}
                        >
                          Send Interval
                        </Label>

                        <div style={{ width: "100%" }}>
                          <SelectField
                            onChange={(chosen) =>
                              setInterval(chosen ? chosen.value : undefined)
                            }
                            options={frequencies}
                            value={
                              surveyInterval
                                ? {
                                    value: surveyInterval,
                                    label: surveyInterval,
                                  }
                                : undefined
                            }
                            isClearable
                            placeholder="Choose a survey type..."
                          ></SelectField>
                        </div>
                      </div>
                    </>
                  )} */}

                  {showTemplate && template && (
                    <div className={styles.surveyTemplateContainer}>
                      <div className={styles.label}>
                        Use as a Survey Template
                      </div>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <TextFieldSimple
                          value={templateName}
                          placeholder="Name"
                          onChange={(val) => setTemplateName(val)}
                          onEnter={(val) => setTemplateName(val)}
                          disable={userRoles?.canCreateEditOrgSurveys ? false : true}
                        ></TextFieldSimple>
                        <TextFieldSimple
                          value={templateDesc}
                          placeholder="Description"
                          onBlur={(val) => setTemplateDesc(val)}
                          disable={userRoles?.canCreateEditOrgSurveys ? false : true}
                        ></TextFieldSimple>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "15px",
                          gap: "10px",
                        }}
                      >
                        <Button
                          red
                          onClick={() => {
                            setTemplate(false);
                            setTemplateName("");
                            setTemplateDesc("");
                          }}
                          shadow
                        >
                          Cancel Template
                        </Button>
                        {templateName && (
                          <Button orange onClick={createAsTemplate} shadow>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {creatingTemplate && (
                                <>
                                  Creating Template{" "}
                                  <Loading height="20" width="20" />
                                </>
                              )}
                              {!creatingTemplate && <>Create Template</>}
                            </div>
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <UploadProgress
                  progress={
                    survey.resultsUploading
                      ? JSON.parse(survey.resultsUploading)
                      : null
                  }
                ></UploadProgress>
              </div>
              <div className={styles.button} style={{ gap: "2em" }}>
                {" "}
                {user.isSuperAdmin && (
                  <Button
                    blue
                    onClick={() => {
                      setShowImport(true);
                      setShow(false);
                    }}
                    disable={false}
                    shadow
                    link
                  >
                    <div className={styles.button}>
                      <i className="bi-cloud-upload"></i> Import Results{" "}
                    </div>
                  </Button>
                )}
                {status === "Draft" && (
                  <Button onClick={handleDelete} red shadow link>
                    <div className={styles.buttonred}>
                      {" "}
                      <i className="bi-trash"></i> Delete Survey
                    </div>
                  </Button>
                )}
              </div>
              {/* <HorizontalBar height={"2px"}></HorizontalBar> */}

              {/* <div className={styles.settingContainer}> */}
              {/* <div className={styles.headerContainer}>
                <div className={styles.settingHeader}>
                  <div className={styles.title}>Permission Access</div>
                  <div className={styles.description}>
                    Give ownership, editing access or view access to a user,
                    team or organization.
                  </div>
                </div>
                <div className={styles.publish}>
                  <span>
                    Survey Is Currently Published. This means that everyone that
                    you have given access can view it
                  </span>
                  <Button shadow disable>
                    Unpublish
                  </Button>
                </div>
              </div> */}
              {/* <div className={styles.access}>
                <SurveyAccessContainer
                  usersWithoutAccess={peopleWithoutAccess?.data?.users}
                  usersWithAccess={peopleWithAccess?.data?.users}
                  orgsWithoutAccess={orgsWithoutAccess?.data.organizations}
                  orgsWithAccess={orgsWithAccess?.data?.users}
                  setWithAccessMain={setWithAccess}
                  survey={survey}
                  checkOwner={checkOwner}
                  giveAccess={giveAccess}
                  onDelete={handleDeleteAccess}
                  setWithAccessOrgsMain={setWithAccessOrgs}
                ></SurveyAccessContainer>
              </div> */}
              {/* </div> */}
              <div className={styles.saveBtn2}>
                <div className={styles.saveBtn}>
                  {!template && (
                    <Button
                      seafoam
                      onClick={() => {
                        setTemplate(true);
                      }}
                      disable={false}
                      shadow
                    >
                      Use As Template
                    </Button>
                  )}
                  {status !== "Open" && (
                    <Button
                      green
                      onClick={() => changeStatus("Open")}
                      disable={userRoles.canCreateEditOrgSurveys ? false : true}
                      shadow
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {!statusChanging && <>Open Survey</>}
                        {statusChanging && (
                          <>
                            Opening Survey
                            <Loading height={30} width={30}></Loading>
                          </>
                        )}
                      </div>
                    </Button>
                  )}
                  {status === "Open" && (
                    <Button
                      yellow
                      onClick={() => changeStatus("Closed")}
                      shadow
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {!statusChanging && <>Close Survey</>}
                        {statusChanging && (
                          <>
                            Closing Survey
                            <Loading height={30} width={30}></Loading>
                          </>
                        )}
                      </div>
                    </Button>
                  )}
                </div>
                <div className={styles.buttonsContainer}>
                  <Button shadow red onClick={() => setShow(false)}>
                    {" "}
                    Close
                  </Button>
                  {userRoles.canCreateEditOrgSurveys && (
                    <Button onClick={handleSave} shadow>
                      {!updateProject.isLoading &&
                        !updateProject.isSuccess &&
                        !updateProject.isError &&
                        savedStatus}
                      {updateProject.isLoading && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Loading height="20" width="20"></Loading>Saving
                        </div>
                      )}
                      {updateProject.isSuccess && (
                        <div>
                          <i className="bi bi-check-lg"></i>Saved
                        </div>
                      )}
                      {updateProject.isError && "Error on save"}
                      {!savedStatus && "Save Changes"}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
      </ReactModal>
      <ReactModal
        show={showImport && !show}
        onClose={() => {
          setShowImport(false);
          setShow(true);
        }}
        dark
        modalStyle={{
          borderRadius: "1em",
          padding: "1em",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // overflow: "visible",
          height: "95%",
          width: "900px",
          gap: "1em",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <CSVReader
            currUser={user}
            progress={
              survey.resultsUploading
                ? JSON.parse(survey.resultsUploading)
                : null
            }
            survey={survey}
          ></CSVReader>
        </div>
        {/* <div className={styles.or}>OR</div> */}
        {/* <div className={styles.internalResults}>
          <div className={styles.header}>Import Internal Results</div>
          <div className={styles.textHolders}>
            <OldSurveyTextField
              oldid={oldid}
              setoldid={setoldid}
              initid={survey.oldSurveyId}
            ></OldSurveyTextField>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button height={35} onClick={onUploadInternal} shadow>
              Submit Upload
            </Button>
          </div>
        </div> */}
      </ReactModal>
    </>
  );
}

export default SurveySettings;

function SurveyAccessContainer({
  usersWithAccess,
  usersWithoutAccess,
  survey,
  checkOwner,
  setWithAccessMain,
  setWithAccessOrgsMain,
  onDelete,
  orgsWithoutAccess,
  orgsWithAccess,

  //   giveAccess,
}) {
  const [withAccess, setWithAccess] = useState(
    initializeUsers(usersWithAccess)
  );
  const [withAccessOrgs, setWithAccessOrgs] = useState(
    initializeOrgs(orgsWithAccess ? orgsWithAccess : [])
  );

  function initializeUsers(array) {
    let temp = [...array];

    for (let i = 0; i < temp?.length; i++) {
      temp[i].value = temp[i].userId;
      temp[i].email = temp[i]?.user?.email;
      temp[i].label = temp[i]?.user?.firstName + " " + temp[i]?.user?.lastName;
      temp[i].name = temp[i]?.user?.firstName + " " + temp[i]?.user?.lastName;
    }
    return temp;
  }

  function initializeOrgs(arrays) {
    let temp = [...arrays];

    for (let i = 0; i < temp?.length; i++) {
      temp[i].value = temp[i].organization?.id;
      temp[i].type = "Organization";
      temp[i].label = temp[i].organization?.name;
      temp[i].name = temp[i].organization?.name;
    }
    return temp;
  }

  function onChangeAccess(users) {
    setWithAccess(users);
    setWithAccessMain(users);
  }
  function onChangeAccessOrgs(orgs) {
    setWithAccessOrgs(orgs);
    setWithAccessOrgsMain(orgs);
  }

  useEffect(() => {
    setWithAccessMain(withAccess);
    setWithAccessOrgsMain(withAccessOrgs);
  }, []);

  return (
    <>
      <ShareSelect
        options={orgsWithoutAccess}
        data={orgsWithAccess}
        placeholder={"Add people to give  access..."}
        selectMultiple
        onChange={onChangeAccessOrgs}
        onButtonClick={() => console.log("Click!")}
        buttonText="Add"
        label={"Organizations/Teams With Access"}
        labelbottom
        owner={
          survey?.organization
            ? { ...survey?.organization, status: "roles" }
            : null
        }
        onDelete={onDelete}
        type={"organization"}
      ></ShareSelect>
      <ShareSelect
        options={usersWithoutAccess}
        data={usersWithAccess}
        placeholder={"Add people to give  access..."}
        selectMultiple
        onChange={onChangeAccess}
        onButtonClick={() => console.log("Click!")}
        buttonText="Add"
        label={"People With Access"}
        labelbottom
        owner={checkOwner()}
        onDelete={onDelete}
      ></ShareSelect>
    </>
  );
}

function OldSurveyTextField({ oldid, setoldid, initid }) {
  useEffect(() => {
    if (initid) {
      let ids = JSON.parse(initid);
      let idsarrayobject = ids.map((id) => {
        return { value: id, label: id };
      });
      setoldid(idsarrayobject);
    } else {
      setoldid(null);
    }
  }, []);

  // function getids() {
  //   if (oldid && oldid.length === 0 && initid && typeof initid === "string") {
  //     let ids = JSON.parse(initid);
  //     let idsarrayobject = ids.map((id) => {
  //       return { value: id, label: id };
  //     });
  //     return idsarrayobject;
  //   } else {
  //     return oldid;
  //   }
  // }

  return (
    <SelectField
      label="Old Survey ID"
      value={oldid}
      // defaultValue={getids()}
      onChange={(val) => setoldid(val)}
      options={[]}
      selectMultiple
    ></SelectField>
  );
}

export function UploadProgress({ progress, message }) {
  return (
    <>
      {progress && (
        <div className={styles.progressContainer}>
          <span>{message ? message : "Uploading Results..."}</span>
          <ProgressBar
            numQuestions={progress.total}
            numAnswered={progress.current}
            settings={{ showProgBar: true }}
          ></ProgressBar>
        </div>
      )}
    </>
  );
}

function CheckForNPS({ checkNPS, survey, setCheckNPS, setType }) {
  const getQuestions = useFetchQuestionsGql(survey.id);
  const [settings, setSettings] = useState(JSON.parse(survey.designSettings));
  const [NPSQid, setNPSQid] = useState();
  const [commentQid, setCommentQid] = useState();
  const [generatedQs, setGeneratedQs] = useState([]);

  const [answerMap, setAnswerMap] = useState(new Map());
  const updateQs = updateQsChangeType();

  function handleGenerate() {
    let temp = [];
    let scoreId = shortId() + "new";
    let score = {
      questionText:
        "How likely are you to reccommend ... to a peer or colleague.",
      projectId: survey.id,
      id: scoreId,
      type: "NumberScale",
      scaleQuestion: {
        id: "jdsfpoij",
        max: 10,
        min: 0,
        step: 1,
      },
    };
    let commentId = shortId() + "new";
    let comment = {
      questionText: "Why did you give us that rating?",
      projectId: survey.id,
      id: commentId,
      type: "Text",
      textQuestion: {
        id: "jdsfpoij",
        placeholder: "Enter Text...",
      },
    };

    temp = [score, comment];
    setNPSQid(scoreId);
    setCommentQid(commentId);
    setGeneratedQs(temp);
  }

  function handleSave() {
    updateQs.mutate(
      {
        commentQId: commentQid.includes("new") ? "new" : commentQid,
        scoreQId: NPSQid.includes("new") ? "new" : NPSQid,
        surveyId: survey.id,
      },
      {
        onSuccess: () => {
          setType("NPS");
          setCheckNPS(false);
        },
      }
    );
  }

  return (
    <ReactModal
      show={checkNPS}
      onClose={() => setCheckNPS(false)}
      modalStyle={{
        width: "50%",
        borderRadius: "1em",
        overflow: "auto",
      }}
    >
      <div className={styles.settingHeader} style={{ padding: "1em" }}>
        <div className={styles.title}>Choose NPS Questions</div>
        <div className={styles.description}>
          In order to change this survey into an NPS survey type you need to
          choose two questions to be your "NPS score" question and "NPS comment"
          question. <br />
          - NPS Score must be a scale question from 0-10
          <br />
          - NPS Comment must be a free response question
          <br />
          If you click "Generate Questions" this will auto generate these two
          questions for you as the first two questions of the survey. You cannot
          change the text of the questions or move them around in your survey
          until you save your changes and then go into the survey builder. You
          must have both questions in order to change type
        </div>
      </div>
      <div className={styles.checkforquestions}>
        {getQuestions.isSuccess &&
          getQuestions.data &&
          getQuestions.data.QuestionByProjectID.length > 0 &&
          [...generatedQs, ...getQuestions.data.QuestionByProjectID].map(
            (q) => (
              <div className={styles.question}>
                {" "}
                <div className={styles.buttons}>
                  {NPSQid != q.id &&
                    q.scaleQuestion &&
                    q.scaleQuestion.min === 0 &&
                    q.scaleQuestion.max &&
                    q.scaleQuestion.step === 1 && (
                      <Button shadow onClick={() => setNPSQid(q.id)}>
                        Set as score question
                      </Button>
                    )}
                  {commentQid != q.id && q.textQuestion && (
                    <Button shadow onClick={() => setCommentQid(q.id)}>
                      Set as comment question
                    </Button>
                  )}
                  {!(
                    q.scaleQuestion &&
                    q.scaleQuestion.min === 0 &&
                    q.scaleQuestion.max &&
                    q.scaleQuestion.step === 1
                  ) &&
                    !q.textQuestion && <div>Not Eligable</div>}
                  {commentQid === q.id && (
                    <div>This is your chosen comment question</div>
                  )}
                  {NPSQid === q.id && (
                    <div>This is your chosen score question</div>
                  )}
                </div>
                <div
                  className={`${styles.questionContainer} ${styles.hover}
               `}
                >
                  {NPSQid === q.id && (
                    <div className={styles.tag}>
                      <Button yellow shadow>
                        Score Question
                      </Button>
                    </div>
                  )}
                  {commentQid === q.id && (
                    <div className={styles.tag}>
                      <Button yellow shadow>
                        Comment Question
                      </Button>
                    </div>
                  )}

                  <Question
                    question={q}
                    key={q.id}
                    onAnswered={() => console.log("answer")}
                    onRetract={() => console.log("retract")}
                    color={settings.baseColor}
                    answerMap={answerMap}
                    settings={settings}
                    otherMap={answerMap}
                    fontSize={"1em"}
                  ></Question>
                </div>
              </div>
            )
          )}
      </div>
      <div className={styles.generate}>
        <Button shadow orange onClick={() => handleGenerate()}>
          Generate Questions
        </Button>
        {NPSQid && commentQid && (
          <Button shadow onClick={handleSave}>
            Save & Change Type
          </Button>
        )}
      </div>
    </ReactModal>
  );
}
