import {
  Global_Holidays,
  matchesHoliday,
  matchesNearby,
  US_Holidays,
} from "../Scheduler";
import styles from "./Calendar.module.scss";
import { useEffect, useRef, useState } from "react";

export function Calendar({ date, onChange, b2b, US }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth());

  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);

  const [top, setTop] = useState(0);

  const ref = useRef();
  const calendarRef = useRef();

  useEffect(() => {
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth());
  }, [currentDate]);

  function getFirstWeek() {
    const startOfMonth = new Date(year, month, 1);
    const dayOfWeek = startOfMonth.getDay();

    let days = [];
    for (let i = 0; i < dayOfWeek; i++) {
      let day = new Date(startOfMonth);
      day.setDate(startOfMonth.getDate() - (dayOfWeek - i));
      days.push(day);
    }

    for (let i = 0; i < 7 - dayOfWeek; i++) {
      let day = new Date(startOfMonth);
      day.setDate(startOfMonth.getDate() + i);
      days.push(day);
    }

    return days;
  }

  function getWeeks() {
    const firstWeek = getFirstWeek();
    let weeks = [firstWeek];
    let lastDay = firstWeek[6];
    let done = false;
    while (!done) {
      let week = [];

      for (let i = 1; i <= 7; i++) {
        let day = new Date(lastDay);
        day.setDate(lastDay.getDate() + i);
        week.push(day);
      }
      weeks.push(week);

      lastDay = week[6];
      let nextDay = new Date(lastDay);
      nextDay.setDate(lastDay.getDate() + 1);

      done = lastDay.getMonth() != month || nextDay.getMonth() != month;
    }

    return weeks;
  }

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getYears() {
    let today = new Date();
    let years = [];
    for (let i = 0; i < 11; i++) {
      years.push(today.getFullYear() + i);
    }
    return years;
  }

  const weeks = getWeeks();

  const goToPreviousMonth = () => {
    setCurrentDate(new Date(year, month - 1, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(new Date(year, month + 1, 1));
  };

  function onClickOut(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setShowMonths(false);
      setShowYears(false);
      setTop(0);
      document.removeEventListener("click", onClickOut, false);
    }
  }

  function setUpDrop(e, setShow) {
    if (!showMonths && !showYears) {
      let spot = e.target;
      let cal = calendarRef.current;
      if (spot && cal) {
        setTimeout(
          () => document.addEventListener("click", onClickOut, false),
          10
        );
        setShow(true);

        let spotBox = spot.getBoundingClientRect();
        let calBox = cal.getBoundingClientRect();

        let dist = spotBox.bottom - calBox.top + 5;
        setTop(dist);
      }
    }
  }

  function onClickDropDown(i) {
    if (showMonths) {
      setShowMonths(false);
      setMonth(i);
    } else {
      setShowYears(false);
      let thisYear = new Date().getFullYear();
      setYear(thisYear + i);
    }
  }

  function chooseDay(day) {
    let newDate = new Date(day);
    newDate.setHours(date.getHours());
    newDate.setMinutes(date.getMinutes());
    onChange(newDate);
  }

  function getBestPracticeShade(day) {
    const weekday = day.getDay();

    if (b2b) {
      if (weekday == 1 || weekday == 5 || weekday == 6) return styles.red;
    } else if (weekday == 6 || weekday == 5) {
      return styles.red;
    }

    let holiday = findHoliday(day);
    if (holiday) return styles.red;

    let nextTo = findNearbyHoliday(day);
    if (nextTo) return styles.red;

    if (b2b) {
      if (weekday == 0 || weekday == 4) return styles.yellow;
    }

    return styles.green;
  }

  const holidays = [...Global_Holidays, ...(US ? US_Holidays : [])];

  function findNearbyHoliday(day) {
    for (let h of holidays) {
      if (matchesNearby(h, day)) return h;
    }

    return null;
  }

  function findHoliday(day) {
    for (let h of holidays) {
      if (matchesHoliday(h, day)) return h;
    }

    return null;
  }

  function isHoliday(day) {
    return findHoliday(day) ? true : false;
  }

  function getHoliday(day) {
    return findHoliday(day).name;
  }

  return (
    <div className={styles.container}>
      <div className={styles.calendar} ref={calendarRef}>
        <div className={styles.header}>
          <div className={styles.arrow} onClick={goToPreviousMonth}>
            <i className={"bi-chevron-compact-left"}></i>
          </div>
          <div className={styles.bigTitle}>
            <div
              className={styles.month}
              onClick={(e) => setUpDrop(e, setShowMonths)}
            >
              {/* {currentDate.toLocaleString("default", { month: "long" })} */}
              {months[month]}
            </div>
            <div
              className={styles.year}
              onClick={(e) => setUpDrop(e, setShowYears)}
            >
              {year}
            </div>
          </div>

          <div className={styles.arrow} onClick={goToNextMonth}>
            <i className={"bi-chevron-compact-right"}></i>
          </div>
        </div>

        <div className={styles.row}>
          {daysOfWeek.map((day) => (
            <div key={day} className={styles.dayName}>
              {day}
            </div>
          ))}
        </div>

        {weeks.map((week) => (
          <div className={styles.row}>
            {week.map((day) => (
              <div
                className={`${styles.day} ${
                  day.getMonth() == month ? "" : styles.otherMonth
                } ${getBestPracticeShade(day)} ${
                  day.getFullYear() == date.getFullYear() &&
                  day.getMonth() === date.getMonth() &&
                  day.getDate() === date.getDate()
                    ? styles.chosen
                    : ""
                }`}
                onClick={() => chooseDay(day)}
              >
                {day.getFullYear() == date.getFullYear() &&
                  day.getMonth() === date.getMonth() &&
                  day.getDate() === date.getDate() && (
                    <>
                      <i className={`bi-check2 ${styles.check}`}></i>
                    </>
                  )}
                <div className={styles.date}>{day.getDate()}</div>

                {isHoliday(day) && (
                  <div className={styles.holiday}>{getHoliday(day)}</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      {(showMonths || showYears) && (
        <div className={styles.dropDown} style={{ top: top + "px" }}>
          <div className={styles.dropDownList} ref={ref}>
            {(showMonths ? months : getYears()).map((m, i) => (
              <div
                className={styles.dropDownOption}
                onClick={() => onClickDropDown(i)}
              >
                {m}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
