import React, { useEffect, useState } from "react";
import styles from "./Register.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import Modal from "components/ReactModal/ReactModal";
import ContactSales from "pages/ContactReactionForms/ContactSales";

function AccountType({ setActiveStep }) {
  const navigate = useNavigate();
  const [contactSales, setContactSales] = useState(false);

  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.header_3} style={{ margin: "0" }}>
        The Reaction Platform Experience
      </div>
      <div className={styles.text_2} style={{ maxWidth: "800px" }}>
        To gain access to our full suite of solutions, we ask that you chat with
        one of our Reaction Experts. If you already have a join code click
        "Create Account" below.
      </div>
      <Button
          blue
          shadow
          width={150}
          onClick={() => {
            navigate("/register/your-details");
          }}
        >
          Create Account
        </Button>
      <FlexRow gap={"2rem"}>
        <div className={`${styles.box}`}>
          <FlexRow justify={"space-between"}>Premium Account Offers</FlexRow>
          <div
            className={`${styles.header_5} `}
            style={{ margin: "0", fontSize: "1.2rem" }}
          >
            Customized Plan
          </div>

          <div className={styles.list}>
            <div>
              <i className={"bi-check"}></i>1+ admin users
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>3+ additional users
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>Unrestricted access.
            </div>
            <div>
              <i className={"bi-check"}></i>Unlimited Survey Creation.
            </div>
            <div>
              <i className={"bi-check"}></i>Unlimited Email & SMS Messaging.
            </div>
            <div>
              <i className={"bi-check"}></i>On-Demand expertise and best
              practice help.
            </div>
          </div>
          <FlexRow wrap={"wrap"} style={{rowGap: "0"}}>
            <Button
              blue
              shadow
              style={{ margin: "1rem 0rem", whiteSpace: "nowrap" }}
              onClick={() => setContactSales(true)}
            >
              Contact Us
            </Button>
            <div>Inquire about a no risk trial today</div>
          </FlexRow>
        </div>
      </FlexRow>
      <div className={styles.text_2} style={{ maxWidth: "800px" }}>
        To learn more about what Reaction offers without talking to a Rep, go to <a className={styles.link} href="https://reactiondata.com">reactiondata.com</a>
      </div>
      {contactSales && (
        <Modal show={contactSales} onClose={() => setContactSales(false)} dark modalStyle={{width: "700px"}}>
          <ContactSales />
        </Modal>
      )}
    </div>
  );
}

export default AccountType;