import React from "react";
import styles from "./FlexRow.module.scss";

function FlexRow({
  align,
  justify,
  gap,
  style,
  start,
  className,
  onClick,
  id,
  children,
  fit,
  wrap,
}) {
  return (
    <div
      className={`${styles.flexrow} ${className}`}
      id={id}
      style={{
        ...{
          justifyContent: start ? "flex-start" : justify ? justify : "",
          gap: gap ? (typeof gap === "number" ? gap + "px" : gap) : "",
          alignItems: align ? align : "",
          width: fit ? "fit-content" : "",
          flexWrap: wrap ? 'wrap' : "",
        },
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default FlexRow;
