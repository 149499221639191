import React, { useEffect, useState } from "react";
import styles from "./CustomUpdates.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import {
  linkedAccountStatusOptions,
  statusOptions,
} from "./SalesforceSettings";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import axios from "axios";
import { Loading } from "components/Loading/Loading";
import { objectData, operators } from "assets/functions/Variables";
import {
  useCreateObjectConnection,
  useFetchCurrOrgsFieldsMut,
  useFetchObjectConnection,
  useFetchOrganizationLinkedAccounts,
  useUpdateObjectConnection,
} from "api/resources/organization/organization";
import { useFetchCalculations } from "api/resources/organization/calculations";
import { useNavigate, useParams } from "react-router-dom";

const reactionObjects = [
  { label: "Contact", value: "Contact" },
  // { label: "Survey", value: "Survey" },
  // { label: "Question", value: "Question" },
  // { label: "Answer", value: "Answer" },
  // { label: "Participation", value: "Participation" },
];

function CustomUpdates({ user, organization }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(organization.id);
  const fetchCalculations = useFetchCalculations();
  const object = useFetchObjectConnection(id);
  return (
    <>
      {(MyOrg.isLoading || fetchCalculations.isLoading || object.isLoading) && (
        <div style={{ width: "100%", height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}
      {MyOrg.isSuccess && fetchCalculations.isSuccess && object.isSuccess && (
        <CustomUpdatesContent
          user={user}
          organization={organization}
          linkedAccountId={
            MyOrg?.data?.organization?.linkedAccounts?.find(
              (a) => a.accountType === "Salesforce"
            )?.id
          }
          calculations={fetchCalculations?.data?.calculations}
          object={object?.data?.object}
        ></CustomUpdatesContent>
      )}
    </>
  );
}

function CustomUpdatesContent({
  user,
  organization,
  linkedAccountId,
  calculations,
  object,
}) {
  const [objectSettings, setObjectSettings] = useState({
    id: object?.id,
    connectionStatus: object?.connectionStatus,
    updateType: object?.updateType,
    updateFields: object?.updateFields
      ? JSON.parse(object.updateFields)
      : [{ calculation: "None" }],
    sendExistingData: object?.sendExistingData,
    createNewData: object?.createNewData,
    linkedAccountId: linkedAccountId,
    description: object?.description,
    updater: true,
    internalField: object?.internalField,
    externalField: object?.externalField,
    internalName: object?.internalName,
    externalName: object?.externalName,
  });

  const createObjectMutation = useCreateObjectConnection();
  const updateObjectMutation = useUpdateObjectConnection();
  const navigate = useNavigate();

  function saveObjectSettings() {
    let finalSettings = {
      ...objectSettings,
    };

    if (objectSettings?.id != "new") {
      finalSettings.updateFields = JSON.stringify(finalSettings.updateFields);
      const id = `${finalSettings.id}`;
      delete finalSettings.id;
      if (finalSettings.matchFields) {
        delete finalSettings.matchFields;
      }

      updateObjectMutation.mutate(
        {
          data: finalSettings,
          id: id,
        },
        {
          onSuccess: () => {
            navigate("/organization/" + organization.id + "/Salesforce");
            location.reload();
          },
        }
      );
    } else {
      finalSettings.updateFields = JSON.stringify(finalSettings.updateFields);
      delete finalSettings.id;
      if (finalSettings.matchFields) {
        delete finalSettings.matchFields;
      }

      createObjectMutation.mutate(
        {
          data: finalSettings,
        },
        {
          onSuccess: () => {
            navigate("/organization/" + organization.id + "/Salesforce");
            location.reload();
          },
        }
      );
    }

    // if (object?.id === "new" && objectSettings?.sendExistingData) {
    //   finalSettings.createNewData = objectSettings?.createNewData
    //     ? "True"
    //     : "False";
    //   finalSettings.sendExistingData =
    //     objectSettings?.sendExistingData &&
    //     objectSettings?.connectionStatus === "active"
    //       ? "True"
    //       : "False";

    //   let url = process.env.REACT_APP_SALESFORCE_OBJECT_SAVE_CONNECTION;
    //   const config = {
    //     withCredentials: true,
    //     headers: {
    //       accept: "application/json",
    //     },
    //     params: {
    //       organization_id: organization.id,
    //       user_id: user.id,
    //     },
    //   };

    //   finalSettings.matchFields = [];
    //   finalSettings.internalName = "";
    //   finalSettings.externalName = "";

    //   const formData = new FormData();
    //   formData.append("objectSettings", JSON.stringify(finalSettings));

    //   axios
    //     .post(url, formData, config)
    //     .then((response) => {
    //       //
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       if (error?.response?.status === 401) {
    //         setSessionExpired(true);
    //         setTimeout(() => {
    //           handleLoginSalesforce();
    //         }, 1000);
    //       }
    //     });
    // } else if (object?.id === "new" && !objectSettings?.sendExistingData) {
    //
    // } else {

    // }
  }

  function pushData(object) {
    if (objectSettings?.connectionStatus === "active") {
      setObjectSettings({ ...objectSettings, connectionStatus: "pushing" });
      let url = process.env.REACT_APP_SALESFORCE_SYNC_QUERY;
      const config = {
        withCredentials: true,
        headers: {
          accept: "application/json",
        },
        params: {
          organization_id: organization.id,
          user_id: user.id,
        },
      };

      let finalData = { ...object };
      finalData.matchFields = finalData.matchFields
        ? finalData.matchFields
        : [];
      finalData.updateFields = finalData.updateFields
        ? finalData.updateFields
        : [];
      const formData = new FormData();
      formData.append("objectSettings", JSON.stringify(finalData));

      axios
        .post(url, formData, config)
        .then((response) => {
          if (
            response?.status === 200 &&
            response?.data?.message === "success"
          ) {
            setObjectSettings({
              ...objectSettings,
              connectionStatus: "active",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          if (error?.response?.status === 401) {
            setSessionExpired(true);
            setTimeout(() => {
              // handleLoginSalesforce();
            }, 1000);
          }
        });
    }
  }

  return (
    <div className={styles.page}>
      <>
        <FlexCol gap="1rem">
          <div
            className={`${styles.header_3} `}
            style={{ fontWeight: "400", color: "black", margin: "0" }}
          >
            {object?.id === "new"
              ? "New Update Connection Setup"
              : "Update Connection Setup"}
          </div>
          <FlexRow justify={"space-between"}>
            <FlexRow>
              {" "}
              <Button
                shadow
                style={{ gap: ".5rem" }}
                onClick={() =>
                  navigate("/organization/" + organization?.id + "/Salesforce")
                }
              >
                <Icon green iconName={"arrow-left-circle"}></Icon>Go Back
              </Button>{" "}
              {objectSettings?.id != "new" && (
                <Button
                  shadow
                  style={{ gap: ".5rem" }}
                  onClick={() => pushData(objectSettings)}
                  disable={objectSettings?.connectionStatus === "pushing"}
                >
                  <Icon sapphire iconName={"box-arrow-right"}></Icon>Push Data
                </Button>
              )}
            </FlexRow>

            {objectSettings?.id != "new" && (
              <Button
                shadow
                style={{ gap: ".5rem", whiteSpace: "nowrap" }}
                onClick={() =>
                  navigate("/organization/" + organization?.id + "/Salesforce")
                }
                disable={objectSettings?.connectionStatus === "pushing"}
              >
                <Icon red iconName={"trash"}></Icon> Delete Connection
              </Button>
            )}
          </FlexRow>
        </FlexCol>
        <HorizontalBar width={"100%"} height={2}></HorizontalBar>
        <FlexCol style={{ maxWidth: "500px", gap: "1rem" }}>
          <FlexCol>
            <div className={`${styles.header_4} `} style={{ margin: "0" }}>
              Settings
            </div>
            <div
              className={`${styles.text_3} `}
              style={{ marginBottom: "1rem" }}
            >
              These settings apply to all field updates in this connection
            </div>
          </FlexCol>
          <SelectFieldCustom
            options={
              objectSettings?.connectionStatus != "pushing"
                ? linkedAccountStatusOptions
                : []
            }
            label={objectSettings?.id === "new" ? "Initial Status" : "Status"}
            style={{ maxWidth: "250px" }}
            value={statusOptions.find(
              (v) => v.value === objectSettings?.connectionStatus
            )}
            onChange={(v) => {
              if (objectSettings?.connectionStatus != "pushing") {
                setObjectSettings(() => {
                  return { ...objectSettings, connectionStatus: v.value };
                });
              }
            }}
            noCreate
          ></SelectFieldCustom>

          <TextFieldSimple
            label={"  Updates Name Description"}
            className={styles.textarea}
            onChange={(v) =>
              setObjectSettings(() => {
                return { ...objectSettings, description: v };
              })
            }
            value={objectSettings?.description}
            placeholder="Updates Name Description"
          ></TextFieldSimple>

          {/* {objectSettings?.id === "new" &&
            objectSettings?.connectionStatus === "active" && (
              <FlexRow style={{ marginTop: "1rem" }}>
                <Checkbox
                  checked={objectSettings?.sendExistingData}
                  onChange={(e) =>
                    setObjectSettings(() => {
                      return {
                        ...objectSettings,
                        sendExistingData: e.target.checked,
                      };
                    })
                  }
                ></Checkbox>
                <div className={styles.text_2}>
                  Send Existing Reaction Object Data into Salesforce
                </div>
              </FlexRow>
            )} */}
        </FlexCol>
        <HorizontalBar width={"100%"} height={2}></HorizontalBar>
        <FlexCol gap=".25rem">
          <div className={`${styles.header_4} `} style={{ margin: "0" }}>
            Field Updates and Mapping
          </div>
          <div className={`${styles.text_3} `} style={{ marginBottom: "1rem" }}>
            Choose where and what data to use to populate a Salesforce field.
          </div>

          <Update
            // key={index}
            objectSettings={objectSettings}
            setObjectSettings={setObjectSettings}
            calculations={calculations}
            // updateRow={updateRow}
            // index={index}
            organization={organization}
            user={user}
          ></Update>

          {/* <Button
            shadow
            onClick={() => {
              let objectSetTemp = { ...objectSettings };
              objectSetTemp?.updateFields?.push({});
              setObjectSettings(objectSetTemp);
            }}
            style={{ marginTop: "1rem" }}
          >
            + Add An Update
          </Button> */}
        </FlexCol>

        <HorizontalBar width={"100%"} height={2}></HorizontalBar>
        <FlexCol gap={"1rem"}>
          <FlexCol>
            <div className={`${styles.header_4} `} style={{ margin: "0" }}>
              Field Update Frequency
            </div>
            <div
              className={`${styles.text_3} `}
              style={{ marginBottom: "1rem" }}
            >
              How often do you want Reaction to send field updates?
            </div>
          </FlexCol>

          <FlexRow
            style={{
              flexWrap: "wrap",
              marginTop: "2rem",
              gap: "2rem",
            }}
          >
            <div
              className={`${styles.box} ${
                objectSettings?.updateType === "manually" && styles.boxfill
              }`}
              onClick={() => {
                setObjectSettings(() => {
                  return { ...objectSettings, updateType: "manually" };
                });
              }}
            >
              <div className={`${styles.square}`}>
                <i className={"bi-caret-right-square"}></i>
              </div>
              <div
                className={styles.text_2}
                style={{ margin: "0", fontWeight: "400" }}
              >
                Manually
              </div>
              <div
                className={styles.text_3}
                style={{ margin: "0", fontWeight: "400" }}
              >
                On button click
              </div>
            </div>
            <div
              className={`${styles.box} ${
                objectSettings?.updateType === "automatically" && styles.boxfill
              }`}
              onClick={() => {
                // setObjectSettings(() => {
                //   return { ...objectSettings, updateType: "automatically" };
                // });
              }}
            >
              <div
                className={`${styles.square}`}
                // style={{ border: "4px dotted #a3a4a8" }}
              >
                <i
                  className={"bi-arrow-repeat"}
                  style={{ fontSize: "4.8rem" }}
                ></i>
              </div>
              <div
                className={styles.text_2}
                style={{ margin: "0", fontWeight: "400" }}
              >
                Automatically
              </div>
              <div
                className={styles.text_3}
                style={{ margin: "0", fontWeight: "400" }}
              >
                (Coming Soon)
                {/* Real time data flow */}
              </div>
            </div>
            <div
              className={`${styles.box} ${
                objectSettings?.updateType === "schedule" && styles.boxfill
              }`}
              onClick={() => {
                // setObjectSettings(() => {
                //     return { ...objectSettings, updateType: "ma" };
                //   });
              }}
            >
              <div className={`${styles.square}`}>
                <i className={"bi-calendar"}></i>
              </div>
              <div
                className={styles.text_2}
                style={{ margin: "0", fontWeight: "400" }}
              >
                Schedule
              </div>
              <div
                className={styles.text_3}
                style={{ margin: "0", fontWeight: "400" }}
              >
                (Coming Soon)
              </div>
            </div>
          </FlexRow>
        </FlexCol>
        <HorizontalBar width={"100%"} height={2}></HorizontalBar>
        <FlexRow>
          <Button
            width={75}
            shadow
            onClick={() =>
              navigate("/organization/" + organization?.id + "/Salesforce")
            }
          >
            Cancel
          </Button>
          <Button
            blue
            shadow
            width={75}
            disable={
              !objectSettings?.internalField ||
              !objectSettings?.externalField ||
              objectSettings?.connectionStatus === "pushing"
            }
            onClick={() => saveObjectSettings()}
          >
            Save
          </Button>
        </FlexRow>
      </>
    </div>
  );
}

export default CustomUpdates;

function Update({
  objectSettings,
  setObjectSettings,
  calculations,
  updateRow,
  index,
  organization,
  user,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [salesforceObjects, setSalesforceObjects] = useState([]);
  const [objectFields, setObjectFields] = useState([]);
  const [objectFieldsReaction, setObjectFieldsReaction] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [loadingFieldsSales, setLoadingFieldsSales] = useState(false);
  const [calculationOptions] = useState(() => {
    let calcs = [{ label: "None", value: "None" }];
    let temp = calculations.map((c) => {
      return { value: c.id, label: c.name + ` (${c.type})` };
    });
    return [...calcs, ...temp];
  });

  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    if (objectSettings?.internalName) {
      setReactionObjectFields(objectSettings.internalName);
    }
    if (objectSettings?.externalName) {
      getObjectFields(objectSettings?.externalName);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    let url = process.env.REACT_APP_SALESFORCE_OBJECTS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let fields = [];
        if (response?.data?.objects && response?.data?.objects?.length > 0) {
          setSalesforceObjects(response?.data?.objects);
          fields = response?.data?.objects;
          let external = updateRow?.externalName
            ? fields.find((f) => f.value === updateRow?.externalName)
            : undefined;

          // debugger;
          if (external) {
            getObjectFields(external.value);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });

    let internal = updateRow?.internalName
      ? reactionObjects.find((f) => f.value === updateRow?.internalName)
      : undefined;
    if (internal) {
      setReactionObjectFields(internal.value);
    }
  }, []);

  function getObjectFields(name) {
    setLoadingFieldsSales(true);
    let url = process.env.REACT_APP_SALESFORCE_OBJECT_FIELDS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
        name: name,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.fields && response?.data?.fields?.length > 0) {
          setObjectFields(response?.data?.fields);
        }
        setLoadingFieldsSales(false);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }

  const getContactFields = useFetchCurrOrgsFieldsMut();

  function setReactionObjectFields(name) {
    if (name === "Contact") {
      getContactFields.mutate(
        {},
        {
          onSuccess: (data) => {
            if (data?.fields && data?.fields?.length > 0) {
              const transformedArray = data?.fields.map((item) => ({
                value: item.name,
                label: item.displayName + ` (${item.dataType})`,
                id: item.id,
                dataType: item.dataType,
              }));
              setObjectFieldsReaction(transformedArray);
            }
          },
        }
      );
      return;
    }
    setObjectFieldsReaction(objectData[name]);
  }

  useEffect(() => {
    if (objectFieldsReaction?.length > 0) {
      setLoadingFields(true);
      setTimeout(() => {
        setLoadingFields(false);
      }, 1000);
    }
  }, [objectFieldsReaction]);

  useEffect(() => {
    if (objectFields?.length > 0) {
      setLoadingFieldsSales(true);
      setTimeout(() => {
        setLoadingFieldsSales(false);
      }, 1000);
    }
  }, [objectFields]);

  return (
    <>
      <div className={styles.connection}>
        <FlexCol style={{ maxWidth: "700px" }}>
          <div
            className={styles.header_5}
            style={{ margin: "0", marginTop: "1.2rem" }}
          >
            Filter Data:
          </div>
          <div className={styles.text_3} style={{ marginBottom: "1rem" }}>
            Choose how Reaction will know what data to update.
          </div>

          <FlexRow style={{ marginBottom: "1rem" }}>
            <SelectFieldCustom
              // style={{ width: "250px" }}
              options={reactionObjects}
              label={
                <span>
                  <span style={{ fontWeight: "600" }}>Reaction</span> Object
                </span>
              }
              // searchable
              value={
                objectSettings?.internalName
                  ? reactionObjects.find(
                      (f) => f.value === objectSettings?.internalName
                    )
                  : undefined
              }
              onChange={(val) => {
                let objectSetTemp = { ...objectSettings };
                objectSetTemp.internalName = val.value;
                setObjectSettings(objectSetTemp);
                setReactionObjectFields(val.value);
              }}
              noCreate
              icon={<Icon blue iconName={"database"}></Icon>}
            ></SelectFieldCustom>
            {loadingFields && (
              <div style={{ width: "100%" }}>
                <Loading height={60} width={60}></Loading>
              </div>
            )}
            {!loadingFields && (
              <SelectFieldCustom
                // style={{ width: "250px" }}
                label="Object Field"
                value={
                  objectSettings?.internalField
                    ? objectFieldsReaction?.find(
                        (f) => f.value === objectSettings?.internalField
                      )
                    : undefined
                }
                onChange={(val) => {
                  let objectSetTemp = { ...objectSettings };
                  objectSetTemp.internalField = val.value;
                  setObjectSettings(objectSetTemp);
                }}
                // searchable
                icon={<Icon blue iconName={"database"}></Icon>}
                noCreate
                options={objectFieldsReaction}
              ></SelectFieldCustom>
            )}
          </FlexRow>
          <SelectFieldCustom
            select
            label={"Operator"}
            shadow
            value={{ value: 0, label: "equals" }}
            options={operators}
            noCreate
          />
          {/* <HorizontalBar height={2}></HorizontalBar> */}
          {/* <div
              className={styles.header_4}
              style={{ margin: "0", marginTop: "1.2rem" }}
            >
              Salesforce Side:
            </div>
            <div className={styles.text_3} style={{ marginBottom: "1rem" }}>
              Choose which Salesforce object and field you want to map to
            </div> */}
          <FlexRow style={{ margin: "1rem 0" }}>
            {" "}
            {loading && (
              <div style={{ width: "100%" }}>
                <Loading height={60} width={60}></Loading>
              </div>
            )}
            {!loading && (
              <SelectFieldCustom
                // style={{ width: "250px" }}
                options={salesforceObjects}
                label={
                  <span>
                    <span style={{ fontWeight: "600" }}>Salesforce</span> Object
                  </span>
                }
                // searchable
                value={
                  objectSettings?.externalName
                    ? salesforceObjects.find(
                        (f) => f.value === objectSettings?.externalName
                      )
                    : undefined
                }
                onChange={(v) => {
                  let objectSetTemp = { ...objectSettings };
                  objectSetTemp.externalName = v.value;
                  setObjectSettings(objectSetTemp);
                  getObjectFields(v.value);
                }}
                noCreate
              ></SelectFieldCustom>
            )}
            {loadingFieldsSales && (
              <div style={{ width: "100%" }}>
                <Loading height={60} width={60}></Loading>
              </div>
            )}
            {!loadingFieldsSales && (
              <SelectFieldCustom
                // style={{ width: "250px" }}
                label={"Object Field"}
                value={
                  objectSettings?.externalField
                    ? objectFields.find(
                        (f) => f.value === objectSettings?.externalField
                      )
                    : undefined
                }
                onChange={(val) => {
                  let objectSetTemp = { ...objectSettings };
                  objectSetTemp.externalField = val.value;
                  setObjectSettings(objectSetTemp);
                }}
                icon={<Icon blue iconName={"database"}></Icon>}
                noCreate
                options={objectFields}
                // searchable
                
              ></SelectFieldCustom>
            )}
          </FlexRow>
        </FlexCol>
        {/* <HorizontalBar height={2}></HorizontalBar> */}
        <FieldMatching
          calculationOptions={calculationOptions}
          objectFields={objectFields}
          loadingFieldsSales={loadingFieldsSales}
          objectSettings={objectSettings}
          setObjectSettings={setObjectSettings}
        ></FieldMatching>
      </div>{" "}
    </>
  );
}

function FieldMatching({
  objectFields,
  loadingFieldsSales,
  calculationOptions,
  objectSettings,
  setObjectSettings,
}) {
  return (
    <>
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        <thead>
          <tr>
            <th>
              <div
                className={styles.header_5}
                style={{ margin: "0", marginTop: "1.2rem" }}
              >
                Reaction Calculation
              </div>
              <div className={styles.text_3} style={{ marginBottom: "1rem" }}>
                Choose what calculation will run to update the Saleforce field
              </div>
            </th>
            <th></th>
            <th>
              <div
                className={styles.header_5}
                style={{ margin: "0", marginTop: "1.2rem" }}
              >
                Salesforce Field
              </div>
              <div className={styles.text_3} style={{ marginBottom: "1rem" }}>
                Choose what field will be populated with the calculation
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {objectSettings?.updateFields?.map((updateRow, index) => (
            <tr>
              <td style={{ width: "300px" }}>
                <div>
                  <SelectFieldCustom
                    style={{ marginBottom: "1rem" }}
                    // label="Calculation"
                    value={
                      updateRow?.calculation
                        ? calculationOptions.find(
                            (f) => f.value === updateRow?.calculation
                          )
                        : undefined
                    }
                    onChange={(val) => {
                      let objectSetTemp = { ...objectSettings };
                      let updateField = { ...updateRow };
                      updateField.calculation = val.value;
                      objectSetTemp.updateFields[index] = updateField;
                      setObjectSettings(objectSetTemp);
                    }}
                    searchable
                    icon={<Icon blue iconName={"calculator"}></Icon>}
                    options={calculationOptions}
                    noCreate
                    dropdownStyle={{top: "2.9rem"}}
                  />
                </div>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi-arrow-right"
                  style={{ fontSize: "1.1rem", paddingBottom: ".8rem" }}
                ></i>
              </td>

              <td style={{ width: "300px" }}>
                {loadingFieldsSales && (
                  <div style={{ width: "100%" }}>
                    <Loading height={60} width={60}></Loading>
                  </div>
                )}
                {!loadingFieldsSales && (
                  <div>
                    {" "}
                    <SelectFieldCustom
                      style={{ marginBottom: "1rem" }}
                      // label={"Populate Object Field"}
                      value={
                        updateRow?.populateField
                          ? objectFields.find(
                              (f) => f.value === updateRow?.populateField
                            )
                          : undefined
                      }
                      onChange={(val) => {
                        let objectSetTemp = { ...objectSettings };
                        let updateField = {
                          ...objectSetTemp.updateFields[index],
                        };
                        updateField.populateField = val.value;
                        objectSetTemp.updateFields[index] = updateField;
                        setObjectSettings(objectSetTemp);
                      }}
                      icon={<Icon blue iconName={"database"}></Icon>}
                      noCreate
                      options={objectFields}
                      searchable
                      dropdownStyle={{top: "2.9rem"}}
                    ></SelectFieldCustom>
                  </div>
                )}
              </td>

              <td
                style={{
                  width: "fit-content",
                  paddingBottom: "1.2rem",
                }}
              >
                <Button
                  shadow
                  onClick={() => {
                    let objectSetTemp = { ...objectSettings };
                    objectSetTemp?.updateFields?.splice(index, 1);
                    setObjectSettings(objectSetTemp);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>{" "}
      <FlexRow>
        <Button
          shadow
          onClick={() => {
            let objectSetTemp = { ...objectSettings };
            objectSetTemp?.updateFields?.push({});
            setObjectSettings(objectSetTemp);
          }}
        >
          + Add Field
        </Button>
      </FlexRow>
    </>
  );
}
