import styles from "./PhoneShell.module.scss";
import prof from "assets/images/blank-profile-picture.png";

export function PhoneShell({ phoneRef, children }) {
  return (
    <>
      <div className={styles.phone} ref={phoneRef}>
        <div className={styles.textAppHeader}>
          <img
            src={prof}
            height={"40px"}
            width={"40px"}
            className={styles.profPic}
          />
          <div className={styles.numDisplay}>54321</div>
        </div>
        <div className={styles.notch}></div>
        <div className={styles.slider}></div>

        {children}

        <div className={styles.textAppFooter}>
          <div className={styles.fakeInput}>
            Text Message <div className={styles.dot}></div> SMS
          </div>
        </div>
      </div>
    </>
  );
}
