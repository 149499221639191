import React from "react";
import styles from "./ContactSales.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

function ContactSales({}) {
  return (
    <form className={styles.form}>
      <div className={styles.header}>
        <div className={styles.header_3} style={{ margin: "0", padding: "0" }}>
          Connect With a Reaction Expert
        </div>
        <div className={styles.text_1} style={{ margin: "0 .15rem" }}>
          Submit this form and we'll aim to respond within one business day
        </div>
      </div>
      <div className={styles.content}>
        <SelectFieldCustom
          label={"Organization Type"}
          options={[
            { value: "Healthcare Provider", label: "Healthcare Provider" },
            { value: "Non-Profit", label: "Non-profit" },
            { value: "Med-Tech", label: "Med-Tech" },
            { value: "Life Sciences", label: "Life Sciences" },
            { value: "Health Insurance", label: "Health Insurance" },
            { value: "Other", label: "Other" },
          ]}
        ></SelectFieldCustom>
        <FlexRow wrap={"wrap"}>
          <TextFieldSimple
            containerStyle={{ flex: 1, minWidth: "250px" }}
            label="First Name"
            icon="person-circle"
          ></TextFieldSimple>
          <TextFieldSimple
            containerStyle={{ flex: 1, minWidth: "250px" }}
            label="Last Name"
            icon="person-circle"
          ></TextFieldSimple>
        </FlexRow>

        <TextFieldSimple type="email" icon="at" label="Email"></TextFieldSimple>
        <FlexRow wrap={"wrap"}>
          <TextFieldSimple
            containerStyle={{ flex: 1, minWidth: "250px" }}
            type="phone"
            icon="telephone"
            label="Phone"
          ></TextFieldSimple>
          <TextFieldSimple
            containerStyle={{ flex: 1, minWidth: "250px" }}
            type="text"
            icon="building"
            label="Company"
          ></TextFieldSimple>
        </FlexRow>
        <FlexCol gap={".3rem"}>
          <small style={{paddingLeft: ".2rem"}}>Anything you would like to let us know?</small>
          <TextArea style={{ height: "100px" }}></TextArea>
        </FlexCol>

        <Button
          shadow
          blue
          height={"50px"}
          width={"100%"}
          style={{ marginTop: "2rem", marginBottom: "3rem" }}
          onClick={() => undefined}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

export default ContactSales;