// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import { Login, Register, SurveyTaker, SurveyTakerNoPart } from "pages";
import {
  ForgotPassword,
  ForgotPasswordStart,
} from "pages/ForgotPassword/ForgotPassword";
import Unsubscribe from "pages/unsubscribe/Unsubscribe";
import { SurveyPreview } from "pages/projects/project/survey_build/preview/SurveyPreview";
import EmailVerification from "pages/register/EmailVerifiction";
import { MFA } from "pages/register/MFA";
import TAC from "pages/register/TAC";
import PA from "pages/register/PA";
import { SurveyTesting } from "pages/projects/project/survey_taker/SurveyTesting";

export const NonSignInRoutesRoutes = ({ setToken }) => {
  return (
    <>
      <Routes>
        <Route path="/health" element={<h1>I'm Healthy</h1>} />
        <Route path="/" element={<Navigate to="login" />} />
        <Route path="login" element={<Login setToken={setToken} />} />
        <Route path="register/*" element={<Register setToken={setToken} />} />
        <Route path="email-notification" element={<EmailVerification />} />
        <Route path="login/:id" element={<MFA login finish />} />
        <Route path="forgot-password" element={<ForgotPasswordStart />} />
        <Route path="forgot-password/:token" element={<ForgotPassword />} />
        <Route path="privacy-policy" element={<PA />} />
        <Route path="terms-and-services-agreement" element={<TAC />} />
        <Route path="account-payment/:id" element={<Unsubscribe />} />
        <Route path="take-survey/:projectId" element={<SurveyTakerNoPart />} />
        <Route
          path="take-survey/specific/:projectId/:participantId/:distId"
          element={<SurveyTaker />}
        />
        <Route
          path="test-survey/:record/:projectId/:participantId/:distId"
          element={<SurveyTesting />}
        />
        <Route path="unsubscribe/:id" element={<Unsubscribe />} />
        <Route path="unsubscribe/:id/:deliveryId" element={<Unsubscribe />} />
        <Route path="survey/:id/preview" element={<SurveyPreview />} />
        <Route path="*" element={<Navigate to="login" />} />
        <Route path="privacy" element={<PA />} />
      </Routes>
    </>
  );
};
