export function calculateQuestionHealth(q) {
  let question = {};
  let tips = [];

  if (q) {
    question = q;
  } else {
    return 0;
  }
  let health = 0;
  if (question?.questionText && question?.questionText?.length <= 186) {
    health += 25;
  } else {
    if (tips) {
      tips.push(
        "Your question name length exceeds 185 characters. Participants are much more likely to skip this question because of its length."
      );
    }
  }
  // console.log(health)
  // console.log(q.questionText)

  if (
    !question?.description ||
    (question?.description && question?.description?.length <= 81)
  ) {
    health += 25;
  } else {
    if (tips) {
      tips.push(
        "Your instructions length exceeds 80 characters. Participants are much more likely to skip this question because of its length."
      );
    }
  }

  if (question?.choiceQuestion) {
    if (question?.choiceQuestion?.choices) {
      if (question?.choiceQuestion?.choices?.length <= 5) {
        health += 25;
      } else {
        if (tips) {
          tips.push(
            "Have 3-5 options in a multiple choice question. This question will more likely be skipped if you have more."
          );
        }
      }
      let good = true;
      for (let choice of question?.choiceQuestion?.choices) {
        if (choice.length > 50) {
          good = false;
        }
      }
      if (good) {
        health += 25;
      } else {
        if (tips) {
          tips.push(
            "One of your choice options is more than 50 characters. Participants are much more likely to skip this question because of its length."
          );
        }
      }
    } else {
      if (tips) {
        tips.push("Your multiple choice question has no choices");
      }
    }
  }
  if (question?.textQuestion) {
    health += 50;
  }
  if (question?.scaleQuestion) {
    if (
      question?.description &&
      question?.description != "" &&
      question?.description != "\n"
    ) {
      health += 50;
    } else {
      if (tips) {
        tips.push(
          "No instructions are included in the scale question. Not having instructions on a scale question may confuse the participant."
        );
      }
    }
  }
  return { health: health, tips: tips };
}

export function calculateSurveyHealth(survey, questions, setDone) {
  let tips = [];
  let health = 0;
  if (survey.numPages <= 5) {
    health += 25;
  } else {
    tips.push(
      "Having more than 5 pages exausts the participants and it is very likely they do not finish the survey"
    );
  }

  if (questions.length <= 5) {
    health += 25;
  } else {
    tips.push(
      "Having more than 5 questions exausts the participants and it is very likely they do not finish the survey"
    );
  }

  let total = questions.length;
  for (let question of questions) {
    let questionHealth = calculateQuestionHealth(question, tips);
    if (questionHealth < 100) {
      total -= 1;
    }
  }

  if (total > 0) {
    let part = 50 / questions.length;
    let healthy = Math.round(total * part);
    health += healthy;
  }
  if (setDone) {
    setDone(true);
  }

  return { health: health, tips: tips };
}

export function getAnonLink(survey) {
  return "https://reaction.health/take-survey/" + survey.id;
}

export function responseTimeString(initSeconds) {
  let minutes = Math.floor(initSeconds / 60);
  let seconds = Math.floor(initSeconds % 60);
  return `${minutes <= 9 ? "0" + minutes : minutes}:${seconds}`;
}
