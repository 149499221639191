import React, { useEffect, useRef, useState } from "react";
import {
  useCreateAnalysis,
  useFetchAnalysis,
  useUpdateAnalysis,
  useDeleteAnalysis,
  useUpdateAnalysisFilter,
  useCopyAnalysis,
} from "api/resources/organization/analysis";
import {
  useCopyReport,
  useUpdateTiedSurveys,
  useUpdateReport,
} from "api/resources/organization/reports";
import Button from "components/Button/Button";
import { TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";
import Analysis from "pages/results/Analysis/Analysis";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Report.module.scss";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { Share } from "./Share";
import {
  useFix,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { PageFilter } from "./PageFilter";
import IconShopper from "../Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { parsedViz } from "../Charts/Visualization";
import ConfigureBox from "../Charts/Settings/ConfigureBox";
import {
  useCreateVizAndAppendToAnalysis,
  useGetVizMutation,
  useUpdateVisualization,
} from "api/resources/projects/visualizations";
import { Sidebar2 } from "components/sidebars";
import { trimDate } from "assets/functions/DateFunctions";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import {
  defaultChartSettings,
  MetricType,
  TableType,
  VertBarType,
} from "../Charts/QChart/QuestionChart";
import ReportStyles from "./ReportStyles";
import { defaultMetricSettings } from "../Charts/ScoreChart";
import { defaultTableSettings } from "../Charts/TableViz";
import { defaultNoteSettings, NoteType } from "../Charts/Note";
import { defaultLinkSettings, LinkType } from "../Charts/Link/Link";
import Modal from "components/ReactModal/ReactModal";
import ChooseLink from "../Charts/Link/ChooseLink";
import { DownloadTable } from "../Charts/Tables/DownloadTable/DownloadTable";
import { useFetchReportWorthyCustomFields } from "api/resources/organization/organization";

const tagOptions = [
  {
    value: "Survey Results",
    label: "Survey Results",
  },
  {
    value: "Ongoing Results",
    label: "Ongoing Results",
  },
  {
    value: "Program Results",
    label: "Program Results",
  },
  {
    value: "Raw Survey Results",
    label: "Raw Survey Results",
  },
  {
    value: "Analytics Report",
    label: "Analytics Report",
  },
  {
    value: "Info Report",
    label: "Info Report",
  },
];

const menuItems = [
  {
    id: "settings",
    name: "Report Settings",
    // to: `survey-details`,
    icon: "bi bi-gear",
  },
  // {
  //   id: "file",
  //   name: "Export Settings",
  //   // to: `survey-details`,
  //   icon: "bi bi-file-earmark",
  // },
  // {
  //   id: "style",
  //   name: "Global Style",
  //   // to: `survey-details`,
  //   icon: "bi bi-brush",
  // },
  // {
  //   id: "finished",
  //   name: "Finished",
  //   // to: `survey-details`,
  //   icon: "bi bi-check-circle",
  // },
  // {
  //   id: "header",
  //   name: "Page Header",
  //   // to: `survey-details`,
  //   icon: "bi bi-card-heading",
  // },
  {
    id: "nav",
    name: "Page Navigation",
    // to: `survey-details`,
    icon: "bi bi-stickies",
  },
];

export default function Report({
  report,
  refetchReport,
  canEdit,
  basic,
  editing,
  setEditing,
  onEditChart,
  editRef,
  publish,
  deleteDraft,
  widescreen,
  dash,
}) {
  const { reportid } = useParams();
  const pageOrder =
    typeof report.pageOrder === "string"
      ? JSON.parse(report.pageOrder)
      : report?.pageOrder
      ? report?.pageOrder
      : [];

  const findFirstAnal = () => {
    let pageNum = localStorage.getItem("Report:" + reportid);
    if (pageNum) {
      let analId = pageOrder[pageNum];
      if (analId) return analId;
      localStorage.setItem("Report:" + reportid, null);
    }

    return pageOrder[0];
  };

  const [analysisId, setAnalysisId] = useState(findFirstAnal());

  const fetchCustomFields = useFetchReportWorthyCustomFields();

  const createAnalysisPage = useCreateAnalysis();
  const updateReport = useUpdateReport();
  const [saveStatus, setSaveStatus] = useState("Saved");
  const [currReport, setCurrReport] = useState(report);

  const [icons, setIcons] = useState(false);

  useEffect(() => {
    setCurrReport(report);
  }, [report]);

  function saveUpdatedTime() {
    setSaveStatus(
      <div style={{ display: "flex", flexDirection: "row" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );
    let data = {
      updatedAt: new Date(),
    };
    updateReport.mutate(
      {
        data: data,
        id: report.id,
      },
      {
        onSuccess: (data) => {
          setSaveStatus("Saved");
        },
        onError: (err) => {
          console.log("Can't update");
          // console.log(err);
        },
      }
    );
  }

  function changeName(value) {
    let data = { name: value };
    onSave(data);
  }

  function changeType(value) {
    let temp = { ...currReport, ...{ type: value } };
    setCurrReport(temp);
    let data = { type: value };
    onSave(data);
  }

  function changeIcon(i) {
    let temp = { ...currReport, ...{ icon: i } };
    setCurrReport(temp);

    let data = { icon: i };
    onSave(data);
    setIcons(false);
  }

  function onSave(data) {
    setSaveStatus(
      <div style={{ display: "flex", flexDirection: "row" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );
    updateReport.mutate(
      {
        data: data,
        id: report.id,
      },
      {
        onSuccess: (data) => {
          setSaveStatus("Saved");
        },
      }
    );
  }

  function addPage(name) {
    createAnalysisPage.mutate(
      {
        name: name,
        reportId: report.id,
      },
      {
        onSuccess: (data) => {
          let num = report.analysis.length;
          localStorage.setItem("Report:" + reportid, num);

          setAnalysisId(data.createAnalysis.id);
          if (refetchReport) {
            refetchReport();
          }
        },
      }
    );
  }

  function switchToAnalysis(id) {
    let pageNum = pageOrder.findIndex((p) => p === id);
    if (pageNum > -1) {
      localStorage.setItem("Report:" + reportid, pageNum);
    }
    setAnalysisId(id);
  }

  function onSelectPage(id) {
    if (report.analysis.some((a) => a.id === id)) {
      switchToAnalysis(id);
    }
  }

  function switchToPage(pageId) {
    switchToAnalysis(pageId);
    refetchReport();
  }

  function nextPage() {
    let index = pageOrder?.findIndex((id) => id === analysisId);
    let newIndex = index + 1;
    if (newIndex > pageOrder?.length - 1) {
      newIndex = 0;
    }
    let newId = pageOrder[newIndex];
    onSelectPage(newId);
  }

  function prevPage() {
    let index = pageOrder?.findIndex((id) => id === analysisId);
    let newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = pageOrder?.length - 1;
    }
    let newId = pageOrder[newIndex];
    onSelectPage(newId);
  }

  return (
    <>
      {fetchCustomFields.isLoading && <Loading />}
      {fetchCustomFields.isSuccess && (
        <AnalysisContainer
          analysisId={analysisId}
          report={currReport}
          canEdit={canEdit}
          onSelectPage={onSelectPage}
          custom_fields={fetchCustomFields.data.fields}
          changeName={changeName}
          pageOrder={pageOrder}
          basic={basic}
          prevPage={prevPage}
          nextPage={nextPage}
          addPage={addPage}
          switchToPage={switchToPage}
          refetchReport={refetchReport}
          icons={icons}
          changeIcon={changeIcon}
          changeType={changeType}
          setIcons={setIcons}
          saveStatus={saveStatus}
          editing={editing}
          setEditing={setEditing}
          onEditChart={onEditChart}
          editRef={editRef}
          publish={publish}
          deleteDraft={deleteDraft}
          onQuickSave={saveUpdatedTime}
          widescreen={widescreen}
          saveUpdatedTime={saveUpdatedTime}
          dash={dash}
        />
      )}
    </>
  );
}

function AnalysisContainer({
  analysisId,
  report,
  canEdit,
  onSelectPage,
  custom_fields,
  pageOrder,
  basic,
  prevPage,
  nextPage,
  addPage,
  switchToPage,
  refetchReport,
  changeName,
  icons,
  changeIcon,
  changeType,
  setIcons,
  saveStatus,
  editing,
  setEditing,
  onEditChart,
  editRef,
  publish,
  deleteDraft,
  onQuickSave,
  widescreen,
  saveUpdatedTime,
  dash,
}) {
  const fetchAnalysis = useFetchAnalysis(analysisId, onLoad);
  const useUpdateFilters = useUpdateAnalysisFilter();
  const getViz = useGetVizMutation();
  const [showFilter, setShowFilter] = useState(false);
  const [externalFilter, setExternalFilter] = useState();
  const [seePages, setSeePages] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showDownloads, setShowDownloads] = useState(false);
  const [showDrill, setShowDrill] = useState(false);
  const [brandNew, setBrandNew] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [visibleItems, setVisibleItems] = useState();
  const [labels, setLabels] = useState();
  const [zoom, setZoom] = useState(1);
  const [currViz, setCurrViz] = useState();
  const [reloadSettings, setReloadSettings] = useState(false);
  const [showShare, setShowShare] = useState(false);

  function pdfExport() {
    // setOpenSettings(false);
    setTimeout(() => {
      let input = window.document.getElementById(analysisId);
      html2canvas(input, { scale: 3 })
        .then((canvas) => {
          const img = canvas.toDataURL("image/png", 1);
          // img.save("newimgage");
          const pdf = new jsPDF("p", "pt");
          // console.log(input.clientHeight);
          pdf.internal.pageSize.height = input.clientHeight;
          pdf.internal.pageSize.width = input.clientWidth;
          pdf.addImage(img, "png", 0, 0, input.clientWidth, input.clientHeight);
          //give pdf a name and download.
          let str = report.name;
          if (fetchAnalysis.isSuccess) {
            str += "_" + fetchAnalysis.data.analysis.name;
          }
          str += ".pdf";

          // str = str.replace(/\s+/g, "");
          // str = str.toLocaleLowerCase();
          pdf.save(str);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }, 1000);
  }

  useEffect(() => {
    setExternalFilter("");
  }, [analysisId]);

  useEffect(() => {
    if (currViz) {
      saveUpdatedTime();
      handleSave(currViz);
      setActive(null);
    } else if (labels) {
      setLabels();
    }
  }, [currViz]);

  function onLoad(data) {
    if (!externalFilter && data?.analysis?.filters) {
      setExternalFilter(data?.analysis?.filters);
    }

    if (editRef && editRef.current) {
      const anal = data?.analysis;
      const info = editRef.current;
      if (pageOrder.indexOf(anal.id) === info.page) {
        let layout = JSON.parse(anal.layout);
        if (layout[info.row] && layout[info.row][info.seat]) {
          let vizId = layout[info.row][info.seat];
          getViz.mutate(
            { id: vizId },
            {
              onSuccess: (data) => {
                if (data.viz) {
                  let viz = parsedViz(data.viz);
                  if (viz && viz.type === info.type) {
                    if (viz.type !== NoteType) {
                      let parsed = parsedViz(viz);
                      setCurrViz(parsed);
                    }
                  }
                }
              },
            }
          );

          setTimeout(() => {
            if (scrollableAreaRef.current) {
              let el = scrollableAreaRef.current;
              if (el.scrollHeight > el.clientHeight) {
                let vizEl = document.getElementById(vizId + "Container");
                if (vizEl) {
                  vizEl.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                  });
                }
              }
            }
          }, 500);
        }
      }

      editRef.current = null;
    }
  }

  function onFilterChange(filtersObj) {
    let filtersString = JSON.stringify(filtersObj);
    setExternalFilter(filtersString);

    if (editing) {
      useUpdateFilters.mutate(
        {
          id: analysisId,
          filters: filtersString,
        },
        {
          onSuccess: (data) => {
            saveUpdatedTime();
          },
        }
      );
    }
  }

  function getPages() {
    let order = JSON.parse(report.pageOrder);
    let list = [];
    for (let id of order) {
      if (id !== analysisId) {
        let analysis = report.analysis.find((a) => a.id === id);
        if (analysis) {
          list.push(analysis);
        }
      }
    }
    return list;
  }

  const updateViz = useUpdateVisualization();
  const handleSave = (newViz) => {
    let data = {
      title: newViz.title,
      titleValue: newViz.titleValue,
      type: newViz.type,
      dataMethod: newViz.dataMethod,
      questionIds: newViz.question ? newViz.question.map((q) => q.id) : null,
      designSettings: JSON.stringify(newViz.designSettings),
      pivotString: newViz.pivotString,
      filters:
        typeof newViz.filters !== "string"
          ? JSON.stringify(newViz.filters)
          : newViz.filters,
      tagIdsArray: JSON.stringify(newViz.tagIdsArray),
      projectIdsArray: JSON.stringify(newViz.projectIdsArray),
      programIds: newViz.programIds ? JSON.stringify(newViz.programIds) : null,
      questionTags: newViz.questionTags
        ? JSON.stringify(newViz.questionTags)
        : null,
      comparison: newViz.comparison ? JSON.stringify(newViz.comparison) : null,
      // data: stringifyData(),
    };

    updateViz.mutate(
      {
        id: newViz?.id,
        data: data,
      },
      {
        onError: (data) => console.log(data),
      }
    );
  };

  const [active, setActive] = useState();

  function updateActive(val) {
    setActive(val);
    if (currViz) setCurrViz(null);
  }

  function zoomOut() {
    // const container = document.getElementById("report-container-" + analysis?.id);
    // const content = document.getElementById("report-content-" + analysisId);
    if (zoom) {
      var multiplier = Math.pow(10, 1);
      let z = Math.round(zoom * multiplier) / multiplier;
      // content.style.transform = `scale(${zoom - 0.1})`;
      setZoom(z - 0.1);
    } else {
      setZoom(0.9);
    }
    // setZoom(prevZoom => Math.max(prevZoom - 0.2, 0.5));
  }

  function zoomIn() {
    // const container = document.getElementById("report-container-" + analysis?.id);
    // const content = document.getElementById("report-content-" + analysisId);
    if (zoom) {
      var multiplier = Math.pow(10, 1);
      let z = Math.round(zoom * multiplier) / multiplier;
      // content.style.transform = `scale(${zoom + 0.1})`;
      setZoom(z + 0.1);
    } else {
      setZoom(1.1);
    }

    // setZoom(prevZoom => prevZoom + 0.2);
  }

  const refOptions = useRef(null);
  const ref2 = useRef(null);

  const handleClickOutside = (event) => {
    if (refOptions.current && !refOptions.current.contains(event.target)) {
      setShowOptions(false);
    }
    if (ref2.current && !ref2.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const scrollableAreaRef = useRef(null);
  const contentRef = useRef(null);

  const navigate = useNavigate();

  const [discarding, setDiscarding] = useState(false);

  function onDiscard(e) {
    e.stopPropagation();
    if (
      window.confirm(
        "Are you sure you'd like to discard this draft? This is not recoverable "
      )
    ) {
      setDiscarding(true);
      deleteDraft(report);
    }
  }

  const [publishing, setPublishing] = useState(false);

  const copyReport = useCopyReport();

  function analyzeFurther() {
    copyReport.mutate(
      {
        id: editing ? report?.draftId : report?.id,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          localStorage.setItem("activepage", 2);
          localStorage.removeItem("activeprojecttab");
          localStorage.removeItem("activecontactstab");
          localStorage.removeItem("activeorgtab");
          localStorage.removeItem("activeaccounttab");
          localStorage.removeItem("activeresultstab");
          localStorage.removeItem("activeanalysistab");
          localStorage.removeItem("activesurveystab");
          localStorage.removeItem("reportspageitem");
          localStorage.removeItem("activeaudiencetab");
          window.localStorage.removeItem("page1");
          window.localStorage.removeItem("page2");
          window.localStorage.removeItem("page3");
          if (report?.draftId) {
            localStorage.removeItem("analysis-editing-" + report?.draftId);
          } else {
            localStorage.removeItem("analysis-editing-" + report?.id);
          }

          navigate("/report/" + data.copyReport.id);
        },
      }
    );
  }

  function addAnswerFilterSubtitle(conditions) {
    let string = "";
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      let op = condition.operator;

      string += condition.surveyName + ": ";

      if (op === "have answered") {
        string += "answered '" + condition.qName + "'";
      } else if (op === "have not answered") {
        string += "did not answer '" + condition.qName + "'";
      } else if (
        op === "passives" ||
        op === "promoters" ||
        op === "detractors"
      ) {
        string += op;
      } else if (Array.isArray(condition.answer)) {
        if (op === "answer" || op === "do not answer" || op === "falls in") {
          if (op === "do not answer") {
            string += "not ";
          }
          for (let i = 0; i < condition.answer.length; i++) {
            if (i > 0) {
              if (i < condition.answer.length - 1) {
                string += ", ";
              } else if (i == condition.answer.length - 1) {
                string += " or ";
              }
            }
            string += condition.answer[i];
          }
        } else if (op === "between") {
          let them = getSortedArray(condition.answer, (a, b) => a - b);
          string += "between " + them[0] + " and " + them[1];
        }
      } else if (op === "below" || op === "above") {
        string += op + " " + condition.answer;
      } else if (op === "contains") {
        string += "'" + condition.answer + "'";
      } else if (op === "does not contain") {
        string += op + "  '" + condition.answer + "'";
      }

      if (op === "is longer than" || op === "is shorter than") {
        if (op === "is longer than") {
          string += "more than ";
        } else {
          string += "less than ";
        }
        string += condition.answer + " characters";
      }

      if (i < conditions.length - 1) {
        string += " & ";
      }
    }

    return string;
  }

  function getFilterSubtitle() {
    let filters = JSON.parse(externalFilter);
    let string = "";
    for (let one in filters) {
      if (string) {
        string += " & ";
      }

      if (one === "surveys") {
        let all = filters.surveys;
        string += "Survey: ";
        for (let i = 0; i < all.length; i++) {
          string += all[i]?.name;
          if (i < all.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      } else if (one === "answers") {
        string += addAnswerFilterSubtitle(filters[one]);
      } else {
        let field = filters[one];
        let name =
          field?.name?.charAt(0)?.toUpperCase() + field?.name?.slice(1);
        string += name + ": ";
        for (let i = 0; i < field.properties.length; i++) {
          string += field.properties[i];
          if (i < field.properties.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      }
    }
    return string;
  }

  function showFilterSubtitle() {
    if (!externalFilter) {
      return false;
    }

    let filters = JSON.parse(externalFilter);
    let count = 0;
    for (let key in filters) {
      if (key === "surveys" || key === "answers") {
        count += filters[key].length;
      } else {
        count += filters[key]?.properties?.length;
      }
    }
    return count > 0 && count < 6;
  }

  const createViz = useCreateVizAndAppendToAnalysis();
  function onAddChart(type, settings) {
    const chart = {
      type: VertBarType,
      designSettings: JSON.stringify(defaultChartSettings),
    };

    const metric = {
      type: MetricType,
      designSettings: JSON.stringify(defaultMetricSettings),
    };

    const table = {
      type: TableType,
      designSettings: JSON.stringify(defaultTableSettings),
    };

    const note = {
      type: NoteType,
      designSettings: JSON.stringify(defaultNoteSettings),
    };

    const link = {
      type: LinkType,
      designSettings: JSON.stringify({
        ...defaultLinkSettings,
        ...settings,
      }),
    };

    createViz.mutate(
      {
        data:
          type === "chart"
            ? chart
            : type === "table"
            ? table
            : type === "note"
            ? note
            : type === "link"
            ? link
            : metric,

        analysisId: analysisId,
      },
      {
        onSuccess: (data) => {
          if (type !== "note" && type !== "link") {
            let parsed = parsedViz(data.viz);
            parsed.newChart = true;
            setCurrViz(parsed);
          } else if (currViz) {
            setCurrViz();
          }

          setBrandNew(data.viz.id);
          setLabels(null);

          setTimeout(() => {
            if (scrollableAreaRef.current) {
              let el = scrollableAreaRef.current;
              if (el.scrollHeight > el.clientHeight) {
                let vizEl = document.getElementById(data.viz.id + "Container");
                if (vizEl) {
                  vizEl.scrollIntoView({ behavior: "smooth", block: "end" });
                }
              }
            }
            setBrandNew("");
          }, 1500);
        },
        onError: (data) => {},
      }
    );
  }

  function prepOnEditChart(viz) {
    if (onEditChart) {
      let info = {
        type: viz.type,
        page: pageOrder?.findIndex((a) => a === analysisId),
      };
      let layout = JSON.parse(fetchAnalysis.data.analysis.layout);
      for (let i = 0; i < layout.length; i++) {
        for (let j = 0; j < layout[i].length; j++) {
          if (layout[i][j] === viz.id) {
            info.row = i;
            info.seat = j;
          }
        }
      }

      onEditChart(info);
    }
  }

  function handlePublish() {
    if (
      window.confirm(
        "Are you sure you'd like to publish your changes to the live report?"
      )
    ) {
      publish();
      setPublishing(true);
    }
  }

  function closeLink() {
    setShowLink(false);
    setShowDrill(false);
  }

  function changeCurrViz(viz) {
    if (viz && currViz) {
      if (viz.id !== currViz.id) {
        setReloadSettings(true);
        setVisibleItems("");
        setTimeout(() => setReloadSettings(false), 100);
      }
    }

    setCurrViz(viz);
  }

  const fix = useFix();

  function runFix() {
    fix.mutate(
      {},
      {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (err) => console.log(err),
      }
    );
  }

  return (
    <>
      {editing && (
        <div className={`${styles.pageContainer2}`}>
          <Sidebar2
            menuItems={menuItems}
            active={active}
            updateActive={updateActive}
            condensed
            style={{ paddingTop: "5rem", width: "70px" }}
          ></Sidebar2>

          <div
            className={`${styles.configBox} ${
              !active && currViz ? styles.chartSettings : ""
            }`}
            style={!active && !currViz ? { width: "0px" } : undefined}
          >
            {active === "nav" && (
              <div className={styles.pagesTableContainer}>
                <ReportPages
                  report={report}
                  addPage={addPage}
                  onSelectPage={onSelectPage}
                  switchToPage={switchToPage}
                  activePage={analysisId}
                  refetch={refetchReport}
                  canEdit={canEdit}
                ></ReportPages>
              </div>
            )}

            {active === "style" && (
              <ReportStyles report={report}></ReportStyles>
            )}

            {/* {active === "file" && (
          
              <div className={styles.label5} style={{ fontWeight: "500" }}>
                File Settings
              </div>
              <FlexRow start>
                <Icon
                  iconName={"filetype-pdf"}
                  style={{ fontSize: "1.1rem" }}
                ></Icon>
                <Button link> Export page as PDF</Button>
              </FlexRow>
          )} */}

            {active === "settings" && (
              <>
                <div
                  className={styles.label5}
                  style={{ fontWeight: "500", padding: "1rem 1rem" }}
                >
                  Report Settings
                </div>
                <div style={{ padding: "1rem", width: "100%" }}>
                  <TextFieldSimple
                    label="Report Name"
                    value={report.name}
                    onChange={changeName}
                    disable={!report.userId && !canEdit}
                    style={{ fontSize: ".7rem" }}
                  ></TextFieldSimple>
                </div>

                <TieToSurvey
                  report={report}
                  refetch={refetchReport}
                  setShowDownload={setShowDownload}
                />

                <div style={{ padding: "1rem 1rem" }}>
                  <CombinedInput
                    options={tagOptions}
                    value={{ value: report.type, label: report.type }}
                    onChange={(obj) => changeType(obj.value)}
                    label="Report Tag or Type"
                    select
                    icon="bi-chevron-down"
                  ></CombinedInput>
                </div>
                <div className={styles.reportIcon}>
                  <Label style={{ width: "fit-content", padding: "0em" }}>
                    Report Icon
                  </Label>
                  <i
                    className={`bi-${
                      report?.icon ? report?.icon : "bar-chart"
                    }`}
                    onClick={() => setIcons(!icons)}
                  ></i>
                </div>
                {icons && (
                  <div className={styles.iconShopper}>
                    <IconShopper
                      folderView
                      graphs
                      onPick={(val) => changeIcon(val)}
                    ></IconShopper>
                  </div>
                )}
              </>
            )}

            {currViz &&
              !active &&
              currViz.type !== NoteType &&
              currViz.type !== LinkType && (
                <ConfigureBox
                  visualizationCopy={currViz}
                  setVisualizationCopy={setCurrViz}
                  custom_fields={custom_fields}
                  externalFilter={externalFilter}
                  visibleItems={visibleItems}
                  setVisibleItems={setVisibleItems}
                  labels={labels}
                  newChart={currViz.newChart}
                  reloadSettings={reloadSettings}
                />
              )}
          </div>
        </div>
      )}

      <div
        className={`${styles.container} ${editing && styles.back}`}
        onClick={() => {
          setCurrViz(null);
          setActive(null);
        }}
        id={"report-container-" + analysisId}
      >
        <div
          className={styles.navPresent}
          style={{ top: canEdit ? "" : "1rem" }}
        >
          {!editing && !dash && (
            <div className={styles.nav}>
              <FlexRow start style={{ width: "fit-content" }}>
                <div className={styles.isEdit}>
                  <Icon iconName={"info-circle"} blue></Icon>
                  Presentation
                  {canEdit && (
                    <span
                      onClick={() => setEditing(true)}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Edit Report?
                    </span>
                  )}
                </div>
                <div className={`${styles.bubble} ${styles.live}`}>Live</div>
                {/* <div
                  className={styles.section_label6}
                  style={{
                    whiteSpace: "nowrap",
                    margin: "0",
                    display: "flex",
                    gap: ".5rem",
                  }}
                >
                  Last Updated
                  {` ${trimDate(report?.updatedAt, true)}`}
                </div> */}
              </FlexRow>
              {basic && (
                <Button
                  shadow
                  blue
                  onClick={
                    report.project
                      ? report.project.length === 1
                        ? () => setShowDownload(report.project[0])
                        : () => setShowDownloads(true)
                      : null
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  Download Results
                </Button>
              )}
            </div>
          )}

          {editing && (
            <div className={styles.nav}>
              <FlexRow start style={{ width: "fit-content" }}>
                <div className={styles.isEdit}>
                  <Icon iconName={"info-circle"} blue></Icon>
                  Now Editing
                  {report.draftId && (
                    <span onClick={() => setEditing(false)}>Close Editor</span>
                  )}
                </div>
                <div className={`${styles.bubble} ${styles.draft}`}>Draft</div>
                <div
                  className={styles.section_label6}
                  style={{
                    whiteSpace: "nowrap",
                    margin: "0",
                    display: "flex",
                    gap: ".5rem",
                  }}
                >
                  {saveStatus}
                  {` ${trimDate(report?.updatedAt, true)}`}
                </div>
              </FlexRow>
              <FlexRow start style={{ width: "fit-content" }}>
                <AddChartButton
                  onAdd={onAddChart}
                  onShowLink={() => setShowLink(true)}
                  dash={dash}
                />
                <Button shadow onClick={onDiscard}>
                  {discarding ? (
                    <FlexRow start>
                      <Loading height={25} width={25}></Loading>
                      Discarding
                    </FlexRow>
                  ) : (
                    "Discard Draft"
                  )}
                </Button>
                <Button blue shadow onClick={handlePublish}>
                  {publishing ? (
                    <FlexRow start>
                      Publishing <Loading height={25} width={25}></Loading>
                    </FlexRow>
                  ) : (
                    "Publish Changes"
                  )}
                </Button>
                {/* <div className={styles.header_6} style={{ margin: "0" }}>
                  {report.name}
                </div> */}
              </FlexRow>
            </div>
          )}

          <FlexRow
            start
            style={{
              width: "fit-content",
              paddingTop: dash && !editing ? "20px" : "",
              padding: "0 1rem",
            }}
            gap={".2rem"}
          >
            {!dash && (
              <>
                {" "}
                <span
                  className={`${styles.header_6} ${styles.spanner}`}
                  style={{
                    margin: "0",
                    backgroundColor: showOptions ? "#d8d9d9" : "",
                  }}
                  onClick={() => setShowOptions(true)}
                >
                  Options
                </span>
                <span
                  className={`${styles.header_6} ${styles.spanner}`}
                  style={{
                    margin: "0",
                    backgroundColor: seePages ? "#d8d9d9" : "",
                  }}
                  onClick={() => setSeePages(true)}
                >
                  Pages
                </span>
              </>
            )}
            <span
              className={`${styles.header_6} ${styles.spanner}`}
              style={{
                margin: "0",
                backgroundColor: showFilter ? "#d8d9d9" : "",
              }}
              onClick={() => setShowFilter(true)}
            >
              Filters
            </span>

            {/* <span
              className={styles.reportSettingsButton}
              style={{
                margin: "0",
                backgroundColor: showFilter ? "#d8d9d9" : "",
              }}
              onClick={runFix}
            >
              Fix
            </span> */}
            {!dash && (
              <span
                className={`${styles.header_6} ${styles.spanner}`}
                style={{
                  margin: "0",
                  backgroundColor: showShare ? "#d8d9d9" : "",
                }}
                onClick={() => setShowShare(true)}
              >
                Share
              </span>
            )}
          </FlexRow>
          {showOptions && (
            <>
              <div
                className={styles.settingsBox}
                ref={refOptions}
                style={{ left: "1rem", width: "fit-content", top: "6rem" }}
                onClick={(e) => e.stopPropagation()}
              >
                {report.project.length > 0 && (
                  <div
                    className={styles.menuItem}
                    onClick={
                      report.project.length === 1
                        ? () => setShowDownload(report.project[0])
                        : () => setShowDownloads(true)
                    }
                  >
                    <i
                      className="bi bi-cloud-arrow-down"
                      style={{ color: "#15BCC7" }}
                    ></i>
                    Download Results
                  </div>
                )}
                <div className={styles.menuItem} onClick={analyzeFurther}>
                  <i className="bi bi-layers" style={{ color: "#15BCC7" }}></i>
                  Copy This Report
                </div>
                <div className={styles.menuItem} onClick={pdfExport}>
                  <i
                    className="bi bi-filetype-pdf"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  Export Page as PDF
                </div>
              </div>
            </>
          )}
          {showFilter && (
            <div
              className={`${styles.filter} ${
                dash && !editing ? styles.liveDashFilter : ""
              }`}
              style={{ right: canEdit ? "" : "0em" }}
              ref={ref2}
            >
              <div className={styles.filterHeader}>
                <div>Filter Page By</div>
                <div style={{ fontSize: ".6em" }}>
                  Changes override individual chart filters
                </div>
              </div>

              <PageFilter
                onFilterChange={onFilterChange}
                chosenFilter={externalFilter ? JSON.parse(externalFilter) : {}}
                analysisId={analysisId}
              ></PageFilter>
            </div>
          )}
          {showFilterSubtitle() && (
            <div className={styles.filtersSubtitle}>
              <>
                <i className="bi-funnel-fill"></i>
                {getFilterSubtitle()}
              </>
            </div>
          )}
          {!widescreen && (
            <FlexRow
              start
              style={{ width: "fit-content", padding: "1rem" }}
              gap={".2rem"}
            >
              <FlexRow start style={{ width: "fit-content" }} gap={"0rem"}>
                {zoom != 1 && (
                  <span
                    className={`${styles.header_6} ${styles.spanner}`}
                    style={{ margin: "0" }}
                    onClick={() => setZoom(1)}
                  >
                    Reset
                  </span>
                )}
                <span
                  className={`${styles.header_6} ${styles.aa}`}
                  style={{ margin: "0" }}
                >
                  {Math.floor(zoom * 100)}%
                </span>
                <span className={` ${styles.spanner}`} onClick={zoomOut}>
                  <Icon iconName="dash"></Icon>
                </span>
                <span className={`${styles.spanner}`} onClick={zoomIn}>
                  <Icon iconName="plus"></Icon>
                </span>
              </FlexRow>
              <span
                className={`${styles.header_6} ${styles.aa}`}
                style={{ margin: "0" }}
              >
                {report.name}
              </span>
            </FlexRow>
          )}
          <ReportHeader
            report={report}
            canEdit={canEdit}
            onSelectPage={onSelectPage}
            setShowShare={setShowShare}
            showShare={showShare}
            analysisId={analysisId}
            custom_fields={custom_fields}
            externalFilter={externalFilter}
            showDownload={showDownload}
            setShowDownload={setShowDownload}
            basic={basic}
            showFilter={showFilter}
            seePages={seePages}
            setSeePages={setSeePages}
            showDownloads={showDownloads}
            setShowDownloads={setShowDownloads}
          ></ReportHeader>
        </div>

        {showLink && (
          <Modal
            show
            onClose={closeLink}
            modalStyle={{ borderRadius: ".5rem" }}
          >
            <ChooseLink
              whenDone={(data) => {
                onAddChart("link", data);
                closeLink();
              }}
            />
          </Modal>
        )}

        {fetchAnalysis.isLoading && (
          <div className={styles.analysisLoadingDock}>
            <Loading></Loading>
          </div>
        )}
        {fetchAnalysis.isSuccess && (
          <div
            className={`${styles.zoomcontainer} ${editing && styles.back}`}
            onClick={() => {
              setCurrViz(null);
              setActive(null);
            }}
            id={"report-container2-" + analysisId}
          >
            <div
              className={`${styles.scrollablearea} ${
                dash && !editing ? styles.liveDash : ""
              }`}
              ref={scrollableAreaRef}
              id={"report-content-" + analysisId}
            >
              <div
                className={
                  !showDrill && !widescreen ? styles.zoomablecontent : ""
                }
                id={"scaledContainer" + analysisId}
                ref={contentRef}
                style={{
                  transform:
                    showDrill || widescreen ? "none" : `scale(${zoom})`,
                }}
              >
                <Analysis
                  key={analysisId}
                  analysis={fetchAnalysis.data.analysis}
                  refetch={() => fetchAnalysis.refetch()}
                  addChart={onAddChart}
                  canEdit={canEdit}
                  externalFilter={externalFilter}
                  custom_fields={custom_fields}
                  page={
                    pageOrder &&
                    pageOrder?.findIndex((a) => a === analysisId) + 1
                  }
                  pages={getPages()}
                  goToPage={switchToPage}
                  reportId={report.id}
                  currViz={currViz}
                  setCurrViz={changeCurrViz}
                  editing={editing}
                  setEditing={setEditing}
                  setVisibleItems={setVisibleItems}
                  visibleItems={visibleItems}
                  setLabels={setLabels}
                  zoom={zoom}
                  setZoom={setZoom}
                  setShowDrill={setShowDrill}
                  brandNew={brandNew}
                  setBrandNew={setBrandNew}
                  onEditChart={prepOnEditChart}
                  onQuickSave={onQuickSave}
                  widescreen={widescreen}
                  dash={dash}
                />
              </div>
            </div>
          </div>
        )}

        {report.analysis.length > 1 && !showDownload && !showShare && (
          <div className={styles.footer}>
            <div className={styles.reportFooter}>
              <div className={styles.reportFooterPage}>
                {" "}
                Page {pageOrder?.findIndex((a) => a === analysisId) + 1} of{" "}
                {report.analysis.length}
              </div>

              <Button shadow onClick={() => prevPage()} width={70}>
                Prev
              </Button>
              <Button shadow onClick={() => nextPage()} width={70}>
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function ReportHeader({
  report,
  canEdit,
  onSelectPage,
  analysisId,
  showShare,
  setShowShare,
  custom_fields,
  externalFilter,
  showDownload,
  setShowDownload,
  basic,
  showFilter,
  seePages,
  setSeePages,
  showDownloads,
  setShowDownloads,
}) {
  const [showSettings, setShowSettings] = useState(false);

  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const handleClickOutside = (event) => {
    if (ref3.current && !ref3.current.contains(event.target)) {
      setShowSettings(false);
    }
    if (ref4.current && !ref4.current.contains(event.target)) {
      setShowDownloads(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {seePages && (
        <PagesNav
          report={report}
          canEdit={canEdit}
          onSelectPage={onSelectPage}
          activePage={analysisId}
          close={() => setSeePages(false)}
        />
      )}
      {showShare && !basic && <Share setShow={setShowShare} />}
      {showDownload && (
        <DownloadTable
          onClose={() => setShowDownload(false)}
          surveyId={showDownload.id}
          externalChosenfilter={externalFilter ? externalFilter : {}}
        />
      )}
      {showDownloads &&
        !showFilter &&
        !showSettings &&
        !seePages &&
        !showShare && (
          <div className={styles.projectTies} ref={ref4}>
            {report.project.map((survey, i) => (
              <div
                className={styles.surveyRow}
                key={i}
                onClick={() => setShowDownload(survey)}
              >
                {survey?.name}
              </div>
            ))}
          </div>
        )}
    </>
  );
}

function AddChartButton({ onAdd, onShowLink, dash }) {
  const [show, setShow] = useState(false);

  function onClickListener() {
    setShow(false);
    document.removeEventListener("click", onClickListener, false);
  }

  function setUpShow() {
    if (!show) {
      setTimeout(
        () => document.addEventListener("click", onClickListener, false),
        100
      );
      setShow(true);
    }
  }

  const ref = useRef(null);

  return (
    <div
      className={styles.addChart}
      onClick={setUpShow}
      style={show ? { backgroundColor: "light gray" } : undefined}
    >
      <div>+ Add</div>

      {show && (
        <div className={styles.chartTypeMenu} ref={ref}>
          <div className={styles.chartType} onClick={() => onAdd("chart")}>
            Chart
            <i className={`bi bi-bar-chart-fill ${styles.chart} `}></i>
            {/* <img style={chartImageStyle} src={VerticalBarImage} alt="IMG" /> */}
          </div>
          <div className={styles.chartType} onClick={() => onAdd("table")}>
            <i
              className={`bi bi-layout-text-window-reverse ${styles.table}`}
            ></i>
            Table
            {/* <img style={chartImageStyle} src={TableImage} alt="IMG" /> */}
          </div>
          <div className={styles.chartType} onClick={() => onAdd("metric")}>
            Metric <i className={`bi bi-123 ${styles.metric} `}></i>
          </div>

          <div className={styles.chartType} onClick={() => onAdd("note")}>
            <i className={`bi bi-text-center ${styles.notes} `}></i>Note
          </div>

          {dash && (
            <div className={styles.chartType} onClick={onShowLink}>
              <i className={`bi bi-link-45deg ${styles.link} `}></i>Link
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function PagesNav({ report, canEdit, onSelectPage, activePage, close }) {
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={styles.pagesPopout}
      style={{ right: canEdit ? "" : "-6em" }}
      ref={ref}
    >
      <ReportPages
        report={report}
        onSelectPage={onSelectPage}
        activePage={activePage}
        pagesTab
        noTitle
      ></ReportPages>
    </div>
  );
}

function ReportPages({
  report,
  addPage,
  onSelectPage,
  switchToPage,
  activePage,
  pagesTab,
  noTitle,
  refetch,
  canEdit,
}) {
  const pageOrder =
    typeof report.pageOrder === "string"
      ? JSON.parse(report.pageOrder)
      : report?.pageOrder
      ? report?.pageOrder
      : [];

  const [pages, setPages] = useState();
  const [newPage, setNewPage] = useState(null);
  const [copying, setCopying] = useState("");

  const deleteAnalysis = useDeleteAnalysis();
  const copyAnalysis = useCopyAnalysis();
  const updateReport = useUpdateReport();
  const updateAnalysis = useUpdateAnalysis();

  function addTempPage() {
    // let temp = [...pages];
    let tempPage = { name: "", id: "new" };
    // temp.push(tempPage);
    setNewPage(tempPage);
  }

  function deletePage(pageId) {
    let anal = report.analysis.find((a) => a.id === pageId);
    if (window.confirm("Are you sure you want to delete " + anal?.name + "?")) {
      deleteAnalysis.mutate(
        {
          deleteAnalysisId: pageId,
        },
        {
          onSuccess: (data) => {
            console.log("page deleted");
            let newPages = [...pageOrder];
            let ind = newPages.indexOf(pageId);
            newPages.splice(ind, 1);

            if (pageId === activePage) {
              if (ind < newPages?.length) {
                switchToPage(newPages[ind]); // Will now be the one after it
              } else {
                switchToPage(newPages[ind - 1]); // Was last page
              }
            }
            refetch();
          },
          onError: () => {
            console.log("Error deleting the page");
          },
        }
      );
    }
  }

  function copyPage(pageId) {
    copyAnalysis.mutate(
      {
        copyAnalysisId: pageId,
      },
      {
        onSuccess: (data) => {
          if (data.copyAnalysis) {
            console.log("page copied!");
            refetch();
            setCopying("");
          }
        },
        onError: () => {
          console.log("Error copying the page");
        },
      }
    );
    setCopying(pageId);
  }

  function handleNewPage(name) {
    if (!name) {
      name = "Page " + (pageOrder.length + 1);
    }
    addPage(name);
    setNewPage(false);
  }

  function savePageOrder(order) {
    updateReport.mutate(
      {
        data: {
          pageOrder: JSON.stringify(order),
        },
        id: report.id,
      },
      {
        onSuccess: (data) => {
          console.log("Order Saved");
          if (refetch) {
            refetch();
          }
        },
        onError: () => {
          console.log("Error Saving Order");
        },
      }
    );
  }

  function handleDragEnd({ active, over }) {
    if (!over?.id) {
      return;
    }
    if (over.id === active.id) {
      return;
    }

    let activeInd = active.data.current.sortable.index;
    let overInd = over.data.current.sortable.index;

    let newPages = [...pageOrder];

    let activeId = newPages[activeInd];
    let overId = newPages[overInd];

    newPages.splice(activeInd, 1);
    let newOverInd = newPages.indexOf(overId);

    if (activeInd > overInd) {
      // moving up
      newPages.splice(newOverInd, 0, activeId);
    } else {
      // moving down
      newPages.splice(newOverInd + 1, 0, activeId);
    }

    setPages(newPages);
    setTimeout(() => {
      setPages();
    }, 1500);

    savePageOrder(newPages);
  }

  function saveName(pageId, name) {
    updateAnalysis.mutate(
      {
        id: pageId,
        data: {
          name: name,
        },
      },
      {
        onSuccess: (data) => {
          console.log("Updated name");
          refetch();
        },
        onError: (data) => {
          console.log("Error saving name");
        },
      }
    );
  }

  return (
    <div
      className={`${styles.pagesTable} ${noTitle ? styles.pagesRadius : ""}`}
    >
      {!noTitle && (
        <div
          className={styles.label5}
          style={{ padding: ".5rem", fontWeight: "500" }}
        >
          Report Pages
        </div>
      )}
      <table>
        <tbody>
          <DndContext
            // sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              id={"Sortable Pages"}
              items={pageOrder}
              strategy={verticalListSortingStrategy}
            >
              {(pages ? pages : pageOrder).map((pageId, index) => (
                <PageRow
                  key={index}
                  pageId={pageId}
                  report={report}
                  isUser={report.userId}
                  active={pageId === activePage}
                  pagesTab={pagesTab}
                  index={index}
                  canEdit={canEdit}
                  copyPage={copyPage}
                  deletePage={deletePage}
                  saveName={saveName}
                  onSelectPage={onSelectPage}
                  copying={copying === pageId}
                />
              ))}
            </SortableContext>
          </DndContext>

          {newPage && (
            <tr>
              <Input
                saveText="Add"
                onSave={handleNewPage}
                onExit={() => setNewPage(false)}
                initText=""
                index={pageOrder.length}
              />
            </tr>
          )}

          {(report.userId || canEdit) && !pagesTab && (
            <tr onClick={addTempPage}>
              <td style={{ padding: "10px 50px", width: "100%" }}>
                <div className={styles.addpage}>+ Page</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function PageRow({
  pageId,
  isUser,
  active,
  pagesTab,
  report,
  index,
  canEdit,
  copyPage,
  deletePage,
  saveName,
  onSelectPage,
  copying,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: pageId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    width: "100%",
    backgroundColor: active ? "#E9E9E9" : "",
  };

  const [editName, setEditName] = useState(false);

  function handleSaveName(name) {
    saveName(pageId, name);
  }

  const analysis = report.analysis.find((a) => a.id === pageId);

  return (
    <tr
      ref={setNodeRef}
      {...attributes}
      style={{ ...style }}
      onClick={
        (pagesTab && !active) || editName ? () => onSelectPage(pageId) : null
      }
    >
      {!editName && (
        <td className={styles.pageTableRow}>
          <div
            className={styles.page}
            onClick={!active ? () => onSelectPage(pageId) : null}
          >
            {pagesTab && <>{index + 1}. </>}
            {analysis && analysis.name ? analysis.name : "Page " + (index + 1)}
          </div>
          {(isUser || canEdit) && !pagesTab && (
            <div className={styles.icons}>
              <i
                className={`bi bi-pencil ${styles.editName}`}
                onClick={() => setEditName(true)}
              ></i>
              {/* <i
                  className="bi bi-clipboard"
                  onClick={copyReport}
                ></i> */}
              {report.analysis.length > 1 && (
                <i
                  className={`bi bi-trash3 ${styles.trash}`}
                  onClick={() => deletePage(pageId)}
                ></i>
              )}
              {!copying && (
                <i
                  className={`bi bi-copy ${styles.copy}`}
                  onClick={() => copyPage(pageId)}
                ></i>
              )}
              {copying && <Loading height={20} width={20} />}
              <div className={styles.grid} {...listeners}>
                <i className="bi bi-grip-vertical"></i>
              </div>
            </div>
          )}
        </td>
      )}
      {editName && (
        <Input
          onSave={handleSaveName}
          onExit={() => setEditName(false)}
          initText={analysis?.name}
          saveText="Save"
          index={index}
        />
      )}
    </tr>
  );
}

function Input({ initText, saveText, onExit, onSave, index }) {
  const [value, setValue] = useState(initText);

  function listenForEnter(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      onSave(value);
    }
  }

  function handleSave() {
    onSave(value);
    onExit();
  }

  return (
    <td>
      <div className={styles.page}>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={styles.inputLine}
          onKeyDown={listenForEnter}
          placeholder={"Page " + (index + 1)}
        ></input>

        <div className={styles.exitOrSave}>
          <span onClick={onExit}>X</span>
          <Button onClick={handleSave} blue>
            {saveText}
          </Button>
        </div>
      </div>
    </td>
  );
}

function TieToSurvey({ report, refetch, setShowDownload }) {
  const [edited, setEdited] = useState(false);
  const [tied, setTied] = useState([...report.project]);
  const [show, setShow] = useState(false);

  const updateTies = useUpdateTiedSurveys();

  function saveTies() {
    updateTies.mutate(
      {
        reportId: report.id,
        projectIds: {
          list: tied.map((s) => s.id),
        },
      },
      {
        onSuccess: (data) => {
          console.log("updated:", data);
          refetch();
        },
        onError: (data) => {
          setTied(report.project);
        },
      }
    );
    setEdited(false);
  }

  function untie(survey) {
    let copy = [...tied];
    let index = copy.findIndex((s) => s.id === survey.id);
    if (index >= 0) {
      copy.splice(index, 1);
    }
    setTied(copy);
    setEdited(true);
  }

  function tie(survey) {
    let copy = [...tied];
    copy.push(survey);
    setTied(copy);
    setShow(false);
    setEdited(true);
  }

  function revert() {
    setEdited(false);
    setTied([...report.project]);
  }

  return (
    <div className={styles.tiedToContainer}>
      <div className={styles.label6}>Surveys Tied to Report:</div>

      <div className={styles.tiedSurveys}>
        {tied.map((survey, i) => (
          <TiedSurvey
            key={i}
            survey={survey}
            untie={untie}
            setShowDownload={setShowDownload}
          />
        ))}
        <div className={styles.addSurvey}>
          <Button shadow onClick={() => setShow(true)}>
            + add
          </Button>
        </div>
        {show && (
          <AddProject
            included={tied}
            close={() => setShow(false)}
            addProject={tie}
          />
        )}

        {edited && (
          <div className={styles.saveTies}>
            <Button shadow onClick={saveTies}>
              Save
            </Button>
            <Button red onClick={revert}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function TiedSurvey({ survey, untie, setShowDownload }) {
  const [over, setOver] = useState(false);

  return (
    <div className={`${styles.tiedSurvey} ${over ? styles.careful : ""}`}>
      <span>
        {survey.name}{" "}
        <span className={styles.link} onClick={() => setShowDownload(survey)}>
          View Results
        </span>
      </span>
      <div
        className={styles.deleteSurvey}
        onClick={() => untie(survey)}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <i className={`bi bi-${over ? "trash3-fill" : "trash3"}`}></i>
      </div>
    </div>
  );
}

function AddProject({ included, addProject, close }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    fetchProjects.refetch();
  }, [searchString]);

  const fetchProjects = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.addProjectAnchor}>
      <div className={styles.addProjects} ref={ref}>
        <div className={styles.search}>
          <TextFieldSimple
            value={searchString}
            placeholder="Search Surveys..."
            onChange={(val) => setSearchString(val)}
            customStyles={styles.searchSurveys}
          ></TextFieldSimple>
        </div>

        {fetchProjects.isSuccess && (
          <div className={styles.projectOptions}>
            {fetchProjects.data?.surveys.map((project, index) => (
              <React.Fragment key={index}>
                {!included.some((p) => p.id === project.id) && (
                  <div
                    key={index}
                    className={styles.projectOption}
                    onClick={() => {
                      addProject(project);
                    }}
                  >
                    {project.name}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
