// External
import React, { useEffect, useMemo, useState } from "react";

// Internal
import {
  useDeleteContactGql,
  usefetchContactsFilters,
  useSearchContacts,
  useUpdateContactAttGql,
} from "api/resources/contacts/contacts";
import styles from "./AllContacts.module.scss";
import Modal from "components/ReactModal/ReactModal.jsx";
import { TabPill } from "components/layouts";
import { Manual } from "./NewContacts/manual/Manual";
import { Upload } from "./NewContacts/upload/Upload";
import ExternalImport from "../ExternalImport/ExternalImport";
import ContactDetails from "components/Popout/ContactProfile";
import { useFetchOrganizationLinkedAccounts } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import ObjectTable from "components/tables/BasicTable/ObjectTable";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import FieldAdjuster from "./FieldAdjuster";
import ColumnsToDisplay from "./ColumnsToDisplay";
import ImportHow from "./ImportHow";
import { useNavigate } from "react-router-dom";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { AddContact } from "./NewContacts/AddContact/AddContact";
import CreateAudience from "../audiences/CreateAudience";
import { Export } from "./export/Export";
import ObjectLayout from "components/layouts/ObjectLayout/ObjectLayout";

export const AllContacts = ({ user, organization, roles, onExport, updateRoute }) => {
  const MyOrg = useFetchOrganizationLinkedAccounts(organization?.id);
  useMemo(() => {
     if (updateRoute) {
       updateRoute(0);
     }
   }, []);
  return (
    <div className={styles.pager}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <AllContactsContent
          user={user}
          organization={MyOrg.data?.organization}
          roles={roles}
          customFields={MyOrg.data?.organization?.custom_field}
          onExport={onExport}
        ></AllContactsContent>
      )}
    </div>
  );
};

const AllContactsContent = ({
  user,
  organization,
  roles,
  customFields,
  onExport,
}) => {
  const [activeContact, setActiveContact] = useState();

  const seeContact = (contact) => {
    setActiveContact(contact);
  };

  const obscure = !roles?.canSeeContactInfo;
  const [selected, setSelected] = useState([]);
  const [headers, setHeaders] = useState(initHeaders());
  function initHeaders() {
    let heads = [];

    for (let field of customFields) {
      heads.push({
        // width: 200,
        id: field?.id,
        value: field?.generic ? field?.name : field?.id,
        label: field?.displayName ? field?.displayName : field?.name,
        name: field?.name,
        dataType: field?.dataType,
        enabled: field?.enabled,
        editable: field?.editable,
        custom: true,
        sort: false,
        canSort: false,
        obscure: obscure,
        salesforceColumn: field?.salesforceColumn,
        icon: field?.icon ? field?.icon : "person",
        multiple: field?.multiple,
        delimiter: field?.delimiter,
        generic: field?.generic,
        index: field?.index,
        min: field?.min ? field?.min : 0,
        max: field?.max ? field?.max : 100,
        trueValue: field?.trueValue,
        falseValue: field?.falseValue,
        unique: field?.unique ? true : false,
        required: field?.required ? true : false,
        cell_style: (name, id, i, contact) =>
          checkStyle(name, id, i, contact, field),
      });
    }
    return heads;
  }

  function checkStyle(name, id, i, contact, field) {
    if (field?.name === "contactName" && contact) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <div
            className={`${styles.link} ${styles.blueDark}`}
            style={{ fontWeight: "500" }}
            onClick={() => {
              seeContact(contact);
            }}
          >
            {contact && `${contact?.firstName} ${contact?.lastName}`}
          </div>
        </div>
      );
    }

    if (field?.dataType === "boolean") {
      if (name === true) {
        return field?.trueValue ? field?.trueValue : "true";
      }
      if (!name || name === false) {
        return field?.falseValue ? field?.falseValue : "false";
      }
    }

    return name;
  }


  const tabBarItems = [
    {
      id: 0,
      name: "Upload From File",
      // to: `questions`,
    },
    {
      id: 1,
      name: "Manual Entry",
      // to: `design`,
    },
    {
      id: 2,
      name: "External Import",
      // to: `design`,
    },
  ];
  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);

  const [perPage, setPerpage] = useState(50);
  const [active, setActive] = useState(0);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [show, setShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [adjustFields, setAdjustFields] = useState(false);
  const [displayColumns, setDisplayColumns] = useState(false);
  const [importer, setImporter] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [createAudience, setCreateAudience] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showLayout, setShowLayout] = useState(false);

  const handleActiveUpdate = (item) => {
    setActive(item);
  };

  const newOptions = [
    {
      onClick: () => setAddContact(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-fill"}></Icon> Create a new contact
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a new contact from scratch with columns.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setImporter(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"people-fill"}></Icon> Import Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Import in bulk with a file or an external account.
          </div>
        </div>
      ),
    },
  ];
  const dotOptions = [
    {
      onClick: () => setShowLayout(true),
      data: (
        <>
          <Icon sapphire iconName={"layout-text-window-reverse"}></Icon> Contact
          Layout
        </>
      ),
    },
  ];

  const columnOptions = [
    {
      onClick: () =>
        roles?.canEditContacts ? setAdjustFields(!adjustFields) : undefined,
      data: (
        <>
          <Icon sapphire={roles?.canEditContacts} iconName={"pencil"}></Icon>{" "}
          Edit columns
        </>
      ),
      style: { backgroundColor: !roles?.canEditContacts ? "lightgray" : "" },
    },
    {
      onClick: () => setDisplayColumns(true),
      data: (
        <>
          <Icon sapphire iconName={"columns"}></Icon> Select columns to display
        </>
      ),
    },
    {
      onClick: () => (roles?.canEditContacts ? setNewColumn(true) : undefined),
      data: (
        <>
          <Icon sapphire={roles?.canEditContacts} iconName={"plus"}></Icon>{" "}
          Create a new column
        </>
      ),
      style: { backgroundColor: !roles?.canEditContacts ? "lightgray" : "" },
    },
  ];

  function handleSelectPage() {
    let toadd = [];
    let firstIndex = 100;
    for (let c of data) {
      let index = selected.findIndex((i) => i.id === c.id);
      if (index < 0) {
        toadd.push(c);
      } else if (index < firstIndex) {
        firstIndex = index;
      }
    }
    let items = [...selected, ...toadd];
    if (toadd.length === 0) {
      items.splice(firstIndex, data.length);
    }

    setSelected(items);
  }

  const selectedOptions = [
    { onClick: () => handleSelectPage(), data: "Select Page" },
    { onClick: () => setSelected([]), data: "Remove Selected" },
  ];

  const contactsQuery = useSearchContacts(
    searchString,
    perPage,
    sort,
    "",
    "",
    JSON.stringify(chosenFilters)
  );
  const data = parseCustomFields(
    contactsQuery?.data?.pages.flatMap((page) => page?.response?.contacts) ||
      [],
    headers
  );

  const count =
    contactsQuery?.data?.pages?.length > 0
      ? contactsQuery?.data?.pages[0]?.response?.count
      : 0;

  function handleFilterChange(val) {
    setChosenFilters(val);
  }

  function handleSetHeaders(vals) {
    setHeaders(vals);
  }

  function handleSearch(val) {
    contactsQuery?.remove();
    contactsQuery?.refetch();
  }

  function handleColumns(val) {
    setDisplayColumns(val);
  }

  function handlePageNum(val) {
    
    if (contactsQuery?.hasNextPage) {
      contactsQuery?.fetchNextPage();
      setPageNumber(val);
    }
  }

useEffect(() => {
  contactsQuery.remove();
  contactsQuery.refetch();
}, [chosenFilters])

  const navigate = useNavigate();
  const updateContact = useUpdateContactAttGql();

  function saveContact(value, header, rowdata, i) {
    let contactData = {};
    let attributeData = { attributes: [] };
    if (!header?.generic) {
      let attributes = [];
      if (header?.multiple) {
        attributes = value?.value?.map((a) => {
          return { id: a?.id, name: a?.name, customFieldId: header?.id };
        });
      } else {
        let newAtt = {
          id: value?.id,
          name: value?.value,
          customFieldId: header?.id,
        };
        attributes = [newAtt];
      }
      attributeData = { attributes: attributes };
    }

    if (header?.generic === true) {
      contactData[header?.value] = value?.value;
    }

    updateContact.mutate(
      {
        data: contactData,
        attributeData: attributeData,
        id: rowdata?.id,
        newContact: false,
      },
      {
        onSuccess: (data) => {
          // setEditName(false);
        },
        onError: () => {

        }
      }
    );

   
  }

  const deletePerson = useDeleteContactGql();

  const deleteContact = (data) => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      deletePerson.mutate(
        {
          deleteContactId: data?.id,
        },
        {
          onSuccess: (data) => {
            location.reload();
          },
        }
      );
    }
  };

  return (
    <>
      <ObjectTable
        data={data}
        headers={headers}
        setHeaders={setHeaders}
        maxPage={count / perPage < 1 ? 1 : Math.ceil(count / perPage)}
        maxItems={count}
        pageNum={pageNumber}
        setPageNum={handlePageNum}
        perPage={perPage}
        setPerPage={setPerpage}
        sort={sort}
        setSort={setSort}
        pageSkip={pageSkip}
        setPageSkip={setPageSkip}
        customFields={customFields}
        user={user}
        roles={roles}
        searchString={searchString}
        setSearchString={setSearchString}
        onSearch={handleSearch}
        searchPlaceholder={"Search Contacts..."}
        emptyMessage={"No Contacts"}
        addOptions={newOptions}
        addOptionsHeight={100}
        settingOptions={dotOptions}
        settingOptionsHeight={50}
        filters={chosenFilters}
        setFilters={handleFilterChange}
        loading={contactsQuery?.isLoading}
        fullLoading={
          !contactsQuery?.isLoading && contactsQuery?.isFetchingNextPage
        }
        select
        selected={selected}
        setSelected={setSelected}
        selectedOptions={selectedOptions}
        selectedOptionsHeight={50}
        allColumns={customFields}
        columnOptions={columnOptions}
        columnOptionsHeight={50}
        exportClick={
          roles?.canSeeContactInfo ? () => setShowExport(true) : undefined
        }
        bottomButton={
          <div
            className={styles.link}
            onClick={() => {
              navigate("/previous-imports");
            }}
          >
            View Import Jobs
          </div>
        }
        onCreateAudienceClick={
          roles?.canCreateEditOrgAudiences
            ? () => setCreateAudience(true)
            : undefined
        }
        onSaveEditableCell={saveContact}
        onClickEditAction={seeContact}
        onClickDeleteAction={roles?.canEditContacts ? deleteContact : undefined}
        actionCell
      ></ObjectTable>

      <Modal
        show={showLayout}
        onClose={() => {
          setShowLayout(false);
        }}
        modalStyle={{
          borderRadius: "1em",
          height: "100%",
          width: "100%",
          // maxWidth: "1200px",
          overflow: "hidden",
        }}
        dark
      >
        {showLayout && (
          <ObjectLayout organizationId={organization?.id}></ObjectLayout>
        )}
      </Modal>

      <Modal
        show={show}
        onClose={() => setShow(false)}
        modalStyle={{
          borderRadius: "1em",
          height: "100%",
          width: "100%",
          maxWidth: "1200px",
        }}
        dark
      >
        <div className={styles.createContactsContainer}>
          <div className={styles.top}>
            <div style={{ maxWidth: "390px", paddingBottom: "10px" }}>
              <div className={styles.header}>Create/Update Contacts</div>
              <div className={styles.desc}>
                Upload a .csv file of your audience. If contact email already
                exists in your organization, then only the fields for that
                contact that you uploaded will be updated.{" "}
                <a>First three headers must be: firstName, lastName, email</a>
              </div>
            </div>
            <div className={styles.pill}>
              <TabPill
                tabBarItems={tabBarItems}
                active={active}
                updateActive={handleActiveUpdate}
                wings
              ></TabPill>
            </div>
          </div>
          {active === 0 && <Upload user={user} />}
          {active === 1 && (
            <div className={styles.manualContainer}>
              <Manual></Manual>
            </div>
          )}
          {active === 2 && (
            <ExternalImport user={user} organization={organization} />
          )}
        </div>
      </Modal>

      {activeContact && (
        <ContactDetails
          onClose={() => {
            setActiveContact(null);
          }}
          contact={activeContact}
          editable={roles?.canEditContacts}
          roles={roles}
        ></ContactDetails>
      )}

      {adjustFields && (
        <FieldAdjuster
          show={adjustFields}
          setShow={setAdjustFields}
          salesforce={organization?.linkedAccounts?.find(
            (a) => a?.accountType === "Salesforce"
          )}
        ></FieldAdjuster>
      )}

      {displayColumns && (
        <ColumnsToDisplay
          show={displayColumns}
          setShow={handleColumns}
          columns={headers}
          setColumns={handleSetHeaders}
        />
      )}

      {createAudience && (
        <Modal
          show={createAudience}
          onClose={() => setCreateAudience(false)}
          modalStyle={{
            borderRadius: ".5rem",
            height: "500px",
            width: "900px",
          }}
          dark
        >
          <CreateAudience
            audience={{}}
            filters={chosenFilters}
            setSettings={setCreateAudience}
          ></CreateAudience>
        </Modal>
      )}

      {importer && (
        <Modal
          show={importer}
          onClose={() => setImporter(false)}
          modalStyle={{
            borderRadius: "1em",
            height: "500px",
            width: "900px",
          }}
          dark
        >
          <ImportHow
            cancel={() => setImporter(false)}
            user={user}
            organization={organization}
            objectColumns={headers}
          ></ImportHow>
        </Modal>
      )}
      {newColumn && (
        <Modal
          dark
          show={newColumn}
          modalStyle={{ width: "800px" }}
          onClose={() => setNewColumn(undefined)}
        >
          <EditSingleCustomField
            onClose={() => setNewColumn(false)}
            field={{}}
            // onSaved={handleSaved}

            brandNew={true}
            // noModal
            // deleteField={handleDeleteField}
          />
        </Modal>
      )}
      {addContact && (
        <AddContact onClose={() => setAddContact(false)} org={organization} />
      )}

      {showExport && (
        <Export
          onClose={() => setShowExport(false)}
          org={organization}
          filters={chosenFilters}
          search={searchString}
          sort={sort}
          headers={headers}
          onExport={onExport}
        />
      )}
    </>
  );
};
