/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 brodyspencer. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer, natehanson

*/
import { useState, useEffect, useRef } from "react";
import styles from "./SurveyProject.module.scss";
import { trimDate } from "assets/functions/DateFunctions";
import {
  // getParticipationCount,
  useCopyProject,
  useDeleteProjectGql,
  useFetchProgramRecent,
} from "api/resources/projects/projects";
import { useNavigate } from "react-router-dom";
import Chart from "components/Charts/Chart";
import { Loading } from "components/Loading/Loading";
import Status from "components/Status/Status";
import Button from "components/Button/Button";
import AddLinkToDash from "pages/dashboard/AddToDash/AddLinkToDash";

function SurveyProject({
  survey,
  onClick,
  roles,
  refetch,
  iterations,
  isCreate,
  onClickChoose,
  chosen,
}) {
  const copyProjectMutation = useCopyProject();
  const deleteProject = useDeleteProjectGql();


  const [showOptions, setShowOptions] = useState(false);

  const optionsRef = useRef(null);

  function clickShow(e) {
    e.stopPropagation();
    setShowOptions(true);
  }

  function checkCanDelete() {
    if (survey.status === "Draft" && roles.canCreateEditOrgSurveys) {
      return true;
    }
    return false;
  }

  let navigate = useNavigate();
  function changeRoute(path) {
    navigate(path);
  }

  function copyProject(id) {
    copyProjectMutation.mutate(
      {
        data: {
          projectId: id,
        },
      },
      {
        onSuccess: (data) => {
          window.localStorage.removeItem("page1");
          window.localStorage.removeItem("page2");
          window.localStorage.removeItem("page3");
          window.localStorage.removeItem("page");
          let path = data.copyProject.id;
          if (data.copyProject.isProgram) {
            localStorage.setItem("activeprojecttab", 0);
            navigate("/program/" + path + "/survey-details");
          } else {
            localStorage.setItem("activeprojecttab", 0);
            navigate("/project/" + path + "/survey-details");
          }
        },
      }
    );
  }


  function onDelete() {
    if (confirm("Are you sure you want to delete this survey?")) {
      deleteProject.mutate(
        {
          projectId: survey.id,
        },
        {
          onSuccess: () => {
            if (refetch) {
              refetch();
            }
          },
        }
      );
    }
  }

  const [showPreview, setShowPreview] = useState(false);

  function handleClick(e) {
    if (!optionsRef.current || !optionsRef.current.contains(e.target)) {
      onClick(survey);
    }
  }


  return (
    <>
      <div
        className={`${styles.surveyWidget} ${
          isCreate && chosen === survey?.id && styles.isCreate
        }`}
        onClick={isCreate ? undefined : handleClick}
        onMouseEnter={isCreate ? () => setShowPreview(true) : undefined}
        onMouseLeave={isCreate ? () => setShowPreview(false) : undefined}
      >
        {showPreview && (
          <div className={styles.buttons}>
            <Button
              shadow
              blue
              onClick={(e) => {
                e.stopPropagation();
                onClick(survey);
              }}
            >
              Preview
            </Button>
            {chosen != survey?.id && (
              <Button
                shadow
                onClick={(e) => {
                  e.stopPropagation();
                  onClickChoose(survey);
                }}
                blue
              >
                Choose
              </Button>
            )}
            {chosen === survey?.id && (
              <Button
                shadow
                onClick={(e) => {
                  e.stopPropagation();
                  onClickChoose("");
                }}
                blue
              >
                Undo
              </Button>
            )}
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.topLeft}>
            <div className={styles.pills}>
              <div
                className={styles.header_4}
                style={{
                  margin: "0",
                  fontSize: window.innerWidth < 560 ? ".7rem" : "1rem",
                }}
              >
                {survey?.name}
              </div>
              {!survey.userId && survey.organizationId && (
                <Status blue>Survey</Status>
              )}
              {!survey.userId &&
                survey.organizationId &&
                survey.type != "project" &&
                survey.type != "Custom" && (
                  <Status sapphire>{survey.type}</Status>
                )}
              {survey?.status === "Open" && window.innerWidth < 560 && (
                <Status green style={{ fontSize: ".6rem" }}>
                  {survey?.status}
                </Status>
              )}
              {survey?.status === "Closed" && window.innerWidth < 560 && (
                <Status orange style={{ fontSize: ".6rem" }}>
                  {survey?.status}
                </Status>
              )}
              {survey?.status === "Draft" && window.innerWidth < 560 && (
                <Status yellow style={{ fontSize: ".6rem" }}>
                  {survey?.status}
                </Status>
              )}
            </div>
          </div>
          <div className={styles.pills}>
            <div className={styles.startedText}>
              <span>Questions:</span>
              <div>
              {survey?.questionCount}
              </div>
            </div>
            |
            <div className={styles.startedText}>
              <span>Messages:</span>
              <div>{survey?.distributionCount}</div>
            </div>
            |
            <div className={styles.startedText}>
              <span>Responses:</span>
              <div>{survey?.responseCount}</div>
            </div>
          </div>
          <div className={styles.pills}>
            <div className={styles.startedText}>
              <span>Created:</span>
              <div>{trimDate(survey.createdAt)}</div>
            </div>
            |
            {survey?.distributionCount > 0 && (
              <div className={styles.startedText}>
                <span>Last Send:</span>
                <div>{trimDate(survey?.lastDistributionDate)}</div>
              </div>
            )}
          </div>
        </div>
        <div
          className={styles.section}
          style={{
            alignItems: "flex-end",
            justifyContent: "center",
            gap: "0em",
            padding: "0em 2em",
          }}
        >
          <div style={{ width: "fit-content" }}>
            {survey?.status === "Open" && window.innerWidth > 560 && (
              <Status green style={{ fontSize: "1rem" }}>
                {survey?.status}
              </Status>
            )}
            {survey?.status === "Closed" && window.innerWidth > 560 && (
              <Status orange style={{ fontSize: "1rem" }}>
                {survey?.status}
              </Status>
            )}
            {survey?.status === "Draft" && window.innerWidth > 560 && (
              <Status yellow style={{ fontSize: "1rem" }}>
                {survey?.status}
              </Status>
            )}
          </div>
          <div className={styles.stats}>
            {/* {survey.status != "Draft" && !survey.isProgram && (
                <Stat title={"Responses"} value={survey?.responseCount} />
              )} */}

            {survey?.status === "Draft" &&
              survey?.responseCount < 1 &&
              survey?.questionCount < 1 && (
                <div
                  className={styles.surveyState}
                  onClick={(e) => {
                    e.stopPropagation();
                    localStorage.setItem("activeprojecttab", 1);
                    changeRoute(
                      survey.isProgram
                        ? `/program/${survey.id}/survey-build`
                        : `/project/${survey.id}/survey-build`
                    );
                  }}
                >
                  <i className={`bi-ui-checks ${styles.blue}`}></i> Add
                  questions
                </div>
              )}
            {window.innerWidth > 560 &&
              survey.status === "Draft" &&
              survey?.responseCount < 1 &&
              survey?.questionCount > 0 &&
              survey?.distributionCount > 0 && (
                <div
                  className={styles.surveyState}
                  onClick={(e) => {
                    e.stopPropagation();
                    localStorage.setItem("activeprojecttab", 2);
                    changeRoute(
                      survey.isProgram
                        ? `/program/${survey.id}/delivery`
                        : `/project/${survey.id}/delivery`
                    );
                  }}
                >
                  <i className={`bi-truck ${styles.blue}`}></i> Ready to send
                </div>
              )}
            {window.innerWidth > 560 &&
              survey?.status === "Draft" &&
              survey?.responseCount < 1 &&
              survey?.questionCount > 0 &&
              survey?.distributionCount > 0 && (
                <div
                  className={styles.surveyState}
                  onClick={(e) => {
                    e.stopPropagation();
                    localStorage.setItem("activeprojecttab", 2);
                    changeRoute(
                      survey.isProgram
                        ? `/program/${survey.id}/delivery/messages`
                        : survey.activeLink
                        ? `/project/${survey.id}/delivery`
                        : `/project/${survey.id}/delivery/messages`
                    );
                  }}
                >
                  <i className={`bi-truck ${styles.blue}`}></i> Build Messages
                </div>
              )}
            {window.innerWidth > 560 &&
              survey.status === "Draft" &&
              survey.responseCount < 1 &&
              survey?.questionCount > 0 &&
              survey.isProgram &&
              survey.distribution.length < 0 && (
                <div
                  className={styles.surveyState}
                  onClick={(e) => {
                    e.stopPropagation();
                    localStorage.setItem("activeprojecttab", 2);
                    changeRoute(
                      survey.isProgram
                        ? `/program/${survey.id}/delivery/messages`
                        : survey.activeLink
                        ? `/project/${survey.id}/delivery`
                        : `/project/${survey.id}/delivery/messages`
                    );
                  }}
                >
                  <i className={`bi-truck ${styles.blue}`}></i> Build Messages
                </div>
              )}

            {window.innerWidth > 560 &&
              survey.status === "Draft" &&
              survey.responseCount < 1 &&
       
              survey?.questionCount > 0 &&
              survey.isProgram &&
              survey.distribution.length > 0 && (
                <div
                  className={styles.surveyState}
                  onClick={(e) => {
                    e.stopPropagation();
                    localStorage.setItem("activeprojecttab", 0);
                    changeRoute(
                      survey.isProgram
                        ? `/program/${survey.id}/survey-details`
                        : `/project/${survey.id}/survey-details`
                    );
                  }}
                >
                  <i className={`bi-truck ${styles.blue}`}></i> Send First
                  Survey
                </div>
              )}

            {/* {window.innerWidth > 650 &&
              survey.isProgram &&
              survey.status != "Draft" && (
                <TrendChart survey={survey}></TrendChart>
              )} */}

            {/* {window.innerWidth > 900 &&
            survey?.distribution?.length > 0 &&
            !survey.isProgram && (
              <div style={{ fontSize: "1.2em" }}>
                {" "}
                <Stat title={"Distribution Methods"} />
                <div className={styles.stats} style={{ marginTop: "10px" }}>
                  {checkMethods()}
                </div>
              </div>
            )} */}
          </div>
        </div>
        {!isCreate && roles?.canCreateEditOrgSurveys && (
          <i
            className={`bi bi-three-dots ${styles.threeDots}`}
            onClick={clickShow}
          ></i>
        )}
        {showOptions && !isCreate && (
          <Options
            survey={survey}
            canDelete={checkCanDelete()}
            roles={roles}
            copyProject={copyProject}
            changeRoute={changeRoute}
            onDelete={onDelete}
            setShowOptions={setShowOptions}
            iterations={iterations}
            optionsRef={optionsRef}
          />
        )}
      </div>
    </>
  );
}

export default SurveyProject;

function Options({
  survey,
  canDelete,
  copyProject,
  changeRoute,
  onDelete,
  setShowOptions,
  iterations,
  roles,
  optionsRef,
}) {
  function deleteProj(e) {
    e.stopPropagation();
    onDelete();
  }

  function clickOutside(e) {
    if (!optionsRef.current || !optionsRef.current?.contains(e.target)) {
      setShowOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  return (
    <div
      className={styles.settingsBox}
      // onMouseLeave={() => setShowOptions(false)}
      ref={optionsRef}
    >
      <div
        className={styles.menuItem}
        onClick={(e) => {
          e.stopPropagation();
          localStorage.setItem("activeprojecttab", 1);
          changeRoute(`/project/${survey.id}/survey-build`);
        }}
      >
        <i className="bi-pencil"></i> Edit Questions
      </div>
      {!iterations && (
        <div
          className={styles.menuItem}
          onClick={(e) => {
            e.stopPropagation();
            localStorage.setItem("activeprojecttab", 2);
            changeRoute(`/project/${survey.id}/delivery`);
          }}
        >
          <i className="bi-truck" style={{ color: "#15BCC7" }}></i> Send Survey
        </div>
      )}
      {survey.responseCount > 0 && (
        <div
          className={styles.menuItem}
          onClick={(e) => {
            e.stopPropagation();
            localStorage.setItem("activeprojecttab", 3);
            changeRoute(
              `/project/${survey.id}/surveyresults/${survey.rawReportId}`
            );
          }}
        >
          <i className="bi-bar-chart" style={{ color: "#A7D5C5" }}></i> View
          Results
        </div>
      )}
      {!iterations && (
        <div
          className={styles.menuItem}
          onClick={(e) => {
            e.stopPropagation();
            copyProject(survey?.id);
          }}
        >
          <i className="bi-layers" style={{ color: "#F2C85C" }}></i> Make a Copy
        </div>
      )}
      {/* <div className={styles.menuItem} onClick={(e) => onDelete(e)}>
        <i className="bi-layers"></i> Mark Closed
      </div> */}
      {/* {canDelete && (
        <div className={styles.menuItem} onClick={deleteProj}>
          <i className="bi bi-trash3" style={{ color: "#FF8878" }}></i> Delete
        </div>
      )} */}
      {(roles.isAdmin || roles.canEditPersonalDashboard) && (
        <AddLinkToDash projectId={survey?.id} role={roles} />
      )}
    </div>
  );
}

const defaultColors = [
  "#15bcc7",
  "#7fcfd3",
  "#b5e1df",
  "#ed9146",
  "#edb57e",
  "#f4e3c2",
  "#a4c6d0",
  "#8dabb2",
  "#738c91",
];
const defaultSettingsProgram = {
  hasDataLabels: true,
  dataLabelValue: {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 12,
  },
  dataLabelLabel: {
    show: false,
  },
  dataLabelPercent: {
    show: false,
  },
  hasTitle: true,
  hasSubtitle: true,
  artificialTitle: true,
  titleFontSize: 18,
  titleAlignment: "center",
  hasLegend: false,
  hasLegendTitle: false,
  legendTitle: "",
  legendPosition: "top",
  legendFontSize: 14,
  legendPointStyle: true,
  legendLabel: true,
  legendValue: false,
  legendPercent: false,
  drawXLines: false,
  drawYLines: false,
  colors: defaultColors,
  currPalletId: "default",
  lineWidth: 1,
  pointRadius: 3,
  lineGraphWidth: 3,
  lineGraphColor: "#2A627C",
  uniformPointColor: "#2A627C",
  hasUniformPointColor: true,
  sortData: "none",
  barPercentage: 0.5,
  cutout: 50,
  byPercent: false,
  answerType: "answer count",
  stacked: false,
  hasAxisTitles: false,
  autoGenAxisTitles: true,
  YAxisTitle: "Answer Count",
  XAxisTitle: "Choices",
  axisTitleFontSize: 12,
  tableFields: {
    firstName: { name: "first name", show: true },
    lastName: { name: "last name", show: true },
    email: { name: "email", show: false },
  },
  tableOrder: ["firstName", "lastName", "email"],
  tablePivotFields: {},
  tablePivotOrder: [],
  valueType: "answercount",
  split: "",
  lineTension: 0.3,
  graphMax: null,
  graphMin: null,
  undefinedLabel: "Undefined",
  zoomOut: 5,
  segLabelReplacements: {},
  textboxes: [
    // { top: "10%", left: "5%", text: "Avg Score: 5", color: "gray", backgroundColor: "white" },
    // { top: "10%", left: "95%", text: "Avg Score: 10", color: "gray", backgroundColor: "white" },
  ],
  showUndefined: true,
  showN: false,
  NLabel: "N",
  useTagColor: false,
  showNonParticipating: false,
  afterSort: false,
  hideNonChosen: false,
  dynamic: false,
  userConstraints: [],
  showFilterSubtitle: true,
  sigFigs: 0,
  hideXticks: true,
  hideYticks: true,
  hideTicks: true,
  hideBorder: true,
  borderRadius: 5,
  borderSkipped: false,
  XLabelFonts: 10,
};

function TrendChart({ survey }) {
  const mostRecent = useFetchProgramRecent(
    survey.programId,
    survey?.id,
    "responses",
    true
  );
  return (
    <>
      {mostRecent.isLoading && <Loading height={75} width={75}></Loading>}
      {mostRecent.isSuccess && (
        <div style={{ width: "99%", position: "relative" }}>
          <div className={styles.headerChart}>Responses</div>
          <div className={styles.chart}>
            <Chart
              viz={{
                type: "linechart",
                designSettings: defaultSettingsProgram,
                id: survey.id,
              }}
              onSegClick={() => console.log("click")}
              data={{
                datasets: [
                  {
                    // borderRadius: 10,
                    // borderSkipped: false,
                    // borderColor: "#7FCFD3",
                    data: mostRecent.data.data.responses,
                    backgroundColor: [
                      "#7FCFD3",
                      "#7FCFD3",
                      "#7FCFD3",
                      "#7FCFD3",
                      "#7FCFD3",
                      "#7FCFD3",
                      "#7FCFD3",
                      "#7FCFD3",
                    ],
                  },
                ],
                labels: mostRecent.data.data.labels,
              }}
            ></Chart>
          </div>
        </div>
      )}
    </>
  );
}
