import { useState, useEffect } from "react";
import styles from "../AnswerTable.module.scss";
import ContactDetails from "components/Popout/ContactProfile";
import TableChart from "components/Charts/Table/TableChart";
import {
  getEmailCodes,
  getStatCodes,
  statBars,
} from "../../StatChart/StatChart";
import { getTitleContainerStyle, getTitleStyle } from "../Table";
import { getReversed } from "assets/functions/ArrayFunctions";
import {
  useFetchProjectParticipationInfo,
  useGetStatChartProjects,
} from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import { addColIdStrings } from "assets/functions/ObjectFunctions";

export default function BackendStatDataTable({
  viz,
  filters,
  onClose,
  custom_fields,
  toggleSpreadsheet,
  setChartData,
  chartData,
}) {
  function handleClickOutside(event) {
    if (event.target === document.getElementById("outside-data-table")) {
      document.removeEventListener("click", handleClickOutside, true);
      if (onClose) {
        onClose();
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const getProjects = useGetStatChartProjects(viz);

  return (
    <>
      <div className={styles.popoutBackground} id="outside-data-table">
        <div className={styles.popout}>
          {onClose && (
            <i
              className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
              onClick={onClose}
            ></i>
          )}
          {getProjects.isLoading && <Loading />}
          {getProjects.isSuccess && (
            <PullStats
              viz={viz}
              projects={getProjects.data.survey}
              filters={filters}
              custom_fields={custom_fields}
              toggleSpreadsheet={toggleSpreadsheet}
              setChartData={setChartData}
              chartData={chartData}
            />
          )}
        </div>
      </div>
    </>
  );
}

function PullStats({
  viz,
  filters,
  projects,
  custom_fields,
  toggleSpreadsheet,
  setChartData,
  chartData,
}) {
  let columns = { all: true };

  const fetchInfo = useFetchProjectParticipationInfo(
    projects.map((p) => p.id),
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    columns
  );

  return (
    <>
      {fetchInfo.isLoading && (
        <>
          <Loading />
          <div className={styles.mightTake}>This might take a minute...</div>
        </>
      )}
      {fetchInfo.isSuccess && (
        <DataTable
          partStats={fetchInfo.data.info.partStats}
          distributions={fetchInfo.data.info.distributions}
          deliveries={fetchInfo.data.info.deliveries}
          viz={viz}
          projects={projects}
          custom_fields={custom_fields}
          toggleSpreadsheet={toggleSpreadsheet}
          setChartData={setChartData}
          chartData={chartData}
        />
      )}
    </>
  );
}

function DataTable({
  partStats,
  distributions,
  deliveries,
  viz,
  projects,
  custom_fields,
  toggleSpreadsheet,
  setChartData,
  chartData,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [headers, setHeaders] = useState();
  const [contact, setContact] = useState(null);

  const style = (value) => <span className={styles.text}>{value}</span>;

  const initHeaders = [
    {
      name: "First Name",
      accessor: "firstName",
      cell_style: style,
    },
    {
      name: "Last Name",
      accessor: "lastName",
      cell_style: style,
    },
    {
      name: "Email",
      accessor: "email",
      cell_style: style,
    },
  ];

  function getColumns() {
    let columns = [...initHeaders];

    if (projects.length > 1) {
      columns.push({
        name: "Survey",
        accessor: "survey",
        cell_style: style,
      });
    }

    columns.push({
      name: "Status",
      accessor: "status",
      cell_style: style,
    });

    for (let dist of distributions) {
      columns.push({
        name: dist.name,
        accessor: dist.id,
        cell_style: style,
      });
    }

    for (let field of custom_fields.filter((f) => f.filterable !== false)) {
      if (field.generic) {
        let alreadyIn = columns.find((c) => c.accessor === field.name);
        if (alreadyIn) {
          alreadyIn.name = field.displayName;
        } else {
          columns.push({
            name: field.displayName ? field.displayName : field.name,
            accessor: field.name,
            cell_style: style,
          });
        }
      } else {
        columns.push({
          name: field.displayName ? field.displayName : field.name,
          accessor: field.id,
          cell_style: style,
        });
      }
    }

    return columns;
  }

  const reversedBars = getReversed(statBars.slice(1));

  function passesOnlyLinks(partStat, distributions) {
    return (
      partStat.stats.receivedInvite ||
      getEmails(partStat).some(
        (e) => distributions.some((d) => d.id === e.distributionId && d.hasLink) // includes blocked or bounced survey invites
      )
    );
  }

  const deliveryMap = {};

  function setUpDeliveryMap() {
    for (let delivery of deliveries) {
      if (!deliveryMap[delivery.participationId]) {
        deliveryMap[delivery.participationId] = [delivery];
      } else {
        deliveryMap[delivery.participationId].push(delivery);
      }
    }
  }

  function getEmails(partStat) {
    if (!Object.keys(deliveryMap).length) {
      setUpDeliveryMap();
    }
    let emails = deliveryMap[partStat.participation.id];
    return emails ? emails : [];
  }

  function getDataField(partStat, contact) {
    let dataField = {};

    if (contact) {
      dataField = { ...contact };
      dataField.anon = false;
    } else {
      dataField = {
        firstName: "Anonymous",
        anon: true,
      };
    }

    if (projects.length > 1) {
      let proj = projects.find(
        (p) => p.id === partStat.participation?.projectId
      );
      dataField.survey = proj?.name;

      if (contact) {
        dataField.contact_survey = contact.id + "_" + proj.id;
      }
    }

    if (viz.designSettings.onlyLinks) {
      if (!passesOnlyLinks(partStat, distributions)) {
        return dataField;
      }
    }

    let statCodes = getStatCodes(partStat);
    for (let bar of reversedBars) {
      if (statCodes.includes(bar.value)) {
        let includedBar = viz.designSettings.showBars.find(
          (b) => b.value === bar.value
        );
        dataField.status = includedBar ? includedBar.label : bar.label;
        break;
      }
    }

    for (let email of getEmails(partStat)) {
      let emailCodes = getEmailCodes(partStat, email, distributions);
      for (let bar of reversedBars) {
        if (emailCodes.includes(bar.value)) {
          let includedBar = viz.designSettings.showBars.find(
            (b) => b.value === bar.value
          );
          let string = includedBar ? includedBar.label : bar.label;
          if (bar.value === "blocked" || bar.value === "bounced") {
            string += ": " + email.bounce_classification;
          }
          dataField[email.distributionId] = string;
          break;
        }
      }
    }

    return dataField;
  }

  function getData(columns) {
    let dataMap = {};
    let anonymous = [];

    for (let partStat of partStats) {
      let contact = partStat.participation?.contact
        ? { ...partStat.participation?.contact }
        : null;

      if (contact) {
        addColIdStrings(contact);
      }

      let dataField = getDataField(partStat, contact);
      if (!dataField) {
        continue;
      }

      if (dataField.anon) {
        anonymous.push(dataField);
      } else {
        let key = projects.length > 1 ? dataField.contact_survey : dataField.id;
        if (!dataMap[key]) {
          dataMap[key] = dataField;
        } else {
          let orig = dataMap[key];
          dataMap[key] = { ...orig, ...dataField };
        }
      }
    }

    let data = [];
    for (let key in dataMap) {
      data.push(dataMap[key]);
    }

    sortData(data, columns);

    data = [...data, ...anonymous];

    return data;
  }

  useEffect(() => {
    if (partStats && distributions) {
      let columns = getColumns();
      let data = getData(columns);
      setHeaders(columns);
      setDataArray(data);
    }
  }, [viz]);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
      }
    }
  }

  function sortData(data, columns) {
    // sort data so contacts are next to each other by last name, then first name, then by surveys
    if (columns.some((c) => c.accessor === "survey")) {
      let copy = [...projects];
      copy.sort((p1, p2) => {
        let a = p1.startedAt ? new Date(p1.startedAt).getTime() : 0;
        let b = p2.startedAt ? new Date(p2.startedAt).getTime() : 0;
        return a - b;
      });

      let projectMap = {};
      for (let i = 0; i < copy.length; i++) {
        projectMap[copy[i].name] = i;
      }

      data.sort((row1, row2) => {
        let a = projectMap[row1.survey];
        let b = projectMap[row2.survey];

        return b - a;
      });
    }

    if (columns.some((c) => c.accessor === "firstName")) {
      sortField(data, "firstName");
    }
    if (columns.some((c) => c.accessor === "lastName")) {
      sortField(data, "lastName");
    }
    sortStatus(data);
  }

  function sortStatus(data) {
    data.sort((row1, row2) => {
      let a = row1.status;
      let b = row2.status;

      if (a) {
        if (b) {
          let aInd = statBars.findIndex((bar) => bar.label === a);
          let bInd = statBars.findIndex((bar) => bar.label === b);

          return aInd - bInd;
        }
        return -1;
      }
      if (b) {
        return 1;
      }
    });
  }

  function sortField(data, field) {
    data.sort((row1, row2) => {
      let a = row1[field];
      let b = row2[field];
      if (!a) {
        a = "";
      }
      if (!b) {
        b = "";
      }

      if (a.toLowerCase() < b?.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <>
      {(!headers || !dataArray) && (
        <div style={{ width: "65vw", height: "80%" }}></div>
      )}
      {headers && dataArray && (
        <TableChart
          initHeaders={headers}
          data={dataArray}
          threeDots
          onRowClick={chartData ? undefined : handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          downloadCsv
          toggleSpreadsheet={toggleSpreadsheet}
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          chartData={chartData}
          setChartData={setChartData}
          showScroll
        />
      )}
      {contact && (
        <ContactDetails
          onClose={() => setContact(null)}
          contact={contact}
        ></ContactDetails>
      )}
    </>
  );
}
