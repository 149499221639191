// External
import React, { useMemo, useState } from "react";

// Internal
import styles from "./Audiences.module.scss";
import { OrgAudiences } from "./OrgAudiences";

export const Audiences = ({ user, organization, roles, updateRoute }) => {
   useMemo(() => {
      if (updateRoute) {
        updateRoute(1);
      }
    }, []);
  return (
    <>
      <div className={styles.page}>
        <OrgAudiences user={user} organization={organization} roles={roles} />
      </div>
    </>
  );
};
