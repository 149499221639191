import styles from "./SliderMatrixOption.module.scss";
import { useState, useEffect } from "react";
import { Slider } from "@mui/material";

export function SliderMatrixOption({
  value,
  handleChange,
  color,
  scaleQ,
  span,
  first,
  qId,
}) {
  // const [padding, setPadding] = useState(0);

  // function runCalculation() {
  //   let th = document.getElementById(qId + "th " + scaleQ.min);
  //   if (th) {
  //     let amount = Math.floor(th.clientWidth / 2 + 0.5);

  //     setPadding(amount);
  //   }
  // }

  // useEffect(() => {
  //   setTimeout(() => runCalculation(), 100);
  // }, []);

  const StyledSlider = {
    // marginTop: "50px",
    color: "#d8d9d9",
    height: 6,
    "& .MuiSlider-track": {
      border: "none",
      color: color,
    },
    "& .MuiSlider-thumb": {
      height: 18,
      width: 18,
      backgroundColor: "#fff",
      border: "3px solid",
      borderColor: color,
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    // "& .MuiSlider-valueLabel": {
    //   lineHeight: 1.2,
    //   fontSize: 12,
    //   background: "unset",
    //   padding: 0,
    //   width: 32,
    //   height: 32,
    //   borderRadius: "50% 50% 50% 0",
    //   backgroundColor: color,
    //   transformOrigin: "bottom left",
    //   transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    //   "&:before": { display: "none" },
    //   "&.MuiSlider-valueLabelOpen": {
    //     transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    //   },
    //   "& > *": {
    //     transform: "rotate(45deg)",
    //   },
    // },
  };

  return (
    <td
      className={styles.slider}
      colSpan={2}
      style={{
        padding: `${first ? 8 : 0}px 20px 0px`
      }}
      // colSpan={span}
      // style={{
      //   padding: `${first ? 8 : 0}px ${padding + 5}px 0px ${padding - 5}px`,
      // }}
    >
      <Slider
        defaultValue={value}
        onChangeCommitted={(e, val) => handleChange(val)}
        sx={StyledSlider}
        min={Number(scaleQ.min)}
        max={Number(scaleQ.max)}
        step={Number(scaleQ.step)}
        valueLabelDisplay={"auto"}
      />
    </td>
  );
}
