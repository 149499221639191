import React, { useState, useRef } from "react";
import styles from "./Drip.module.scss";

export function Drip({ email, onChange, disable }) {
  const options = [
    {
      value: 1,
      label: "1 (second)",
    },
    {
      value: 2,
      label: "2 (seconds)",
    },
    {
      value: 3,
      label: "3 (seconds)",
    },

    {
      value: 4,
      label: "4 (seconds)",
    },
    {
      value: 5,
      label: "5 (seconds)",
    },
    {
      value: 10,
      label: "10 (seconds)",
    },
    {
      value: 30,
      label: "30 (seconds)",
    },
    {
      value: 60,
      label: "1 (min)",
    },
    {
      value: 120,
      label: "2 (min)",
    },
    {
      value: 300,
      label: "5 (min)",
    },
    {
      value: 600,
      label: "10 (min)",
    },
  ];

  const optionsNum = [
    {
      value: 1,
      label: "1 (email)",
    },
    {
      value: 2,
      label: "2 (emails)",
    },
    {
      value: 3,
      label: "3 (emails)",
    },

    {
      value: 4,
      label: "4 (emails)",
    },
    {
      value: 5,
      label: "5 (emails)",
    },
    {
      value: 10,
      label: "10 (emails)",
    },
  ];

  return (
    <>
      <div
        className={`${styles.container}`}
        style={{
          pointerEvents: disable ? "none" : "",
        }}
      >
        <div className={styles.label}>
          <div className={styles.label6}>Drip:</div>
          <div className={styles.info}>
            <i className={`bi bi-info-circle`}></i>

            <div className={styles.help}>
              Helps prevent email providers from blocking emails. Spaces out
              your send.
            </div>
          </div>
        </div>

        <div className={styles.config}>
          <select
            onChange={(e) =>
              onChange("timeDelayCount", parseInt(e.target.value))
            }
            value={email.timeDelayCount}
            className={styles.select}
          >
            {optionsNum.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <span>every</span>
          <select
            onChange={(e) => onChange("timeDelay", parseInt(e.target.value))}
            value={email.timeDelay}
            className={styles.select}
          >
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
