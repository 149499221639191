import Survey from "components/Survey/Survey";
import styles from "./SurveyHandler.module.scss";
import { useParams } from "react-router-dom";
import { useGetParticipation } from "api/resources/projects/participations";
import { Loading } from "components/Loading/Loading";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { useEffect } from "react";
import { SurveyTaker } from "./SurveyTaker";

export function SurveyTesting() {
  const { record } = useParams();

  return <>{record === "record" ? <SurveyTaker testing /> : <TestSurvey />}</>;
}

function TestSurvey() {
  const { projectId, distId, participantId } = useParams();

  const getParticipation = useGetParticipation();

  useEffect(() => {
    getParticipation.mutate({
      participationId: participantId,
    });
  }, []);

  return (
    <div className={styles.container}>
      {!distId && (
        <ErrorBanner message={"Unable to retrieve distribution data"} />
      )}
      {getParticipation.isLoading && <Loading></Loading>}
      {getParticipation.isError && (
        <ErrorBanner
          error={getParticipation.error}
          message={"Unable to retrieve participation data"}
        />
      )}
      {getParticipation.isSuccess && <Survey projectId={projectId} />}
    </div>
  );
}
