/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction Data. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
import { useEffect, useRef, useState } from "react";
import styles from "./EmailEditor.module.scss";
import { shortId } from "components/tables/EditableTable/utils";
import { TextColorPicker } from "components/inputs/input_fields/TextEditorTry/TextColorPicker";
import { forEach } from "assets/functions/ArrayFunctions";
import { Menu } from "./Menu/Menu";
import { defaultLink } from "./Menu/Link/Link";
import Font from "components/inputs/input_fields/TextEditorTry/Fonts";

export default function EmailEditor({
  encoding,
  defaultStyle,
  onSave,
  editable,
  bestPractices,
  tips,
  setTips,
  reDraw,
  onFromChange,
  incoming,
  customFields,
  conditions,
  setConditions,
}) {
  const [lastSelection, setLastSelection] = useState();
  const lastSelRef = useRef();
  const [runSave, setRunSave] = useState(false);
  const [edited, setEdited] = useState(false);
  const [bold, setBold] = useState(
    defaultStyle && defaultStyle.fontWeight ? true : false
  );
  const [italics, setItalics] = useState(
    defaultStyle && defaultStyle.fontStyle ? true : false
  );
  const [underline, setUnderline] = useState(
    defaultStyle && defaultStyle.textDecoration
      ? defaultStyle.textDecoration.includes("underline")
      : false
  );
  const [font, setFont] = useState(
    defaultStyle && defaultStyle.fontFamily ? defaultStyle.fontFamily : ""
  );
  const [strike, setStrike] = useState(
    defaultStyle && defaultStyle.textDecoration
      ? defaultStyle.textDecoration.includes("line-through")
      : false
  );

  const defaultBlack = "#050606";
  const [color, setColor] = useState(
    defaultStyle && defaultStyle.color ? defaultStyle.color : defaultBlack
  );
  const [fontSize, setFontSize] = useState(
    defaultStyle && defaultStyle.fontSize ? defaultStyle.fontSize : "11pt"
  );
  const [stylesToBe, setStylesToBe] = useState([]);

  const [record, setRecord] = useState([]);

  const [menu, setMenu] = useState();
  const recordRef = useRef();

  const breakRef = useRef(0);

  function createDisplay(dataCopy, onComplete) {
    if (entry.current) {
      let images = [];
      for (let div of dataCopy) {
        if (div.image) {
          let img = document.getElementById(div.id + "_image");
          if (img) {
            images.push(img);
          }
        }
      }

      entry.current.innerHTML = "";

      let currentList = null;

      let charCount = 0;
      let newTips = [];

      for (let k = 0; k < dataCopy.length; k++) {
        let div = dataCopy[k];

        const parentElem = document.createElement(div.list ? "li" : "div");
        parentElem.id = div.id;

        if (!div.text) {
          if (div.image) {
            let editBox = document.createElement("div");
            editBox.style.height = div.height + "px";
            editBox.style.width = div.width + "px";
            editBox.className = styles.editBox;

            let image = images.find((img) => img.id === div.id + "_image");
            if (!image) {
              image = document.createElement("img");
              image.src = div.src;
              image.id = div.id + "_image";
            }
            image.style.width = div.width + "px";
            image.style.height = div.height + "px";
            image.style.cursor = "move";
            image.onclick = (e) => setUpBox(e, div, dataCopy);

            editBox.appendChild(image);
            parentElem.appendChild(editBox);
          } else if (div.divider) {
            let hr = document.createElement("hr");
            hr.style.border = "1px solid #e9e9e9";
            hr.style.height = "fit-content";
            parentElem.appendChild(hr);
          } else {
            let span = document.createElement("span");
            span.appendChild(document.createTextNode("\n"));

            div.styles.sort((a, b) => a.from - b.from);
            let fontSizeStyle = div.styles.find((s) => s.code === "fontSize");
            span.style.fontSize = fontSizeStyle ? fontSizeStyle.value : "11pt";
            parentElem.appendChild(span);
          }
        } else {
          let textBoxes = div.text.split("");
          let keys = textBoxes.map((c) => {
            return { char: c, styleIndeces: [] };
          });

          let stylesInOrder = [...div.styles.sort((a, b) => a.from - b.from)];

          let toBe = charCount + keys.length;
          const littleLong = 425;
          const tooLong = 525;

          // Best Practices
          if (
            bestPractices &&
            !div.sig &&
            div.text.toLowerCase().trim() !== "{signature}" &&
            (toBe > littleLong || charCount > littleLong)
          ) {
            if (toBe <= tooLong || charCount <= tooLong) {
              const code = "Email - Little Long";
              let existing = tips.find((t) => t.code === code);

              let from = charCount >= littleLong ? 0 : littleLong - charCount;
              let to =
                toBe < tooLong ? keys.length - 1 : tooLong - charCount - 1;

              if (!existing || !existing.ignore) {
                stylesInOrder.push({
                  code: "color",
                  value: "#fccb05", // Yellow",
                  from: from,
                  to: to,
                  bp: true,
                });
              }

              if (!newTips.some((t) => t.code === code)) {
                newTips.push({
                  code: code,
                  title: (
                    <>
                      {"Email is getting a "}
                      <span style={{ color: "#fccb05" }}>little long</span>
                      {". Ideally it is below 425 characters."}
                    </>
                  ),
                  ignore: existing ? existing.ignore : false,
                });
              }
            }

            if (charCount > tooLong || toBe > tooLong) {
              const code = "Email - Too Long";
              let existing = tips.find((t) => t.code === code);

              let from = charCount >= tooLong ? 0 : tooLong - charCount;

              if (!existing || !existing.ignore) {
                stylesInOrder.push({
                  code: "color",
                  value: "#ff391f", // Red",
                  from: from,
                  to: keys?.length - 1,
                  bp: true,
                });
              }

              if (!newTips.some((t) => t.code === code)) {
                newTips.push({
                  code: code,
                  title: (
                    <>
                      {"Email is a "}
                      <span style={{ color: "#ff391f" }}>too long</span>
                      {". Ideally it is below 425 characters."}
                    </>
                  ),
                  ignore: existing ? existing.ignore : false,
                  red: true,
                });
              }
            }
          }
          if (!div.sig) {
            charCount += keys.length;
          }

          // Custom Fields

          for (let key of ["{recipient", "{condition"]) {
            if (div.text.includes(key)) {
              let ind = div.text.indexOf(key);
              while (ind > -1) {
                // stylesInOrder.push({  // Make brackets always black?
                //   code: "color",
                //   value: defaultBlack,
                //   from: ind,
                //   to: ind,
                //   cf: true,
                // });

                stylesInOrder.push({
                  code: "color",
                  value: "#a3a4a8", // Gray 100
                  from: ind + 1,
                  to: ind + "recipient".length, // 'same length as condition so doesn't matter'
                  cf: true,
                });

                if (div.text.includes("}", ind)) {
                  let closeInd = div.text.indexOf("}", ind);
                  let nextOpenInd = div.text.indexOf("{", ind + 1);
                  if (nextOpenInd === -1 || closeInd < nextOpenInd) {
                    // It closed the one that is open

                    const from = ind + "recipient".length + 2; // account for " " space;
                    const to = closeInd - 1;
                    let text = div.text.slice(from, to + 1);

                    if (replaceable(key, text)) {
                      stylesInOrder.push({
                        code: "color",
                        value: "#7fcfd3", // Blue 60
                        from: from,
                        to: to,
                        cf: true,
                        con: key === "{condition",
                      });

                      // stylesInOrder.push({
                      //   code: "color",
                      //   value: defaultBlack,
                      //   from: closeInd,
                      //   to: closeInd,
                      //   cf: true,
                      // });
                    }
                  }
                }

                ind = div.text.indexOf(key, ind + 1);
              }
            }
          }

          // Links / Buttons
          if (div.links) {
            for (let link of div.links) {
              stylesInOrder.push({
                ...link,
                link: true,
              });
            }
          }

          if (div.text.trim().toLowerCase() === "{signature}") {
            let start = div.text.indexOf("{");
            let end = div.text.indexOf("}");
            stylesInOrder.push({
              code: "color",
              value: defaultBlack,
              from: start,
              to: start,
              sig: true,
            });
            stylesInOrder.push({
              code: "color",
              value: "#a3a4a8",
              from: start + 1,
              to: end - 1,
              sig: true,
            });
            stylesInOrder.push({
              code: "color",
              value: defaultBlack,
              from: end,
              to: end,
              sig: true,
            });
          }

          if (div.text.toLowerCase().includes("{survey url}")) {
            let ind = div.text.toLowerCase().indexOf("{survey url}");

            while (ind > -1) {
              let end = div.text.indexOf("}", ind + 1);
              stylesInOrder.push({
                code: "color",
                value: defaultBlack,
                from: ind,
                to: end,
                sig: true,
              });
              stylesInOrder.push({
                code: "color",
                value: "#a3a4a8",
                from: ind + 1,
                to: end - 1,
                sig: true,
              });
              stylesInOrder.push({
                code: "color",
                value: defaultBlack,
                from: end,
                to: end,
                sig: true,
              });

              ind = div.text.toLowerCase().indexOf("{survey url}", ind + 1);
            }
          }

          forEach(stylesInOrder, (style, ind) => {
            for (let i = style.from; i <= style.to; i++) {
              if (!keys[i]) {
                restartText();
                return;
              }
              keys[i].styleIndeces.push(ind);
            }
          });

          let blocks = [];
          let current = "";
          for (let key of keys) {
            let stringedStyle = JSON.stringify(key.styleIndeces);
            if (stringedStyle !== current) {
              // start a new span
              blocks.push({ styleIndeces: key.styleIndeces, text: key.char });
            } else {
              // add it
              blocks[blocks.length - 1].text += key.char;
            }
            current = stringedStyle;
          }

          let spans = [];
          for (let span of blocks) {
            let realSpan = document.createElement("span");

            for (let ind of span.styleIndeces) {
              let style = stylesInOrder[ind];

              if (style.con) {
                realSpan.onclick = (e) =>
                  onConditionClick(span.text, style.from);
                realSpan.style.cursor = "pointer";
              }

              if (style.link) {
                continue;
              }

              if (
                style.bp &&
                span.styleIndeces.some(
                  (i) =>
                    stylesInOrder[i].code === style.code && !stylesInOrder[i].bp
                )
              ) {
                // has another color style - switch to a dashed underline
                let there = realSpan.style.textDecoration;
                let line = there.includes("line-through") ? "line-through" : "";
                let kind = there.includes("underline") ? "solid" : "dotted";
                let shouldBe =
                  line + " underline " + kind + " " + style.value + " ";
                realSpan.style.textDecoration = shouldBe;
              } else if (style.cf || style.sig) {
                realSpan.style[style.code] = style.value;
              } else if (realSpan.style[style.code]) {
                realSpan.style[style.code] += " " + style.value;
              } else {
                realSpan.style[style.code] = style.value;
              }
            }

            if (span.styleIndeces.some((i) => stylesInOrder[i].link)) {
              let linkInd = span.styleIndeces.find(
                (i) => stylesInOrder[i].link
              );
              let link = stylesInOrder[linkInd];

              realSpan.style.cursor = "pointer";
              if (link.asButton) {
                realSpan.style.fontWeight = "600";
                realSpan.style.padding = "3px 20px";
                realSpan.style.borderRadius = ".5rem";
                realSpan.style.backgroundColor = link.color;
                realSpan.style.color = link.fontColor;
              } else {
                realSpan.style.color = link.color;
                if (link.underline) {
                  realSpan.style.textDecoration = "underline";
                }
              }
              realSpan.onclick = (e) => onLinkClick(e, link, span.text);
            }

            realSpan.appendChild(document.createTextNode(span.text));
            spans.push(realSpan);
          }

          for (let span of spans) {
            parentElem.appendChild(span);
          }
        }

        if (div.textAlign) {
          parentElem.style.textAlign = div.textAlign;
          if (div.image) {
            parentElem.style.display = "flex";
            let just =
              div.textAlign === "left"
                ? "flex-start"
                : div.textAlign === "center"
                ? "center"
                : "flex-end";
            parentElem.style.justifyContent = just;
          }
        }

        if (div.indent) {
          parentElem.style.marginLeft = div.indent * 20 + "px";
        }

        if (div.list) {
          if (!k || dataCopy[k - 1].list !== div.list) {
            let list = document.createElement(div.list);
            list.appendChild(parentElem);
            currentList = list;
            entry.current.appendChild(list);
          } else {
            currentList.appendChild(parentElem);
          }
        } else {
          entry.current.appendChild(parentElem);
          currentList = null;
        }
      }

      if (setTips) {
        setTips(newTips);
      }

      if (onComplete) {
        onComplete();
      }
    }
  }

  function replaceable(key, text) {
    if (key === "{recipient") {
      let lowered = text.toLowerCase();

      return (
        lowered === "full name" ||
        lowered === "last name" ||
        lowered === "first name" ||
        lowered === "doctor name" ||
        customFields.some((f) => f.name === text)
      );
    }
    if (conditions) {
      return conditions.some((c) => c.name === text);
    }
    return false;
  }

  const initEncoding = (text) => {
    let given = text ? text : "";
    if (given) {
      let t = typeof text;
      if (t !== "string") {
        if (t === "number") {
          given = given.toString();
        } else {
          given = "";
        }
      }
    }

    let div = {
      id: shortId(),
      text: given,
      styles: [],
    };

    if (defaultStyle) {
      if (!("fontSize" in defaultStyle)) {
        div.styles.push({
          code: "fontSize",
          value: "11pt",
          from: 0,
          to: div.text ? div.text.length - 1 : 0,
        });
      }

      for (let code in defaultStyle) {
        if (code === "textAlign") {
          div.textAlign = defaultStyle[code];
        }
        div.styles.push({
          code: code,
          value: defaultStyle[code],
          from: 0,
          to: div.text ? div.text.length - 1 : 0,
        });
      }
    } else {
      div.styles = [
        // {
        //   code: "fontSize",
        //   value: "11pt",
        //   from: 0,
        //   to: div.text ? div.text.length - 1 : 0,
        // },
        // {
        //   code: "fontWeight",
        //   value: "600",
        //   from: 10,
        //   to: 15,
        // },
      ];
    }

    return [div];
    return [
      // div,
      {
        id: "12345",
        text: "I think this is great",
        // list: "ul",
        styles: [
          {
            code: "fontSize",
            value: "11pt",
            from: 0,
            to: 20,
          },
          {
            code: "fontWeight",
            value: "600",
            from: 10,
            to: 15,
          },
        ],
      },
      {
        id: "54321",
        text: "okay",
        // list: "ul",
        // indent: 1,
        styles: [
          {
            code: "fontWeight",
            value: "600",
            from: 2,
            to: 3,
          },
          {
            code: "fontSize",
            value: "11pt",
            from: 0,
            to: 3,
          },
        ],
      },
      {
        id: "blank",
        // divider: true,
        // text: "",
        // styles: [],
        height: 60,
        width: 60,
        image: true,
        src: "https://reaction-staging-images.s3.amazonaws.com/f069ad2c-a73c-4808-b032-3e3441f7f365/7udwD-circlelogo.png",
      },
      {
        id: "jkl;",
        text: "alrighty",
        // indent: 3,
        styles: [
          {
            code: "fontSize",
            value: "10pt",
            from: 0,
            to: 7,
          },
        ],
      },
    ];
  };

  function decode() {
    if (!encoding) {
      // return initEncoding("I'm doing some very quick analysis...");
      return initEncoding("");
    }
    let decoded = null;
    try {
      decoded = JSON.parse(encoding);
    } catch (e) {
      return initEncoding(encoding);
    }

    if (Array.isArray(decoded)) {
      for (let div of decoded) {
        div.id = shortId();
      }
      return decoded;
    } else {
      return initEncoding("");
    }
  }

  function prepareEncoding() {
    let copy = getDataCopy();
    for (let div of copy) {
      delete div.id;
    }
    return JSON.stringify(copy);
  }

  const [data, setData] = useState(decode());

  useEffect(() => {
    createDisplay(data);
  }, []);

  useEffect(() => {
    if (runSave) {
      onBlur();
      setRunSave(false);
    }
  }, [runSave]);

  function saveData(copy) {
    let recordCopy = [...record];
    recordCopy.push({ data: data, selection: lastSelection });
    setRecord(recordCopy);
    setData(copy);
    setEdited(true);

    if (recordRef.current) {
      recordRef.current = copy;
    }
  }

  function saveSelection(selection) {
    setLastSelection(selection);
    lastSelRef.current = selection;
  }

  function restartText() {
    let copy = initEncoding();
    let selection = {
      divId: copy[0].id,
      offset: 0,
      isCollapsed: true,
    };
    if (editable) {
      createDisplayAndDoSelection(copy, selection);
      setRecord([]);
      setStylesToBe([]);
    } else {
      createDisplay(copy);
    }
    setData(copy);
  }

  function getDataCopy() {
    let copy = [...data];
    let trueCopy = JSON.parse(JSON.stringify(copy));
    return trueCopy;
  }

  function getDataCopyWithMenuUp() {
    if (recordRef.current) {
      if (Array.isArray(recordRef.current)) {
        return JSON.parse(JSON.stringify(recordRef.current));
      }
    }
    return getDataCopy(); // needed because the event handler in Menu.jsx can be out of scope
  }

  function onBlur() {
    if (onSave && edited) {
      let text = "";
      for (let i = 0; i < data.length; i++) {
        text += data[i].text;
        if (i < data.length - 1) {
          text += "\n";
        }
      }
      let code = prepareEncoding();
      let html = undefined;
      if (entry.current) {
        html = entry.current.innerHTML;
      }
      if (onSave) {
        onSave(text, code, html);
      }

      setEdited(false);
    }
  }

  function handleChange(e) {
    e.preventDefault();

    if (!lastSelection) {
      return;
    }

    if (lastSelection.isCollapsed) {
      if (
        e.nativeEvent.inputType === "deleteContentBackward" &&
        lastSelection.offset == 0
      ) {
        onDeleteBegginningOfDiv();
        return;
      }

      let copy = getDataCopy();
      let index = data.findIndex((d) => d.id === lastSelection.divId);
      let endOffset = lastSelection.offset;

      if (e.nativeEvent.inputType === "insertText") {
        if (copy[index].image) {
          let dir = endOffset ? 1 : 0;
          index += dir;
          copy.splice(index, 0, {
            text: e.nativeEvent.data,
            styles: [],
            id: shortId(),
          });
          endOffset = 1;
        } else {
          if (endOffset === copy[index].text.length) {
            copy[index].text += e.nativeEvent.data;
          } else if (!endOffset) {
            // at the start
            copy[index].text = e.nativeEvent.data + copy[index].text;
          } else {
            let first = copy[index].text.slice(0, endOffset);
            let second = copy[index].text.slice(endOffset);
            copy[index].text = first + e.nativeEvent.data + second;
          }

          for (let char of e.nativeEvent.data) {
            addedChar(copy[index], endOffset);
            endOffset++;
          }
        }
      } else if (e.nativeEvent.inputType === "deleteContentBackward") {
        if (copy[index].image) {
          copy[index] = {
            id: shortId(),
            text: "",
            styles: [],
          };
          createDisplayAndDoSelection(copy, {
            divId: copy[index].id,
            offset: 0,
            isCollapsed: true,
          });
          saveData(copy);
          return;
        } else if (endOffset === copy[index].text.length) {
          copy[index].text = copy[index].text.slice(
            0,
            copy[index].text.length - 1
          );
        } else {
          let first = copy[index].text.slice(0, endOffset - 1);
          let second = copy[index].text.slice(endOffset);
          copy[index].text = first + second;
        }

        deletedChar(copy[index], endOffset - 1);
        endOffset--;
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(copy[index].id);
        let subCount = 0;
        let count = 0;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            if (text.nodeValue) {
              subCount = 0;
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endOffset) {
                  // here
                  count++;
                  subCount++;
                }
              }

              if (count == endOffset) {
                // here
                target = text;
                break;
              }
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, subCount);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      // Inserting or deleting on a selection
      let isADelete = e.nativeEvent.inputType === "deleteContentBackward";
      let isAnInsert = e.nativeEvent.inputType === "insertText";
      let isSpellCheck = e.nativeEvent.inputType === "insertReplacementText";
      if (isAnInsert || isADelete || isSpellCheck) {
        let newChar = isAnInsert || isSpellCheck ? e.nativeEvent.data : "";
        handleSelectionInsert(newChar);
      }
    }
  }

  function onDeleteBegginningOfDiv() {
    let copy = getDataCopy();
    // Merge a section back on to another one;
    let index = copy.findIndex((c) => c.id === lastSelection.divId);

    if (copy[index].list) {
      delete copy[index].list;
      createDisplayAndDoSelection(copy, lastSelection);
      saveData(copy);
      return;
    }

    if (copy[index].indent) {
      delete copy[index].indent;
      createDisplayAndDoSelection(copy, lastSelection);
      saveData(copy);
      return;
    }

    if (!index) {
      // it's at the top
      createDisplayAndDoSelection(data, lastSelection);
      return;
    }

    if (copy[index].image) {
      // Before image
      if (copy[index - 1].image) {
        createDisplayAndDoSelection(data, {
          divId: copy[index - 1].id,
          offset: 1,
          isCollapsed: true,
        });
        return;
      } else {
        if (!copy[index - 1].text) {
          copy.splice(index - 1, 1);
          createDisplayAndDoSelection(copy, lastSelection);
          saveData(copy);
          return;
        }

        createDisplayAndDoSelection(data, {
          divId: copy[index - 1].id,
          offset: copy[index - 1].text.length,
          isCollapsed: true,
        });
        return;
      }
    }

    if (copy[index - 1].image) {
      if (copy[index].text) {
        createDisplayAndDoSelection(data, {
          divId: copy[index - 1].id,
          offset: 1,
          isCollapsed: true,
        });
        return;
      } else {
        copy.splice(index, 1);
        createDisplayAndDoSelection(copy, {
          divId: copy[index - 1].id,
          offset: 1,
          isCollapsed: true,
        });
        saveData(copy);
        return;
      }
    }

    if (copy[index - 1].divider) {
      copy.splice(index - 1, 1);
      createDisplayAndDoSelection(copy, lastSelection);
      saveData(copy);
      return;
    }

    let selectionToBe = {
      isCollapsed: true,
      divId: copy[index - 1].id,
      offset: copy[index - 1].text.length,
    };

    if (copy[index].links) {
      // merge the links
      if (!copy[index - 1].links) {
        copy[index - 1].links = [];
      }
      for (let link of copy[index].links) {
        link.from += copy[index - 1].text.length;
        link.to += copy[index - 1].text.length;
        copy[index - 1].links.push(link);
      }
    }

    if (copy[index].text) {
      //  merge the styles
      for (let i = 0; i < copy[index].styles.length; i++) {
        let style = copy[index].styles[i];

        // check if there are the same styles up until it
        let found = false;
        for (let styleBefore of copy[index - 1].styles) {
          if (
            styleBefore.to == copy[index - 1].text.length - 1 &&
            styleBefore.code === style.code &&
            styleBefore.value === style.value
          ) {
            styleBefore.to += style.to + 1; // + 1 because of 0 index
            found = true;
            break;
          }
        }

        if (!found) {
          let mergeIndex = copy[index - 1].text.length;
          copy[index - 1].styles.push({
            ...style,
            from: mergeIndex + style.from,
            to: mergeIndex + style.to, // no + 1 because accounted for in mergeIndex = ...text.length
          });
        }
      }

      copy[index - 1].text += copy[index].text;
    }

    copy.splice(index, 1);
    saveData(copy);
    createDisplayAndDoSelection(copy, selectionToBe);
  }

  function insertNewLine() {
    if (!lastSelection) {
      return;
    }

    if (lastSelection.isCollapsed) {
      // if it's got text, split the text and the styles.....
      let copy = getDataCopy();
      let index = copy.findIndex((d) => d.id === lastSelection.divId);

      if (copy[index].image) {
        let id = shortId();
        copy.splice(index + lastSelection.offset, 0, {
          text: "",
          styles: [],
          id: id,
        });
        createDisplayAndDoSelection(copy, {
          divId: id,
          offset: 0,
          isCollapsed: true,
        });
        saveData(copy);
        return;
      }

      let firstPart = copy[index].text.substring(0, lastSelection.offset);
      let secondPart = copy[index].text.substring(lastSelection.offset);

      copy[index].text = firstPart;

      if (copy[index].list && !copy[index].text) {
        // Empty bullet point - take off the bullet point
        delete copy[index].list;
        createDisplayAndDoSelection(copy, {
          divId: copy[index].id,
          offset: 0,
          isCollapsed: true,
        });
        saveData(copy);
        return;
      }

      let newDiv = {
        ...oldDivBreakOff(copy[index]),
        id: shortId(),
        text: secondPart,
        styles: [],
      };

      let takeOutIndeces = [];
      for (let i = 0; i < copy[index].styles.length; i++) {
        let style = copy[index].styles[i];
        if (
          !secondPart &&
          (!firstPart.length || style.to == firstPart.length - 1)
        ) {
          // At the end of the line, continue the style from the last character
          newDiv.styles.push({ ...style, from: 0, to: 0 });
        } else if (style.from >= lastSelection.offset) {
          // Keep with the broken piece only
          let newTo = style.to - firstPart.length;
          let newFrom = style.from - firstPart.length;

          newDiv.styles.push({ ...style, from: newFrom, to: newTo });
          takeOutIndeces.push(i);
        } else if (style.to > firstPart.length - 1) {
          // It includes the broken piece.
          let newTo = style.to - firstPart.length;
          copy[index].styles[i].to = firstPart.length
            ? firstPart.length - 1
            : 0;
          newDiv.styles.push({ ...style, from: 0, to: newTo });
        }
      }

      if (copy[index].links) {
        for (let link of copy[index].links) {
          if (link.from >= lastSelection.offset) {
            if (!newDiv.links) {
              newDiv.links = [];
            }
            newDiv.links.push({
              ...link,
              from: link.from - firstPart.length,
              to: link.to - firstPart.length,
            });
          }
        }
        copy[index].links = copy[index].links.filter(
          (l) => l.to < lastSelection.offset
        );
        if (!copy[index].links) {
          delete copy[index].links;
        }
      }

      removeStyleIndices(copy[index], takeOutIndeces);

      copy.splice(index + 1, 0, newDiv);
      saveData(copy);

      createDisplay(copy, () => {
        let targetNode = document.getElementById(newDiv.id);
        while (targetNode.nodeName !== "#text") {
          targetNode = targetNode.childNodes[0];
        }

        const newRange = document.createRange();
        newRange.setStart(targetNode, 0);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      let copy = getDataCopy();
      let divTrashCan = [];
      let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
      let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

      for (let i = startInd; i <= endInd; i++) {
        if (i === startInd && i === endInd) {
          if (copy[i].image || copy[i].divider) {
            copy[i] = {
              id: copy[i].id,
              text: "",
              styles: [],
            };
            let newDiv = {
              ...oldDivBreakOff(copy[i]),
              styles: [],
              text: "",
              id: shortId(),
            };
            copy.splice(i + 1, 0, newDiv);
          } else {
            let beginning = copy[i].text.substring(
              0,
              lastSelection.start.offset
            );
            let end = copy[i].text.substring(lastSelection.end.offset + 1);
            copy[i].text = beginning;
            let newDiv = {
              ...oldDivBreakOff(copy[i]),
              text: end,
              styles: [],
              id: shortId(),
            };

            let keeping = [];
            for (let style of copy[i].styles) {
              if (style.to > lastSelection.end.offset) {
                newDiv.styles.push({
                  ...style,
                  from: 0,
                  to: style.to - lastSelection.end.offset - 1,
                });
              }
              if (style.from < lastSelection.start.offset) {
                style.to = lastSelection.start.offset - 1;
                keeping.push(style);
              }
            }
            copy[i].styles = keeping;

            if (copy[i].links) {
              for (let link of copy[i].links) {
                if (link.from > lastSelection.end.offset) {
                  if (!newDiv.links) {
                    newDiv.links = [];
                  }
                  newDiv.links.push({
                    ...link,
                    from: link.from - lastSelection.end.offset - 1,
                    to: link.to - lastSelection.end.offset - 1,
                  });
                }
              }
              copy[i].links = copy[i].links.filter(
                (l) => l.to < lastSelection.start.offset
              );
              if (!copy[i].links.length) {
                delete copy[i].links;
              }
            }

            copy.splice(i + 1, 0, newDiv);
          }
        } else if (i == startInd) {
          if (copy[i].image || copy[i].divider) {
            copy[i] = {
              id: copy[i].id,
              text: "",
              styles: [],
            };
          } else {
            let textLength = copy[i].text.length;
            for (let c = textLength - 1; c >= lastSelection.start.offset; c--) {
              deletedChar(copy[i], c);
            }
            let beginning = copy[i].text.substring(
              0,
              lastSelection.start.offset
            );
            copy[i].text = beginning;
          }

          if (copy[endInd].image || copy[endInd].divider) {
            copy[endInd] = {
              id: copy[endInd].id,
              text: "",
              styles: [],
            };
          } else {
            for (let c = lastSelection.end.offset; c >= 0; c--) {
              deletedChar(copy[endInd], c);
            }
            let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
            copy[endInd].text = end;
          }

          //add appended end text and styles
        } else if (startInd < i && i < endInd) {
          divTrashCan.push(i);
        }
      }

      if (divTrashCan.length) {
        let keeping = [];
        forEach(copy, (d, i) => {
          if (!divTrashCan.includes(i)) {
            keeping.push(d);
          }
        });
        copy = keeping;
      }

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        divId: copy[startInd + 1].id,
        offset: 0,
        isCollapsed: true,
      });
    }
  }

  function oldDivBreakOff(start) {
    let obj = {};
    if (start.textAlign) {
      obj.textAlign = start.textAlign;
    }
    if (start.list) {
      obj.list = start.list;
    }
    if (start.indent) {
      obj.indent = start.indent;
    }
    return obj;
  }

  function handlePaste(e) {
    e.preventDefault();
    if (lastSelection && e.clipboardData.types.includes("text/plain")) {
      let newText = e.clipboardData.getData("text");
      let paragraphs = newText.split("\n");
      if (paragraphs.length > 1) {
        handlePasteParagraphs(paragraphs);
        return;
      }

      if (lastSelection.isCollapsed) {
        let copy = getDataCopy();
        let div = copy.find((d) => d.id === lastSelection.divId);
        if (div.image) {
          let ind = copy.findIndex((d) => d.id === div.id);
          let id = shortId();
          copy.splice(ind + lastSelection.offset, 0, {
            text: newText,
            styles: [],
            id: id,
          });
          saveData(copy);
          createDisplayAndDoSelection(copy, {
            divId: id,
            offset: newText.length,
            isCollapsed: true,
          });
          return;
        }
        // add new text to this div
        let beginning = div.text.substring(0, lastSelection.offset);
        let end = div.text.substring(lastSelection.offset);
        div.text = beginning + newText + end;

        for (let i = 0; i < newText.length; i++) {
          addedChar(div, lastSelection.offset + i);
        }

        saveData(copy);
        createDisplay(copy, () => {
          let target = document.getElementById(lastSelection.divId);
          let count = 0;
          let targetOffset = 0;
          for (let node of target.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text) {
              let textOffset = 0;
              if (text.nodeValue) {
                for (let i = 0; i < text.nodeValue.length; i++) {
                  if (count < lastSelection.offset + newText.length) {
                    count++;
                    textOffset++;
                  }
                }
              }
              if (count == lastSelection.offset + newText.length) {
                target = text;
                targetOffset = textOffset;
                break;
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(target, targetOffset);
          newRange.collapse(true); // Collapse the range to the start position

          // Remove any existing selections and set the new range
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        });
      } else {
        handleSelectionInsert(newText);
      }
    }
  }

  function handleSelectionInsert(newText) {
    let copy = getDataCopy();
    let divTrashCan = [];
    let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
    let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

    for (let i = startInd; i <= endInd; i++) {
      if (i === startInd && i === endInd) {
        if (copy[i].image || copy[i].divider) {
          copy[i] = { id: copy[i].id, text: newText, styles: [] };
          continue;
        }
        let beginning = copy[i].text.substring(0, lastSelection.start.offset);
        let end = copy[i].text.substring(lastSelection.end.offset + 1);
        copy[i].text = beginning + end;

        for (
          let c = lastSelection.end.offset;
          c >= lastSelection.start.offset;
          c--
        ) {
          deletedChar(copy[i], c);
        }
        copy[i].text = beginning + newText + end;
        // insert the new text after deleting the old.

        for (let j = 0; j < newText.length; j++) {
          addedChar(copy[i], lastSelection.start.offset + j); // added char goes in start div
        }
      } else if (i == startInd) {
        if (copy[i].image || copy[i].divider) {
          copy[i] = { id: copy[i].id, text: newText, styles: [] };
        } else {
          let textLength = copy[i].text.length;
          for (let c = textLength - 1; c >= lastSelection.start.offset; c--) {
            deletedChar(copy[i], c);
          }

          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          copy[i].text = beginning + newText;

          // add new text
          for (let j = 0; j < newText.length; j++) {
            addedChar(copy[i], lastSelection.start.offset + j); // added char goes in start div
          }
        }

        //add appended end text and styles
        if (!copy[endInd].image && !copy[endInd].divider) {
          let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
          if (end) {
            for (let j = 0; j < end.length; j++) {
              // lengthen out the current styles
              let spot = lastSelection.start.offset + newText.length + j;
              addedChar(copy[startInd], spot);
            }
            let appendedStyles = [];
            forEach(copy[endInd].styles, (style) => {
              if (
                style.from <= lastSelection.end.offset + 1 &&
                style.to > lastSelection.end.offset
              ) {
                // it flows past
                style.from = 0;
                style.to -= lastSelection.end.offset + 1;
                appendedStyles.push(style);
              } else if (style.from >= lastSelection.end.offset + 1) {
                // is included
                style.from -= lastSelection.end.offset + 1;
                style.to -= lastSelection.end.offset + 1;
                appendedStyles.push(style);
              }
            });

            forEach(appendedStyles, (style) => {
              style.from += copy[i].text.length;
              style.to += copy[i].text.length;
              copy[i].styles.push(style);
            });
            copy[i].text += end;
          }
        }
      } else if (startInd < i && i < endInd) {
        divTrashCan.push(i);
      } else if (i == endInd) {
        divTrashCan.push(i);
      }
    }

    if (divTrashCan.length) {
      let keeping = [];
      forEach(copy, (d, i) => {
        if (!divTrashCan.includes(i)) {
          keeping.push(d);
        }
      });
      copy = keeping;
    }

    saveData(copy);
    createDisplay(copy, () => {
      let target = document.getElementById(lastSelection.start.divId);
      let count = 0;
      let targetOffset = 0;
      const endOfInsert = lastSelection.start.offset + newText.length;
      for (let node of target.childNodes) {
        let text = node.nodeName === "#text" ? node : node.childNodes[0];
        if (text) {
          let textOffset = 0;
          if (text.nodeValue) {
            for (let i = 0; i < text.nodeValue.length; i++) {
              if (count < endOfInsert) {
                count++;
                textOffset++;
              }
            }
          }
          if (count == endOfInsert) {
            target = text;
            targetOffset = textOffset;
            break;
          }
        }
      }

      const newRange = document.createRange();
      newRange.setStart(target, targetOffset);
      newRange.collapse(true); // Collapse the range to the start position

      // Remove any existing selections and set the new range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(newRange);
    });
  }

  function handlePasteParagraphs(paragraphs) {
    let copy = getDataCopy();
    if (lastSelection.isCollapsed) {
      let index = copy.findIndex((d) => d.id === lastSelection.divId);
      if (copy[index].image) {
        let newDivs = [];
        for (let p of paragraphs) {
          newDivs.push({
            text: p,
            id: shortId(),
            styles: [],
          });
        }

        let splitInd = lastSelection.offset ? index + 1 : index ? index - 1 : 0;

        copy = [
          ...copy.slice(0, splitInd),
          ...newDivs,
          ...copy.slice(splitInd),
        ];
        let lastNewDiv = newDivs[newDivs.length - 1];
        saveData(copy);
        createDisplayAndDoSelection(copy, {
          divId: lastNewDiv.id,
          offset: lastNewDiv.text.length,
          isCollapsed: true,
        });
        return;
      }

      // break the styles in two.
      let endStyles = [];
      let takeOutIndeces = [];
      let beginning = copy[index].text.substring(0, lastSelection.offset);
      let end = copy[index].text.substring(lastSelection.offset);
      copy[index].text = beginning + paragraphs[0];
      for (let i = 0; i < copy[index].styles.length; i++) {
        let style = copy[index].styles[i];
        if (style.from >= lastSelection.offset) {
          // Keep with the broken piece only
          let newTo = style.to - beginning.length;
          let newFrom = style.from - beginning.length;

          endStyles.push({ ...style, from: newFrom, to: newTo });
          takeOutIndeces.push(i);
        } else if (style.to > beginning.length - 1) {
          // It includes the broken piece.
          let newTo = style.to - beginning.length;
          copy[index].styles[i].to = beginning.length
            ? beginning.length - 1
            : 0;
          endStyles.push({ ...style, from: 0, to: newTo });
        }
      }

      removeStyleIndices(copy[index], takeOutIndeces);

      // keep the old styles
      let endFirstPart = paragraphs[paragraphs.length - 1];
      for (let style of endStyles) {
        style.from += endFirstPart.length - 1;
        style.to += endFirstPart.length - 1;
      }

      let endDiv = {
        ...oldDivBreakOff(copy[index]),
        id: shortId(),
        text: endFirstPart + end,
        styles: endStyles,
      };
      copy.splice(index + 1, 0, endDiv);

      if (copy[index].links) {
        for (let link of copy[index].links) {
          if (link.from >= lastSelection.offset) {
            if (!endDiv.links) {
              endDiv.links = [];
            }
            endDiv.links.push({
              ...link,
              to: link.to - lastSelection.offset + endFirstPart.length,
              from: link.from - lastSelection.offset + endFirstPart.length,
            });
          }
        }

        copy[index].links = copy[index].links.filter(
          (l) => l.to < lastSelection.offset
        );
        if (!copy[index].links.length) {
          delete copy[index].links;
        }
      }

      // Add the middle ones in
      for (let i = 1; i < paragraphs.length - 1; i++) {
        let newDiv = {
          ...oldDivBreakOff(copy[index]),
          text: paragraphs[i],
          id: shortId(),
          styles: [],
        };
        copy.splice(index + i, 0, newDiv);
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(endDiv.id);
        let count = 0;
        let targetOffset = 0;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            let textOffset = 0;
            if (text.nodeValue) {
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endFirstPart.length) {
                  count++;
                  textOffset++;
                }
              }
            }
            if (count == endFirstPart.length) {
              target = text;
              targetOffset = textOffset;
              break;
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, targetOffset);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      let divTrashCan = [];
      let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
      let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);
      let endingId;

      for (let i = startInd; i <= endInd; i++) {
        if (i === startInd && i === endInd) {
          if (copy[i].image || copy[i].divider) {
            // add the first and the last paragraphs in here
            copy[i] = {
              id: copy[i].id,
              text: paragraphs[0],
              styles: [],
            };

            endingId = shortId();
            copy.splice(startInd + 1, 0, {
              id: endingId,
              text: paragraphs[paragraphs.length - 1],
              styles: [],
            });
            continue;
          }

          // split the styles
          let startStyles = [];
          let endStyles = [];
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          let end = copy[i].text.substring(lastSelection.end.offset + 1);
          let lastLength = paragraphs[paragraphs.length - 1].length;
          let endDelta = lastLength - lastSelection.end.offset - 1;
          forEach(copy[i].styles, (style) => {
            if (style.from < lastSelection.start.offset) {
              if (style.to < lastSelection.start.offset) {
                // it is only on the left
                startStyles.push(style);
              } else if (style.to <= lastSelection.end.offset) {
                // it bleeds in from the left
                style.to = lastSelection.start.offset - 1;
                startStyles.push(style);
              } else if (style.to > lastSelection.end.offset) {
                // it overflows both ends
                let endStyle = {
                  ...style,
                  from: lastLength,
                  to: style.to + endDelta,
                };
                style.to = lastSelection.start.offset - 1;
                startStyles.push(style);
                endStyles.push(endStyle);
              }
            } else if (
              style.from <= lastSelection.end.offset &&
              style.to > lastSelection.end.offset
            ) {
              // it bleeds in from the right
              style.from = paragraphs[paragraphs.length - 1].length;
              style.to += endDelta;
              endStyles.push(style);
            } else if (style.from > lastSelection.end.offset) {
              // it is only on the right
              style.from += endDelta;
              style.to += endDelta;
              endStyles.push(style);
            }
          });

          let newStartDiv = {
            ...oldDivBreakOff(copy[startInd]),
            text: beginning + paragraphs[0],
            id: shortId(),
            styles: startStyles,
          };
          let newEndDiv = {
            ...oldDivBreakOff(copy[startInd]),
            text: paragraphs[paragraphs.length - 1] + end,
            id: shortId(),
            styles: endStyles,
          };
          endingId = newEndDiv.id;

          if (copy[i].links) {
            let startLinks = copy[i].links.filter(
              (l) => l.to < lastSelection.start.offset
            );
            if (startLinks.length) newStartDiv.links = startLinks;

            let endLinks = [];
            for (let link of copy[i].links) {
              if (lastSelection.end.offset < link.from) {
                let lastParagraph = paragraphs[paragraphs.length - 1];
                endLinks.push({
                  ...link,
                  from:
                    link.from -
                    lastSelection.end.offset +
                    lastParagraph.length -
                    1,
                  to:
                    link.to -
                    lastSelection.end.offset +
                    lastParagraph.length -
                    1,
                });
              }
            }
            if (endLinks.length) newEndDiv.links = endLinks;
          }

          copy.splice(startInd, 1, newStartDiv);
          copy.splice(startInd + 1, 0, newEndDiv);
        } else if (i == startInd) {
          if (copy[i].image || copy[i].divider) {
            copy[i] = {
              id: copy[i].id,
              text: paragraphs[0],
              styles: [],
            };
            continue;
          }

          let textLength = copy[i].text.length;
          for (let c = textLength - 1; c >= lastSelection.start.offset; c--) {
            deletedChar(copy[i], c);
          }

          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          copy[i].text = beginning + paragraphs[0];
        } else if (startInd < i && i < endInd) {
          divTrashCan.push(i);
        } else if (i == endInd) {
          if (copy[i].image || copy[i].divider) {
            endingId = shortId();
            copy[i] = {
              id: endingId,
              text: paragraphs[paragraphs.length - 1],
              styles: [],
            };
            continue;
          }

          // append the last paragraph onto the end div
          for (let j = lastSelection.end.offset; j >= 0; j--) {
            deletedChar(copy[i], j);
          }

          let lastParagraphText = paragraphs[paragraphs.length - 1];
          forEach(copy[i].styles, (style) => {
            style.from += lastParagraphText.length;
            style.to += lastParagraphText.length;
          });
          if (copy[i].links) {
            forEach(copy[i].links, (link) => {
              link.from += lastParagraphText.length;
              link.to += lastParagraphText.length;
            });
          }
          let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
          copy[i].text = lastParagraphText + end;
          endingId = copy[i].id;
        }
      }

      if (divTrashCan.length) {
        let keeping = [];
        forEach(copy, (d, i) => {
          if (!divTrashCan.includes(i)) {
            keeping.push(d);
          }
        });
        copy = keeping;
      }

      // Add the middle ones in
      for (let j = 1; j < paragraphs.length - 1; j++) {
        let newDiv = {
          ...oldDivBreakOff(copy[startInd]),
          text: paragraphs[j],
          id: shortId(),
          styles: [],
        };
        copy.splice(startInd + j, 0, newDiv);
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(endingId);
        let count = 0;
        let targetOffset = 0;
        let endOfPaste = paragraphs[paragraphs.length - 1].length;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            let textOffset = 0;
            if (text.nodeValue) {
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endOfPaste) {
                  count++;
                  textOffset++;
                }
              }
            }
            if (count == endOfPaste) {
              target = text;
              targetOffset = textOffset;
              break;
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, targetOffset);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    }
  }

  function handleCut(e) {
    if (lastSelection) {
      if (!lastSelection.isCollapsed) {
        if (navigator?.clipboard) {
          let startInd = data.findIndex(
            (div) => div.id === lastSelection.start.divId
          );
          let endInd = data.findIndex(
            (div) => div.id === lastSelection.end.divId
          );
          let string = "";

          for (let i = startInd; i <= endInd; i++) {
            if (data[i].image || data[i].divider) continue;
            if (i == startInd && i == endInd) {
              string = data[i].text.slice(
                lastSelection.start.offset,
                lastSelection.end.offset + 1
              );
            } else if (i == startInd) {
              string += data[i].text.slice(lastSelection.start.offset) + "\n";
            } else if (i > startInd && i < endInd) {
              string += data[i].text + "\n";
            } else if (i == endInd) {
              string += data[i].text.slice(0, lastSelection.end.offset + 1);
            }
          }

          if (document.hasFocus()) {
            navigator.clipboard.writeText(string);
          }
        }

        handleSelectionInsert("");
      }
    }
  }

  function addedChar(div, newCharSpot) {
    if (stylesToBe.length) {
      toggleStyle_onCollapsedSelection(div, newCharSpot);
    }

    for (let style of div.styles) {
      if (style.stop) {
        // from toggleStyle_onCollapsedSelection
        delete style.stop;
        continue;
      }

      // get the beginning styles up to speed
      if (newCharSpot == 0 && style.from == 0 && !style.to) {
        continue;
      }

      // increment to's for all included - or one char previous
      if (style.from < newCharSpot && style.to >= newCharSpot - 1) {
        style.to++;
      }

      // for all after, increase from and to's
      else if (style.from >= newCharSpot) {
        if (style.from > 0) {
          style.from++;
        }
        style.to++;
      }
    }

    if (div.links) {
      for (let link of div.links) {
        if (link.from <= newCharSpot && newCharSpot - 1 <= link.to) {
          if (menu || link.to != newCharSpot - 1) {
            // Protect the front
            link.to++;
          }
          if (!menu && link.from == newCharSpot) {
            // Protect the back
            link.from++;
          }
        } else if (link.from > newCharSpot) {
          link.to++;
          link.from++;
        }
      }
    }
  }

  function deletedChar(div, deletedCharSpot) {
    let removedIndices = [];
    for (let i = 0; i < div.styles.length; i++) {
      // decrement to's for all included - or one char previous
      let style = div.styles[i];

      if (deletedCharSpot == 0 && style.to == 0) {
        if (!div.text) {
          // Keep it since its at the end
          continue;
        } else {
          style.to--;
          // remove it since is still text in this div
        }
      } else if (style.from <= deletedCharSpot && style.to >= deletedCharSpot) {
        style.to--;
      }

      // for all after, decrement from and to's
      else if (deletedCharSpot < style.from) {
        style.from--;
        style.to--;
      }

      if (style.to < style.from) {
        // style is totally erased
        removedIndices.push(i);
      }
    }

    if (div.links) {
      let keeping = [];
      for (let link of div.links) {
        if (link.from <= deletedCharSpot && deletedCharSpot <= link.to) {
          link.to--;
        } else if (deletedCharSpot < link.from) {
          link.from--;
          link.to--;
        }
        if (link.to >= link.from) {
          keeping.push(link);
        }
      }
      if (keeping.length) {
        div.links = keeping;
      } else {
        delete div.links;
      }
    }

    removeStyleIndices(div, removedIndices);
  }

  // on add a char
  function toggleStyle_onCollapsedSelection(div, newCharSpot) {
    let newStyles = [];
    let trashCan = [];
    for (let styleToBe of stylesToBe) {
      const isToggableStyle =
        styleToBe.code === "fontWeight" ||
        styleToBe.code === "textDecoration" ||
        styleToBe.code === "fontStyle";
      if (styleToBe.divId === div.id && styleToBe.spot === newCharSpot) {
        let insert = true;
        for (let i = 0; i < div.styles.length; i++) {
          let style = div.styles[i];
          if (
            style.code === styleToBe.code &&
            (!isToggableStyle || style.value === styleToBe.value)
          ) {
            // same style
            if (style.from < newCharSpot && style.to >= newCharSpot) {
              // if a same style bleeds in, split it in two and don't insert
              newStyles.push({
                ...style,
                from: newCharSpot + 1,
                to: style.to + 1,
                stop: true,
              });
              style.to = newCharSpot - 1;
              style.stop = true;
              insert = false;
            } else if (style.to == newCharSpot - 1) {
              style.stop = true;
              insert = false;
            } else if (style.from == newCharSpot) {
              if (style.to === style.from) {
                // empty style. Get rid of it.
                trashCan.push(i);
              } else {
                style.to++;
              }
              style.stop = true;
              insert = false;
            }
          }
        }
        if (insert || !isToggableStyle) {
          newStyles.push({
            code: styleToBe.code,
            value: styleToBe.value,
            from: newCharSpot,
            to: newCharSpot,
            stop: true,
          });
        }
      }
    }

    for (let style of newStyles) {
      div.styles.push(style);
    }

    if (trashCan.length) {
      removeStyleIndices(div, trashCan);
    }

    setStylesToBe([]);
  }

  const container = useRef();
  const entry = useRef();
  const colorRef = useRef();

  const alignments = ["left", "center", "right"];

  function onAlignment(a) {
    if (lastSelection) {
      let copy = getDataCopy();
      if (lastSelection.isCollapsed) {
        let ind = copy.findIndex((d) => d.id === lastSelection.divId);
        copy[ind].textAlign = a;
      } else {
        let startInd = copy.findIndex(
          (d) => d.id === lastSelection.start.divId
        );
        let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);
        for (let i = startInd; i <= endInd; i++) {
          copy[i].textAlign = a;
        }
      }

      saveData(copy);
      createDisplay(copy);
      setRunSave(true);
    } else if (data.length == 1 && !data[0].text) {
      let copy = getDataCopy();
      copy[0].textAlign = a;
      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[0].id,
        offset: 0,
      });
    }
  }

  function onBulletPoint(type) {
    if (lastSelection) {
      let copy = getDataCopy();
      if (lastSelection.isCollapsed) {
        let ind = copy.findIndex((d) => d.id === lastSelection.divId);
        if (copy[ind].list && copy[ind].list === type) {
          delete copy[ind].list;
        } else {
          copy[ind].list = type;
        }
      } else {
        let startInd = copy.findIndex(
          (d) => d.id === lastSelection.start.divId
        );
        let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

        let allInList = true;
        let olCount = 0;
        let ulCount = 0;

        for (let i = startInd; i <= endInd; i++) {
          if (!copy[i].list) {
            allInList = false;
          } else {
            if (copy[i].list === "ol") {
              olCount++;
            } else {
              ulCount++;
            }
          }
        }

        if (
          allInList &&
          ((olCount && !ulCount && type === "ol") ||
            (ulCount && !olCount && type === "ul")) // all the selected type
        ) {
          for (let i = startInd; i <= endInd; i++) {
            delete copy[i].list;
          }
        } else {
          for (let i = startInd; i <= endInd; i++) {
            copy[i].list = type;
          }
        }
      }

      saveData(copy);
      createDisplayAndDoSelection(copy, lastSelection);
      setRunSave(true);
    } else if (data.length == 1 && !data[0].text) {
      let copy = getDataCopy();
      if (copy[0].list && copy[0].list === type) {
        delete copy[0].list;
      } else {
        copy[0].list = type;
      }
      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[0].id,
        offset: 0,
      });
    }
  }

  function onIndent(val) {
    let copy = getDataCopy();
    function addIndent(i) {
      if (i > -1) {
        if (copy[i].indent) {
          copy[i].indent += val;
          if (!copy[i].indent) {
            delete copy[i].indent;
          }
        } else if (val > 0) {
          copy[i].indent = val;
        }
      }
    }
    if (lastSelection) {
      if (lastSelection.isCollapsed) {
        let ind = copy.findIndex((d) => d.id === lastSelection.divId);
        addIndent(ind);
      } else {
        let startInd = copy.findIndex(
          (d) => d.id === lastSelection.start.divId
        );
        let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);
        for (let i = startInd; i <= endInd; i++) {
          addIndent(i);
        }
      }

      saveData(copy);
      createDisplayAndDoSelection(copy, lastSelection);
      setRunSave(true);
    } else if (data.length == 1 && !data[0].text) {
      addIndent(0);
      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[0].id,
        offset: 0,
      });
    }
  }

  function seeStylesAtSpot(div, spot, collapsed) {
    if (div.image || div.divider) {
      setColor(defaultBlack);
      setFontSize(false);
      setFont(false);
      setBold(false);
      setItalics(false);
      setUnderline(false);
      setStrike(false);

      if (stylesToBe.length) {
        setStylesToBe([]);
      }
      return;
    }

    let atSpot = [];

    for (let style of div.styles) {
      if (
        (style.from < spot || (!style.from && !spot)) &&
        spot <= style.to + 1
      ) {
        atSpot.push(style);
      }
    }

    function toggable(code) {
      return atSpot.some((s) => s.code === code);
    }

    function variable(code, ifNot) {
      let pertaining = atSpot.find((s) => s.code === code);
      if (pertaining) {
        return pertaining.value;
      }
      return ifNot;
    }

    setColor(variable("color", defaultBlack));
    setFontSize(variable("fontSize", ""));
    setFont(variable("fontFamily", ""));
    setBold(toggable("fontWeight"));
    setItalics(toggable("fontStyle"));

    setUnderline(
      atSpot.some((s) => s.code === "textDecoration" && s.value === "underline")
    );
    setStrike(
      atSpot.some(
        (s) => s.code === "textDecoration" && s.value === "line-through"
      )
    );

    if (collapsed) {
      if (stylesToBe.length) {
        // get rid of any styles not to be in the same spot.

        let keep = [];
        for (let style of stylesToBe) {
          if (style.divId === div.id && style.spot == spot) {
            setToBeStyleStatesAtSpot(style, atSpot);
            keep.push(style);
          }
        }
        setStylesToBe(keep);
      }
    } else {
      // wipe stylesToBe when selection is not collapsed.
      if (stylesToBe.length) {
        // Wipe all old stylesToBe - user clicked away before getting to it
        setStylesToBe([]);
      }
    }
  }

  function onSelect(e) {
    if (dragging.current) {
      return;
    }

    const selection = window.getSelection();

    if (!selection.rangeCount) {
      return;
    }
    const range = selection.getRangeAt(0);

    if (range.startContainer == entry.current) {
      // console.log("hit");
      saveSelection(null);
      return;
    }

    if (selection.isCollapsed) {
      let endOffset = range.endOffset;
      const endContainer = range.endContainer;

      let section = endContainer;

      if (!entry.current.contains(section)) {
        return;
      }

      while (
        section.parentElement !== entry.current &&
        !(section.id && data.some((div) => div.id === section.id))
      ) {
        section = section.parentElement;
      }

      let div = data.find((d) => d.id === section.id);

      if (div.divider) {
        let ind = data.findIndex((d) => d.id === section.id);
        if (ind + 1 < data.length) {
          section = document.getElementById(data[ind + 1].id);
          let target = section;

          while (target.childNodes.length) {
            target = target.childNodes[0];
          }

          const newRange = document.createRange();
          newRange.setStart(target, 0);
          newRange.collapse(true);

          selection.removeAllRanges();
          selection.addRange(newRange);
        } else {
          clearSelections();
        }
        return;
      }

      if (
        endContainer.nodeName === "DIV" &&
        endContainer.parentElement === entry.current &&
        endOffset == 1
      ) {
        // its a blank div
        if (!div.image) {
          endOffset = 0;
        }
      } else {
        if (div.image) {
          section = div;
        } else {
          // count the characters until the actual offset
          for (let node of section.childNodes) {
            if (node.nodeName === "BR") {
              continue;
            }
            let text = node.nodeName === "#text" ? node : node.childNodes[0]; // In case the text is outside of the span, which the browser will do on text entry on a blank line/div
            if (text === endContainer) {
              break;
            }
            if (text.nodeValue) {
              endOffset += text.nodeValue.length;
            }
          }
        }
      }

      saveSelection({
        divId: section.id,
        offset: endOffset,
        isCollapsed: selection.isCollapsed,
      });

      if (div) {
        seeStylesAtSpot(div, endOffset, selection.isCollapsed);
      }
    } else {
      // use selection.focusNode && focusOffset to find cursor position
      let start = range.startContainer;
      let startOffset = range.startOffset;

      if (!entry.current.contains(start)) {
        return;
      }

      while (
        start.parentElement !== entry.current &&
        !(start.id && data.some((d) => d.id === start.id))
      ) {
        start = start.parentElement;
      }

      let startDiv = data.find((d) => d.id === start.id);
      if (startDiv.image) {
        if (startOffset == 1) {
          let ind = data.findIndex((d) => d.id === startDiv.id);
          if (data.length > ind + 1) {
            startDiv = data[ind + 1];
            startOffset = 0;
          }
        } else {
          startOffset = 0;
        }
      } else if (startDiv.divider) {
        startOffset = 0;
      } else {
        for (let node of start.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text === range.startContainer) {
            break;
          }
          if (text.nodeValue) {
            startOffset += text.nodeValue.length;
          }
        }
      }

      let end = range.endContainer;
      let endOffset = range.endOffset - 1;

      if (!entry.current.contains(end)) {
        return;
      }

      while (
        end.parentElement !== entry.current &&
        !(end.id && data.some((d) => d.id === end.id))
      ) {
        end = end.parentElement;
      }

      let endDiv = data.find((d) => d.id === end.id);
      if (endDiv.image) {
        if (endOffset == -1) {
          let ind = data.findIndex((d) => d.id === endDiv.id);
          if (ind) {
            endDiv = data[ind - 1];
            endOffset = data[ind - 1].image ? 1 : data[ind - 1].text.length;
          }
        } else {
          endOffset = 1;
        }
      } else if (endDiv.divider) {
        endOffset = 0;
      } else {
        if (endOffset === -1) {
          let ind = data.findIndex((d) => d.id === endDiv.id);
          if (ind > 0) {
            endDiv = data[ind - 1];
            endOffset = endDiv.image
              ? 1
              : endDiv.divider
              ? 0
              : endDiv.text.length - 1;
          }
        } else {
          for (let node of end.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text === range.endContainer) {
              break;
            }
            if (text.nodeValue) {
              endOffset += text.nodeValue.length;
            }
          }
        }
      }

      let focus = null;
      let focusOffset = 0;

      if (selection.focusNode === range.startContainer) {
        focus = startDiv;
        focusOffset = startOffset;
      } else if (selection.focusNode === range.endContainer) {
        focus = endDiv;
        focusOffset = endOffset;
      }

      saveSelection({
        start: {
          divId: startDiv.id,
          offset: startOffset,
        },
        end: {
          divId: endDiv.id,
          offset: endOffset,
        },
        focus: {
          divId: focus?.id,
          offset: focusOffset,
        },
        isCollapsed: selection.isCollapsed,
      });

      if (endDiv) {
        seeStylesAtSpot(endDiv, endOffset, selection.isCollapsed);
      }
    }

    if (incoming) {
      setTimeout(() => setShowContent(incoming), 100);
    }
  }

  function setToBeStyleStatesAtSpot(toBeStyle, atSpot) {
    let code = toBeStyle.code;
    if (code === "color") {
      setColor(toBeStyle.value);
    } else if (code === "fontSize") {
      setFontSize(toBeStyle.value);
    } else if (code === "fontFamily") {
      setFont(toBeStyle.value);
    } else {
      // Toggable Style
      if (code === "fontWeight") {
        setBold(!atSpot.some((s) => s.code === code));
      } else if (code === "textDecoration") {
        if (toBeStyle.value === "underline") {
          setUnderline(
            !atSpot.some(
              (s) => s.code === "textDecoration" && s.value === "underline"
            )
          );
        } else if (toBeStyle.value === "line-through") {
          setStrike(
            !atSpot.some(
              (s) => s.code === "textDecoration" && s.value === "line-through"
            )
          );
        }
      } else if (code === "fontStyle") {
        setItalics(!atSpot.some((s) => s.code === code));
      }
    }
  }

  function changeToggableStyle(code, value, currStyle, setStyle) {
    if (lastSelection) {
      if (!lastSelection.isCollapsed) {
        styleToggle(code, value, setStyle);
      } else {
        let style = {
          code: code,
          value: value,
          divId: lastSelection.divId,
          spot: lastSelection.offset,
        };
        addToggableStyleToBe(style);
        setStyle(!currStyle);
      }
    } else {
      addStartingToggableStyleToBe(code, value);
      setStyle(!currStyle);
    }
  }

  function onBold() {
    changeToggableStyle("fontWeight", "600", bold, setBold);
  }

  function onUnderline() {
    changeToggableStyle("textDecoration", "underline", underline, setUnderline);
  }

  function onItalicize() {
    changeToggableStyle("fontStyle", "italic", italics, setItalics);
  }

  function onStrikethrough() {
    changeToggableStyle("textDecoration", "line-through", strike, setStrike);
  }

  function styleToggle(code, value, setStyle) {
    // Bold, Italics, Underline, Strikethrough, styles that toggle
    // selecting multiple
    let copy = getDataCopy();
    let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
    let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

    let normal = 0;
    let applied = 0;
    for (let i = startInd; i <= endInd; i++) {
      if (copy[i].image || copy[i].divider) continue;
      let appliedIndicies = [];
      forEach(copy[i].styles, (style) => {
        if (style.code === code && style.value === value) {
          for (let ci = style.from; ci <= style.to; ci++) {
            if (i == startInd && i == endInd) {
              if (
                ci >= lastSelection.start.offset &&
                ci <= lastSelection.end.offset
              ) {
                appliedIndicies.push(ci);
              }
            } else if (i == startInd) {
              if (ci >= lastSelection.start.offset) {
                appliedIndicies.push(ci);
              }
            } else if (i > startInd && i < endInd) {
              appliedIndicies.push(ci);
            } else if (i == endInd) {
              if (ci <= lastSelection.end.offset) {
                appliedIndicies.push(ci);
              }
            }
          }
        }
      });

      forEach(copy[i].text.split(""), (c, ci) => {
        if (appliedIndicies.includes(ci)) {
          applied++;
        } else if (i == startInd && i == endInd) {
          if (
            ci >= lastSelection.start.offset &&
            ci <= lastSelection.end.offset
          ) {
            normal++;
          }
        } else if (i == startInd) {
          if (ci >= lastSelection.start.offset) {
            normal++;
          }
        } else if (i > startInd && i < endInd) {
          normal++;
        } else if (i == endInd) {
          if (ci <= lastSelection.end.offset) {
            normal++;
          }
        }
      });
    }
    if (normal >= applied) {
      // APPLY
      setStyle(true);
      // The structure is different here than the else branch because here we are adding styles, and the to and from on the new style can grow with the more styles you see. So, its because of newTo and newFrom. May not always apply though.
      for (let i = startInd; i <= endInd; i++) {
        if (copy[i].image || copy[i].divider) continue;
        if (i == startInd && i == endInd) {
          let toBeRemoved = [];
          let newFrom = lastSelection.start.offset;
          let newTo = lastSelection.end.offset;
          forEach(copy[i].styles, (style, ind) => {
            if (style.code === code && style.value === value) {
              if (
                style.from >= lastSelection.start.offset &&
                style.to <= lastSelection.end.offset
              ) {
                // is contained
                toBeRemoved.push(ind);
              } else if (
                style.from < lastSelection.start.offset &&
                style.to >= lastSelection.start.offset &&
                style.to <= lastSelection.end.offset
              ) {
                // bleeds in from left
                toBeRemoved.push(ind);
                if (style.from < newFrom) {
                  newFrom = style.from;
                }
              } else if (
                style.from >= lastSelection.start.offset &&
                style.from <= lastSelection.end.offset &&
                style.to > lastSelection.end.offset
              ) {
                // bleeds in from right
                toBeRemoved.push(ind);
                if (style.to > newTo) {
                  newTo = style.to;
                }
              } else if (style.to == lastSelection.start.offset - 1) {
                // adjacent on left
                toBeRemoved.push(ind);
                if (style.from < newFrom) {
                  newFrom = style.from;
                }
              } else if (style.from == lastSelection.end.offset + 1) {
                // adjacent on right
                toBeRemoved.push(ind);
                if (style.to > newTo) {
                  newTo = style.to;
                }
              }
            }
          });
          removeStyleIndices(copy[i], toBeRemoved);
          copy[i].styles.push({
            code: code,
            value: value,
            from: newFrom,
            to: newTo,
          });
        } else if (i == startInd) {
          let toBeRemoved = [];
          let newFrom = lastSelection.start.offset;
          let newTo = copy[i].text.length - 1;
          forEach(copy[i].styles, (style, ind) => {
            if (style.code === code && style.value === value) {
              if (
                style.from < lastSelection.start.offset &&
                style.to >= lastSelection.start.offset
              ) {
                // bleeds in from the left
                toBeRemoved.push(ind);
                if (style.from < newFrom) {
                  newFrom = style.from;
                }
              } else if (style.from >= lastSelection.start.offset) {
                // is contained
                toBeRemoved.push(ind);
              } else if (style.to == lastSelection.start.offset - 1) {
                // adjacent on left
                toBeRemoved.push(ind);
                if (style.from < newFrom) {
                  newFrom = style.from;
                }
              }
            }
          });
          removeStyleIndices(copy[i], toBeRemoved);
          copy[i].styles.push({
            code: code,
            value: value,
            from: newFrom,
            to: newTo,
          });
        } else if (i > startInd && i < endInd) {
          let toBeRemoved = [];
          forEach(copy[i].styles, (style, ind) => {
            if (style.code === code && style.value === value) {
              toBeRemoved.push(ind);
            }
          });
          removeStyleIndices(copy[i], toBeRemoved);
          copy[i].styles.push({
            code: code,
            value: value,
            from: 0,
            to: copy[i].text.length - 1,
          });
        } else if (i == endInd) {
          let toBeRemoved = [];
          let newFrom = 0;
          let newTo = lastSelection.end.offset;
          forEach(copy[i].styles, (style, ind) => {
            if (style.code === code && style.value === value) {
              if (
                style.from <= lastSelection.end.offset &&
                style.to > lastSelection.end.offset
              ) {
                // bleeds in from right
                toBeRemoved.push(ind);
                if (newTo < style.to) {
                  newTo = style.to;
                }
              } else if (
                style.from <= lastSelection.end.offset &&
                style.to <= lastSelection.end.offset
              ) {
                // is contained
                toBeRemoved.push(ind);
              } else if (style.from == lastSelection.end.offset + 1) {
                // adjacent on the right
                toBeRemoved.push(ind);
                if (style.to > newTo) {
                  newTo = style.to;
                }
              }
            }
          });
          removeStyleIndices(copy[i], toBeRemoved);
          copy[i].styles.push({
            code: code,
            value: value,
            from: newFrom,
            to: newTo,
          });
        }
      }
    } else {
      // UNAPPLY
      setStyle(false);
      // remove all same styles within this range.
      for (let i = startInd; i <= endInd; i++) {
        if (copy[i].image || copy[i].divider) continue;
        let trashCan = [];
        let repair = [];
        forEach(copy[i].styles, (style, ind) => {
          if (style.code === code && style.value === value) {
            if (i == startInd && i == endInd) {
              if (
                style.from < lastSelection.start.offset &&
                style.to >= lastSelection.start.offset &&
                style.to <= lastSelection.end.offset
              ) {
                // Bleeds in from left
                copy[i].styles[ind].to = lastSelection.start.offset - 1;
              } else if (
                style.from >= lastSelection.start.offset &&
                style.from <= lastSelection.end.offset &&
                style.to > lastSelection.end.offset
              ) {
                // Bleeds in from right
                copy[i].styles[ind].from = lastSelection.end.offset + 1;
              } else if (
                style.from >= lastSelection.start.offset &&
                style.to <= lastSelection.end.offset
              ) {
                // is contained inside
                trashCan.push(ind);
              } else if (
                style.from < lastSelection.start.offset &&
                style.to > lastSelection.end.offset
              ) {
                // Overflows both ends
                let oldTo = copy[i].styles[ind].to;
                copy[i].styles[ind].to = lastSelection.start.offset - 1;
                repair.push({
                  code: code,
                  value: value,
                  from: lastSelection.end.offset + 1,
                  to: oldTo,
                });
              }
            } else if (i == startInd) {
              if (style.from >= lastSelection.start.offset) {
                // is contained inside
                trashCan.push(ind);
              } else if (style.to >= lastSelection.start.offset) {
                // Bleeds in from left
                copy[i].styles[ind].to = lastSelection.start.offset - 1;
              }
            } else if (i > startInd && i < endInd) {
              // is contained inside
              trashCan.push(ind);
            } else if (i == endInd) {
              if (style.to <= lastSelection.end.offset) {
                // is contained inside
                trashCan.push(ind);
              } else if (style.from <= lastSelection.end.offset) {
                // Bleeds in from right
                copy[i].styles[ind].from = lastSelection.end.offset + 1;
              }
            }
          }
        });

        removeStyleIndices(copy[i], trashCan);
        forEach(repair, (style) => copy[i].styles.push(style));
      }
    }

    createDisplayAndDoSelection(copy, lastSelection);
    saveData(copy);
  }

  function removeStyleIndices(div, indices) {
    let keep = [];
    forEach(div.styles, (style, ind) => {
      if (!indices.includes(ind)) {
        keep.push(style);
      }
    });
    div.styles = keep;
  }

  function removeLinkIndices(div, indices) {
    let keep = [];
    forEach(div.links, (link, ind) => {
      if (!indices.includes(ind)) {
        keep.push(link);
      }
    });
    div.links = keep;
  }

  function applyVariableStyle(code, val) {
    // Color, Font size, styles with many possible values
    let copy = getDataCopy();
    let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
    let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

    // remove or split any colors in here

    for (let i = startInd; i <= endInd; i++) {
      if (copy[i].image || copy[i].divider) continue;
      if (i == startInd && i == endInd) {
        let repair = [];
        let trashCan = [];
        let from = lastSelection.start.offset;
        let to = lastSelection.end.offset;

        forEach(copy[i].styles, (style, ind) => {
          if (style.code === code) {
            if (
              style.from < lastSelection.start.offset &&
              style.to >= lastSelection.start.offset &&
              style.to <= lastSelection.end.offset
            ) {
              // Bleeds in from left
              if (style.value === val) {
                trashCan.push(ind);
                if (style.from < from) {
                  from = style.from;
                }
              } else {
                copy[i].styles[ind].to = lastSelection.start.offset - 1;
              }
            } else if (
              style.from >= lastSelection.start.offset &&
              style.from <= lastSelection.end.offset &&
              style.to > lastSelection.end.offset
            ) {
              // Bleeds in from right
              if (style.value === val) {
                trashCan.push(ind);
                if (style.to > to) {
                  to = style.to;
                }
              } else {
                copy[i].styles[ind].from = lastSelection.end.offset + 1;
              }
            } else if (
              style.from >= lastSelection.start.offset &&
              style.to <= lastSelection.end.offset
            ) {
              // is contained inside
              trashCan.push(ind);
            } else if (
              style.from < lastSelection.start.offset &&
              style.to > lastSelection.end.offset
            ) {
              // Overflows both ends
              if (style.value === val) {
                trashCan.push(ind);
                if (style.from < from) {
                  from = style.from;
                }
                if (style.to > to) {
                  to = style.to;
                }
              } else {
                let oldTo = copy[i].styles[ind].to;
                copy[i].styles[ind].to = lastSelection.start.offset - 1;
                repair.push({
                  code: code,
                  value: style.value,
                  from: lastSelection.end.offset + 1,
                  to: oldTo,
                });
              }
            } else if (
              style.to == lastSelection.start.offset - 1 &&
              style.value === val
            ) {
              // adjacent on left
              trashCan.push(ind);
              if (style.from < from) {
                from = style.from;
              }
            } else if (
              style.from == lastSelection.end.offset + 1 &&
              style.value === val
            ) {
              // adjacent on right
              trashCan.push(ind);
              if (style.to > to) {
                to = style.to;
              }
            }
          }
        });
        removeStyleIndices(copy[i], trashCan);
        forEach(repair, (style) => copy[i].styles.push(style));
        copy[i].styles.push({
          code: code,
          value: val,
          from: from,
          to: to,
        });
      } else if (i == startInd) {
        let trashCan = [];
        let from = lastSelection.start.offset;
        let to = copy[i].text.length - 1;
        forEach(copy[i].styles, (style, ind) => {
          if (style.code === code) {
            if (style.from >= lastSelection.start.offset) {
              // is contained inside
              trashCan.push(ind);
            } else if (style.to >= lastSelection.start.offset) {
              // Bleeds in from left
              if (style.value === val) {
                trashCan.push(ind);
                if (style.from < from) {
                  from = style.from;
                }
              } else {
                copy[i].styles[ind].to = lastSelection.start.offset - 1;
              }
            } else if (
              style.to == lastSelection.start.offset - 1 &&
              style.value === val
            ) {
              // adjacent on left
              trashCan.push(ind);
              if (style.from < from) {
                from = style.from;
              }
            }
          }
        });
        removeStyleIndices(copy[i], trashCan);
        copy[i].styles.push({
          code: code,
          value: val,
          from: from,
          to: to,
        });
      } else if (i > startInd && i < endInd) {
        // is contained inside
        let trashCan = [];
        forEach(copy[i].styles, (style, ind) => {
          if (style.code === code) {
            trashCan.push(ind);
          }
        });
        removeStyleIndices(copy[i], trashCan);
        copy[i].styles.push({
          code: code,
          value: val,
          from: 0,
          to: copy[i].text.length - 1,
        });
      } else if (i == endInd) {
        let trashCan = [];
        let from = 0;
        let to = lastSelection.end.offset;
        forEach(copy[i].styles, (style, ind) => {
          if (style.code === code) {
            if (style.to <= lastSelection.end.offset) {
              // is contained inside
              trashCan.push(ind);
            } else if (style.from <= lastSelection.end.offset) {
              // Bleeds in from right
              if (style.value === val) {
                trashCan.push(ind);
                if (style.to > to) {
                  to = style.to;
                }
              } else {
                copy[i].styles[ind].from = lastSelection.end.offset + 1;
              }
            } else if (
              style.from == lastSelection.end.offset + 1 &&
              style.value === val
            ) {
              // adjacent on the right
              trashCan.push(ind);
              if (style.to > to) {
                to = style.to;
              }
            }
          }
        });
        removeStyleIndices(copy[i], trashCan);
        copy[i].styles.push({
          code: code,
          value: val,
          from: from,
          to: to,
        });
      }
    }
    saveData(copy);
    createDisplay(copy);
  }

  function changeVariableStyle(code, value) {
    if (lastSelection) {
      if (!lastSelection.isCollapsed) {
        applyVariableStyle(code, value);
      } else {
        let style = {
          code: code,
          value: value,
          divId: lastSelection.divId,
          spot: lastSelection.offset,
        };

        addVariableStyleToBe(style);
      }
    } else {
      addStartingVariableStyleToBe(code, value);
    }
  }

  function changeColor(clr) {
    clr = clr.toLowerCase();
    setColor(clr);
    changeVariableStyle("color", clr);
  }

  function changeFontSize(val) {
    setFontSize(val);
    changeVariableStyle("fontSize", val);
    setRunSave(true);
  }

  function changeFont(val) {
    setFont(val);
    changeVariableStyle("fontFamily", val);
    setRunSave(true);
  }

  function addStartingVariableStyleToBe(code, val) {
    if (data.length == 1 && !data[0].text) {
      let style = {
        code: code,
        value: val,
        divId: data[0].id,
        spot: 0,
      };
      addVariableStyleToBe(style);
    }
  }

  function addVariableStyleToBe(styleToBe) {
    // don't do it if it's already there
    let div = data.find((d) => d.id === styleToBe.divId);
    if (div) {
      for (let style of div.styles) {
        if (
          style.code === styleToBe.code &&
          style.value === styleToBe.value &&
          style.from <= styleToBe.spot &&
          style.to >= styleToBe.spot
        ) {
          return;
        }
      }

      //check styles for same code?
      let toBe = [...stylesToBe];
      let index = toBe.findIndex((s) => s.code === styleToBe.code);
      if (index > -1) {
        toBe.splice(index, 1);
      }
      toBe.push(styleToBe);
      setStylesToBe(toBe);
    }
  }

  function addStartingToggableStyleToBe(code, val) {
    if (data.length == 1 && !data[0].text) {
      let style = {
        code: code,
        value: val,
        divId: data[0].id,
        spot: 0,
      };
      addToggableStyleToBe(style);
    }
  }

  function addToggableStyleToBe(styleToBe) {
    let toBe = [...stylesToBe];
    let index = toBe.findIndex(
      (s) => s.code === styleToBe.code && s.value === styleToBe.value
    );
    if (index > -1) {
      toBe.splice(index, 1);
    } else {
      toBe.push(styleToBe);
    }
    setStylesToBe(toBe);
  }

  function createDisplayAndDoSelection(dataCopy, selection) {
    createDisplay(dataCopy, () => {
      if (selection) {
        if (selection.isCollapsed) {
          let target = document.getElementById(selection.divId);
          let div = dataCopy.find((d) => d.id === selection.divId);
          let subCount = 0;

          if (div.image) {
            subCount = selection.offset;
          } else if (div.divider) {
            subCount = 0;
          } else {
            let count = 0;
            for (let node of target.childNodes) {
              let text = node.nodeName === "#text" ? node : node.childNodes[0];
              if (text) {
                if (text.nodeValue) {
                  subCount = 0;
                  for (let i = 0; i < text.nodeValue.length; i++) {
                    if (count < selection.offset) {
                      count++;
                      subCount++;
                    }
                  }

                  if (count == selection.offset) {
                    target = text;
                    break;
                  }
                }
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(target, subCount);
          newRange.collapse(true); // Collapse the range to the start position

          // Remove any existing selections and set the new range
          const windowSelection = window.getSelection();
          windowSelection.removeAllRanges();
          windowSelection.addRange(newRange);
        } else {
          let startTarget = document.getElementById(selection.start.divId);
          let subCount = 0;
          let count = 0;

          let startDiv = dataCopy.find((d) => d.id === selection.start.divId);
          if (startDiv.image) {
            subCount = selection.start.offset;
          } else if (startDiv.divider) {
            subCount = 0;
          } else {
            for (let node of startTarget.childNodes) {
              let text = node.nodeName === "#text" ? node : node.childNodes[0];
              if (text) {
                if (text.nodeValue) {
                  subCount = 0;
                  for (let i = 0; i < text.nodeValue.length; i++) {
                    if (count < selection.start.offset) {
                      count++;
                      subCount++;
                    }
                  }

                  if (count == selection.start.offset) {
                    startTarget = text;
                    break;
                  }
                }
              }
            }
          }

          let endTarget = document.getElementById(selection.end.divId);
          let endSubCount = 0;
          count = 0;

          let endDiv = dataCopy.find((d) => d.id === selection.end.divId);
          if (endDiv.image) {
            endSubCount = selection.end.offset;
          } else if (endDiv.divider) {
            endSubCount = 0;
          } else {
            for (let node of endTarget.childNodes) {
              let text = node.nodeName === "#text" ? node : node.childNodes[0];
              if (text) {
                if (text.nodeValue) {
                  endSubCount = 0;
                  for (let i = 0; i < text.nodeValue.length; i++) {
                    if (count < selection.end.offset + 1) {
                      count++;
                      endSubCount++;
                    }
                  }

                  if (count == selection.end.offset + 1) {
                    endTarget = text;
                    break;
                  }
                }
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(startTarget, subCount);
          newRange.setEnd(endTarget, endSubCount);

          // Remove any existing selections and set the new range
          const windowSelection = window.getSelection();
          windowSelection.removeAllRanges();
          windowSelection.addRange(newRange);
        }
      }
    });
  }

  const [showContent, setShowContent] = useState("");

  useEffect(() => {
    if (showContent) {
      setShowContent("");
      setUpIncoming(showContent);
    }
  }, [showContent]);

  function setUpIncoming(content) {
    let copy = getDataCopy();
    let index = -1;
    let offset = 0;
    if (lastSelection.isCollapsed) {
      index = copy.findIndex((d) => d.id === lastSelection.divId);
      offset = lastSelection.offset;
    } else {
      index = copy.findIndex((d) => d.id === lastSelection.start.divId);
      offset = lastSelection.start.offset;
    }

    if (index > -1) {
      if (content === "Divider") {
        addDivider(copy, index);
      } else if (content === "Fields") {
        menuPrep(copy, index, offset, "{recipient ");
      } else if (content === "Link") {
        incomingLinkPrep(copy, index, offset, { ...defaultLink });
      } else if (content === "Button") {
        incomingLinkPrep(copy, index, offset, {
          ...defaultLink,
          asButton: true,
          fontColor: "#ffffff",
        });
      } else if (content === "Unsub Link") {
        incomingLinkPrep(copy, index, offset, {
          ...defaultLink,
          unsub: true,
          text: "unsubscribe",
          underline: true,
        });
      } else if (content === "Image") {
        menuPrep(copy, index, offset, "{");
      } else if (content === "Signature") {
        menuPrep(copy, index, offset, "{");
      }
    }
    // clearIncoming();
  }

  function menuPrep(copy, index, offset, input) {
    setUpMenu_FromIncomingClick(input);

    if (lastSelection.isCollapsed) {
      copy[index].text =
        copy[index].text.slice(0, offset) +
        input +
        copy[index].text.slice(offset);

      for (let char of input) {
        addedChar(copy[index], offset);
        offset++;
      }

      createDisplayAndDoSelection(copy, {
        divId: copy[index].id,
        offset: offset,
        isCollapsed: true,
      });
      saveData(copy);
    } else {
      handleSelectionInsert(input);
    }
  }

  function onFieldInput(string) {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      let endOffset = menu.offset;

      let first = copy[index].text.slice(0, endOffset);
      let second = copy[index].text.slice(endOffset);
      copy[index].text = first + string + second;

      for (let char of string) {
        addedChar(copy[index], endOffset);
        endOffset++;
      }

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[index].id,
        offset: endOffset,
      });

      menu.offset = endOffset;
    }
  }

  function onFieldPrep() {
    onFieldInput("recipient ");
  }

  function onPickField(field) {
    onFieldInput(field.name + "} ");
    closeMenu();
  }

  function onConditionPrep() {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      // it will have 'recipient ',
      let endOffset = menu.offset - 1;

      let first = copy[index].text.slice(0, endOffset - "recipient".length);
      let second = copy[index].text.slice(endOffset);
      copy[index].text = first + "condition" + second;

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[index].id,
        offset: menu.offset,
      });
    }
  }

  function onConditionSwap(condition) {
    if (condition.name !== menu.init) {
      let copy = getDataCopyWithMenuUp();
      let index = copy.findIndex((d) => d.id === menu.divId);
      if (index > -1) {
        let to = copy[index].text.indexOf("}", menu.offset);
        let before = copy[index].text.slice(menu.offset, to);

        let offset = to;
        if (before.length > condition.name.length) {
          // remove text
          let amount = before.length - condition.name.length;
          for (let i = 0; i < amount; i++) {
            deletedChar(copy[index], offset - 1);
            offset--;
          }
        } else if (before.length < condition.name.length) {
          // add text
          let amount = condition.name.length - before.length;
          for (let i = 0; i < amount; i++) {
            addedChar(copy[index], offset);
            offset++;
          }
        }

        copy[index].text =
          copy[index].text.slice(0, menu.offset) +
          condition.name +
          copy[index].text.slice(to);

        saveData(copy);
        createDisplay(copy);
      }
    }

    closeMenu();
  }

  function incomingLinkPrep(copy, index, offset, link) {
    menuPrep(copy, index, offset, link.text); // accounts for non collapsed selections
    clearSelections();

    setTimeout(() => {
      let copy = getDataCopyWithMenuUp(); // Will get the up to date data from the recordRef

      if (!copy[index].links) {
        copy[index].links = [];
      }

      copy[index].links.push({
        ...link,
        from: offset,
        to: offset + link.text.length - 1,
      });

      saveData(copy);
      createDisplay(copy);
    }, 100);
  }

  function onLinkEdit(link) {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);

    if (index > -1) {
      let before = "";

      let linkInd = copy[index].links
        ? copy[index].links.findIndex((l) => l.from === menu.offset)
        : -1;

      if (linkInd > -1) {
        let old = copy[index].links[linkInd];
        before = copy[index].text.slice(old.from, old.to + 1);
        copy[index].links[linkInd] = { ...old, ...link };
      } else {
        // new link
        if (
          copy[index].text.length &&
          copy[index].text[menu.offset - 1] === "{"
        ) {
          copy[index].text = // take off the '{'
            copy[index].text.slice(0, menu.offset - 1) +
            copy[index].text.slice(menu.offset);

          menu.offset--; // Will not be working with the '{' brackets
          deletedChar(copy[index], menu.offset);
        } else {
          // Switch from survey url to something different
          const sub = "{survey url}";
          let text = copy[index].text;

          if (
            text &&
            text.slice(menu.offset, menu.offset + sub.length) === "{survey url}"
          ) {
            let spot = menu.offset + sub.length;
            for (let i = 0; i < sub.length; i++) {
              deletedChar(copy[index], spot - 1);
              spot--;
            }
            copy[index].text =
              text.slice(0, menu.offset) + text.slice(menu.offset + sub.length);
          }
        }

        if (!copy[index].links) {
          copy[index].links = [];
        }

        copy[index].links.push({
          ...link,
          from: menu.offset,
          to: menu.offset - 1,
        });
      }

      let offset = menu.offset + before.length;
      if (before.length > link.text.length) {
        // remove text
        let amount = before.length - link.text.length;
        // styles won't be being edited during this time, so treat every edit at the ends
        for (let i = 0; i < amount; i++) {
          deletedChar(copy[index], offset - 1);
          offset--;
        }
      } else if (before.length < link.text.length) {
        // add text
        let amount = link.text.length - before.length;
        for (let i = 0; i < amount; i++) {
          addedChar(copy[index], offset);
          offset++;
        }
      }

      copy[index].text =
        copy[index].text.slice(0, menu.offset) +
        link.text +
        copy[index].text.slice(menu.offset + before.length);

      saveData(copy);
      createDisplay(copy);

      if (document.activeElement === entry.current) {
        entry.current.blur();
      }
    }
  }

  function onSurveyUrl() {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);

    if (index > -1) {
      let linkInd = copy[index].links
        ? copy[index].links.findIndex((l) => l.from === menu.offset)
        : -1;

      if (linkInd > -1) {
        let old = copy[index].links[linkInd];

        let spot = menu.offset + old.text.length;

        for (let i = 0; i < old.text.length; i++) {
          deletedChar(copy[index], spot - 1);
          spot--;
        }

        copy[index].text =
          copy[index].text.slice(0, menu.offset) +
          copy[index].text.slice(menu.offset + old.text.length);
      }

      const sub = "{survey url}";

      for (let i = 0; i < sub.length; i++) {
        addedChar(copy[index], menu.offset + i);
      }
      copy[index].text =
        copy[index].text.slice(0, menu.offset) +
        sub +
        copy[index].text.slice(menu.offset);

      saveData(copy);
      createDisplay(copy);
    }
  }

  function onSigPick(sig) {
    closeMenu();
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      copy[index].text = // take off the '{'
        copy[index].text.slice(0, menu.offset - 1) +
        copy[index].text.slice(menu.offset);
      deletedChar(copy[index], menu.offset - 1);

      let sigData = sig.dynamic
        ? [
            {
              text: "{Signature}",
              styles: [],
            },
          ]
        : JSON.parse(sig.encoding);

      for (let div of sigData) {
        div.id = shortId();
        div.sig = true;
      }

      copy = [
        ...copy.slice(0, copy[index].text ? index + 1 : index),
        ...sigData,
        ...copy.slice(index + 1),
      ];

      saveData(copy);
      createDisplay(copy);
      setRunSave(true);
      clearSelections();
    }
  }

  function onImagePick(img, width = 60, height = 60) {
    closeMenu();
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      let imgDiv = {
        id: shortId(),
        image: true,
        src: img.imageURL,
        width: width,
        height: height,
      };

      copy[index].text = // take off the '{'
        copy[index].text.slice(0, menu.offset - 1) +
        copy[index].text.slice(menu.offset);

      deletedChar(copy[index], menu.offset - 1);

      if (copy[index].text) {
        copy.splice(index + 1, 0, imgDiv);
      } else {
        imgDiv.id = copy[index].id;
        copy[index] = imgDiv;
      }

      saveData(copy);
      createDisplay(copy);
      setRunSave(true);
    }
  }

  function onAddDivider() {
    closeMenu();
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      copy[index].text = // take off the '{'
        copy[index].text.slice(0, menu.offset - 1) +
        copy[index].text.slice(menu.offset);

      deletedChar(copy[index], menu.offset - 1);

      addDivider(copy, index);
    }
  }

  function addDivider(copy, index) {
    let divDiv = {
      id: shortId(),
      divider: true,
    };
    if (copy.length - 1 == index) {
      // at the very end, add another empty div
      copy.push({
        id: shortId(),
        text: "",
        styles: [],
      });
    }

    if (copy[index].text) {
      copy.splice(index + 1, 0, divDiv);
    } else {
      divDiv.id = copy[index].id;
      copy[index] = divDiv;
    }

    saveData(copy);
    createDisplay(copy);
    setRunSave(true);
    clearSelections();
  }

  // Image Editing
  const heightRef = useRef(0);
  const widthRef = useRef(0);
  const initX = useRef(0);
  const initY = useRef(0);

  const ratioY = useRef(0);
  const ratioX = useRef(0);
  const XPri = useRef(false);

  const direction = useRef({
    height: false,
    upGrow: false,
    width: false,
    leftGrow: false,
  });
  const dragging = useRef(false);

  const imageRef = useRef();
  const boxRef = useRef();

  const midHeightRef = useRef();
  const midWidthRef = useRef();

  const dataRef = useRef();

  const squares = [
    {
      class: styles.topLeft,
      dir: {
        height: true,
        upGrow: true,
        width: true,
        leftGrow: true,
      },
    },
    {
      class: styles.topMiddle,
      dir: {
        height: true,
        upGrow: true,
      },
      midWidth: true,
    },
    {
      class: styles.topRight,
      dir: { height: true, upGrow: true, width: true },
    },
    {
      class: styles.leftMiddle,
      dir: { width: true, leftGrow: true },
      midHeight: true,
    },
    { class: styles.rightMiddle, dir: { width: true }, midHeight: true },
    {
      class: styles.bottomLeft,
      dir: { height: true, width: true, leftGrow: true },
    },
    {
      class: styles.bottomMiddle,
      dir: {
        height: true,
      },
      midWidth: true,
    },
    { class: styles.bottomRight, dir: { height: true, width: true } },
  ];

  function clickOutsideBox(e) {
    if (!boxRef.current || !boxRef.current.contains(e.target)) {
      document.removeEventListener("click", clickOutsideBox, true);
      if (boxRef.current) {
        cleanUpEditBox();
      }
    }
  }

  function removeImage() {
    let elem = boxRef.current;
    while (
      elem.parentElement !== entry.current &&
      !(elem.id && copy.some((div) => div.id === elem.id))
    ) {
      elem = elem.parentElement;
    }

    let copy = [...dataRef.current];
    let ind = copy.findIndex((d) => d.id === elem.id);
    if (ind > -1) {
      copy.splice(ind, 1);
      saveData(copy);
      createDisplay(copy);
      cleanUpEditBox();
    }
  }

  function cleanUpEditBox() {
    let elem = boxRef.current;
    let children = [...elem.childNodes].slice(1);
    for (let i = 0; i < children.length; i++) {
      elem.removeChild(children[i]);
    }
    elem.className = styles.editBox;

    let copy = [...dataRef.current];
    while (
      elem.parentElement !== entry.current &&
      !(elem.id && copy.some((div) => div.id === elem.id))
    ) {
      elem = elem.parentElement;
    }

    let ind = copy.findIndex((d) => d.id === elem.id);
    if (ind > -1) {
      imageRef.current.onclick = (e) => setUpBox(e, copy[ind], copy); // Reset the click listener with the new data
    } else {
      imageRef.current.onclick = null;
    }

    imageRef.current = null;
    boxRef.current = null;
    dataRef.current = null;

    heightRef.current = 0;
    widthRef.current = 0;

    midHeightRef.current = null;
    midWidthRef.current = null;
  }

  function clearSelections() {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      selection.removeAllRanges();
    }
  }

  function setUpBox(e, div, dataCopy) {
    if (!boxRef.current) {
      e.preventDefault();
      e.stopPropagation();

      // setSelection
      saveSelection({
        divId: div.id,
        offset: 0,
        isCollapsed: true,
      });

      let editBox = e.target.parentElement;
      editBox.className = `${styles.editBox} ${styles.activeEditBox}`;

      midHeightRef.current = [];
      midWidthRef.current = [];

      for (let box of squares) {
        let square = document.createElement("div");
        square.className = `${styles.adjuster} ${box.class}`;
        if (box.midHeight) {
          square.style.top = Math.round(div.height / 2) - 4 + "px";
          midHeightRef.current.push(square);
        }
        if (box.midWidth) {
          square.style.left = Math.round(div.width / 2) - 4 + "px";
          midWidthRef.current.push(square);
        }

        square.onmousedown = (event) => onStartHandle(event, box.dir);
        editBox.appendChild(square);
      }

      imageRef.current = e.target;
      boxRef.current = editBox;

      heightRef.current = div.height;
      widthRef.current = div.width;

      dataRef.current = JSON.parse(JSON.stringify(dataCopy));

      clearSelections();

      document.addEventListener("click", clickOutsideBox, true);
    }
  }

  const onStartHandle = (e, dir) => {
    initY.current = e.clientY;
    initX.current = e.clientX;

    direction.current = dir;
    dragging.current = true;

    ratioY.current = heightRef.current / widthRef.current;
    ratioX.current = widthRef.current / heightRef.current;

    document.addEventListener("mousemove", onMoveHandle);
    document.addEventListener("mouseup", onLetGoHandle);

    clearSelections();
  };

  const onMoveHandle = (e) => {
    if (!dragging.current || !imageRef.current || !boxRef.current) {
      document.removeEventListener("mouseup", onLetGoHandle);
      document.removeEventListener("mousemove", onMoveHandle);
      return;
    }

    const deltaY = direction.current.upGrow
      ? initY.current - e.clientY
      : e.clientY - initY.current;
    const deltaX = direction.current.leftGrow
      ? initX.current - e.clientX
      : e.clientX - initX.current;

    if (deltaY || deltaX) {
      let resultY = heightRef.current + deltaY;
      let resultX = widthRef.current + deltaX;

      if (resultY <= 5 || resultX <= 5) {
        return;
      }

      if (direction.current.height && direction.current.width) {
        if (XPri.current) {
          resultY = ratioY.current * resultX;
        } else {
          resultX = ratioX.current * resultY;
        }

        const rect = imageRef.current.getBoundingClientRect();
        if (XPri.current) {
          if (direction.current.upGrow) {
            if (e.clientY <= rect.top) {
              XPri.current = false;
            }
          } else {
            if (e.clientY >= rect.bottom) {
              XPri.current = false;
            }
          }
        } else {
          // Watch for the switch
          if (direction.current.leftGrow) {
            if (e.clientX <= rect.left) {
              XPri.current = true;
            }
          } else {
            if (e.clientX >= rect.right) {
              XPri.current = true;
            }
          }
        }
      }

      if (direction.current.height) {
        imageRef.current.style.height = resultY + "px";
        boxRef.current.style.height = resultY + "px";
        heightRef.current = resultY;

        for (let square of midHeightRef.current) {
          square.style.top = Math.round(resultY / 2) - 4 + "px";
        }
      }
      if (direction.current.width) {
        imageRef.current.style.width = resultX + "px";
        boxRef.current.style.width = resultX + "px";
        widthRef.current = resultX;

        for (let square of midWidthRef.current) {
          square.style.left = Math.round(resultX / 2) - 4 + "px";
        }
      }
    }

    clearSelections();

    initY.current = e.clientY;
    initX.current = e.clientX;
  };

  const onLetGoHandle = (e) => {
    document.removeEventListener("mouseup", onLetGoHandle);
    document.removeEventListener("mousemove", onMoveHandle);

    e.preventDefault();
    e.stopPropagation();

    let copy = [...dataRef.current];
    let elem = boxRef.current;
    while (
      elem.parentElement !== entry.current &&
      !(elem.id && copy.some((div) => div.id === elem.id))
    ) {
      elem = elem.parentElement;
    }

    let ind = copy.findIndex((d) => d.id === elem.id);
    if (ind > -1) {
      copy[ind].height = heightRef.current;
      copy[ind].width = widthRef.current;
    }

    dragging.current = false;
    direction.current = null;

    initY.current = 0;
    initX.current = 0;

    ratioY.current = 0;
    ratioX.current = 0;

    saveData(copy);
  };

  function onLinkClick(e, link, text) {
    link.text = text;
    setUpMenu_FromLinkClick(link);
    clearSelections();
  }

  // useEffect(() => {
  //   if (showLink) {
  //     setUpMenu_FromLinkClick(link);
  //     setShowLink(null);
  //   }
  //   if (showCondition) {
  //     setShowCondition(null);
  //   }
  // }, [showLink, showCondition]);

  function onConditionClick(name, from) {
    setUpMenu_FromConditionClick(name, from);
    clearSelections();
  }

  function undo() {
    let recordCopy = [...record];
    let last = recordCopy.pop();
    if (last) {
      if (last.data && last.selection) {
        createDisplayAndDoSelection(last.data, last.selection);
        setData(last.data);
        setEdited(true);
        setRunSave(true);
      }
      setRecord(recordCopy);
    }
    if (menu) {
      setMenu(null);
    }
  }

  function getMenuPosition() {
    const selection = window.getSelection();
    const rangeDim = selection.getRangeAt(0).getBoundingClientRect();
    const containerDim = container.current.getBoundingClientRect();

    let style = {};

    const menuHeight = 225;
    const menuWidth = 380;

    let buffer = 0;
    if (rangeDim.left - containerDim.left > 15) {
      // accounts for non collapsed selections
      let widthLeft = window.innerWidth - rangeDim.right;
      while (widthLeft + buffer < menuWidth + 10) {
        buffer += 5;
      }
    }

    style.left = rangeDim.left - containerDim.left - buffer + "px";

    const boxHeight = window.innerHeight - containerDim.bottom;
    const placementHeight = window.innerHeight - rangeDim.bottom;
    const heightLeft =
      boxHeight > 0 ? placementHeight - boxHeight : placementHeight;

    if (
      heightLeft < menuHeight &&
      rangeDim.top - containerDim.top > menuHeight
    ) {
      style.bottom = containerDim.bottom - rangeDim.bottom + 30 + "px";
    } else {
      style.top = rangeDim.top - containerDim.top + 25 + "px";
    }

    return style;
  }

  function setUpMenu_FromKeyClick() {
    let menuObj = {
      style: getMenuPosition(),
      divId: lastSelection.isCollapsed
        ? lastSelection.divId
        : lastSelection.start.divId,
      offset: lastSelection.isCollapsed
        ? lastSelection.offset + 1
        : lastSelection.start.offset + 1,
    };

    recordRef.current = true;
    setMenu(menuObj);
  }

  // const recordRef
  function setUpMenu_FromIncomingClick(input) {
    let bump = input.length;
    if (
      showContent === "Link" ||
      showContent === "Button" ||
      showContent === "Unsub Link"
    ) {
      bump = 0;
    }

    let menuObj = {
      style: getMenuPosition(),
      divId: lastSelection.isCollapsed
        ? lastSelection.divId
        : lastSelection.start.divId,
      offset: lastSelection.isCollapsed
        ? lastSelection.offset + bump
        : lastSelection.start.offset + bump,
      pre: showContent ? showContent : "",
    };

    recordRef.current = true;
    setMenu(menuObj);
  }

  function setUpMenu_FromLinkClick(link) {
    const lastSel = lastSelRef.current;

    let copy = { ...link };
    delete copy.from;
    delete copy.to;
    if (lastSel) {
      let menuObj = {
        style: getMenuPosition(),
        divId: lastSel.isCollapsed ? lastSel.divId : lastSel.start.divId,
        offset: link.from,
        pre: "Link",
        init: copy,
      };

      recordRef.current = true;
      setMenu(menuObj);
    }
  }

  function setUpMenu_FromConditionClick(name, offset) {
    const lastSel = lastSelRef.current;

    if (lastSel) {
      let menuObj = {
        style: getMenuPosition(),
        divId: lastSel.isCollapsed ? lastSel.divId : lastSel.start.divId,
        offset: offset,
        pre: "Conditions",
        init: name,
      };

      recordRef.current = true;
      setMenu(menuObj);
    }
  }

  function closeMenu() {
    recordRef.current = undefined;
    setMenu(null);
  }

  function onDragOver(e) {
    let node = getTextNodeAndOffset(e.clientX, e.clientY);
    if (node) {
      const range = document.createRange();
      range.setStart(node.node, node.offset);
      range.collapse(true);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
    e.preventDefault();
  }

  function getTextNodeAndOffset(x, y) {
    const walker = document.createTreeWalker(
      entry.current,
      NodeFilter.SHOW_TEXT,
      null
    );
    let node = null;

    while ((node = walker.nextNode())) {
      const range = document.createRange();
      range.selectNodeContents(node);
      const rects = range.getClientRects();

      for (let rect of rects) {
        if (
          x >= rect.left &&
          x <= rect.right &&
          y >= rect.top &&
          y <= rect.bottom
        ) {
          // Calculate offset within the text node
          const text = node.textContent;
          for (let i = 0; i < text.length; i++) {
            range.setStart(node, i);
            range.setEnd(node, i + 1);
            const charRect = range.getBoundingClientRect();
            if (
              x >= charRect.left &&
              x <= charRect.right &&
              y >= charRect.top &&
              y <= charRect.bottom
            ) {
              return { node, offset: i };
            }
          }
        }
      }
    }

    for (let div of data) {
      let realDiv = document.getElementById(div.id);
      if (realDiv) {
        let space = realDiv.getBoundingClientRect();
        if (
          x >= space.left &&
          x <= space.right &&
          y >= space.top &&
          y <= space.bottom
        ) {
          let end = getEndOfDiv(div, realDiv);
          if (end) {
            return end;
          }
        }
      }
    }

    let lastDiv = data[data.length - 1];
    let realDiv = document.getElementById(lastDiv.id);
    let end = getEndOfDiv(lastDiv, realDiv);
    return end;
  }

  function getEndOfDiv(div, realDiv) {
    let subCount = 0;
    let node = null;

    if (div.image || div.divider) {
      return null;
    } else if (div.divider) {
      return null;
    } else {
      let lastNode = realDiv.lastChild;
      let text =
        lastNode.nodeName === "#text" ? lastNode : lastNode.childNodes[0];
      if (text) {
        node = text;
        if (div.text && text.nodeValue) {
          subCount = text.nodeValue.length;
        }
      }
    }

    if (node) {
      return {
        node: node,
        offset: subCount,
      };
    }
    return null;
  }

  function onDragLeave() {
    clearSelections();
  }

  function onKeydown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!menu) {
        insertNewLine();
      }
    }

    if (e.ctrlKey || e.metaKey) {
      if (e.key === "z") {
        e.preventDefault();
        undo();
      }
      if (e.key === "b") {
        e.preventDefault();
        onBold();
      }
      if (e.key === "i") {
        e.preventDefault();
        onItalicize();
      }
      if (e.key === "u") {
        e.preventDefault();
        onUnderline();
      }
    }

    if (e.key === "Backspace" && imageRef.current) {
      removeImage();
    }

    // One for checking for the \t tab key
    // if (e.key === "Tab") {
    //   debugger;
    // }

    if (e.key === "{" && !menu) {
      setUpMenu_FromKeyClick();
    }
  }

  // useEffect(() => {
  //   if (!editable) {
  //     let val = decode();
  //     setData(val);
  //     createDisplay(val);
  //   }
  // }, [encoding]);

  useEffect(() => {
    if (reDraw) {
      createDisplay(data);
    }
  }, [reDraw]);

  useEffect(() => {
    if (onFromChange) {
      let val = decode();
      setData(val);
      createDisplay(val);
    }
  }, [onFromChange]);

  return (
    <div className={`${styles.container}`} ref={container} onBlur={onBlur}>
      <div className={`${styles.toolbar}`}>
        <div
          className={`${styles.btn} ${bold ? styles.active : ""}`}
          onClick={onBold}
        >
          <i className="bi bi-type-bold"></i>
        </div>
        <div
          className={`${styles.btn} ${italics ? styles.active : ""}`}
          onClick={onItalicize}
        >
          <i className="bi bi-type-italic"></i>
        </div>
        <div
          className={`${styles.btn} ${underline ? styles.active : ""}`}
          onClick={onUnderline}
        >
          <i className="bi bi-type-underline"></i>
        </div>
        <div
          className={`${styles.btn} ${strike ? styles.active : ""}`}
          onClick={onStrikethrough}
        >
          <i className="bi bi-type-strikethrough"></i>
        </div>
        <div className={styles.divider}>|</div>
        <div className={`${styles.btn}`} onClick={() => onBulletPoint("ul")}>
          <i className="bi bi-list-ul"></i>
        </div>
        <div className={`${styles.btn}`} onClick={() => onBulletPoint("ol")}>
          <i className="bi bi-list-ol"></i>
        </div>
        <div className={`${styles.btn}`} onClick={() => onIndent(1)}>
          <i className="bi bi-text-indent-left"></i>
        </div>
        <div className={`${styles.btn}`} onClick={() => onIndent(-1)}>
          <i className="bi bi-text-indent-right"></i>
        </div>
        <Font value={font} onChange={changeFont} />
        <TextColorPicker
          colorRef={colorRef}
          color={color}
          onChange={changeColor}
        />
        <FontSize value={fontSize} onChange={changeFontSize}></FontSize>
        {alignments.map((a, i) => (
          <div key={i} className={styles.btn} onClick={() => onAlignment(a)}>
            <i className={`bi bi-text-${a}`}></i>
          </div>
        ))}
      </div>
      <div
        className={`${styles.entry} ${incoming ? styles.poised : ""}`}
        id="entry"
        contentEditable={editable}
        onInput={handleChange}
        onSelect={editable ? onSelect : null}
        ref={entry}
        onKeyDown={editable ? onKeydown : null}
        onPaste={editable ? handlePaste : null}
        onCut={editable ? handleCut : null}
        placeholder="Type Name"
        // spellCheck={editable}
        suppressContentEditableWarning={true}
        onDragOver={incoming ? onDragOver : undefined}
        onDragLeave={incoming ? onDragLeave : undefined}
      ></div>
      {menu && (
        <Menu
          onClose={closeMenu}
          menu={menu}
          onFieldPrep={onFieldPrep}
          onPickField={onPickField}
          onLinkEdit={onLinkEdit}
          onSurveyUrl={onSurveyUrl}
          onSigPick={onSigPick}
          onImagePick={onImagePick}
          onAddDivider={onAddDivider}
          conditions={conditions}
          setConditions={setConditions}
          onConditionPrep={onConditionPrep}
          onConditionSwap={onConditionSwap}
          customFields={customFields}
        />
      )}
    </div>
  );
}

function FontSize({ value, onChange }) {
  function changeSize(e) {
    onChange(e.target.value);
  }

  return (
    <select
      className={`${styles.select} ${styles.fontSize}`}
      onChange={changeSize}
      value={value ? value : "unknown"}
    >
      <option value="8pt">8</option>
      <option value="9pt">9</option>
      <option value="10pt">10</option>
      <option value="11pt">11</option>
      <option value="12pt">12</option>
      <option value="13pt">13</option>
      <option value="14pt">14</option>
      <option value="16pt">16</option>
      <option value="18pt">18</option>
      <option value="20pt">20</option>
      <option value="22pt">22</option>
      <option value="24pt">24</option>
      <option value="28pt">28</option>
      <option value="32pt">32</option>
      <option value="unknown" hidden></option>
    </select>
  );
}
