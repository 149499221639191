// External
import React, { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";

// Internal
import { DeliveryRoutes } from "routes";
import styles from "./Delivery.module.scss";
import { routeVariants } from "assets/functions/Variables";
import { TabBar } from "components/layouts";

export const Delivery = ({ survey, direction, onBuildEmail, updateRoute }) => {
  useMemo(() => {
    updateRoute(2);
  }, []);

  const menuItems = [
    {
      id: 0,
      name: "Survey Link",
      to: "survey-link",
      icon: `link-45deg`,
    },
    {
      id: 1,
      name: "Messages",
      to: "messages",
      icon: "send",
    },
    {
      id: 2,
      name: "Personal Links",
      to: `personal-links`,
      icon: "person-lock",
    },
  ];
  const [active, setActive] = useState(startPage());

  function startPage() {
    if (localStorage.getItem("activedelivertab")) {
      return localStorage.getItem("activedelivertab");
    }
    return 1;
  }
  const handleActiveUpdate = (item) => {
    localStorage.setItem("activedelivertab", item);
    setActive(item);
  };

  function getAnimate() {
    if (!survey.activeLink) {
      if (
        localStorage.getItem("activedelivertab") === 1 ||
        localStorage.getItem("activedelivertab") === "1"
      ) {
        return true;
      }
      return active === 1;
    } else {
      return active === 0;
    }
  }

  return (
    <motion.div
      variants={routeVariants}
      custom={{
        direction,
        animate: getAnimate(),
      }}
      initial="enter"
      animate="center"
      exit="exit"
      style={{ zIndex: 0 }}
      id="projectpage"
      className={styles.page}
    >
      <div className={styles.pillContainer}>
        <TabBar
          tabBarItems={menuItems}
          active={active}
          updateActive={handleActiveUpdate}
          wings
        />
      </div>

      <DeliveryRoutes survey={survey} onBuildEmail={onBuildEmail} />
    </motion.div>
  );
};
