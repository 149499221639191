import { createContext, useContext, useState } from "react";

const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
  const [banner, setBanner] = useState(null);

  return (
    <BannerContext.Provider value={{ banner, setBanner }}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBanner = () => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error("useBanner must be used within a BannerProvider");
  }
  return context;
};
