/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { forEach } from "assets/functions/ArrayFunctions";
import {
  AvgScore,
  Flywheel,
  NpsScore,
} from "pages/results/Charts/QChart/QuestionChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

/**
 * A component for the vertical bar chart to be used in the visualizations of the survey results.
 * @param {Object} data the data you want to be displayed in the chart. Date Import Example:
 * const data = {
    labels: ["Completed", "Open", "Unopen", "Bounced"],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: "Amount",
        data: [125, 38, 75, 12],
        // you can set indiviual colors for each bar
        backgroundColor: "#ED9146",
        borderWidth: 1,
      },
    ],
  };
 * @param {string} title the title of the chart that will be displayed
 * @param {JSON} settings a json object of the settings used for the chart
 * Example of design setting attributes json:
 * design_settings: {"hasDataLabels": true,"dataLabelFontSize": 12,"dataLabelPosition": "center","dataLabelAlignment": "center","dataLabelSigFig": 1,"dataLabelPercentages": true,"hasTitle": true,"titleLabel": "What is your favorite color?","titleFontSize": 12,"titleAlignment": "center","hasLegend": true,"legendPosition": "bottom","legendFontSize": 12,"legendPointStyle": true}
 * @returns {Bar} a configured chartjs vertical bar chart component
 */
export const BarChartHorizontal = ({
  data,
  responsiveFonts,
  settings,
  onSegClick,
  getPercentOfTotal,
}) => {
  const nps = settings.answerType === NpsScore && settings.split !== "nps";

  const byPercent =
    settings.byPercent &&
    settings.answerType !== AvgScore &&
    !nps &&
    settings.answerType !== Flywheel &&
    !settings.mAvg;

  function getLabelAnchor(ctx) {
    let val = ctx.dataset.data[ctx.dataIndex];
    if (val > 0) {
      return "start";
    } else {
      return "end";
    }
  }

  function getLabelAlignment(ctx) {
    let val = ctx.dataset.data[ctx.dataIndex];
    if (val > 0) {
      return "start";
    } else {
      return "end";
    }
  }

  const options =
    data.labels.length === 0
      ? {}
      : {
          layout: {
            padding: {
              top: settings.zoomOut ? settings.zoomOut * 5 : 10,
              right: settings.zoomOut ? settings.zoomOut * 5 : 5,
              left: settings.zoomOut ? settings.zoomOut * 5 : 5,
              bottom: settings.zoomOut ? settings.zoomOut * 5 : 5,
            },
          },
          indexAxis: "y",
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          scales: {
            y: {
              // afterFit(scale) {
              //   scale.width = 90;
              // },
              border: {
                display: !settings.hideBorder,
              },
              grid: {
                lineWidth: settings.lineWidth,
                display: !settings.hideTicks,
                drawOnChartArea: settings.drawYLines,
              },
              stacked: data.stacked ? true : false,
              title: {
                display: settings.hasAxisTitles
                  ? settings.XAxisTitle != ""
                  : settings.hasAxisTitles,
                text: settings.XAxisTitle,
                font: {
                  size: responsiveFonts ? responsiveFonts(13) : 10,
                  family: settings.AxisTitleFont
                    ? settings.AxisTitleFont
                    : "Poppins, sans-serif",
                  weight: settings.YAxisTitleFontWeight
                    ? settings.YAxisTitleFontWeight
                    : "",
                },
                color: settings.AxisTitleFontColor
                  ? settings.AxisTitleFontColor
                  : "",
              },
              ticks: {
                display: !settings.hideYticks,
                font: {
                  size: responsiveFonts ? responsiveFonts(12) : 10,
                  family: settings.AxisLabelFont
                    ? settings.AxisLabelFont
                    : "Poppins, sans-serif",
                  weight: settings.YAxisLabelFontWeight
                    ? settings.YAxisLabelFontWeight
                    : "",
                },
                color: settings.AxisLabelFontColor
                  ? settings.AxisLabelFontColor
                  : "",
              },
              afterFit: !settings.hideYticks
                ? (scale) => {
                    scale.width = 42;
                    let big = false;
                    let medium = false;
                    let small = false;
                    // console.log(scale);
                    for (let i = 0; i < scale.ticks.length; i++) {
                      if (scale.ticks[i].label.length > 21) {
                        big = true;
                        let sub = scale.ticks[i].label.substring(0, 20);
                        sub += "...";
                        scale.ticks[i].label = sub;
                      }
                      if (scale.ticks[i].label.length > 3) {
                        small = true;
                      }
                      if (scale.ticks[i].label.length > 10) {
                        medium = true;
                      }
                    }
                    if (big) {
                      scale.width = 160;
                    } else if (medium) {
                      scale.width = 130;
                    } else if (small) {
                      scale.width = 100;
                    }
                  }
                : undefined,
            },
            x: {
              border: {
                display: !settings.hideBorder,
              },
              grid: {
                lineWidth: settings.lineWidth,
                display: !settings.hideTicks,
                drawOnChartArea: settings.drawXLines,
              },
              title: {
                display: settings.hasAxisTitles
                  ? settings.YAxisTitle != ""
                  : settings.hasAxisTitles,
                text: settings.YAxisTitle,
                font: {
                  size: responsiveFonts ? responsiveFonts(13) : 10,
                  family: settings.AxisTitleFont
                    ? settings.AxisTitleFont
                    : "Poppins, sans-serif",
                  weight: settings.XAxisTitleFontWeight
                    ? settings.XAxisTitleFontWeight
                    : "",
                },
                color: settings.AxisTitleFontColor
                  ? settings.AxisTitleFontColor
                  : "",
              },
              ticks: {
                display: !settings.hideXticks,
                color: settings.AxisLabelFontColor
                  ? settings.AxisLabelFontColor
                  : "",
                font: {
                  size: responsiveFonts ? responsiveFonts(12) : 10,
                  family: settings.AxisFont
                    ? settings.AxisFont
                    : "Poppins, sans-serif",
                  family: settings.AxisLabelFont
                    ? settings.AxisLabelFont
                    : "Poppins, sans-serif",
                  weight: settings.XAxisLabelFontWeight
                    ? settings.XAxisLabelFontWeight
                    : "",
                },
                callback: byPercent
                  ? function (value, index, ticks) {
                      return value + "%";
                    }
                  : undefined,
              },
              stacked: data.stacked ? true : false,
              suggestedMax: () => {
                if (byPercent) {
                  return 100;
                }
                if (nps) {
                  return 100;
                }

                var max = 0;
                if (data.stacked) {
                  let length = data.datasets[0].data.length;
                  for (let i = 0; i < length; i++) {
                    let sum = 0;
                    for (let dataset of data.datasets) {
                      sum += dataset.data[i];
                    }
                    if (sum > max) {
                      max = sum;
                    }
                  }
                } else {
                  for (let i = 0; i < data.datasets.length; i++) {
                    var points = data.datasets[i].data.length;
                    for (let p = 0; p < points; p++) {
                      var v = parseFloat(data.datasets[i].data[p]);
                      if (v > max) {
                        max = v;
                      }
                    }
                  }
                }

                return max + max * 0.05;
              },
              suggestedMin: () => {
                if (byPercent) {
                  return 0;
                }
                if (nps) {
                  return -100;
                }
                var min = 0;
                for (let i = 0; i < data.datasets.length; i++) {
                  var points = data.datasets[i].data.length;
                  for (let p = 0; p < points; p++) {
                    var v = parseFloat(data.datasets[i].data[p]);
                    if (v < min) {
                      min = v;
                    }
                  }
                }
                return min;
              },
              min: settings.graphMin ? settings.graphMin : undefined,
              max: settings.graphMax ? settings.graphMax : undefined,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },

            datalabels: {
              font: {
                weight: "bold",
                family: settings.AxisFont
                  ? settings.AxisFont
                  : "Poppins, sans-serif",
              },
              labels: settings.dataLabelValue
                ? {
                    value: {
                      display:
                        settings.hasDataLabels && settings.dataLabelValue.show,
                      anchor: settings.dataLabelValue.position,
                      align: settings.dataLabelValue.alignment,

                      anchor: nps
                        ? getLabelAnchor
                        : settings.dataLabelValue.position,
                      align: nps
                        ? getLabelAlignment
                        : settings.dataLabelValue.alignment,
                      color: nps
                        ? (context) => {
                            if (context.hovering) {
                              let val = "";
                              if (context.dataset?.label) {
                                val = context.dataset.backgroundColor[0];
                              } else {
                                val =
                                  context.dataset.backgroundColor[
                                    context.dataIndex
                                  ];
                              }
                              return val;
                            }
                            return settings.dataLabelValue.color;
                          }
                        : settings.dataLabelValue.color,
                      font: {
                        size: responsiveFonts
                          ? responsiveFonts(settings.dataLabelValue.fontSize)
                          : 10,
                        weight: settings.DataLabelFontWeight
                          ? settings.DataLabelFontWeight
                          : "",
                        family: settings.DataLabelFont
                          ? settings.DataLabelFont
                          : "Poppins, sans-serif",
                      },
                      offset: settings.dataLabelValue.offset,
                      formatter: function (value, ctx) {
                        if (value == 0 && settings.dataLabelValue.hideZeros) {
                          return "";
                        }
                        let label = value;
                        if (byPercent) {
                          label += "%";
                        }
                        return label;
                      },
                    },
                    label: {
                      display:
                        settings.hasDataLabels && settings.dataLabelLabel.show,
                      anchor: settings.dataLabelLabel.position,
                      align: settings.dataLabelLabel.alignment,
                      color: settings.dataLabelLabel.color,
                      font: {
                        size: responsiveFonts
                          ? responsiveFonts(settings.dataLabelLabel.fontSize)
                          : 10,
                        weight: settings.DataLabelFontWeight
                          ? settings.DataLabelFontWeight
                          : "",
                        family: settings.DataLabelFont
                          ? settings.DataLabelFont
                          : "Poppins, sans-serif",
                      },
                      offset: settings.dataLabelLabel.offset,
                      formatter: function (value, ctx) {
                        if (!value || value == 0) {
                          return "";
                        }
                        return ctx.chart.data.labels[ctx.dataIndex];
                      },
                    },
                    percent: {
                      display:
                        settings.hasDataLabels &&
                        settings.dataLabelPercent.show &&
                        !byPercent,
                      anchor: settings.dataLabelPercent.position,
                      align: settings.dataLabelPercent.alignment,
                      color: settings.dataLabelPercent.color,
                      offset: settings.dataLabelPercent.offset,
                      font: {
                        size: responsiveFonts
                          ? responsiveFonts(settings.dataLabelPercent.fontSize)
                          : 10,
                        family: settings.AxisFont
                          ? settings.AxisFont
                          : "Poppins, sans-serif",
                        weight: settings.DataLabelFontWeight
                          ? settings.DataLabelFontWeight
                          : "",
                      },
                      formatter: function (value, ctx) {
                        return getPercentOfTotal(value, ctx);
                      },
                    },
 
                    // dataset: { // TODO: Add On
                    //   display:
                    //     settings.hasDataLabels && settings.dataLabelSet?.show,
                    //   anchor: settings.dataLabelPercent.position,
                    //   align: settings.dataLabelPercent.alignment,
                    //   color: settings.dataLabelPercent.color,
                    //   offset: settings.dataLabelPercent.offset,
                    //   font: {
                    //     size: responsiveFonts
                    //       ? responsiveFonts(settings.dataLabelPercent.fontSize)
                    //       : 10,
                    //     family: settings.AxisFont
                    //       ? settings.AxisFont
                    //       : "Poppins, sans-serif",
                    //     weight: settings.DataLabelFontWeight
                    //       ? settings.DataLabelFontWeight
                    //       : "",
                    //   },
                    //   formatter: function (value, ctx) {
                    //     if (value == 0 || !value) {
                    //       return "";
                    //     }
                    //     return ctx.chart.data.datasets[ctx.datasetIndex].label;
                    //   },
                    // },
                  }
                : undefined,
              listeners: {
                click: function (ctx, e) {
                  if (!ctx.dataset.data[ctx.dataIndex]) {
                    let seg = data.labels[ctx.dataIndex];
                    let dataset = "";
                    if (
                      data?.datasets &&
                      data.datasets[ctx.datasetIndex]?.label
                    ) {
                      dataset = data.datasets[ctx.datasetIndex].label;
                    }
                    onSegClick(seg, dataset, ctx.dataIndex, ctx.datasetIndex);
                  }
                },
                enter: nps
                  ? function (context, event) {
                      if (!context.dataset.data[context.dataIndex]) {
                        context.hovering = true;
                        return true;
                      }
                    }
                  : undefined,
                leave: nps
                  ? function (context, event) {
                      if (!context.dataset.data[context.dataIndex]) {
                        context.hovering = false;
                        return true;
                      }
                    }
                  : undefined,
              },
            },
          },
          onClick: function (e, elements, chart) {
            if (elements.length > 0) {
              let seg = elements[0];
              let label = data.labels[seg.index];
              let dataset = "";
              if (data.datasets[seg.datasetIndex]?.label) {
                dataset = data.datasets[seg.datasetIndex].label;
              }
              onSegClick(label, dataset, seg.index, seg.datasetIndex);
            }
          },
        };

  forEach(data.datasets, (set) => {
    set.barPercentage = settings.barPercentage;
    set.borderRadius = settings.borderRadius;
    set.borderWidth = settings?.segmentBorder ? settings.segmentBorder : 0;

    if (settings?.segmentBorderColor) {
      set.borderColor = settings?.segmentBorderColor;
    }

    set.borderSkipped = false;
  });

  return <Bar options={options} data={data} width={"auto"} />;
};
