import { useState } from "react";
import styles from "./Switchboard.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export function PrioritySwitchBoard({ email }) {
  const [sendBoth, setSendBoth] = useState(false);
  const [onlyEmail, setOnlyEmail] = useState(false);
  const [onlyText, setOnlyText] = useState(false);

  const [sendPriority, setSendPriority] = useState(true);
  const [defaultEmail, setDefaultEmail] = useState(true);
  const [whatHasWorked, setSendWhatHasWorked] = useState(true);

  function onBoth(val) {
    setSendBoth(val);
  }

  function onOnlyEmail(val) {
    setOnlyEmail(val);

    if (val) {
      if (onlyText) setOnlyText(false);
      if (sendPriority) setSendPriority(false);
    } else {
      setSendPriority(true);
    }
  }

  function onOnlyText(val) {
    setOnlyText(val);

    if (val) {
      if (onlyEmail) setOnlyEmail(false);
      if (sendPriority) setSendPriority(false);
    } else {
      setSendPriority(true);
    }
  }

  function onPriority(val) {
    setSendPriority(val);

    if (val) {
      if (onlyText) setOnlyText(false);
      if (onlyEmail) setOnlyEmail(false);
    } else {
      setOnlyEmail(true);
    }
  }

  function onWhatHasWorked(val) {
    setSendWhatHasWorked(val);
  }

  return (
    <div className={styles.switchboard}>
      <div className={styles.title}>Email and or Text</div>

      <FlexCol gap={3} fit>
        <Switch checked={sendBoth} onCheck={onBoth}>
          Send both
        </Switch>
        <Switch checked={!sendBoth} onCheck={(val) => setSendBoth(!val)}>
          Send only one
        </Switch>
        <FlexCol gap={3} style={{ paddingLeft: "20px" }}>
          <Switch checked={onlyEmail} onCheck={onOnlyEmail} disable={sendBoth}>
            Send email
          </Switch>
          <Switch checked={onlyText} onCheck={onOnlyText} disable={sendBoth}>
            Send text
          </Switch>

          <Switch
            checked={sendPriority}
            onCheck={onPriority}
            disable={sendBoth}
            note={"*Person by person"}
          >
            Send Priority
          </Switch>
          <FlexCol style={{ paddingLeft: "10px" }} gap={3}>
            <div className={styles.switchLabel} style={{ fontSize: ".8rem" }}>
              If no priority
            </div>
            <FlexCol gap={3} style={{ paddingLeft: "10px" }}>
              <Switch
                checked={whatHasWorked}
                onCheck={onWhatHasWorked}
                disable={sendBoth || !sendPriority}
              >
                What's worked before
              </Switch>

              {whatHasWorked && (
                <div
                  className={styles.switchLabel}
                  style={{ fontSize: ".8rem", paddingLeft: "10px" }}
                >
                  Else
                </div>
              )}
              <FlexCol
                gap={3}
                className={`${styles.allElse} ${
                  whatHasWorked ? styles.slide : ""
                }`}
              >
                <Switch
                  checked={defaultEmail}
                  onCheck={setDefaultEmail}
                  disable={sendBoth || !sendPriority}
                >
                  Email
                </Switch>

                <Switch
                  checked={!defaultEmail}
                  onCheck={(val) => setDefaultEmail(!val)}
                  disable={sendBoth || !sendPriority}
                >
                  Text
                </Switch>
              </FlexCol>
            </FlexCol>
          </FlexCol>
        </FlexCol>
      </FlexCol>

      <div className={styles.aside}>
        *Note: Those with only an email address or phone number listed will be
        sent to accordingly, despite preferences shown here.
      </div>
    </div>
  );
}

function Switch({ checked, onCheck, children, disable, note, style }) {
  return (
    <FlexCol style={style}>
      <FlexRow fit>
        <ToggleSwitch
          startChecked={checked && !disable}
          handleCheck={onCheck}
          disable={disable}
        />

        <div
          className={`${styles.switchLabel} ${
            checked && !disable ? styles.checked : ""
          }`}
        >
          {children}
        </div>
      </FlexRow>
      {note && (
        <FlexRow fit>
          <div style={{ visibility: "hidden" }}>
            <ToggleSwitch disable />
          </div>

          <div className={styles.note}>{note}</div>
        </FlexRow>
      )}
    </FlexCol>
  );
}
