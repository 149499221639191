/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { forEach } from "assets/functions/ArrayFunctions";
import {
  AvgScore,
  Flywheel,
  NpsScore,
} from "pages/results/Charts/QChart/QuestionChart";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

/**
 * A component for the Line Chart to be used in the visualizations of the survey results.
 * @param {Object} data the data you want to be displayed in the chart. Date Import Example:
 * const data = {
    labels: ["Completed", "Open", "Unopen", "Bounced"],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: "Amount",
        data: [125, 38, 75, 12],
        // you can set indiviual colors for each bar
        backgroundColor: "#ED9146",
        borderWidth: 1,
      },
    ],
  };
 * @param {string} title the title of the chart that will be displayed
 * @param {JSON} settings a json object of the settings used for the chart
 * Example of design setting attributes json:
 * design_settings: {"hasDataLabels": true,"dataLabelFontSize": 12,"dataLabelPosition": "center","dataLabelAlignment": "center","dataLabelSigFig": 1,"dataLabelPercentages": true,"hasTitle": true,"titleLabel": "What is your favorite color?","titleFontSize": 12,"titleAlignment": "center","hasLegend": true,"legendPosition": "bottom","legendFontSize": 12,"legendPointStyle": true}
 * @returns {Bar} a configured chartjs Line Chart component
 */
export const LineChart = ({
  data,
  responsiveFonts,
  settings,
  onSegClick,
  getPercentOfTotal,
}) => {
  const nps = settings.answerType === NpsScore && settings.split !== "nps";

  const byPercent =
    settings.byPercent &&
    settings.answerType !== AvgScore &&
    !nps &&
    settings.answerType !== Flywheel &&
    !settings.mAvg;

  const options = {
    layout: {
      // padding: 15,
      padding: {
        top: settings.zoomOut ? settings.zoomOut * 5 : 0,
        right: settings.zoomOut ? settings.zoomOut * 5 : 5,
        left: settings.zoomOut ? settings.zoomOut * 5 : 5,
        bottom: settings.zoomOut ? settings.zoomOut * 5 : 5,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    lineTension: settings.lineTension ? settings.lineTension : 0.3,
    scales: {
      y: {
        border: {
          display: !settings.hideBorder,
        },
        grid: {
          lineWidth: settings.lineWidth,
          display: !settings.hideTicks,
          drawOnChartArea: settings.drawYLines,
        },
        title: {
          display: settings.hasAxisTitles
            ? settings.YAxisTitle != ""
            : settings.hasAxisTitles,
          text: settings.YAxisTitle,
          color: settings.AxisTitleFontColor ? settings.AxisTitleFontColor : "",
          font: {
            size: responsiveFonts(12),
            family: settings.AxisTitleFont
              ? settings.AxisTitleFont
              : "Poppins, sans-serif",
            weight: settings.YAxisTitleFontWeight
              ? settings.YAxisTitleFontWeight
              : "",
          },
        },
        ticks: {
          display: !settings.hideYticks,
          font: {
            size: responsiveFonts(12),
            family: settings.AxisLabelFont
              ? settings.AxisLabelFont
              : "Poppins, sans-serif",
            weight: settings.YAxisLabelFontWeight
              ? settings.YAxisLabelFontWeight
              : "",
          },
          color: settings.AxisLabelFontColor ? settings.AxisLabelFontColor : "",
          callback: byPercent
            ? function (value, index, ticks) {
                return value + "%";
              }
            : undefined,
        },
        suggestedMax: () => {
          if (byPercent) {
            return 100;
          }
          if (nps) {
            return 100;
          }
          var max = 0;
          for (let i = 0; i < data.datasets.length; i++) {
            var points = data.datasets[i].data.length;
            for (let p = 0; p < points; p++) {
              var v = parseFloat(data.datasets[i].data[p]);
              if (v > max) {
                max = v;
              }
            }
          }
          return max + max * 0.05;
        },
        suggestedMin: () => {
          if (byPercent) {
            return 0;
          }
          if (nps) {
            return -100;
          }

          var min = 0;
          for (let i = 0; i < data.datasets.length; i++) {
            var points = data.datasets[i].data.length;
            for (let p = 0; p < points; p++) {
              var v = parseFloat(data.datasets[i].data[p]);
              if (v < min) {
                min = v;
              }
            }
          }
          return min;
        },
        min: settings.graphMin ? settings.graphMin : undefined,
        max: settings.graphMax ? settings.graphMax : undefined,
      },
      x: {
        border: {
          display: !settings.hideBorder,
        },
        grid: {
          lineWidth: settings.lineWidth,
          display: !settings.hideTicks,
          drawOnChartArea: settings.drawXLines,
        },
        title: {
          display: settings.hasAxisTitles
            ? settings.XAxisTitle != ""
            : settings.hasAxisTitles,
          text: settings.XAxisTitle,
          color: settings.AxisTitleFontColor ? settings.AxisTitleFontColor : "",
          font: {
            size: responsiveFonts(12),
            family: settings.AxisTitleFont
              ? settings.AxisTitleFont
              : "Poppins, sans-serif",
            weight: settings.XAxisTitleFontWeight
              ? settings.XAxisTitleFontWeight
              : "",
          },
        },
        ticks: {
          display: !settings.hideXticks,
          font: {
            size: settings?.XLabelFonts
              ? settings?.XLabelFonts
              : responsiveFonts(12),
            family: settings.AxisLabelFont
              ? settings.AxisLabelFont
              : "Poppins, sans-serif",
            weight: settings.XAxisLabelFontWeight
              ? settings.XAxisLabelFontWeight
              : "",
          },
          color: settings.AxisLabelFontColor ? settings.AxisLabelFontColor : "",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        font: {
          weight: "bold",
        },
        labels: settings.dataLabelValue
          ? {
              value: {
                display: settings.hasDataLabels && settings.dataLabelValue.show,
                anchor: "center",
                align: settings.dataLabelValue.alignment,
                color: settings.dataLabelValue.color,
                font: {
                  size: responsiveFonts(settings.dataLabelValue.fontSize),
                  weight: settings.DataLabelFontWeight
                    ? settings.DataLabelFontWeight
                    : "",
                  family: settings.DataLabelFont
                    ? settings.DataLabelFont
                    : "Poppins, sans-serif",
                },
                offset: settings.dataLabelValue.offset,
                formatter: function (value, ctx) {
                  if (value == 0 && settings.dataLabelValue.hideZeros) {
                    return "";
                  }
                  let label = value;
                  if (byPercent) {
                    label += "%";
                  }
                  return label;
                },
              },
              label: {
                display: settings.hasDataLabels && settings.dataLabelLabel.show,
                anchor: "center",
                align: settings.dataLabelLabel.alignment,
                color: settings.dataLabelLabel.color,
                font: {
                  size: responsiveFonts(settings.dataLabelLabel.fontSize),
                  weight: settings.DataLabelFontWeight
                    ? settings.DataLabelFontWeight
                    : "",
                  family: settings.DataLabelFont
                    ? settings.DataLabelFont
                    : "Poppins, sans-serif",
                },
                offset: settings.dataLabelLabel.offset,
                formatter: function (value, ctx) {
                  if (value == 0 || !value) {
                    return "";
                  }
                  return ctx.chart.data.labels[ctx.dataIndex];
                },
              },
              percent: {
                display:
                  settings.hasDataLabels &&
                  settings.dataLabelPercent.show &&
                  !byPercent,
                anchor: "center",
                align: settings.dataLabelPercent.alignment,
                color: settings.dataLabelPercent.color,
                offset: settings.dataLabelPercent.offset,
                font: {
                  size: responsiveFonts(settings.dataLabelPercent.fontSize),
                  weight: settings.DataLabelFontWeight
                    ? settings.DataLabelFontWeight
                    : "",
                  family: settings.DataLabelFont
                    ? settings.DataLabelFont
                    : "Poppins, sans-serif",
                },
                formatter: function (value, ctx) {
                  return getPercentOfTotal(value, ctx);
                },
              },
            }
          : undefined,
      },
    },
    onClick: function (e, elements, chart) {
      if (elements.length > 0) {
        let seg = elements[0];
        let segment = data.labels[seg.index];
        let dataset = "";
        if (data.datasets[seg.datasetIndex]?.label) {
          dataset = data.datasets[seg.datasetIndex].label;
        }
        onSegClick(segment, dataset, seg.index, seg.datasetIndex);
      }
    },
  };

  return <Line options={options} data={data} width={"auto"} />;
};
