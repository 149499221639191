import React, { useEffect, useRef, useState } from "react";
import styles from "./Register.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import {
  useValidateEmail,
  useValidateJoinCode,
} from "api/resources/authentication";
import { useCreateUserAccountRequest } from "api/resources/authentication/register";
import { ToSComponent } from "pages/Policies/TermsAndConditions";
import { PrivacyPolicy } from "pages/Policies/PrivacyPolicy";
import ReCAPTCHA from "react-google-recaptcha";

function YourDetails({ setActiveStep }) {
  const navigate = useNavigate();

  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );
  const [validationRules, setValidationRules] = useState([]);
  const [validCode, setValidCode] = useState();
  const [joinCode, setJoinCode] = useState("");
  const [user, setUser] = useState({});
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validEmail, setValidEmail] = useState([]);
  const [validPassword, setValidPassword] = useState();
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [working, setWorking] = useState(false);
  const [showToS, setShowToS] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [error, setError] = useState("");

  const validateCode = useValidateJoinCode();
  const registerUserRequest = useCreateUserAccountRequest();
  const validateEmail = useValidateEmail();

  useEffect(() => {
    setActiveStep(1);
  }, []);

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = false;
    const rules = [
      {
        rule: "Password must be at least 8 characters and less than 64 characters.",
        valid: p.length >= 8 && p.length <= 64,
      },
      {
        rule: "Password must contain at least 1 uppercase letter.",
        valid: /[A-Z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 lowercase letter.",
        valid: /[a-z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 number.",
        valid: /[0-9]/.test(p),
      },
      {
        rule: "Password must contain at least 1 special character.",
        valid: /[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p),
      },
    ];

    valid = rules.every((rule) => rule.valid);
    setValidationRules(rules);

    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  function validateUserEmail(email) {
    let rules = [
      {
        rule: "Email must not be empty.",
        valid: email.trim().length > 0,
      },
      {
        rule: "Email must contain '@' symbol.",
        valid: email.includes("@"),
      },
      {
        rule: "Email must have a domain name after '@'.",
        valid: email.split("@")[1]?.length > 0,
      },
      {
        rule: "Email must contain a top-level domain (e.g., .com, .org).",
        valid: /\.[a-zA-Z]{2,}$/.test(email),
      },
      {
        rule: "Email must not contain invalid characters.",
        valid: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email),
      },
    ];
    let valid = rules.every((rule) => rule.valid);
    if (!valid) {
      setValidEmail(rules);
      return;
    }
    validateEmail.mutate(
      {
        email: email,
      },
      {
        onSuccess: (data) => {
          if (!data.validateEmail) {
            setValidEmail([
              {
                ...{ rule: "Email is assigned to an account", valid: false },
                ...rules,
              },
            ]);
          } else {
            setValidEmail([]);
          }
        },
      }
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      alert("Please complete the reCAPTCHA!");
      return;
    }
    setWorking(true);
    if (user?.firstName === "") {
      setValidFirstName(false);
    }
    if (user?.lastName === "") {
      setValidLastName(false);
    }
    if (user?.password === "") {
      setValidPassword(false);
    }
    if (user?.email === "" || !user?.email.includes("@")) {
      setValidEmail(false);
    }
    if (
      // validCode &&
      validFirstName &&
      validLastName &&
      validEmail &&
      validPassword &&
      validConfirmPassword &&
      captchaToken
    ) {
      registerUserRequest.mutate(
        {
          data: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            password: user?.password,
            joinCode: joinCode,
            loggedIn: false,
          },
          captchaToken: captchaToken,
        },
        {
          onSettled: (data) => {
            setWorking(false);
            if (data?.response?.success) {
              navigate("/register/verify-email/" + data?.response?.token);
            } else {
              setError(data?.response?.error);
            }
          },
        }
      );
    }
  };

  function handleChangePassword(password) {
    setUser({ ...user, password: password });
    validateUserPassword(password);
  }

  function handleChangeEmail(email) {
    setUser({ ...user, email: email.toLowerCase() });
    validateUserEmail(email);
  }

  function handleChangeValidatePassword(p) {
    if (p === user?.password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
    setConfirmPassword(p);
  }

  const [passwordType, setPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  const [confirmPasswordType, setConfirmPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  function validateJoinCode(code) {
    validateCode.mutate(
      {
        id: code,
      },
      {
        onSuccess: (data) => {
          setValidCode(data.validCodeForUser);
        },
      }
    );
  }

  return (
    <div className={styles.content}>
      <FlexCol>
        <FlexRow>
          <img
            width={"50px"}
            src={require("assets/images/circlelogo.png")}
          ></img>
          <div>
            <div
              className={styles.header_4}
              style={{
                margin: "0",
                fontWeight: "400",
              }}
            >
              Your Account
            </div>
            <div className={styles.text_2} style={{}}>
              Provide your personal information below.
            </div>
          </div>
        </FlexRow>
        <HorizontalBar height={2}></HorizontalBar>
        <FlexCol style={{ maxWidth: "600px" }} gap={"1rem"}>
          <TextFieldSimple
            type="text"
            onChange={setJoinCode}
            value={joinCode}
            valid={validCode}
            onBlur={() => validateJoinCode(joinCode)}
            label="Valid Join Code*"
          />
          <FlexRow wrap={"wrap"}>
            <TextFieldSimple
              containerStyle={{ flex: 1, minWidth: "250px" }}
              type="text"
              onChange={(name) => {
                setUser({ ...user, firstName: name });
              }}
              label="First Name*"
              valid={validFirstName}
              value={user?.firstName}
              invalidMessage="Require a First Name"
            />
            <TextFieldSimple
              containerStyle={{ flex: 1, minWidth: "250px" }}
              type="text"
              onChange={(name) => {
                setUser({ ...user, lastName: name });
              }}
              label="Last Name*"
              value={user?.lastName}
              valid={validLastName}
              invalidMessage="Require a Last Name"
            />
          </FlexRow>
          <TextFieldSimple
            id="emailField"
            type="text"
            onChange={handleChangeEmail}
            value={user?.email}
            valid={validEmail?.length === 0 || !validEmail}
            // invalidMessage={"Invalid Email"}
            label="Email*"
            validationRules={validEmail}
          />
          <TextFieldSimple
            type={passwordType?.type}
            onChange={handleChangePassword}
            value={user?.password}
            valid={user?.password && validPassword}
            finalIcon={passwordType?.icon}
            onClickFinalIcon={() => {
              if (passwordType?.type === "password") {
                setPasswordType({
                  icon: "eye",
                  type: "text",
                });
              } else {
                setPasswordType({
                  icon: "eye-slash",
                  type: "password",
                });
              }
            }}
            invalidMessage={invalidPasswordMessage}
            password
            label="Password*"
            validationRules={validationRules}
            validationRulesTitle="Password Requirements"
          />
          <TextFieldSimple
            type={confirmPasswordType?.type}
            onChange={handleChangeValidatePassword}
            value={confirmPassword}
            finalIcon={confirmPasswordType?.icon}
            onClickFinalIcon={() => {
              if (confirmPasswordType?.type === "password") {
                setConfirmPasswordType({
                  icon: "eye",
                  type: "text",
                });
              } else {
                setConfirmPasswordType({
                  icon: "eye-slash",
                  type: "password",
                });
              }
            }}
            // placeholder="Confirm Password"
            valid={validConfirmPassword}
            invalidMessage={"Passwords must match"}
            password
            label="Confirm Password*"
          />
          <FlexRow style={{ marginLeft: "1rem" }}>
            <Checkbox
              checked={acceptPrivacy}
              onChange={(e) => setAcceptPrivacy(e.target.checked)}
            ></Checkbox>{" "}
            <div className={styles.text_2}>
              I agree to ReactionData's{" "}
              <a
                className={styles.link}
                onClick={() => setShowPrivacyPolicy(true)}
              >
                Privacy
              </a>{" "}
              Statement
            </div>
          </FlexRow>
          <FlexRow style={{ marginLeft: "1rem" }}>
            <Checkbox
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
            ></Checkbox>{" "}
            <div className={styles.text_2}>
              I agree to ReactionData's{" "}
              <a className={styles.link} onClick={() => setShowToS(true)}>
                Terms & Conditions
              </a>
            </div>
          </FlexRow>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(token) => setCaptchaToken(token)}
          />

          {error && <div className={styles.red}>{error}</div>}
          <FlexRow
            gap={"2rem"}
            style={{ marginTop: "1rem" }}
            justify={"center"}
          >
            <Button
              shadow
              width={150}
              onClick={() => {
                navigate("/register/account-type");
              }}
            >
              Previous
            </Button>
            <Button
              blue
              shadow
              width={150}
              disable={
                !user?.firstName ||
                !user?.lastName ||
                !validConfirmPassword ||
                !validPassword ||
                !user?.password ||
                !validEmail ||
                !acceptPrivacy ||
                !acceptTerms ||
                !validCode
              }
              onClick={(e) => {
                if (!working) {
                  handleSubmit(e);
                }
              }}
            >
              {!working ? (
                "Submit"
              ) : (
                <>
                  Working
                  <span className={styles.dots}>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </>
              )}
            </Button>
          </FlexRow>
        </FlexCol>{" "}
        {window.innerWidth <= 800 && (
          <>
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: "4rem",
              }}
            >
              Already have an account?
              <div
                style={{
                  display: "flex",
                  gap: ".5rem",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={() => navigate("/login")}
                  type="submit"
                  shadow
                  blue
                >
                  Sign In
                </Button>
                or{" "}
                <Button
                  onClick={() => {
                    navigate("/register/join-organization"), location.reload();
                  }}
                  type="submit"
                  shadow
                  blue
                >
                  Join Organization
                </Button>
              </div>
            </div>
            <div className={styles.register}>
              Request a demo or learn more at{" "}
              <a href="https://reactiondata.com" className={styles.aLink}>
                {" "}
                reactiondata.com
              </a>
              .
            </div>
          </>
        )}
      </FlexCol>
      {showToS && <ToSComponent show={showToS} setShow={setShowToS} />}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          show={showPrivacyPolicy}
          setShow={setShowPrivacyPolicy}
        />
      )}
    </div>
  );
}

export default YourDetails;