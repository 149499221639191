import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  useAudienceQuery,
  useRemoveContactsFromAudience,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import styles from "./Audience.module.scss";
import AudienceOverview from "./AudienceOverview";
import { CSVLink } from "react-csv";
import { Loading } from "components/Loading/Loading";
import AudienceSettings from "./AudienceSettings";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { useSearchContacts } from "api/resources/contacts/contacts";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import ContactProfile from "components/Popout/ContactProfile";
import UnifinishedUpload from "../all_contacts/NewContacts/upload/UnifinishedUpload";
import { trimDate } from "assets/functions/DateFunctions";
import ObjectTable from "components/tables/BasicTable/ObjectTable";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { parseCustomFields } from "assets/functions/ObjectFunctions";
import FieldAdjuster from "../all_contacts/FieldAdjuster";
import ColumnsToDisplay from "../all_contacts/ColumnsToDisplay";
import ImportHow from "../all_contacts/ImportHow";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import { AddContact } from "../all_contacts/NewContacts/AddContact/AddContact";
import FilterSimple from "../all_contacts/FilterSimple";
import { Export } from "../all_contacts/export/Export";
import { SearchToAdd } from "./SearchContactsToAdd/SearchContactsToAdd";
import { useFetchUploadByAudience } from "api/resources/contacts/uploads";

function Audience({
  user,
  organization,
  audienceId,
  preview,
  roles,
  onExport,
  updateRoute,
}) {
  const { id } = useParams();
  const audienceQuery = useAudienceQuery(audienceId ? audienceId : id);
  const orgFields = useFetchCurrOrgsFields();
  useMemo(() => {
    if (updateRoute) {
      updateRoute(1);
    }
  }, []);

  return (
    <>
      {(audienceQuery.isLoading || orgFields.isLoading) && <Loading></Loading>}
      {audienceQuery.isSuccess && orgFields.isSuccess && (
        <AudienceContainer
          audience={audienceQuery.data?.audience}
          user={user}
          customFields={orgFields?.data?.getCurrOrgFields}
          organization={organization}
          preview={preview}
          roles={roles}
          onExport={onExport}
        ></AudienceContainer>
      )}
    </>
  );
}

export default Audience;

function AudienceContainer({
  audience,
  user,
  customFields,
  organization,
  preview,
  roles,
  onExport,
}) {
  const [settings, setSettings] = useState(false);
  const [upload, setUpload] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    firstName: false,
    lastName: false,
    email: true,
    descend: false,
    item: "email",
  });
  const [searchString, setSearchString] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [active, setActive] = useState("1");
  const [headersExport, setHeadersExport] = useState(false);
  const csvref = useRef(null);
  const [viewPrev, setViewPrev] = useState(false);
  const [chosenFilters, setChosenFilters] = useState([]);
  const [audienceState, setAudienceState] = useState({
    ...audience,
    filters: audience?.filters ? JSON.parse(audience?.filters) : [],
  });
  const [selected, setSelected] = useState([]);
  const [adjustFields, setAdjustFields] = useState(false);
  const [displayColumns, setDisplayColumns] = useState(false);
  const [importer, setImporter] = useState(false);
  const [newColumn, setNewColumn] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [first, setFirst] = useState(true);
  const [changed, setChanged] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [searchToAdd, setSearchToAdd] = useState(false);
  const obscure = !roles?.canSeeContactInfo;

  // When item changes, trigger visibility change
  useEffect(() => {
    if (showFilters) {
      setIsVisible(true);
      setFirst(false);
    } else {
      setIsVisible(false);
    }
  }, [showFilters]);

  // When the animation ends, stop rendering the component
  const handleAnimationEnd = () => {
    if (!isVisible) {
      setShowFilters(false);
    }
  };

  const seeContact = (contact) => {
    setShowContactDetails(true);
    setCurrentContact(contact);
  };

  function initHeaders() {
    let heads = [];
    let order = undefined;
    if (audience?.columnOrder) {
      order = JSON.parse(audience?.columnOrder);
    }

    for (let field of customFields) {
      let index = 1000;
      let enabled = field?.enabled;
      // debugger;
      if (order) {
        index = order?.findIndex((c) => c?.id === field?.id);
        enabled = order[index]?.enabled;
      }

      heads.push({
        id: field?.id,
        value: field?.generic ? field?.name : field?.id,
        label: field?.displayName,
        dataType: field?.dataType,
        enabled: enabled,
        editable: field?.editable,
        custom: true,
        sort: false,
        canSort: false,
        obscure: obscure,
        salesforceColumn: field?.salesforceColumn,
        icon: field?.icon ? field?.icon : "person",
        multiple: field?.multiple,
        delimiter: field?.delimiter,
        generic: field?.generic,
        index: index,
        cell_style:
          field?.name === "contactName"
            ? (name, id, i, contact) =>
                contact && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                    }}
                  >
                    <div
                      className={`${styles.link} ${styles.blueDark}`}
                      style={{ fontWeight: "500" }}
                      onClick={() => {
                        seeContact(contact);
                      }}
                    >
                      {contact && `${contact?.firstName} ${contact?.lastName}`}
                    </div>
                  </div>
                )
            : undefined,
      });
    }

    if (order) {
      const sortedArray = heads.sort((a, b) => order.index - order.index);

      return sortedArray;
    } else {
      return heads;
    }
  }

  const [headers, setHeaders] = useState(initHeaders());

  const updateAudience = useUpdateAudienceGql();

  function saveDynamicChanges() {
    updateAudience.mutate(
      {
        data: {
          dynamic:
            audienceState?.filters.length === 0
              ? false
              : audienceState?.dynamic,
          filters: audienceState?.filters
            ? JSON.stringify(audienceState?.filters)
            : undefined,
        },
        id: audience?.id,
      },
      {
        onSuccess: (data) => {
          setChanged(false);
          setAudienceState({
            ...audienceState,
            updatedAt: data?.audience?.updatedAt,
          });
        },
      }
    );
  }

  const newOptions = [
    {
      onClick: () => setAddContact(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-fill"}></Icon> Create a new contact
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a new contact and add it to this audience.
          </div>
        </div>
      ),
    },

    {
      onClick: () => setImporter(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"people-fill"}></Icon> Import Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Import in bulk to the audience with a file or an external account.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setShowFilters(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"funnel"}></Icon> Add from filter
          </FlexRow>{" "}
          <div className={styles.description2}>
            Add contacts to this audinece from a dynamic filter.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setSearchToAdd(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"search"}></Icon> Search Contacts
          </FlexRow>{" "}
          <div className={styles.description2}>
            Search contacts to add to this audience.
          </div>
        </div>
      ),
    },
  ];
  const dotOptions = [
    {
      onClick: () => console.log("Import"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-plus"}></Icon> Import
        </>
      ),
    },
    {
      onClick: () => console.log("Export View"),
      data: (
        <>
          <Icon sapphire iconName={"cloud-download"}></Icon> Export View
        </>
      ),
    },
  ];

  const columnOptions = [
    {
      onClick: () => setAdjustFields(!adjustFields),
      data: (
        <>
          <Icon sapphire iconName={"pencil"}></Icon> Edit columns
        </>
      ),
    },
    {
      onClick: () => setDisplayColumns(true),
      data: (
        <>
          <Icon sapphire iconName={"columns"}></Icon> Select columns to display
        </>
      ),
    },
  ];

  const contactsQuery = useSearchContacts(
    searchString,
    perPage,
    sort,
    audience?.id,
    audienceState?.filters ? JSON.stringify(audienceState?.filters) : "",
    JSON.stringify(chosenFilters)
  );
  const data = parseCustomFields(
    contactsQuery?.data?.pages.flatMap((page) => page?.response?.contacts) ||
      [],
    headers
  );

  const count =
    contactsQuery?.data?.pages?.length > 0
      ? contactsQuery?.data?.pages[0]?.response?.count
      : 0;

  function handleFilterChange(val) {
    setChosenFilters(val);
  }

  function handleSetHeaders(vals) {
    setHeaders(vals);
  }

  function handleSearch(val) {
    contactsQuery?.remove();
    contactsQuery?.refetch();
  }

  function handlePageNum(val) {
    console.log("Contacts");
    if (contactsQuery?.hasNextPage) {
      contactsQuery?.fetchNextPage();
      setPageNumber(val);
    }
  }

  const selectedOptions = [
    { onClick: () => handleSelectPage(), data: "Select Page" },
    { onClick: () => setSelected([]), data: "Remove Selected" },
  ];

  function handleAudienceFilterChange(val) {
    setChanged(true);
    setAudienceState({
      ...audienceState,
      filters: val,
    });
  }

  useEffect(() => {
    contactsQuery.remove();
    contactsQuery.refetch();
  }, [audienceState, chosenFilters]);

  function pullContacts() {}

  useEffect(() => {
    if (changed) {
      saveDynamicChanges();
    }
  }, [audienceState]);

  const navigate = useNavigate();

  const removeContacts = useRemoveContactsFromAudience();
  const [removing, setRemoving] = useState(false);

  function handleRemoveSelectedFromAudience() {
    if (selected.length > 0) {
      setRemoving(true);
      const contactIds = selected.map((c) => {
        return c?.id;
      });

      removeContacts.mutate(
        {
          id: audience?.id,
          contactArray: {
            contactIds: contactIds,
          },
        },
        {
          onSuccess: () => {
            handleSearch("");
            setSelected([]);
            setRemoving(false);
          },
        }
      );
    }
  }

  function onSaveColumnOrder(columns) {
    const final = JSON.stringify(columns);

    updateAudience.mutate(
      {
        data: {
          columnOrder: final,
        },
        id: audience?.id,
      },
      {
        onSuccess: (data) => {
          setChanged(false);
          setAudienceState({
            ...audienceState,
            updatedAt: data?.audience?.updatedAt,
          });
        },
      }
    );
  }

  return (
    <>
      {showContactDetails && (
        <>
          <div
            className={styles.backArrow}
            onClick={() => setShowContactDetails(false)}
          >
            <i className="bi bi-arrow-left"></i>
          </div>
          <ContactProfile
            onClose={() => setShowContactDetails(false)}
            contact={currentContact}
          ></ContactProfile>
        </>
      )}
      {!upload && (
        <>
          {settings && (
            <Modal
              show={settings}
              onClose={() => setSettings(false)}
              modalStyle={{
                height: "100%",
                width: "fit-content",
              }}
              dark
              rightStyle
            >
              <AudienceSettings
                audience={audience}
                setSettings={setSettings}
              ></AudienceSettings>
            </Modal>
          )}
          {audience && (
            <div className={styles.table}>
              <div
                style={{
                  display: first ? "none" : "",
                  borderRight: isVisible ? "" : "none",
                }}
                className={`${styles.showFilters} ${
                  isVisible ? styles.enter : styles.exit
                }`}
                onAnimationEnd={handleAnimationEnd}
              >
                {showFilters && (
                  <FilterSimple
                    filters={
                      audienceState?.filters ? audienceState?.filters : []
                    }
                    setFilters={handleAudienceFilterChange}
                    allColumns={headers}
                    title="Addition Filters"
                    setShow={setShowFilters}
                    left
                  ></FilterSimple>
                )}
              </div>
              <div className={styles.modalContainer}>
                <div className={styles.nav}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1em",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    <FlexRow start>
                      {" "}
                      {!preview && (
                        <Button
                          shadow
                          seafoam
                          style={{ padding: "10px" }}
                          onClick={() => setSettings(true)}
                        >
                          <i className="bi-gear-fill"></i>
                        </Button>
                      )}
                      <div className={styles.name}> {audience?.name}</div>
                    </FlexRow>
                    {audienceState?.filters?.length > 0 && (
                      <FlexRow start style={{ width: "fit-content" }}>
                        <span className={styles.label5}>Contacts Added:</span>{" "}
                        {audienceState?.filters?.length > 0 &&
                          audienceState?.filters?.map((filter, i) => (
                            <div
                              className={styles.filter}
                              onClick={() => setShowFilters(true)}
                              key={i}
                            >
                              {filter?.label}:
                              {filter?.properties?.map((prop, i) => (
                                <div className={styles.prop} key={i}>
                                  {prop?.label}
                                  {i != filter?.properties?.length - 1
                                    ? ","
                                    : ""}
                                </div>
                              ))}
                            </div>
                          ))}{" "}
                        <Icon
                          iconName={"plus-circle"}
                          onClick={() => setShowFilters(true)}
                        ></Icon>
                      </FlexRow>
                    )}
                  </div>
                  {selected?.length > 0 && (
                    <Button
                      red
                      shadow
                      onClick={handleRemoveSelectedFromAudience}
                    >
                      {!removing ? (
                        <>Remove ({selected?.length}) selected from audience</>
                      ) : (
                        <FlexRow start>
                          Removing... <Loading height={30} width={30}></Loading>
                        </FlexRow>
                      )}
                    </Button>
                  )}
                  <div
                    style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}
                  >
                    <CSVLink
                      data={data ? data : []}
                      headers={headersExport ? headersExport : []}
                      //   headers={[]}
                      filename={audience.name + "-members.csv"}
                      style={{
                        display: "none",
                      }}
                      target="_blank"
                    >
                      <button
                        ref={csvref}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      ></button>
                    </CSVLink>
                    <FlexRow start style={{ width: "fit-content" }}>
                      {!changed && (
                        <span className={styles.label5}>
                          Saved {trimDate(audienceState?.updatedAt, true)}
                        </span>
                      )}
                      {changed && (
                        <FlexRow start style={{ width: "fit-content" }}>
                          <Loading height={30} width={30}></Loading> Saving...
                        </FlexRow>
                      )}
                      {!preview && (
                        <div className={styles.viewPrev}>
                          <Button
                            onClick={() => setViewPrev(true)}
                            shadow
                            yellow
                          >
                            Uploads
                          </Button>
                          <Uploads
                            audience={audience}
                            upload={upload}
                            setUpload={setUpload}
                            viewPrev={viewPrev}
                            setViewPrev={setViewPrev}
                          ></Uploads>
                        </div>
                      )}
                    </FlexRow>
                  </div>
                </div>
                {active === "0" && (
                  <AudienceOverview
                    audience={audience}
                    settings={settings}
                    setSettings={setSettings}
                  />
                )}

                {/*Members Page */}
                {active == "1" && !upload && (
                  <ObjectTable
                    data={data}
                    headers={headers}
                    setHeaders={setHeaders}
                    maxPage={
                      count / perPage < 1 ? 1 : Math.ceil(count / perPage)
                    }
                    maxItems={count}
                    pageNum={pageNumber}
                    setPageNum={handlePageNum}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    sort={sort}
                    setSort={setSort}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    customFields={customFields}
                    user={user}
                    roles={roles}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    onSearch={handleSearch}
                    searchPlaceholder={"Search Contacts..."}
                    emptyMessage={"No Contacts"}
                    addOptions={!preview ? newOptions : undefined}
                    addOptionsHeight={100}
                    filters={chosenFilters}
                    setFilters={handleFilterChange}
                    loading={contactsQuery?.isLoading}
                    fullLoading={
                      !contactsQuery?.isLoading &&
                      contactsQuery?.isFetchingNextPage
                    }
                    select
                    selected={selected}
                    setSelected={setSelected}
                    selectedOptions={selectedOptions}
                    selectedOptionsHeight={50}
                    allColumns={customFields}
                    columnOptions={columnOptions}
                    columnOptionsHeight={50}
                    onClickEditAction={(contact) => {
                      setCurrentContact(contact);
                      setShowContactDetails(true);
                    }}
                    actionCell
                    exportClick={
                      roles?.canSeeContactInfo
                        ? () => setShowExport(true)
                        : undefined
                    }
                    bottomButton={
                      <div
                        className={styles.link}
                        onClick={() => {
                          navigate("/previous-imports");
                        }}
                      >
                        View Import Jobs
                      </div>
                    }
                    onCreateAudienceClick={() => setCreateAudience(true)}
                    // onSaveEditableCell={saveContact}
                  ></ObjectTable>
                )}
              </div>
            </div>
          )}

          {adjustFields && (
            <FieldAdjuster
              show={adjustFields}
              setShow={setAdjustFields}
              salesforce={organization?.linkedAccounts?.find(
                (a) => a?.accountType === "Salesforce"
              )}
            ></FieldAdjuster>
          )}

          {displayColumns && (
            <ColumnsToDisplay
              show={displayColumns}
              setShow={setDisplayColumns}
              columns={headers}
              setColumns={handleSetHeaders}
              onSaveColumnOrder={onSaveColumnOrder}
            />
          )}

          {importer && (
            <Modal
              show={importer}
              onClose={() => setImporter(false)}
              modalStyle={{
                borderRadius: "1em",
                height: "500px",
                width: "900px",
              }}
              dark
            >
              <ImportHow
                cancel={() => setImporter(false)}
                user={user}
                organization={organization}
                objectColumns={customFields?.map((v) => {
                  return { ...v, value: v?.name, label: v?.displayName };
                })}
                audienceId={audience?.id}
              ></ImportHow>
            </Modal>
          )}

          {newColumn && (
            <EditSingleCustomField
              onClose={() => setNewColumn(false)}
              field={{}}
              // onSaved={handleSaved}
              brandNew={true}
              // noModal
              // deleteField={handleDeleteField}
            />
          )}
          {addContact && (
            <AddContact
              onClose={() => setAddContact(false)}
              org={organization}
              audienceId={audience?.id}
              refetch={() => pullContacts()}
            />
          )}

          {showExport && (
            <Export
              onClose={() => setShowExport(false)}
              org={organization}
              filters={chosenFilters}
              search={searchString}
              sort={sort}
              headers={headers}
              onExport={onExport}
              audienceId={audienceState?.id}
              audienceFilters={audienceState?.filters}
              audienceName={audienceState.name}
            />
          )}

          {searchToAdd && (
            <SearchToAdd
              onClose={() => setSearchToAdd(false)}
              audienceId={audienceState.id}
              audienceName={audienceState.name}
              onAdded={() => pullContacts()}
            />
          )}
        </>
      )}
      {upload && (
        <UnifinishedUpload
          upload={upload}
          setUpload={setUpload}
          audience={audience}
        />
      )}
    </>
  );
}

function Uploads({ audience, upload, setUpload, viewPrev, setViewPrev }) {
  const getUploads = useFetchUploadByAudience(audience?.id);

  return (
    <>
      {viewPrev && (
        <div className={styles.recentUploadsBox}>
          {getUploads.isSuccess &&
            getUploads?.data?.upload?.length > 0 &&
            !upload && (
              <div className={styles.recentUploads}>
                <span>
                  Previous uploads{" "}
                  <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
                </span>
                {getUploads?.data?.upload?.map((upload, i) => (
                  <div
                    key={i}
                    className={styles.upload}
                    onClick={() => {
                      setUpload(upload);
                      setViewPrev(false);
                    }}
                  >
                    {trimDate(upload?.uploadDate, true)}
                    <div className={styles.view}>View</div>
                  </div>
                ))}
              </div>
            )}
          {getUploads.isSuccess && getUploads?.data?.upload?.length === 0 && (
            <div
              className={styles.recentUploads}
              style={{ paddingBottom: "1em" }}
            >
              {" "}
              <span>
                No Upload Attempts
                <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
