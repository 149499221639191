/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction Data. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import styles from "./MultipleChoiceOption.module.scss";

export default function MultipleChoiceOption({
  option,
  selected,
  onClick,
  color,
  barDesign,
  isMultiSelect,
  index,
  isRanking,
  settings,
}) {
  const [hovering, setHovering] = useState(false);
  let colorStyle = {};

  if (selected) {
    colorStyle.backgroundColor = color;
    if (barDesign && settings.answerColorActive) {
      colorStyle.color = settings.answerColorActive;
    } else {
      colorStyle.color = "white";
    }
  } else {
    if (barDesign) {
      colorStyle.backgroundColor = "white";
    } else {
      colorStyle.backgroundColor = "#efefef";
      colorStyle.color = "#efefef";
    }
    if (hovering) {
      if (barDesign) {
        colorStyle.backgroundColor = color;
        if (settings.answerColorActive) {
          colorStyle.color = settings.answerColorActive;
        } else {
          colorStyle.color = "white";
        }
      } else {
        colorStyle.backgroundColor = "#d8d9d9";
        colorStyle.color = "#d8d9d9";
      }
    }
  }

  function handleClick() {
    // setChecked(!checked);
    onClick(option);
  }

  return (
    <>
      {barDesign && (
        <label
          className={`${styles.barStyle} ${selected ? styles.chosen : ""}`}
          style={{ ...colorStyle, borderColor: color }}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <input
            id={option}
            type="checkbox"
            checked={selected}
            onChange={handleClick}
          />
          {option}
        </label>
      )}
      {!barDesign && (
        <div className={styles.buttonContainer}>
          <>
            {!isMultiSelect && !isRanking && (
              <>
                <label className={styles.container}>
                  <input
                    id={option}
                    type="checkbox"
                    checked={selected}
                    onChange={handleClick}
                  />
                  <span
                    className={styles.checkmark}
                    style={colorStyle}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                  ></span>
                </label>
                <span onClick={handleClick} className={styles.content}>
                  {option}
                </span>
              </>
            )}
            {isMultiSelect && !isRanking && (
              <>
                <label className={styles.container}>
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={handleClick}
                  />
                  <span
                    className={`${styles.checkmark} ${styles.multi} `}
                    style={colorStyle}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                  ></span>
                </label>
                <span onClick={handleClick} className={styles.content}>
                  {option}
                </span>
              </>
            )}
            {isRanking && !isMultiSelect && (
              <>
                <label className={styles.containerRank}>
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={handleClick}
                    value={option}
                  />
                  <span
                    className={`${styles.checkmarkRank} ${styles.multiRank} `}
                    style={colorStyle}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                  >
                    <p className={styles.ranking}>
                      {index >= 0 ? index + 1 : ""}
                    </p>
                  </span>
                </label>
                <div onClick={handleClick} className={styles.content}>
                  {option}
                </div>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}
