import FlexCol from "components/layouts/FlexColumn/FlexCol";
import styles from "./TestSend.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { useState } from "react";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";

export function PreTakeoffTest({ email, changeField: changeEmailField }) {
  const [test, setTest] = useState(initTest());

  function initTest() {
    if (email.test) return JSON.parse(email.test);
    return {
      test: false,
      toMe: true,
      toEmails: [],
      num: 3,
      time: 15,
      unit: "min",
    };
  }

  const [to, setTo] = useState("");

  const updateTest = (copy) => {
    setTest(copy);
    changeEmailField("test", JSON.stringify(copy));
  };

  function changeField(field, val) {
    let copy = { ...test };
    copy[field] = val;
    updateTest(copy);
  }

  function addEmail(val) {
    if (val) {
      let copy = [...test.toEmails];
      copy.push(val);
      changeField("toEmails", copy);
      setTo("");
    }
  }

  function onKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      addEmail(e.target.value);
    }
  }

  function takeOutEmail(ind) {
    let copy = [...test.toEmails];
    copy.splice(ind, 1);
    changeField("toEmails", copy);
  }

  function changeUnit(e) {
    let unit = e.target.value;
    let copy = { ...test };
    copy.unit = unit;

    if ((unit == "day" || unit == "hour") && copy.time == 15) copy.time = 1;
    else if (unit == "min" && copy.time == 1) copy.time = 15;

    updateTest(copy);
  }

  return (
    <FlexCol gap={10} style={{ paddingTop: "10px" }}>
      {/* <div className={styles.cutoff}></div> */}
      <FlexRow fit>
        <ToggleSwitch
          startChecked={test.test}
          handleCheck={(val) => changeField("test", val)}
        />
        <div className={styles.label}>Send Test Before</div>
      </FlexRow>
      {test.test && (
        <>
          <FlexCol>
            <div className={styles.preSubTitle}>For: </div>
            <FlexRow gap={7} wrap>
              <div className={styles.label}>The first</div>
              <NumberInput
                min={1}
                max={5}
                startNumber={test.num}
                handleNumberChange={(num) => changeField("num", num)}
                style={{ color: "#6793a5" }}
              />
              <div className={styles.label}>people</div>
            </FlexRow>
          </FlexCol>

          <FlexCol>
            <div className={styles.preSubTitle}>When: </div>

            <FlexRow gap={7} wrap>
              <NumberInput
                min={1}
                max={60}
                startNumber={test.time}
                handleNumberChange={(num) => changeField("time", num)}
              />

              <select
                className={`${styles.unit}`}
                onChange={changeUnit}
                value={test.unit}
              >
                <option value="min">Minutes</option>
                <option value="hour">{`Hour${
                  test.time > 1 ? "s" : ""
                }`}</option>
                <option value="day">{`Day${test.time > 1 ? "s" : ""}`}</option>
              </select>

              <div className={styles.label}>before</div>
            </FlexRow>
          </FlexCol>

          <FlexCol>
            <div className={styles.preSubTitle}>To: </div>
            <FlexCol gap={10}>
              <FlexRow fit align={"center"}>
                <ToggleSwitch
                  startChecked={test.toMe}
                  handleCheck={(val) => changeField("toMe", val)}
                />
                <FlexRow fit gap={5}>
                  <div className={styles.label}>My email</div>
                </FlexRow>
              </FlexRow>

              {test.toEmails.length > 0 && (
                <FlexCol align={"flex-end"}>
                  {test.toEmails.map((e, i) => (
                    <FlexRow fit>
                      <div className={styles.label}>{e}</div>
                      <div
                        className={styles.minus}
                        onClick={() => takeOutEmail(i)}
                      >
                        <i className="bi-dash-circle"></i>
                      </div>
                    </FlexRow>
                  ))}
                </FlexCol>
              )}

              <FlexRow>
                <div className={styles.label}>and</div>
                <div className={styles.searchField}>
                  <input
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    type="text"
                    placeholder={"Add Email"}
                    className={styles.search}
                    onKeyDown={onKeyDown}
                    onBlur={(e) => addEmail(e.target.value)}
                  />
                </div>
              </FlexRow>
            </FlexCol>
          </FlexCol>

          <FlexCol></FlexCol>
        </>
      )}
    </FlexCol>
  );
}
