import React, { useEffect, useRef, useState } from "react";
import largelogo from "assets/images/reactionlogodark.png";
import smalllogo from "assets/images/circlelogo.png";
import styles from "./SideMenu.module.scss";
import MenuItem from "./MenuItem";
import { useGetCurrentUser } from "api/resources/organization/users";
import UserProfile from "../../Popout/UserProfile";
import { useNavigate } from "react-router-dom";
import { useFetchRole } from "api/resources/organization/roles";
import { Loading } from "components/Loading/Loading";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import SwitchOrg, { AddOrg } from "pages/organization/SwitchOrg";
import {
  useFetchOrganizationSideMenu,
  useGetOrgLogo,
} from "api/resources/organization/organization";
import ImageEditor from "components/Image Editor/ImageEditor";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import { useQueryClient } from "react-query";
import isEqual from 'lodash/isEqual';

/**
 * @author Nate Hanson
 * @function SideMenu
 **/
/**
 * A sideMenu that is designed to be displayed in every page no matter what. It is collapsable
 * @param user The user that is signed in
 * @param roles the roles that pertain to the user
 * @param organization the organization that the user is signed into
 * @param beforeNavAway if true run a fuction to see if should save before the page is left
 * @param setBeforeNavAway setter function for beforeNavAway
 *
 * @returns {React.ReactElement} a SideMenu component
 */

export const SideMenu = ({
  user,
  roles,
  organization,
  beforeNavAway,
  setBeforeNavAway,
  collapse,
  collapsed,
  logout,
  isMobile,
}) => {
  const [switchOrg, setSwitchOrg] = useState(false);
  const [showAddOrg, setShowAddOrg] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const menuRef = useRef(null);

  const url = window.location.pathname.split("/")[1];
  useEffect(() => {
    if (!url) {
      setActiveItem("/");
    } else {
      setActiveItem("/" + url);
    }
  }, [url]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef?.current && !menuRef?.current?.contains(event.target)) {
        setMobileShow(false);
      }
    }

    if (mobileShow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [mobileShow]);

  const pages = [
    roles?.canViewPersonalDashboard || roles?.canViewOrgDashboard
      ? {
          id: 0,
          name: "Dashboard",
          to: "/",
          page: ["/"],
          iconClassName: "bi bi-speedometer2",
          onClick: () => handleMenuItemClick("/"),
        }
      : "",
    roles?.canViewOrgSurveys
      ? {
          id: 1,
          name: "Surveys",
          to: `/surveys`,
          page: ["/surveys", "/project"],
          iconClassName: "bi bi-card-list",
          onClick: () => handleMenuItemClick("/surveys"),
          // subMenus: [
          //   { name: "Delivery", to: "/projects/courses" },
          //   { name: "Survey Build", to: "/projects/videos" },
          //   { name: "Results", to: "/projects/videos" },
          // ],
        }
      : "",
    roles?.canViewOrgReports
      ? {
          id: 2,
          name: "Analyze",
          to: `/reports`,
          page: ["/reports"],
          iconClassName: "bi bi-clipboard-data",
          onClick: () => handleMenuItemClick("/reports"),
        }
      : "",
    roles?.canViewContacts
      ? {
          id: 4,
          name: "Contacts",
          to: `/contacts`,
          page: ["/contacts"],
          iconClassName: "bi bi-people",
          onClick: () => handleMenuItemClick("/contacts"),
        }
      : "",
  ];

  const userItems = [
    {
      id: 12,
      name: "Running Jobs",
      to: "/tasks",
      page: "/tasks",
      iconClassName: "bi-boxes",
      onClick: () => handleMenuItemClick("/tasks"),
    },
    {
      id: 13,
      name: "Finished Jobs",
      to: "/tasks-finished",
      page: "/tasks-finished",
      iconClassName: "bi-list-check",
      onClick: () => handleMenuItemClick("/tasks-finished"),
    },
    {
      id: 5,
      name: "Notifications",
      to: "/notifications",
      page: "/notifications",
      iconClassName: "bi bi-bell",
      onClick: () => handleMenuItemClick("/notifications"),
    },
    // {
    //   id: 6,
    //   name: "Messages",
    //   to: "/messages",
    //   iconClassName: "bi-chat",
    //   onClick: () => handleMenuItemClick(6),
    // },

    // {
    //   id: 7,
    //   name: "My Account",
    //   to: "/account",
    //   iconClassName: "bi-person-circle",
    //   onClick: () => handleMenuItemClick(7),
    // },
    {
      id: 14,
      name: "Switch/Join Organization",
      // to: ``,
      // page: "/switch",
      iconClassName: "bi-arrow-repeat",
      onClick: () => {
        setMobileShow(false);
        setSwitchOrg(true);
      },
    },
  ];

  const orgItems = [
    {
      id: 8,
      name: "Organization Settings",
      to: `/organization/` + user.organizationId,
      page: "/organization",
      iconClassName: "bi bi-building-gear",
      onClick: () => handleMenuItemClick(`/organization/` + user.organizationId),
    },
    // roles?.canViewOrgTab
    //   ? {
    //       id: 9,
    //       name: "Sub-Organizations",
    //       to: `/organization/` + user.organizationId,
    //       iconClassName: "bi bi-buildings",
    //       onClick: () => handleMenuItemClick(5),
    //     }
    //   : "",
    {
      id: 10,
      name: "Users",
      to: `/users/` + user.organizationId,
      page: "/users",
      iconClassName: "bi-person",
      onClick: () => handleMenuItemClick(`/users/` + user.organizationId),
    },
    {
      id: 11,
      name: "User Roles",
      to: `/roles/` + user.organizationId,
      page: "/roles",
      iconClassName: "bi-person-lock",
      onClick: () => handleMenuItemClick(`/roles/` + user.organizationId),
    },
  ];

  const quicklinks = [
    {
      id: 15,
      name: "Support",
      to: "/support",
      page: "support",
      iconClassName: "bi-question-circle",
      onClick: () => handleMenuItemClick('/support'),
    },
  ];

  const adminlinks = [
    // {
    //   id: 16,
    //   name: "Contact Database",
    //   to: "/contact-database",
    //   iconClassName: "bi-person-rolodex",
    //   onClick: () => handleMenuItemClick(16),
    // },
    {
      id: 17,
      name: "Organizations",
      to: "/organizations",
      page: "/organizations",
      iconClassName: "bi-buildings",
      onClick: () => handleMenuItemClick(16),
    },
    // {
    //   id: 18,
    //   name: "Support",
    //   to: "/give-support",
    //   iconClassName: "bi-person-raised-hand",
    //   onClick: () => handleMenuItemClick(16),
    // },
  ];

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const [showingUserProf, setShowingUserProf] = useState(false);

  const [display, setDisplay] = useState();

  const queryClient = useQueryClient();
  function clearAllExcept(excludedKeys = []) {
  
  // Get all cached queries
    const allQueries = queryClient.getQueryCache().getAll();
  
    allQueries.forEach(query => {
      // Check if the query should be excluded
      const shouldExclude = excludedKeys.some(excludedKey => {
        // Adjust the equality check based on your query key structure.
        // Here we're using lodash's isEqual for deep equality.
        return isEqual(query.queryKey, [excludedKey]);
      });
      
      if (!shouldExclude) {
       
        // Remove this query from the cache
        queryClient.removeQueries(query.queryKey, { exact: true });
      }
    });
  }

  function handleMenuItemClick(path) {
    clearAllExcept(['GetCurrentUserRoles', 'GetCurrentUser', "SideMenuOrganizationLogo"])

    if (beforeNavAway) {
      let component = beforeNavAway.getDisplay(() => {
        routeChange(path);
        setDisplay(undefined);
        setBeforeNavAway(undefined);
      });
      setDisplay(component);
    } else {
      routeChange(path);
    }
  }

  function handleToggleExpand() {
    collapse(!collapsed);
  }

  function onGoToAccount() {
    handleMenuItemClick(pages.length);
    localStorage.setItem("activeaccounttab", 0);
    routeChange("/account");
  }

  return (
    <>
      <div
        className={styles.mobileShowMenu}
        onClick={() => setMobileShow(true)}
      >
        <Button>
          <i className="bi-list" style={{ fontSize: "1.5rem" }}></i>
        </Button>
        <img
          src={require("../../../assets/images/reactionlogonew.png")}
          style={{
            // width: "1.8rem",
            height: "1.8rem",
          }}
        ></img>
      </div>

      {/* {((isMobile && mobileShow) || !isMobile) && ( */}
      <div
        className={`${styles.sideMenu} ${collapsed && styles.collapsed} ${
          !collapsed && styles.expanded
        } ${mobileShow && styles.mobileShow}`}
        style={{ zIndex: collapsed === "mobile" ? "140" : "" }}
        ref={menuRef}
      >
        <div
          className={`${styles.topMenu} 
        ${collapsed && styles.collapsed} 
        ${!collapsed && styles.expanded} 
        `}
        >
          <OrgLogo
            collapsed={collapsed}
            orgid={user?.organizationId}
            organization={organization}
            logout={logout}
            handleToggleExpand={handleToggleExpand}
          ></OrgLogo>

          <div
            className={styles.section_label7}
            style={{ height: "fit-content", display: collapsed ? "none" : "" }}
          >
            {" "}
            Signed into {organization?.name}
          </div>

          {mobileShow && (
            <i
              className={`bi-chevron-left ${styles.closeMobile}`}
              onClick={(e) => {
                e.stopPropagation();
                setMobileShow(false);
              }}
              style={{ zIndex: "140" }}
            ></i>
          )}


            <>
              <div className={styles.section_label7}>
                {collapsed ? " " : "General"}
              </div>
              {pages.map(
                (menuItem, index) =>
                  menuItem != "" && (
                    <MenuItem
                      key={index}
                      menuItem={menuItem}
                      name={menuItem.name}
                      to={menuItem.to}
                      subMenus={menuItem.subMenus || []}
                      iconClassName={menuItem.iconClassName}
                      active={menuItem?.page?.includes(activeItem)}
                      onClick={() => menuItem?.onClick()}
                      collapsed={collapsed}
                    />
                  )
              )}
            </>


          {roles.isAdmin && (
            <>
              <div className={styles.section_label7}>
                {collapsed ? " " : "Organization"}
              </div>
              {orgItems.map(
                (menuItem, index) =>
                  menuItem != "" && (
                    <MenuItem
                      key={index}
                      menuItem={menuItem}
                      name={menuItem.name}
                      to={menuItem.to}
                      subMenus={menuItem.subMenus || []}
                      iconClassName={menuItem.iconClassName}
                      active={activeItem == menuItem.page}
                      onClick={menuItem.onClick}
                      collapsed={collapsed}
                    />
                  )
              )}
            </>
          )}
          <div className={styles.section_label7}>
            {collapsed ? " " : "Personal"}
          </div>
          {userItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              menuItem={menuItem}
              name={menuItem.name}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              active={activeItem == menuItem.page}
              onClick={menuItem.onClick}
              collapsed={collapsed}
            />
          ))}

          {user.isSuperAdmin && (
            <>
              {" "}
              <div className={styles.section_label7}>
                {collapsed ? " " : "Reaction Admin"}
              </div>
              {adminlinks.map(
                (menuItem, index) =>
                  menuItem != "" && (
                    <MenuItem
                      key={index}
                      menuItem={menuItem}
                      name={menuItem.name}
                      to={menuItem.to}
                      subMenus={menuItem.subMenus || []}
                      iconClassName={menuItem.iconClassName}
                      active={activeItem == menuItem.page}
                      onClick={menuItem.onClick}
                      collapsed={collapsed}
                    />
                  )
              )}
            </>
          )}
        </div>

        <div
          className={`${styles.bottomMenu} 
        ${collapsed && styles.collapsed} 
        ${!collapsed && styles.expanded}`}
        >
          {showingUserProf && (
            <UserProfile
              currUser={user}
              image={user?.image}
              logout={logout}
              setShowingUserProf={setShowingUserProf}
              onGoToAccount={onGoToAccount}
            />
          )}
          <div
            className={`${styles.userContainer} ${
              collapsed ? styles.closedUser : ""
            } ${!collapsed ? styles.openUser : ""} ${
              showingUserProf ? styles.active : ""
            }`}
            onClick={() => setShowingUserProf(!showingUserProf)}
            id="userInfoBtn"
          >
            <div>
              <ImageEditor
                src={user?.image?.imageURL}
                isUser
                canEdit={false}
                position={
                  user?.image?.position
                    ? JSON.parse(user?.image?.position)
                    : { x: 0.5, y: 0.5 }
                }
                image={user?.image}
                height={40}
                width={40}
              ></ImageEditor>
            </div>

            {!collapsed && (
              <div className={styles.userInfo}>
                <span>
                  {user?.firstName} {user?.lastName}
                </span>
              </div>
            )}
          </div>

          {!collapsed && (
            <>
              {" "}
              <HorizontalBar height={2}></HorizontalBar>
              <div
                className={`${styles.logo} `}
                style={{
                  paddingLeft: ".5rem",
                  gap: ".25rem",
                  justifyContent: "center",
                  margin: ".75rem 0",
                }}
              >
                <div className={styles.text_3}>Powered By</div>

                <img
                  src={require("../../../assets/images/circlelogo.png")}
                  style={{
                    minHeight: "15px",
                    minWidth: "15px",
                    maxHeight: "15px",
                    maxWidth: "15px",
                  }}
                ></img>

                <div className={styles.text_3}>Reaction</div>
              </div>{" "}
            </>
          )}
        </div>
      </div>
      {/* )} */}
      <ReactModal
        show={switchOrg}
        onClose={() => setSwitchOrg(false)}
        modalStyle={{height: "100%"}}
        rightStyle
        dark
      >
        <SwitchOrg
          show={switchOrg}
          userRoles={roles}
          currentUser={user}
          setShow={setSwitchOrg}
          setShowAddOrg={setShowAddOrg}
        ></SwitchOrg>
      </ReactModal>
      <AddOrg setShow={setShowAddOrg} show={showAddOrg} join></AddOrg>
      {display && <>{display}</>}
    </>
  );
};

function OrgLogo({
  collapsed,
  handleToggleExpand,
  organization,
}) {

  const getLogo = useGetOrgLogo("SideMenuOrganizationLogo");

  return (
    <>
      <div
        className={`${styles.logo} ${collapsed ? styles.smallLogo : ""} ${
          !collapsed ? styles.largeLogo : ""
        } ${collapsed ? styles.smallLogo : ""}`}
        onClick={handleToggleExpand}
      >
        {
          getLogo.isSuccess &&
          getLogo.data?.getOrgLogo?.imageURL &&
          !collapsed && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: !collapsed ? "flex-start" : "center",
                overflow: "hidden",
                maxHeight: !collapsed ? "3.5rem" : "1rem",
              }}
            >
              <ImageEditor
                src={getLogo.data?.getOrgLogo?.imageURL}
                alt={organization?.name}
                canEdit={false}
                isLogo={true}
                position={
                  getLogo.data.getOrgLogo?.position &&
                  typeof getLogo.data.getOrgLogo?.position === "string"
                    ? JSON.parse(getLogo.data.getOrgLogo?.position)
                    : getLogo.data.getOrgLogo?.position
                }
                image={getLogo.data.getOrgLogo}
                height={collapsed ? 50 : 150}
                width={collapsed ? 50 : 206}
                noShadow
                customScale={getLogo.data.getOrgLogo?.scale - 0.05}
              ></ImageEditor>
            </div>
          )}
        {
          getLogo.isSuccess &&
          !getLogo.data?.getOrgLogo?.imageURL &&
          !collapsed && (
            <div className={styles.logo_name}>{organization.name}</div>
          )}
        {
          getLogo.isSuccess &&
          getLogo.data?.getOrgLogo?.imageURL &&
          collapsed && (
            <Icon iconName={"list"} style={{ fontSize: "1.4rem" }}></Icon>
          )}
      </div>
    </>
  );
}
