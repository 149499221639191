/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

export const useSearchDistributions = (
  id,
  sort,
  pageSkip,
  perPage,
  searchString,
  type,
  unique
) => {
  const query = gql`
    query Query(
      $projectId: String!
      $sort: SortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
      $type: String!
    ) {
      distributions: searchDistributions(
        search: $search
        projectId: $projectId
        sort: $sort
        page: $page
        perPage: $perPage
        type: $type
      ) {
        id
        linkName
        linkStyle
        audience {
          id
          name
          description
          members
          timeZone
        }
        distConfig {
          id
          signature
          aliasName
          type
          fromAddress
        }
        messageType
        subject
        messageBody
        sender
        status
        scheduledDate
        sentDate
        createdDate
        updatedDate
        emailStrength
        link
        conditions
        index
        sendRequestLoading
        name
        icon
        dynamicFrom
        dynamicConditions
        sendCount
        timeDelay
        timeDelayCount
        showUnsub
        sendHistory
        audienceSchedule
        replyTo
        requests
        requestProgress
        scheduleCount
        delivered
        projectId
        encoding
        html
        useHTML
        recurringSend
        sendInterval
        timeZone
      }
    }
  `;

  return useGqlQuery(
    ["searchDist:" + id + searchString + pageSkip + perPage + unique],
    query,
    {
      search: searchString,
      projectId: id,
      sort: sort,
      page: pageSkip,
      perPage: perPage,
      type: type,
    }
  );
};

export const useSearchDistributionsCount = (
  id,
  sort,
  pageSkip,
  perPage,
  searchString,
  type,
  unique
) => {
  const query = gql`
    query Query(
      $projectId: String!
      $sort: SortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
      $type: String!
    ) {
      count: searchDistributionsCount(
        search: $search
        projectId: $projectId
        sort: $sort
        page: $page
        perPage: $perPage
        type: $type
      )
    }
  `;

  return useGqlQuery("searchDist:" + unique, query, {
    search: searchString,
    projectId: id,
    sort: sort,
    page: pageSkip,
    perPage: perPage,
    type: type,
  });
};

export const useGetDistributionEvents = (id) => {
  const query = gql`
    query Query($projectId: String!) {
      events: getDistributionEvents(projectId: $projectId) {
        id
        title
        start
        end
        distId
        index
      }
    }
  `;

  return useGqlQuery(["getdistevents: " + id], query, {
    projectId: id,
  });
};

export const useGetEmailsInSurveys = (projectIds) => {
  const data = {
    projectIDs: projectIds,
  };

  const query = gql`
    query GetEmailsInSurveys($data: projectIDArray!) {
      emails: getEmailsInSurveys(data: $data) {
        id
        hasLink
        name
        index
        subject
      }
    }
  `;
  return useGqlQuery(["emails in surveys:" + projectIds.toString()], query, {
    data: data,
  });
};

export const useFetchDistributionsSimpleGql = (
  id,
  sort,
  pageSkip,
  perPage,
  searchString
) => {
  const query = gql`
    query DistributionByProject(
      $projectId: String!
      $sort: SortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
    ) {
      distributions: searchDistribution(
        projectId: $projectId
        sort: $sort
        page: $page
        perPage: $perPage
        search: $search
      ) {
        id
        status
        subject
        messageType
        scheduledDate
        sentDate
        createdDate
        sender
        index
        name
      }
    }
  `;

  return useGqlQuery(["distributionssimple"], query, {
    search: searchString,
    projectId: id,
    sort: sort,
    page: pageSkip,
    perPage: perPage,
  });
};

export const useFetchDistributionsCountGql = (
  id,
  sort,
  pageSkip,
  perPage,
  searchString
) => {
  const query = gql`
    query Query(
      $projectId: String!
      $sort: SortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
    ) {
      count: searchDistributionCount(
        projectId: $projectId
        sort: $sort
        page: $page
        perPage: $perPage
        search: $search
      )
    }
  `;

  return useGqlQuery(["searchDistribCount", searchString], query, {
    search: searchString,
    projectId: id,
    sort: sort,
    page: pageSkip,
    perPage: perPage,
  });
};

export const useFetchDistConfigs = () => {
  const query = gql`
    query {
      signatures: distsConfigByOrganization {
        id
        signature
        aliasName
        type
        fromAddress
        encoding
      }
    }
  `;

  return useGqlQuery(["distConfigs"], query, {});
};

export const useSearchDistConfigByOrg = (searchString, page, perPage, sort) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: SortSelectionDelivery!
    ) {
      distconfigs: searchDistConfig(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        id
        signature
        aliasName
        type
        fromAddress
        createdAt
        updatedAt
        encoding
        user {
          id
          firstName
          lastName
          activeDistConfigId
        }
      }
    }
  `;

  return useGqlQuery(["searchDistConfig", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useCountSearchDistConfigByOrg = (searchString, page, perPage) => {
  const query = gql`
    query Query($search: String!, $page: Float!, $perPage: Float!) {
      count: countSearchDistConfig(
        search: $search
        page: $page
        perPage: $perPage
      )
    }
  `;

  return useGqlQuery(["searchDistConfigCount", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
  });
};

export const useFetchAudContactsGql = (
  page,
  perPage,
  pageSort,
  searchString
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: SortSelectionDelivery!
    ) {
      items: searchAudContacts(
        search: $search
        sort: $sort
        page: $page
        perPage: $perPage
      ) {
        id
        name
        description
        members
      }
    }
  `;

  return useGqlQuery(["searchAudience/contacts"], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: pageSort,
  });
};

export const useFetchUserSigs = (userId) => {
  const query = gql`
    query GetSigsForUser($userId: String!) {
      sigs: getSigsForUser(userId: $userId) {
        aliasName
        encoding
        fromAddress
        id
        organizationId
        signature
        user {
          id
          firstName
          lastName
          activeDistConfigId
        }
      }
    }
  `;

  return useGqlQuery(["user sigs", "sigs for user: " + userId], query, {
    userId: userId,
  });
};

export const useUpdateDistribution = () => {
  const mutation = gql`
    mutation UpdateDistribution(
      $localOffset: Float!
      $id: String!
      $data: distributionData
      $audienceIds: audienceIDs
    ) {
      updateDistribution(
        localOffset: $localOffset
        id: $id
        data: $data
        audienceIds: $audienceIds
      ) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("distributions");
      queryClient.invalidateQueries(
        "distribution: " + data.updateDistribution.id
      );
      queryClient.invalidateQueries("email: " + data.updateDistribution.id);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateDistConfig = () => {
  const mutation = gql`
    mutation Mutation($data: DistConfigInput!) {
      sig: createDistConfig(data: $data) {
        id
        user {
          id
          firstName
          lastName
          activeDistConfigId
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("searchDistConfig");
      queryClient.invalidateQueries("user sigs");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchDistConfigsByMutation = () => {
  const mutation = gql`
    mutation DistsConfigsByMutation {
      signatures: distsConfigsByMutation {
        aliasName
        encoding
        fromAddress
        id
        signature
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("distributions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateDistConfig = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: DistConfigUpdate!) {
      updateDistConfig(id: $id, data: $data) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("searchDistConfig");
      queryClient.invalidateQueries("user sigs");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteDistribution = () => {
  const mutation = gql`
    mutation DeleteDistribution($id: String!) {
      deleteDistribution(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("distributions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchEmailStats = () => {
  const mutation = gql`
    mutation GetEmailStats($data: emailStatsInput!) {
      getEmailStats(data: $data) {
        bounces
        opens
        reportedSpam
        unsubscribes
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useFetchBouncesGql = () => {
  const mutation = gql`
    mutation Mutation($data: badEmailRetrievalInput!) {
      getAllBounces(data: $data) {
        date
        email
        reason
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useFetchBlocksGql = () => {
  const mutation = gql`
    mutation Mutation($data: badEmailRetrievalInput!) {
      getAllBlocks(data: $data) {
        date
        email
        reason
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useSearchBounceGql = () => {
  const mutation = gql`
    mutation Mutation($searchString: String!) {
      searchBounce(searchString: $searchString) {
        date
        reason
        email
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useSearchBlocksGql = () => {
  const mutation = gql`
    mutation Mutation($searchString: String!) {
      searchBlocks(searchString: $searchString) {
        date
        reason
        email
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useSearchSpamReportsGql = () => {
  const mutation = gql`
    mutation Mutation($searchString: String!) {
      searchSpamReports(searchString: $searchString) {
        date
        reason
        email
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useSearchInvalidEmailGql = () => {
  const mutation = gql`
    mutation Mutation($searchString: String!) {
      searchInvalidEmails(searchString: $searchString) {
        date
        reason
        email
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useFetchSpamReportsGql = () => {
  const mutation = gql`
    mutation Mutation($data: badEmailRetrievalInput!) {
      getAllSpamReports(data: $data) {
        date
        email
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      //console.log(data);
      queryClient.invalidateQueries("distributions");
    },
  };
  return useGqlMutation(mutation, {});
};

export const useFetchInvalidEmailGql = () => {
  const mutation = gql`
    mutation Mutation($data: badEmailRetrievalInput!) {
      getAllInvalidEmails(data: $data) {
        date
        email
        reason
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useGetDistributionById = (id) => {
  const query = gql`
    query {
      dist: distributionByID(id: "${id}") {
        id
        linkName
        linkStyle
        audience {
          id
          name
          description
          members
          timeZone
        }
        distConfig {
          id
          signature
          aliasName
          type
          fromAddress
        }
        distConfigId
        messageType
        subject
        messageBody
        sender
        status
        scheduledDate
        sentDate
        createdDate
        updatedDate
        emailStrength
        link
        conditions
        index
        sendRequestLoading
        name
        icon
        dynamicFrom
        dynamicConditions
        sendCount
        timeDelay
        timeDelayCount
        showUnsub
        sendHistory
        audienceSchedule
        replyTo
        requests
        requestProgress
        scheduleCount
        delivered
        projectId
        scheduleCount
        encoding
        html
        useHTML
      }
    }
  `;

  return useGqlQuery(["distribution: " + id], query, {});
};

export const useGetEmailById = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      email: emailById(id: $id) {
        id
        linkName
        linkStyle
        audience {
          id
          name
          description
          members
          timeZone
          filters
          dynamic
        }
        distConfig {
          id
          signature
          aliasName
          type
          fromAddress
        }
        distConfigId
        messageType
        subject
        messageBody
        sender
        status
        scheduledDate
        timeZone
        sentDate
        createdDate
        updatedDate
        emailStrength
        link
        conditions
        index
        sendRequestLoading
        name
        icon
        dynamicFrom
        dynamicConditions
        sendCount
        timeDelay
        timeDelayCount
        showUnsub
        sendHistory
        audienceSchedule
        replyTo
        requests
        requestProgress
        scheduleCount
        delivered
        projectId
        scheduleCount
        encoding
        html
        useHTML
        sendInterval
        recurringSend
        emailReminder
        reminderDate
        audienceSchedule
        byAudience
        frequency
        lastSend
        textMessageEncoding
        test
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useBuildNewEmail = () => {
  const mutation = gql`
    mutation Mutation($projectId: String!) {
      email: buildNewEmail(projectId: $projectId) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("distributions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCopyEmail = () => {
  const mutation = gql`
    mutation Mutation($distId: String!) {
      copyEmail(distId: $distId) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("distributions");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteDistConfig = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      deleted: deleteDistConfig(id: $id)
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useGetEmailPreview = () => {
  const mutation = gql`
    mutation Mutation(
      $conditions: String!
      $distConfigId: String!
      $dynamicConditions: String!
      $projectId: String!
      $fields: String!
      $encoding: String!
    ) {
      preview: getEmailPreview(
        conditions: $conditions
        distConfigId: $distConfigId
        dynamicConditions: $dynamicConditions
        projectId: $projectId
        fields: $fields
        encoding: $encoding
      ) {
        from {
          email
          name
        }
        html
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useSendEmailPreview = () => {
  const mutation = gql`
    mutation Mutation(
      $subject: String!
      $to: String!
      $projectId: String!
      $fields: String!
      $encoding: String!
      $dynamicConditions: String!
      $html: String!
      $distConfigId: String!
      $conditions: String!
    ) {
      sendPreview(
        subject: $subject
        to: $to
        projectId: $projectId
        fields: $fields
        encoding: $encoding
        dynamicConditions: $dynamicConditions
        html: $html
        distConfigId: $distConfigId
        conditions: $conditions
      )
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useGetHTMLEmailPreview = () => {
  const mutation = gql`
    mutation Mutation(
      $fields: String!
      $dynamicConditions: String!
      $distConfigId: String!
      $html: String!
      $projectId: String!
    ) {
      preview: getHTMLEmailPreview(
        fields: $fields
        dynamicConditions: $dynamicConditions
        distConfigId: $distConfigId
        html: $html
        projectId: $projectId
      ) {
        from {
          email
          name
        }
        html
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

export const useGetTextMessagePreview = () => {
  const mutation = gql`
    mutation Mutation(
      $conditions: String!
      $projectId: String!
      $fields: String!
      $encoding: String!
    ) {
      text: getTextMessagePreview(
        conditions: $conditions
        projectId: $projectId
        fields: $fields
        encoding: $encoding
      )
    }
  `;
  return useGqlMutation(mutation);
};

export const useSendTextPreview = () => {
  const mutation = gql`
    mutation Mutation(
      $conditions: String!
      $projectId: String!
      $fields: String!
      $encoding: String!
      $to: String!
    ) {
      sent: sendTextPreview(
        conditions: $conditions
        projectId: $projectId
        fields: $fields
        encoding: $encoding
        to: $to
      )
    }
  `;
  return useGqlMutation(mutation);
};

export const useSendEmailTest = () => {
  const mutation = gql`
    mutation Mutation(
      $toEmails: StringList!
      $toMe: Boolean!
      $contactIds: StringList!
      $randomCount: Float!
      $random: Boolean!
      $distId: String!
      $record: Boolean!
    ) {
      response: sendEmailTest(
        toEmails: $toEmails
        toMe: $toMe
        contactIds: $contactIds
        randomCount: $randomCount
        random: $random
        distId: $distId
        record: $record
      ) {
        success
        error
        asIf {
          id
          firstName
          lastName
          email
        }
        shouldTo
      }
    }
  `;
  return useGqlMutation(mutation);
};

export const useRunLoadTest = () => {
  const mutation = gql`
    mutation Mutation(
      $onFailCount: Float!
      $onFailAge: Float!
      $onCompleteCount: Float!
      $onCompleteAge: Float!
      $duration: Float!
      $limit: Float!
      $concurrency: Float!
      $cadence: Float!
      $drip: Float!
      $number: Float!
      $distId: String!
    ) {
      runLoadTest(
        onFailCount: $onFailCount
        onFailAge: $onFailAge
        onCompleteCount: $onCompleteCount
        onCompleteAge: $onCompleteAge
        duration: $duration
        limit: $limit
        concurrency: $concurrency
        cadence: $cadence
        drip: $drip
        number: $number
        distId: $distId
      )
    }
  `;
  return useGqlMutation(mutation);
};

export const useMakeDefaultSig = () => {
  const mutation = gql`
    mutation Mutation($sigId: String!, $userId: String!) {
      makeDefaultSig(sigId: $sigId, userId: $userId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("searchDistConfig");
      queryClient.invalidateQueries("user sigs");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useTieUserToSig = () => {
  const mutation = gql`
    mutation Mutation($sigId: String!, $userId: String!) {
      tieUserToSig(sigId: $sigId, userId: $userId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("searchDistConfig");
      queryClient.invalidateQueries("user sigs");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUntieUserFromSig = () => {
  const mutation = gql`
    mutation Mutation($sigId: String!, $userId: String!) {
      untieUserFromSig(sigId: $sigId, userId: $userId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("searchDistConfig");
      queryClient.invalidateQueries("user sigs");
    },
  };

  return useGqlMutation(mutation, options);
};
