/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 natehanson. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
// External
import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

// Internal
import {
  Dash as Dashboard,
  Project,
  Organization,
  SurveyTaker,
  SurveyTakerNoPart,
  ReportsPage,
  AccountDetails,
  Notifications,
  Contacts,
  CustomReport,
  Users,
  Roles,
  PreviousImports,
} from "pages";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
import { Surveys } from "pages/projects/Surveys";
import Unsubscribe from "pages/unsubscribe/Unsubscribe";
import { SurveyPreview } from "pages/projects/project/survey_build/preview/SurveyPreview";
import { Tasks } from "pages/account/Tasks/Tasks";
import Organizations from "pages/adminpages/Organizations";
import { CreateSurvey } from "pages/projects/NewSurveySetUp/CreateSurvey";
import UnifinishedUpload from "pages/contacts/all_contacts/NewContacts/upload/UnifinishedUpload";
import { useExportContacts } from "api/resources/contacts/contacts";
import CreateOrganization from "pages/organization/organization_details/CreateOrganization";
import { PrivacyPolicy } from "pages/Policies/PrivacyPolicy";
import { useExportDeliveries } from "api/resources/projects/deliveries";
import TAC from "pages/register/TAC";
import PA from "pages/register/PA";
import { SurveyTesting } from "pages/projects/project/survey_taker/SurveyTesting";

export const MainContentRoutes = ({
  refetchN,
  setBeforeNavAway,
  refetchTasks,
  collapse,
  collapsed,
}) => {
  const getCurrUser = useGetCurrentUser("GetCurrentUser");
  const getCurrRole = useFetchRole("GetCurrentUserRoles");

  const exportContacts = useExportContacts(); // So a user could leave the export modal and still have it download
  function onContactExport(data, options) {
    const {
      audienceFilters,
      audienceId,
      filters,
      sort,
      search,
      settings,
      headers,
    } = data;

    exportContacts.mutate(
      {
        audienceFilters: audienceFilters,
        audienceId: audienceId,
        filters: filters,
        sort: sort,
        search: search,
        settings: settings,
        headers: headers,
      },
      options
    );
  }

  const exportDeliveries = useExportDeliveries();
  function onExportDeliveries(data, options) {
    exportDeliveries.mutate(data, options);
  }

  const navigate = useNavigate();
  return (
    <>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <Routes>
          <Route path="survey/:id/preview" element={<SurveyPreview />} />
          <Route
            path="take-survey/:projectId"
            element={<SurveyTakerNoPart />}
          />
          <Route
            path="take-survey/specific/:projectId/:participantId/:distId"
            element={<SurveyTaker />}
          />
          <Route
            path="test-survey/:record/:projectId/:participantId/:distId"
            element={<SurveyTesting />}
          />
          <Route path="previous-imports" element={<PreviousImports />} />
          <Route path="previous-imports/:id" element={<UnifinishedUpload />} />
          <Route path="privacy-notice" element={<PrivacyPolicy />} />
          <Route path="terms-and-services-agreement" element={<TAC />} />

          {(getCurrRole.data.role.canViewPersonalDashboard ||
            getCurrRole.data.role.canViewOrgDashboard) && (
            <>
              <Route path="*" element={<Navigate to={`/`} />} />
              <Route
                path="/"
                element={
                  <Dashboard
                    user={getCurrUser.data.me}
                    role={getCurrRole.data.role}
                    collapse={collapse}
                  />
                }
              />
            </>
          )}

          {getCurrUser.data.me.isSuperAdmin && (
            <>
              {" "}
              <Route
                path="/organizations"
                element={
                  <Organizations
                    user={getCurrUser.data.me}
                    roles={getCurrRole.data.role}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                  />
                }
              />
            </>
          )}
          {getCurrUser.data.me.isSuperAdmin && (
            <>
              {" "}
              <Route
                path="/organizations/create"
                element={
                  <CreateOrganization
                    user={getCurrUser.data.me}
                    roles={getCurrRole.data.role}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    setShow={() => navigate("/organizations")}
                  ></CreateOrganization>
                }
              />
            </>
          )}

          <Route path="/privacy-policy" element={<PA />} />

          {getCurrUser.data.me.isSuperAdmin && (
            <>
              <Route
                path="/organizations/:id/*"
                element={
                  <Organization
                    role={getCurrRole.data.role}
                    currentUser={getCurrUser.data.me}
                  />
                }
              />
            </>
          )}
          {!(
            getCurrRole.data.role.canViewPersonalDashboard ||
            getCurrRole.data.role.canViewOrgDashboard
          ) &&
            getCurrRole.data.role.canViewOrgSurveys && (
              <>
                {" "}
                <Route path="*" element={<Navigate to={`/surveys`} />} />
                <Route path="/" element={<Navigate to={`/surveys`} />} />
              </>
            )}
          {!(
            getCurrRole.data.role.canViewPersonalDashboard ||
            getCurrRole.data.role.canViewOrgDashboard
          ) &&
            !getCurrRole.data.role.canViewOrgSurveys && (
              <>
                {" "}
                <Route path="*" element={<Navigate to={`/account`} />} />
                <Route path="/" element={<Navigate to={`/account`} />} />
              </>
            )}

          {getCurrRole.data.role.canViewOrgSurveys && (
            <>
              <Route
                path="/surveys/*"
                element={
                  <Surveys
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data?.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                  />
                }
              />
              <Route
                path="/surveys/create-project"
                element={
                  <CreateSurvey
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data?.me}
                    org={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                  />
                }
              />

              {/* TODO: Change to Surveys */}
              <Route
                path="/project/:id/*"
                element={
                  <Project
                    roles={getCurrRole.data.role}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    user={getCurrUser.data?.me}
                    setBeforeNavAway={setBeforeNavAway}
                    collapse={collapse}
                    collapsed={collapsed}
                    onExportDeliveries={onExportDeliveries}
                  />
                }
              />
              {/* <Route
                path="/surveys/:id/*"
                element={
                  <Project
                    roles={getCurrRole.data.role}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    user={getCurrUser.data?.me}
                    setBeforeNavAway={setBeforeNavAway}
                    collapse={collapse}
                    collapsed={collapsed}
                    onExportDeliveries={onExportDeliveries}
                  />
                }
              /> */}
            </>
          )}
          {getCurrRole.data.role.isAdmin && (
            <>
              <Route
                path="/organization/:id/*"
                element={
                  <Organization
                    userRoles={getCurrRole.data.role}
                    currentUser={getCurrUser.data.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                  />
                }
              />

              <Route
                path="/roles/:id"
                element={<Roles roles={getCurrRole.data.role} />}
              />

              <Route
                path="/users/:id"
                element={
                  <Users
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data?.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                  />
                }
              />
            </>
          )}

          {getCurrRole.data.role.canViewContacts && (
            <Route
              path="/contacts/*"
              element={
                <Contacts
                  user={getCurrUser.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  roles={getCurrRole.data.role}
                  refetchTasks={refetchTasks}
                  onExport={onContactExport}
                />
              }
            />
          )}

          <Route
            path="/account"
            element={
              <AccountDetails
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                roles={getCurrRole.data.role}
              />
            }
          />
          <Route
            path="/notifications"
            element={<Notifications user={getCurrUser.data.me} />}
          />
          <Route path="/tasks" element={<Tasks user={getCurrUser.data.me} />} />
          <Route
            path="/tasks-finished"
            element={<Tasks user={getCurrUser.data.me} finished={true} />}
          />
          {getCurrRole.data.role?.canViewOrgReports && (
            <>
              <Route
                path="/reports/*"
                element={
                  <ReportsPage
                    roles={getCurrRole.data.role}
                    user={getCurrUser.data.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                  />
                }
              />

              <Route
                path="/report/:reportid"
                element={
                  <CustomReport
                    collapse={collapse}
                    user={getCurrUser.data.me}
                  />
                }
              />
            </>
          )}

          <Route path="unsubscribe/:id" element={<Unsubscribe />} />
          <Route path="unsubscribe/:id/:deliveryId" element={<Unsubscribe />} />

          {/* <Route
            path="import-contacts/manual/:id"
            element={
              <Importer
                manual={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/manual"
            element={
              <Importer
                manual={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/csv/:id"
            element={
              <Importer
                csv={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/csv"
            element={
              <Importer
                csv={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/excel/:id"
            element={
              <Importer
                excel={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/excel"
            element={
              <Importer
                excel={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/salesforce/:id"
            element={
              <Importer
                salesforce={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
                refetchTasks={refetchTasks}
              />
            }
          />
          <Route
            path="import-contacts/salesforce"
            element={
              <Importer
                salesforce={true}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
              />
            }
          /> */}
        </Routes>
      )}
    </>
  );
};
