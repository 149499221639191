import React, { useEffect, useState } from "react";
import styles from "./NavBar.module.scss";
import { MenuItem } from "./MenuItem";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import Icon from "components/Icon/Icon";

/**
 * A generic sub sidebar used throghout the website
 * @param {Array} menuItems array of objects that have the menuItems and their paths to go to
 * @param {Int} active id of the active menuItem
 * @param {Function} updateActive a function that is called when a menuItem is clicked on. Updates the active id
 * @returns {React.ReactElement} a Sidebar2 component
 */

export const NavBar = ({
  menuItems,
  active,
  updateActive,
  left,
  folder,
  beforeNavAway,
  setBeforeNavAway,
  first,
  noMobile,
}) => {
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(
    window.innerWidth > 1024 ? false : true
  );

  useEffect(() => {
    const resizeListener = () => {
      let width = window.innerWidth;
      // change width from the state object
      if (width < 1024) {
        setShowButton(true);
        setShow(false);
      } else if (width > 1024) {
        setShowButton(false);
        setShow(true);
      }
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className={`${styles.menuContainer} ${show && styles.showStyle}`}>
        {showButton && !noMobile && (
          <Button
            shadow
            // onClick={() => {
            //   setShow(!show);
            // }}
            height={2.1}
            options={menuItems.map((item) => {return {
              onClick: () => navigate(item?.to),
              data: (
                <>
                  <Icon sapphire iconName={item?.icon}></Icon> {item?.name}
                </>
              ),
            }})}
            optionStyle={{left: "0", width: "fit-content"}}
          >
            <i className="bi bi-list"></i>
          </Button>
        )}
        {(window.innerWidth > 1024 || show || noMobile) && (
          <div
            className={
              window.innerWidth > 1024 || noMobile
                ? styles.menu
                : styles.menuMobile
            }
            style={{ right: left ? "none" : "", left: left ? "0" : "" }}
            onMouseLeave={() => setShow(false)}
          >
            {menuItems.map((menuItem, i) => (
              <React.Fragment key={i}>
                {menuItem && menuItem != "" && (
                  <MenuItem
                    key={menuItem?.id}
                    id={menuItem?.id}
                    label={menuItem?.name}
                    to={menuItem?.to}
                    iconClassName={menuItem?.iconClassName}
                    condensed
                    isActive={active == menuItem?.id}
                    type={menuItem?.type}
                    subMenus={menuItem?.subMenus}
                    makeActive={(index, type) =>
                      updateActive && !menuItem?.noChange
                        ? updateActive(index, type)
                        : ""
                    }
                    hasFolders={folder}
                    beforeNavAway={beforeNavAway}
                    setBeforeNavAway={setBeforeNavAway}
                    index={i}
                    last={i === menuItems?.length - 1}
                    first={first && i === 0}
                    noMobile={noMobile}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
