import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import styles from "./Loading.module.scss";

/**
 * The loading circle of doom. Designed to be displayed whenever anything is loading. Size is customizable
 * @param {Int} height height of the image
 * @param {Int} width width of the image
 * @param {Boolean} fullScreen boolean value that determines if it is full screen
 *  * @param {String} message message or error to be displayed underneath the loading circle
 * @returns {React.ReactElement} a loading gif/image component
 */

export const Loading = ({ height, width, fullScreen, message, dim }) => {
  function getHeight() {
    if (height) {
      if (isNaN(height)) {
        return height;
      } else {
        return `${height}px`;
      }
    }
    if (isNaN(dim)) {
      return dim;
    } else {
      return `${dim}px`;
    }
  }
  function getWidth() {
    if (width) {
      if (isNaN(width)) {
        return width;
      } else {
        return `${width}px`;
      }
    }

    if (isNaN(dim)) {
      return dim;
    } else {
      return `${dim}px`;
    }
  }

  return (
    <div className={`${fullScreen && styles.fullScreen}`}>
      <Player
        autoplay
        loop
        src={require("assets/animations/ReactionLoading.json")}
        style={{
          height: height || dim ? getHeight() : "200px",
          width: width || dim ? getWidth() : "200px",
        }}
      />
      {message && <p>{message}</p>}
    </div>
  );
};
