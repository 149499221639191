/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import React, { useState, useEffect, useRef } from "react";
import { Loading } from "components/Loading/Loading";
import styles from "./CustomFieldsEditor.module.scss";
import Button from "components/Button/Button";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { ErrorPage } from "pages";
import {
  useFetchContact,
  useUpdateContactAttGql,
} from "api/resources/contacts/contacts";
import EditSingleCustomField from "./EditSingle";
import Icon from "components/Icon/Icon";
import { Cell } from "components/tables/BasicTable/BasicTable";

export default function CustomFieldsEditor({ contact, disabled, setAdd, add }) {
  const orgFields = useFetchCurrOrgsFields();
  const fetchContact = useFetchContact(contact.id);

  return (
    <>
      {(orgFields.isLoading || fetchContact.isLoading) && <Loading />}
      {(orgFields.isError || fetchContact.isError) && (
        <ErrorPage error={orgFields.error} />
      )}
      {orgFields.isSuccess && fetchContact.isSuccess && (
        <>
          <EditFields
            contact={fetchContact.data.contact}
            orgFields={orgFields.data.getCurrOrgFields}
            disabled={disabled}
            refetch={() => {
              orgFields.refetch();
              fetchContact.refetch();
            }}
            setAdd={setAdd}
            add={add}
          />
        </>
      )}
    </>
  );
}

function EditFields({ orgFields, contact, refetch, add, setAdd, disabled }) {
  const initFields = () => {
    let columns = [];

    if (contact.attribute) {
      for (let field of orgFields) {
        let attributes = contact.attribute
          .filter((a) => a.customFieldId === field.id)
          .map((a) => a.name);
        if (attributes.length) {
          columns.push({
            field: field,
            attributes: attributes,
          });
        }
      }
    }
    return columns;
  };

  const [columns, setColumns] = useState(initFields());
  const [nameAndEmail, setNameAndEmail] = useState({
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    phone: contact.phone,
  });
  const [changed, setChanged] = useState(false);
  const [saveWords, setSaveWords] = useState(`Save Contact Fields`);
  const [createNew, setCreateNew] = useState(false);

  function addField(field) {
    let copy = [...columns];
    copy.push({
      field: field,
      attributes: [],
    });

    setColumns(copy);
    setAdd(false);
    setChanged(true);
  }

  const updateContact = useUpdateContactAttGql();

  function saveUserFields() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );

    let attributeData = [];
    for (let col of columns) {
      for (let att of col?.attributes) {
        if (att?.changed) {
          attributeData.push({
            customFieldId: col?.field?.id,
            name: att?.value,
            id: att?.id,
          });
        }
      }
    }

    updateContact.mutate(
      {
        data: {
          firstName: nameAndEmail.firstName,
          lastName: nameAndEmail.lastName,
          email: nameAndEmail.email,
          phone: nameAndEmail.phone
        },
        id: contact.id,
        attributeData: {
          attributes: attributeData,
        },
      },
      {
        onSuccess: (data) => {
          onSaved();
          // setColumns(finalFields ? finalFields : {});
        },
      }
    );
  }

  function onSaved() {
    setSaveWords(
      <>
        Saved <i className="bi bi-check"></i>
      </>
    );
    setTimeout(() => {
      setChanged(false);
      setSaveWords(`Save Contact Fields`);
    }, 1000);
    if (refetch) {
      refetch();
    }
  }

  function changeField(ind, options) {
    let copy = [...columns];
    let column = copy[ind];
    options.changed = true;
    if (column.field.delimiter && column.field.multiple) {
      column.attributes = options.map((a) => {
        return a.value;
      });
    } else {
      column.attributes = [options];
    }

    setColumns(copy);
    setChanged(true);
  }

  function changename(val) {
    setNameAndEmail(val);
    setChanged(true);
  }

  function cancel() {
    setColumns(initFields());
    setChanged(false);
  }

  function handleNewField(field) {
    addField(field);
    setCreateNew(false);
    refetch();
  }

  function checkStatus() {
    if (!contact.lastEmailStatus) {
      return "warning";
    }
    if (contact.lastEmailStatus === "verified") {
      return "verified";
    }
    if (contact.lastEmailStatus != "verified") {
      return "bad";
    }
  }

  return (
    <>
      {" "}
      {add && (
        <AddField
          onClickOut={() => setAdd(false)}
          onAdd={addField}
          options={orgFields.filter(
            (c) => !columns.some((col) => col.field.id === c.id)
          )}
          onCreateNew={() => {
            setCreateNew(true);
            setAdd(false);
          }}
        />
      )}
      <div className={styles.userFields}>
        <table style={{ borderCollapse: "collapse" }}>
          <tbody>
            <FieldEditor
              label="First Name"
              icon="person-circle"
              value={nameAndEmail?.firstName}
              id={"firstName"}
              setChanged={setChanged}
              setNameAndEmail={(val) =>
                changename({ ...nameAndEmail, firstName: val })
              }
              disabled={disabled}
              column={{
                field: orgFields.find(
                  (f) => f?.generic === true && f?.name === "firstName"
                ),
              }}
            ></FieldEditor>
            <FieldEditor
              label="Last Name"
              icon="person-circle"
              value={nameAndEmail?.lastName}
              id={"lastName"}
              setChanged={setChanged}
              setNameAndEmail={(val) =>
                changename({ ...nameAndEmail, lastName: val })
              }
              disabled={disabled}
              column={{
                field: orgFields.find(
                  (f) => f?.generic === true && f?.name === "lastName"
                ),
              }}
            ></FieldEditor>
            <FieldEditor
              label="Email"
              icon="envelope"
              value={nameAndEmail?.email}
              id={"email"}
              setChanged={setChanged}
              setNameAndEmail={(val) =>
                changename({ ...nameAndEmail, email: val })
              }
              disabled={disabled}
              status={checkStatus()}
              column={{
                field: orgFields.find(
                  (f) => f?.generic === true && f?.name === "email"
                ),
              }}
            ></FieldEditor>
            <FieldEditor
              label="Phone"
              icon="telephone"
              value={nameAndEmail?.phone}
              id={"phone"}
              setChanged={setChanged}
              setNameAndEmail={(val) =>
                changename({ ...nameAndEmail, phone: val })
              }
              disabled={disabled}
              column={{
                field: orgFields.find(
                  (f) => f?.generic === true && f?.name === "phone"
                ),
              }}
            ></FieldEditor>

            {columns.map((column, i) => {
              const multiple =
                column.field.delimiter && column.field.multiple ? true : false;
              const value = multiple ? column.attributes : column.attributes[0];
              return (
                <FieldEditor
                  key={i}
                  label={
                    column?.field?.displayName
                      ? column?.field?.displayName
                      : column?.field?.name
                  }
                  description={column?.field?.description}
                  icon={column.field?.icon ? column.field?.icon : "person"}
                  value={value}
                  column={column}
                  id={column.id}
                  setChanged={setChanged}
                  changeField={changeField}
                  index={i}
                  disabled={disabled}
                  options={column?.field?.properties}
                  multiple={multiple}
                  select={!column.field.multiple}
                  delimiter={column.field.delimiter}
                ></FieldEditor>
              );
            })}
          </tbody>
        </table>

        {!add && changed && !disabled && (
          <div
            style={{
              display: "flex",
              gap: "15px",
              height: "30px",
              marginTop: "20px",
              position: "sticky",
              bottom: "2em",
            }}
          >
            <Button seafoam shadow onClick={cancel}>
              <div>Cancel</div>
            </Button>
            <Button shadow onClick={saveUserFields}>
              {saveWords}
            </Button>
          </div>
        )}
        {createNew && (
          <EditSingleCustomField
            brandNew
            onClose={() => setCreateNew(false)}
            onSaved={handleNewField}
          />
        )}
      </div>
    </>
  );
}

export function AddField({ onClickOut, onAdd, options, onCreateNew }) {
  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      onClickOut(e);
      document.removeEventListener("click", clickOutside, true);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  const onClick = (name) => {
    onAdd(name);
  };

  return (
    <div ref={ref} className={styles.customDropDown}>
      {options.map((field, i) => (
        <div key={i} className={styles.choice} onClick={() => onClick(field)}>
          {field?.displayName ? field.displayName : field.name}
        </div>
      ))}
      <div
        className={`${styles.choice} ${styles.addNew}`}
        onClick={onCreateNew}
      >
        + New Field
      </div>
    </div>
  );
}

export function FieldEditor({
  value,
  label,
  icon,
  setChanged,
  setNameAndEmail,
  changeField,
  index,
  disabled,
  multiple,
  options,
  select,
  status,
  description,
  delimiter,
  column,
}) {
  const [edit, setEdit] = useState(false);

  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setEdit(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function handleSave(value) {
    if (changeField && !multiple) {
      changeField(index, value);
    }
    if (changeField && multiple) {
      changeField(index, value);
    }
    if (setNameAndEmail) {
      setNameAndEmail(value?.value);
    }
  }

  return (
    <tr className={styles.customField} ref={ref} onClick={() => setEdit(true)}>
      {/* <td className={styles.customField} onClick={() => setEdit(true)}> */}
      {/* <FlexRow start gap=".5rem"> */}
      <td
        className={styles.label6}
        style={{
          borderTopLeftRadius: ".25rem",
          borderBottomLeftRadius: ".25rem",
        }}
      >
        {/* <i className={`bi-${icon}`}></i> */}
        <Icon iconName={icon} tooltip={description}></Icon>
      </td>
      <td
        className={styles.label6}
        style={{ display: "flex", alignItems: "center", gap: ".25rem" }}
      >
        {label}:
        {status && (
          <div
            className={`${styles.status} ${
              (status === "verified" || "delivered" || "Delivered") &&
              styles.green
            } ${status === "warning" && styles.yellow} ${
              status === "bad" && styles.red
            }`}
          ></div>
        )}
      </td>
      {/* <td className={styles.text_3}>
        {multiple ? (
          <>
            {value?.map((a, i) => (
              <React.Fragment key={i}>
                {a}
                {i === value?.length - 1 ? "" : `${delimiter} `}
              </React.Fragment>
            ))}
          </>
        ) : (
          value
        )}
      </td> */}
      <Cell
        head={column?.field}
        i={0}
        initvalue={value ? value : ""}
        className={styles.text_3}
        triggerEdit={edit}
        editClassName={styles.editThem}
        onSaveEditableCell={handleSave}
      ></Cell>

      {/* </FlexRow> */}
      <td className={styles.editcon}>
        <i className="bi-pencil"></i>
      </td>
      {/* </td> */}
      {/* {edit && (
        <div className={styles.edit}>
          {changeField && (
            <SelectField
              label={label}
              value={
                multiple
                  ? value?.map((a) => {
                      return { value: a, label: a };
                    })
                  : { value: value, label: value }
              }
              onChange={(val) => {
                if (changeField && !multiple) {
                  changeField(index, val.value);
                }
                if (changeField && multiple) {
                  changeField(index, val);
                }
                if (setChanged) {
                  setChanged(true);
                }
              }}
              disable={disabled}
              style={{ backgroundColor: "white" }}
              // icon={contact?.salesforceId ? "bi-cloud" : ""}
              shadow
              selectMultiple={multiple}
              search={multiple || select}
              options={options.map((o) => {
                return { label: o, value: o };
              })}
              labelIcon={icon}
              pushDropdown
            ></SelectField>
          )}
          {setNameAndEmail && (
            <CombinedInput
              label={label}
              value={value}
              onChange={(val) => {
                if (setNameAndEmail) {
                  setNameAndEmail(val);
                }
              }}
              disable={disabled}
              style={{ backgroundColor: "white" }}
              // icon={contact?.salesforceId ? "bi-cloud" : ""}
              shadow
            ></CombinedInput>
          )}
        </div>
      )} */}
    </tr>
  );
}
{
  /* <CombinedInput
                  label={column.field.name}
                  value={value}
                  onChange={(val) => changeField(i, val)}
                  options={column.field.properties}
                  selectMultiple={multiple}
                  select={!multiple}
                  search
                  disable={disabled}
                  style={{ backgroundColor: "white" }}
                  shadow
                ></CombinedInput> */
}
