//External
import React, { useState, useEffect } from "react";
//Internal
import { useLoginRequest } from "api/resources/authentication";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import styles from "./Login.module.scss";
import reactionLogo from "assets/images/reactionlogonew.png";
import { Header } from "components/layouts";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { MFA } from "pages/register/MFA";
import { Footer } from "App";
import ReCAPTCHA from "react-google-recaptcha";

/**
 * The login functionality to login the user in
 * @param {Function} setToken sets the current token when called
 * @returns {token} the token that has been set
 */

export function Login({ setToken }) {
  const loginUserRequest = useLoginRequest();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [verifyMFA, setVerifyMFA] = useState(false);
  const [user, setUser] = useState(null);
  const [working, setWorking] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);

  const handleSubmit = (e) => {
    if (!working) {
      if (email === "") {
        setErrMsg("Email required");
      } else if (password === "") {
        setErrMsg("Password required");
      } else {
        setWorking(true);
        loginUserRequest.mutate(
          {
            email: email,
            password: password,
            captchaToken: captchaToken,
          },
          {
            onSuccess: (data) => {
              if (data?.login?.success) {
                setWorking(false);
                if (data.login.needsMFA) {
                  setVerifyMFA(true);
                  setUser(data.login.user);
                } else {
                  onCleared(data.login.user.id);
                  // window.location.reload();
                }
              }
            },
            onError: (err) => {
              let msg = err.response.errors[0].message;
              setErrMsg(msg);
              setWorking(false);
              setCaptchaToken(false);
              setResetCaptcha(true);
            },
          }
        );
      }
    }
  };

  const navigate = useNavigate();
  const handleRequestPasswordChange = (e) => {
    navigate("/forgot-password");
  };

  function onCleared(userId) {
    setToken(userId);
    navigate("/");
    localStorage.setItem("activepage", "0");
  }

  const [passwordType, setPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  useEffect(() => {
    if (resetCaptcha) {
      setResetCaptcha(false);
    }
  }, [resetCaptcha]);

  return (
    <div className={styles.page}>
      <FlexRow
        justify={"center"}
        style={{ height: "100%", minHeight: "fit-content" }}
        align={"flex-start"}
      >
        {verifyMFA && <MFA user={user} onCleared={onCleared}></MFA>}
        {!verifyMFA && (
          <>
            {/* <img
                  src={MainPageImage}
                  alt="Description"
                  className={styles.mainPageImage}
                ></img> */}
            <div className={styles.formContainer}>
              <div className={styles.imageContainer}>
                <img src={reactionLogo} />
              </div>
              <Header title="Welcome!"></Header>
              <span>Sign into your account below</span>
              {errMsg && (
                <div className={`${styles.text_2} ${styles.red}`}>{errMsg}</div>
              )}
              <div className={styles.form}>
                <div>
                  <TextFieldSimple
                    type="email"
                    onChange={setEmail}
                    placeholder="Email"
                    valid={
                      errMsg === "Invalid email" || errMsg === "Email required"
                        ? false
                        : true
                    }
                    invalidMessage={errMsg}
                    email
                    icon="at"
                  />
                </div>
                <div>
                  <TextFieldSimple
                    type={passwordType?.type}
                    finalIcon={passwordType?.icon}
                    onClickFinalIcon={() =>
                      setPasswordType({
                        type:
                          passwordType?.type === "password"
                            ? "text"
                            : "password",
                        icon:
                          passwordType?.type === "password"
                            ? "eye"
                            : "eye-slash",
                      })
                    }
                    onChange={setPassword}
                    placeholder="Password"
                    password
                    valid={
                      errMsg == "Invalid password" ||
                      errMsg === "Password required"
                        ? false
                        : true
                    }
                    invalidMessage={errMsg}
                    // onEnter={handleSubmit}
                    icon="key"
                  />
                </div>
              </div>

              {!resetCaptcha && (
                <FlexRow justify={"center"}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(token) => setCaptchaToken(token)}
                    className={styles.captcha}
                  />
                </FlexRow>
              )}

              <div className={styles.buttonContainer}>
                <Button
                  blue
                  onClick={handleSubmit}
                  type="submit"
                  shadow
                  height={2.6}
                 
                  style={{
                    flexgrow: 1,

                    // window.innerWidth < 512
                    //     ? "100%"
                    //     : "",
                  }}
                >
                  {!working ? (
                    "Sign In"
                  ) : (
                    <>
                      Logging In
                      <span className={styles.dots}>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </span>
                    </>
                  )}
                </Button>
              </div>

              <Button link={true} onClick={handleRequestPasswordChange}>
                Forgot Password?
              </Button>

              <>
                <HorizontalBar
                  height={2}
                  backgroundColor="#E9E9E9"
                  width="100%"
                  style={{margin: "2rem 0"}}
                />
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  Don&apos;t have an account?
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      onClick={() => navigate("/register")}
                      type="submit"
                      shadow
                      seafoam
                    >
                      Create Account
                    </Button>
                    or{" "}
                    <Button
                      onClick={() => navigate("/register/join-organization")}
                      type="submit"
                      shadow
                      seafoam
                    >
                      Join Organization
                    </Button>
                  </div>
                </div>
                <div
                  className={styles.register}
                  style={{ marginTop: "1rem", textAlign: "center" }}
                >
                  Request a demo or learn more at{" "}
                  <a href="https://reactiondata.com" className={styles.aLink}>
                    {" "}
                    reactiondata.com
                  </a>
                  .
                </div>
              </>
            </div>
          </>
        )}
      </FlexRow>

      <Footer></Footer>
    </div>
  );
}

export const useToken = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
};
