/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2025 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

import { useGqlQuery, useGqlMutation } from "api/Api";

export const useCheckOrgName = () => {
  const mutation = gql`
    mutation Mutation($name: String!) {
      exists: checkOrgName(name: $name)
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchMyOrg = () => {
  const query = gql`
    query MyOrganization {
      organization: myOrganization {
        id
        name
        custom_field {
          id
          properties
          name
          displayName
          delimiter
          generic
        }
      }
    }
  `;

  return useGqlQuery(["orgDash"], query, {});
};

export const useCheckOrgDash = () => {
  const query = gql`
    query MyOrganization {
      response: myOrganization {
        dashboard {
          id
        }
        showDash
        dashboardId
      }
    }
  `;

  return useGqlQuery(["dash for org"], query, {});
};

export const useFetchCurrOrgsFields = () => {
  const query = gql`
    query Query {
      getCurrOrgFields {
        delimiter
        id
        name
        properties
        displayName
        filterable
        salesforceColumn
        icon
        multiple
        unique
        required
        length
        description
        dataType
        generic
        enabled
        index
        editable
        falseValue
        trueValue
        max
        min
        attribute {
          id
          name
        }
      }
    }
  `;

  return useGqlQuery(["orgFields"], query, {});
};

export const useFetchOrganizationCustomFields = () => {
  const query = gql`
    query Query {
      response: fetchOrganizationCustomFields {
        fields {
          id
          name
          displayName
          icon
          dataType
          required
          unique
        }
        count
        error
      }
    }
  `;

  return useGqlQuery(["Fetch Organization Custom Fields"], query, {});
};

export const useFetchCustomFields = () => {
  const query = gql`
    query FetchCustomFields {
      fields: fetchCustomFields {
        id
        name
        displayName
        generic
        filterable
        dataType
      }
    }
  `;

  return useGqlQuery(["all custom fields"], query, {});
};

export const useFetchEmailWorthyCustomFields = () => {
  const query = gql`
    query EmailWorthyCustomFields {
      fields: emailWorthyCustomFields {
        id
        name
        displayName
        generic
        multiple
        attribute {
          name
        }
      }
    }
  `;

  return useGqlQuery(["email worthy custom fields"], query, {});
};

export const useFetchReportWorthyCustomFields = () => {
  const query = gql`
    query ReportWorthyCustomFields {
      fields: reportWorthyCustomFields {
        id
        name
        displayName
        generic
        filterable
      }
    }
  `;

  return useGqlQuery(["report worthy custom fields"], query, {});
};

export const useFetchFilterableCustomFields = () => {
  const query = gql`
    query FilterableCustomFields {
      fields: filterableCustomFields {
        id
        name
        displayName
      }
    }
  `;

  return useGqlQuery(["filterable custom fields"], query, {});
};

export const useFetchFilterableFieldAtts = () => {
  const query = gql`
    query FilterableFieldAttributes {
      fields: filterableFieldAttributes {
        id
        name
        displayName
        attribute {
          name
        }
      }
    }
  `;

  return useGqlQuery(["filterable column atts"], query, {});
};

export const useFetchCurrOrgsFieldsMut = () => {
  const mutation = gql`
    mutation Mutation {
      fields: getCurrOrgFieldsMut {
        delimiter
        id
        name
        properties
        displayName
        filterable
        salesforceColumn
        icon
        multiple
        unique
        required
        length
        description
        dataType
        generic
        enabled
        index
        editable
        falseValue
        trueValue
        max
        min
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchUnassignedOrgsFields = (search) => {
  const query = gql`
    query Query {
      fields: searchUnassignedOrgFields(search: "${search}") {
        delimiter
        id
        name
        displayName
        filterable
        salesforceColumn
        icon
        multiple
        unique
        required
        length
        description
        dataType
        generic
        enabled
        index
        editable
        falseValue
        trueValue
        max
        min
        customFieldGroupId
        attribute {
          id
          name
        }
      }
    }
  `;

  return useGqlQuery(["searchUnassignedOrgsFields"], query, {});
};

export const useSearchOrgGroups = (search, organizationId) => {
  const query = gql`
    query Query {
      groups: searchOrgGroups(search: "${search}") {
        id
        name
        position
        enabled
        icon
        editable
        customFieldGroupRow {
          id
          position
          customFieldGroupRowField {
            id
            position
            customField {
              id
              name
              displayName
              generic
              dataType
              min
              max
              falseValue
              trueValue
              enabled
              icon
              length
              unique
              required
              multiple
              generic
              delimiter
              editable
              defaultValue
            }
          }
        }
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
  };

  return useGqlQuery(
    ["OrgGroups:" + organizationId],
    query,
    undefined,
    options
  );
};

export const useSearchEnabledOrgGroups = (search, organizationId) => {
  const query = gql`
    query Query {
      groups: searchEnabledOrgGroups(search: "${search}") {
        id
        name
        position
        enabled
        icon
        editable
        customFieldGroupRow {
          id
          position
          customFieldGroupRowField {
            id
            position
            customField {
              id
              name
              displayName
              generic
              dataType
              min
              max
              falseValue
              trueValue
              enabled
              icon
              length
              unique
              required
              multiple
              generic
              delimiter
              editable
            }
          }
        }
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
  };

  return useGqlQuery(
    ["OrgGroups:" + organizationId],
    query,
    undefined,
    options
  );
};

export const useSearchCurrOrgsFields = (search) => {
  const query = gql`
    query {
      fields: searchCurrOrgFields(search: "${search}") {
        delimiter
        id
        name
        properties
        displayName
        filterable
        salesforceColumn
        attribute {
          id
          name
        }
      }
    }
  `;

  return useGqlQuery(["search_org_fields"], query, {});
};

export const useFetchAnOrgsFields = (id) => {
  const query = gql`
    query {
      getOrgFields(id: "${id}") {
        id
        properties
        name
        displayName
        delimiter
      }
    }
  `;

  return useGqlQuery([id + " customFields"], query, {});
};

export const useFetchAnOrgsFieldsMutation = () => {
  const mutation = gql`
    mutation (id: String!) {
      getOrgFieldsMut(id: $id) {
        id
        name
        displayName
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useFetchOrgFieldsNoProps = (id) => {
  const query = gql`
    query {
      getOrgFields(id: "${id}") {
        id
        name
        displayName
        salesforceColumn
      }
    }
  `;

  return useGqlQuery([id + " customFieldsNoProp"], query, {});
};

export const useIsHealthCatalyst = () => {
  const query = gql`
    query Query {
      isHealthCatalyst
    }
  `;

  return useGqlQuery(["isHC"], query, {});
};

export const useGetAttCounts = (id) => {
  const query = gql`
    query {
      count: getAttributeCounts(attributeId: "${id}")
    }
  `;

  return useGqlQuery("attCount: " + id, query, {});
};

export const useUpdateCustomField = () => {
  const mutation = gql`
    mutation UpdateCustomField($data: CustomFieldInput!, $id: String!) {
      updateCustomField(data: $data, id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useDeleteCustomField = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      deleteCustomField(id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useCreateCustomField = () => {
  const mutation = gql`
    mutation Mutation($data: CustomFieldInput!) {
      createCustomField(data: $data) {
        id
        name
        properties
        displayName
        delimiter
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchCustomField = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      field: fetchCustomField(id: $id) {
        id
        name
        displayName
        delimiter
        filterable
        salesforceColumn
        defaultValue
        unique
        description
        length
        dataType
        multiple
        icon
        generic
        trueValue
        falseValue
        max
        min
        required
        attribute {
          id
          name
        }
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useGetCustomField = (id) => {
  const query = gql`
    query Query($id: String!) {
      field: getCustomField(id: $id) {
        id
        name
        displayName
        delimiter
        filterable
        defaultValue
        unique
        description
        length
        dataType
        multiple
        icon
        generic
        trueValue
        falseValue
        max
        min
        required
      }
    }
  `;

  return useGqlQuery(["Get Custom Field: ", id], query, { id: id });
};

export const useFetchCustomFieldNoAtt = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      field: fetchCustomField(id: $id) {
        id
        name
        displayName
        delimiter
        filterable
        salesforceColumn
        defaultValue
        unique
        description
        length
        dataType
        multiple
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("orgFields");
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchOrganizations = (search) => {
  const query = gql`
    query {
      organizations: getOrganizations(search: "${search}") {
        name
        displayName
        timezone
        id
        image {
          isLogo
          imageURL
        }
      }
    }
  `;

  return useGqlQuery(["fetchmyorgs"], query, {});
};

export const useFetchAllOrganizations = (searchString, page, perPage, sort) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: SortSelectionDelivery!
    ) {
      object: getAllOrganizations(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        organizations {
          name
          displayName
          timezone
          id
          customFields
          defaultLocale
          sendGridName
          createdAt
          subdomain
          showDash
          dashboardId
          address1
          address2
          country
          city
          state
          postalCode
          billingAddress1
          billingAddress2
          billingCountry
          billingCity
          billingState
          billingPostalCode
          invoiceAddress1
          invoiceAddress2
          invoiceCountry
          invoiceCity
          invoiceState
          invoicePostalCode
          usersAllowed
          contactsAllowed
          reportsAllowed
          responsesAllowed
          surveysAllowed
          isLiscense
          plan
          planFrequency
          planPrice
          isLiscense
        }
        count
      }
    }
  `;

  return useGqlQuery(["reportOrganization"], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchOrganization = (id) => {
  const query = gql`
    query {
      organization(id: "${id}") {
        name
        displayName
        timezone
        id
        customFields
        defaultLocale
        sendGridName
        createdAt
        subdomain
        showDash
        dashboardId
        address1
        address2
        country
        city
        state
        postalCode
        billingAddress1
        billingAddress2
        billingCountry
        billingCity
        billingState
        billingPostalCode
        invoiceAddress1
        invoiceAddress2
        invoiceCountry
        invoiceCity
        invoiceState
        invoicePostalCode
        usersAllowed
        contactsAllowed
        reportsAllowed
        responsesAllowed
        surveysAllowed
        adminsAllowed
        plan
        planFrequency
        planPrice
        primaryColor
        secondaryColor
        isLiscense
        requireMFA

        project {
          id
          status
        }
        custom_field {
          id
          properties
          name
          displayName
          delimiter
          filterable
        }
      }
    }
  `;

  return useGqlQuery(["fetchmyorg:" + id], query, {});
};

export const useFetchOrgMFA = (id) => {
  const query = gql`
    query {
      organization(id: "${id}") {
        id
        name
        requireMFA
      }
    }
  `;

  return useGqlQuery(["orgMFA:" + id], query, {});
};

export const useFetchOrganizationLinkedAccounts = (id) => {
  const query = gql`
    query {
      organization: organizationWithLinkedAccounts(id: "${id}") {
        name
        displayName
        timezone
        id
        customFields
        defaultLocale
        sendGridName
        createdAt
        subdomain
        showDash
        dashboardId
        address1
        address2
        country
        city
        state
        postalCode
        billingAddress1
        billingAddress2
        billingCountry
        billingCity
        billingState
        billingPostalCode
        invoiceAddress1
        invoiceAddress2
        invoiceCountry
        invoiceCity
        invoiceState
        invoicePostalCode
        usersAllowed
        contactsAllowed
        reportsAllowed
        responsesAllowed
        surveysAllowed
        adminsAllowed
        plan
        planFrequency
        planPrice
        primaryColor
        secondaryColor
        isLiscense
        sfClientId
        sfClientSecret
        sfConnectedAccount
        sfSyncType
        sfSyncPriority
        project {
          id
          status
        }
        custom_field {
          id
          properties
          name
          displayName
          delimiter
          filterable
          generic
          unique
          required
          description
          multiple
          dataType
          icon
          editable
          index
          enabled
          trueValue
          falseValue
          min
          max
        }
        linkedAccounts {
          id
          accountType
          syncing
          active
          instanceUrl
          username
        }
      }
    }
  `;

  return useGqlQuery(["fetchmyorgLinkedAccounts:" + id], query, {});
};

export const useFetchObjectConnections = (id, updater) => {
  const query = gql`
    query {
      objects: fetchObjectConnections(linkedAccountId: "${id}", updater: ${updater}) {
        id
        externalName
        internalName
        externalField
        internalField
        matchFields
        updateFields
        updateType
        sendExistingData
        createNewData
        connectionStatus
        description
      }
    }
  `;

  return useGqlQuery(
    ["fetchmylinkedaccountconnections:" + id + updater],
    query,
    {}
  );
};

export const useFetchContactCount = (id) => {
  const query = gql`
    query Query {
      contactCountByOrgId(id: "${id}")
      audienceCountByOrgId(id: "${id}")
      projectCountByOrgId(id: "${id}")
      userCountByOrgId(id: "${id}")
    }
  `;

  return useGqlQuery(["orgDashCounts:" + id], query, {});
};

export const useFetchObjectConnection = (id) => {
  const query = gql`
    query {
      object: fetchObjectConnection(id: "${id}") {
        id
        externalName
        internalName
        externalField
        internalField
        matchFields
        updateFields
        updateType
        sendExistingData
        createNewData
        connectionStatus
        updater
        description
      }
    }
  `;

  return useGqlQuery(["fetchmylinkedaccountconnection:" + id], query, {});
};

export const useCreateObjectConnection = () => {
  const mutation = gql`
    mutation Mutation($data: ObjectConnectionInput!) {
      createObjectConnection(data: $data)
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
  };
  return useGqlMutation(mutation, options);
};

export const useUpdateObjectConnection = () => {
  const mutation = gql`
    mutation Mutation($data: ObjectConnectionInput!, $id: String!) {
      updateObjectConnection(data: $data, id: $id)
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
  };
  return useGqlMutation(mutation, options);
};

export const useUpdateLinkedAccounts = () => {
  const mutation = gql`
    mutation Mutation($data: LinkedAccountInput!, $id: String!) {
      updateLinkedAccount(data: $data, id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
      queryClient.invalidateQueries("dash for org");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useFetchOrganizationSideMenu = (id) => {
  const query = gql`
    query {
      organization(id: "${id}") {
        name
        displayName
      }
    }
  `;

  return useGqlQuery(["fetchmyorgsidemenu"], query, {});
};

export const useGetOrgName = () => {
  const query = gql`
    query MyOrganization {
      myOrganization {
        id
        name
        displayName
      }
    }
  `;

  return useGqlQuery(["orgName"], query, {});
};

export const useFetchSurveyTemplates = () => {
  const query = gql`
    query GetSurveyTemplatesByOrg {
      getSurveyTemplatesByOrg {
        projectId
        organizationId
        name
        description
        id
        imageURL
        category
        type
      }
    }
  `;

  return useGqlQuery(["surveyTemplates"], query, {});
};

export const useUpdateOrgSettings = () => {
  const mutation = gql`
    mutation Mutation(
      $data: OrganizationInput!
      $updateOrganizationId: String!
    ) {
      updateOrganization(data: $data, id: $updateOrganizationId) {
        name
        displayName
        timezone
        showDash
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
      queryClient.invalidateQueries("dash for org");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useVerifyClient = () => {
  const mutation = gql`
    mutation Mutation($clientId: String!, $clientSecret: String!) {
      success: verifyClient(clientId: $clientId, clientSecret: $clientSecret)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
      queryClient.invalidateQueries("dash for org");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useUpdateOrg = () => {
  const mutation = gql`
    mutation Mutation($data: OrganizationInput!) {
      updateOrganizationSimple(data: $data)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useCreateOrgainization = () => {
  const mutation = gql`
    mutation Mutation(
      $data: OrganizationInput!
      $users: String!
      $meAdmin: Boolean!
    ) {
      createOrganization(data: $data, users: $users, meAdmin: $meAdmin) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("createOrganization");
    },
  };
  return useGqlMutation(mutation, [], options);
};

export const useJoinOrgainization = () => {
  const mutation = gql`
    mutation Mutation($joinCode: String!) {
      success: joinOrganization(joinCode: $joinCode)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("joinOrganization");
    },
  };
  return useGqlMutation(mutation, [], options);
};

export const useJoinOrgainizationExternal = () => {
  const mutation = gql`
    mutation Mutation(
      $joinCode: String!
      $data: registerInput!
      $captchaToken: String!
      $newAccount: Boolean!
    ) {
      data: joinOrganizationExternal(
        joinCode: $joinCode
        data: $data
        captchaToken: $captchaToken
        newAccount: $newAccount
      ) {
        email
        id
        token
        success
        description
      }
    }
  `;

  return useGqlMutation(mutation, [], {});
};

export const useCreateOrgDash = () => {
  const mutation = gql`
    mutation CreateOrgDash($orgId: String!) {
      createOrgDash(orgId: $orgId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
      queryClient.invalidateQueries("dash for org");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useGetOrgLogo = (key = "OrganizationLogo") => {
  const query = gql`
    query getOrgLogo {
      getOrgLogo {
        id
        imageURL
        position
        scale
        name
        about
      }
    }
  `;

  return useGqlQuery([key], query, {});
};

export const useGetOrgLogoUnique = (orgId, index) => {
  const query = gql`
  query {
    getLogoLinkForOrg(orgId: "${orgId}") {
      id
      imageURL
      position
      scale
      name 
      about
    }
  }
  
  `;

  return useGqlQuery(["logo, " + orgId + " " + index], query, {});
};

export const useRefreshOrgCustomFields = () => {
  const mutation = gql`
    mutation RefreshCustomFields($orgId: String!) {
      refreshCustomFields(orgId: $orgId)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("fetchmyorg");
    },
  };
  return useGqlMutation(mutation, options);
};

export const useAddAttribute = () => {
  const mutation = gql`
    mutation Mutation($customFieldId: String!, $name: String!) {
      added: addAttribute(customFieldId: $customFieldId, name: $name) {
        id
        name
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useDeleteAttribute = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      deleted: deleteAttribute(id: $id)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useUpdateAttName = () => {
  const mutation = gql`
    mutation Mutation($name: String!, $id: String!) {
      updated: updateAttName(name: $name, id: $id)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useUpdateAtts = () => {
  const mutation = gql`
    mutation Mutation($attributes: [CustomFieldInput!]!) {
      updated: updateAtt(attributes: $attributes)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useCreateUser = () => {
  const mutation = gql`
    mutation Mutation($data: CreateUserInput!) {
      createUser(data: $data) {
        id
      }
    }
  `;

  return useGqlMutation(mutation);
};
