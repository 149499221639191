/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import styles from "./Table.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { CSVLink } from "react-csv";
import {
  forEach,
  getReversed,
  getSortedArray,
} from "assets/functions/ArrayFunctions";
import "components/inputs/input_fields/reactgrid/styles.scss";
import "@fortune-sheet/react/dist/index.css";
import SimpleBar from "simplebar-react";
import { Loading } from "components/Loading/Loading";
import Filter from "../../Settings/Filter/Filter";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Export } from "./export/Export";
import { read, writeFile, utils } from "xlsx";
import { useEmailTableFile } from "api/resources/projects/visualizations";

/**
 * A Table that is used to display data
 * @param {type} initHeaders something
 * @param {type} createTitle something
 * @param {type} createMethod something
 * @param {type} data something
 * @param {type} onRowClick something
 * @param {type} setPageNumber something
 * @param {type} pageNumber something
 * @param {type} bottomLeft something
 * @param {type} bottomRight something
 * @param {boolean} noFilter no filter button
 * @param {boolean} noSearch no search bar
 * @param {type} maxPage something
 * @returns {React.ReactElement} a Table component
 */

export function DTable({
  headers,
  setHeaders,
  data,
  onRowClick,
  survey,
  filters,
  setFilters,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [topOfList, setTopOfList] = useState();

  useEffect(() => {
    if (dataArray) {
      let list = dataArray.slice(
        0,
        topOfList && topOfList.length > 100 ? topOfList.length : 100
      );
      setTopOfList(list);
    }
  }, [dataArray]);

  function onScroll(e) {
    if (topOfList?.length < dataArray?.length) {
      var scrollableContainer = e.target;
      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 100) {
        let nextlength = topOfList?.length + 100;
        setTopOfList(dataArray.slice(0, nextlength));
      }
    }
  }

  const [searchValue, setSearchValue] = useState("");
  const [editHeaders, setEditHeaders] = useState(false);
  const [searching, setSearching] = useState(false);
  const [sort, setSort] = useState([{ col: "timeTaken", sort: "down" }]);

  const [showOptions, setShowOptions] = useState(false);
  const [exporting, setExporting] = useState(false);

  const dotsRef = useRef();
  const tableRef = useRef();

  const emailFile = useEmailTableFile();

  const handleClickOutsideDots = (event) => {
    if (dotsRef.current && !dotsRef.current.contains(event.target)) {
      setEditHeaders(false);
      document.removeEventListener("click", handleClickOutsideDots, true);
      event.stopPropagation();
    }
  };

  function setUpDots() {
    setEditHeaders(!editHeaders);
    setTimeout(
      () => document.addEventListener("click", handleClickOutsideDots, true),
      200
    );
  }
  const gray110 = "#616565";
  const gray100 = "#d8d9d9";

  function toggleHeader(head) {
    let copy = [...headers];
    let ind = copy.findIndex((h) => h.acc === head.acc);
    copy[ind].show = !copy[ind].show;
    setHeaders(copy);
  }

  function searchFor(string) {
    if (string === "") {
      if (data) {
        return [...data];
      } else {
        return [];
      }
    }

    string = string.toString().toLowerCase();
    let applicable = [];
    let left = [];
    let nonApplicable = [];

    forEach(data, (entry) => {
      let contains = false;
      for (let col of headers) {
        if (col.show) {
          if (entry[col.acc]) {
            let substring = entry[col.acc]
              .toString()
              .toLowerCase()
              .substring(0, string?.length);
            if (substring === string) {
              contains = true;
              break;
            }
          }
        }
      }

      contains ? applicable.push(entry) : left.push(entry);
    });

    forEach(left, (entry) => {
      let contains = false;
      for (let col of headers) {
        if (col.show) {
          if (entry[col.acc]) {
            if (entry[col.acc].toString().toLowerCase().includes(string)) {
              contains = true;
              break;
            }
          }
        }
      }
      contains ? applicable.push(entry) : nonApplicable.push(entry);
    });

    return applicable;
  }

  function sortUp(dataCopy, col) {
    if (col === "timeTaken") {
      dataCopy.sort((a, b) => {
        if (a.startedAt) {
          if (b.startedAt) {
            let aDate = new Date(a.startedAt);
            let bDate = new Date(b.startedAt);
            return aDate.getTime() - bDate.getTime();
          }
          return -1;
        }
        if (b.startedAt) {
          return 1;
        }
        return 0;
      });
    } else {
      dataCopy.sort((a, b) => {
        a = a[col];
        b = b[col];
        if (!a && a !== 0) {
          // For undefined values
          a = "";
        }
        if (!b && b !== 0) {
          b = "";
        }

        if (typeof a === "number" && typeof b === "number") {
          return b - a;
        }
        if (typeof a === "string" && typeof b === "string") {
          if (!a && b) {
            // empty strings at the top
            return -1;
          }
          if (a && !b) {
            // empty strings at the top
            return 1;
          }

          if (a.toLowerCase() < b?.toLowerCase()) {
            return 1;
          }
          if (a.toLowerCase() > b?.toLowerCase()) {
            return -1;
          }
          return 0;
        }

        if (typeof a === "number") {
          // numbers before letters
          return -1;
        }
        if (typeof b === "number") {
          return 1;
        }

        return 0;
      });
    }
  }

  function sortDown(dataCopy, col) {
    if (col === "timeTaken") {
      dataCopy.sort((a, b) => {
        if (a.startedAt) {
          if (b.startedAt) {
            let aDate = new Date(a.startedAt);
            let bDate = new Date(b.startedAt);
            return bDate.getTime() - aDate.getTime();
          }
          return -1;
        }
        if (b.startedAt) {
          return 1;
        }
        return 0;
      });
    } else {
      dataCopy.sort((a, b) => {
        a = a[col];
        b = b[col];
        if (!a && a !== 0) {
          a = "";
        }
        if (!b && b !== 0) {
          b = "";
        }

        if (typeof a === "number" && typeof b === "number") {
          return a - b;
        }

        if (typeof a === "string" && typeof b === "string") {
          if (!a && b) {
            // empty strings at the bottom
            return 1;
          }
          if (a && !b) {
            // empty strings at the bottom
            return -1;
          }

          if (a.toLowerCase() < b?.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b?.toLowerCase()) {
            return 1;
          }
          return 0;
        }

        if (typeof a === "number") {
          // numbers before letters
          return -1;
        }
        if (typeof b === "number") {
          return 1;
        }
        return 0;
      });
    }
  }

  function sortBy(col) {
    let dataCopy = [];

    if (searchValue) {
      dataCopy = searchFor(searchValue);
    } else {
      dataCopy = [...data];
    }

    let sortCopy = [...sort];
    let index = sortCopy.findIndex((s) => s.col === col);
    if (index >= 0) {
      if (sortCopy[index].sort === "up") {
        sortCopy.splice(index, 1);
      } else {
        sortCopy[index].sort = "up";
      }
    } else {
      sortCopy.push({
        col: col,
        sort: "down",
      });
    }

    for (let sorting of getReversed(sortCopy)) {
      if (sorting.sort === "down") {
        sortDown(dataCopy, sorting.col, sortCopy);
      } else if (sorting.sort === "up") {
        sortUp(dataCopy, sorting.col, sortCopy);
      }
    }

    setDataArray(dataCopy);
    setSort(sortCopy);
  }

  function searchAndSort() {
    let array = searchFor(searchValue);
    for (let sorting of getReversed(sort)) {
      if (sorting.sort === "down") {
        sortDown(array, sorting.col, sort);
      } else if (sorting.sort === "up") {
        sortUp(array, sorting.col, sort);
      }
    }

    return array;
  }

  useEffect(() => {
    let theData = searchAndSort();
    setDataArray(theData);
    setTopOfList(null);
  }, [data, searchValue]);

  function getSortCaret(col) {
    let index = sort.findIndex((s) => s.col === col.acc);
    const has = index >= 0;
    let color = has ? gray110 : gray100;
    let icon = has
      ? sort[index].sort === "down"
        ? "bi-caret-down-fill"
        : "bi-caret-up-fill"
      : "bi-dash";

    return (
      <i
        className={`bi ${icon} ${styles.sortTriangle} `}
        style={{ color: color }}
      >
        {has && sort?.length > 1 ? index + 1 : ""}
      </i>
    );
  }

  function normalizeHeader(header) {
    if (!header) {
      return "";
    }
    const iterator = header[Symbol.iterator]();
    let nextChar = iterator.next();
    let breaks = [];
    for (let i = 0; i < header.length; i++) {
      if (i != 0 && /^[A-Z]+$/.test(nextChar)) {
        // (x >= "A" && x <= "Z")
        breaks.push(i);
      }
      nextChar = iterator.next();
    }
    for (let i = 0; i < breaks.length; i++) {
      let first = header.substring(0, breaks[i] + i);
      let second = header.substring(breaks[i] + i);
      header = first + " " + second;
    }

    header = header.charAt(0).toUpperCase() + header.slice(1);

    //trim it
    if (header.length < 26) {
      return header;
    }
    let text = header.substring(0, 25);
    text += "...";
    return text;
  }

  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [shadow, setShadow] = useState(false);

  useLayoutEffect(() => {
    if (dataArray?.length > 0) {
      let element = tableRef.current;
      element.addEventListener("scroll", function () {
        if (element.scrollTop < 1) {
          //user is at the top of the page; no need to show the back to top button
          setShadow(false);
        } else if (element.scrollTop > 0) {
          setShadow(true);
        }
      });
    }
  }, []);

  function getSortedHeaders() {
    return getSortedArray(headers, (a, b) => {
      if (a.show) {
        if (b.show) return 0;
        return -1;
      }
      if (b.show) return 1;
      return 0;
    });
  }

  function commify(value) {
    if (typeof value === "string") {
      if (value.includes(",")) {
        value = `"${value}"`;
      }
      if (value.includes("\n")) {
        value = value.replaceAll("\n", " ");
      }
    }
    return value;
  }

  function getArrayOfArrays(forCSV = true) {
    let headerRow = headers
      .filter((h) => h.show)
      .map((h) => (forCSV ? commify(h.label) : h.label));
    let rows = [headerRow];
    for (let entry of dataArray) {
      let row = [];
      for (let col of headers) {
        if (col.show) {
          let val = entry[col.acc] ? entry[col.acc] : "";
          if (typeof val === "number") val = val.toString();
          row.push(forCSV ? commify(val) : val);
        }
      }
      rows.push(row);
    }
    return rows;
  }

  function onExport(settings) {
    setExporting(true);

    if (settings.locally) {
      if (settings.fileType === "csv") {
        const rows = getArrayOfArrays();
        const csvString = rows.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csvString], {
          type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", settings.name + ".csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        const rows = getArrayOfArrays(false);
        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet(rows);
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
        writeFile(workbook, settings.name + ".xlsx");
      }

      if (!settings.email) {
        setExporting(false);
        setShowOptions(false);
      }
    }

    if (settings.email) {
      const rows = getArrayOfArrays();
      const csvString = rows.map((row) => row.join(",")).join("\n");

      emailFile.mutate(
        {
          fileName: settings.name,
          fileType: settings.fileType,
          csvString: csvString,
        },
        {
          onSuccess: (data) => {
            setExporting(false);
            setShowOptions(false);
          },

          onError: (err) => {
            console.log(err);
            setExporting(false);
          },
        }
      );
    }
  }

  const [showFilters, setShowFilters] = useState(false);

  const filtersRef = useRef(null);

  const clickOutFilters = (event) => {
    if (!filtersRef.current || !filtersRef.current.contains(event.target)) {
      setShowFilters(false);
      document.removeEventListener("click", clickOutFilters, true);
    }
  };

  function setUpFilters() {
    document.addEventListener("click", clickOutFilters, true);
    setShowFilters(true);
  }

  const optionsRef = useRef(null);

  const clickOutOptions = (event) => {
    if (!optionsRef.current || !optionsRef.current.contains(event.target)) {
      setShowOptions(false);
      document.removeEventListener("click", clickOutOptions, true);
    }
  };

  function setUpOptions() {
    document.addEventListener("click", clickOutOptions, true);
    setShowOptions(true);
  }

  return (
    <div className={styles.container}>
      {/* <div className={styles.tableHeader}>
        <span>Rows: {dataArray.length}</span>

        <div className={styles.download}>
          <CSVLink data={csvData ? csvData : ""} filename={csvName}>
            <div className={styles.csvDownload}>
              <i className="bi bi-cloud-download"></i> Download
            </div>
          </CSVLink>
        </div>
      </div> */}

      <div className={styles.tableHeader}>
        <FlexRow className={styles.title} style={{ whiteSpace: "nowrap" }}>
          <>{survey.name}</>

          {data && (
            <>
              <HorizontalBar rotate width={"2px"} height={"60%"} />
              <FlexRow gap={".5rem"}>
                <div className={styles.rows}>Rows:</div>
                <div className={styles.rowCount}>{dataArray.length}</div>
              </FlexRow>
            </>
          )}
        </FlexRow>

        <FlexRow gap={".7rem"} fit>
          {/* <NavTextField
            value={searchValue}
            setValue={onSearch}
            placeholder="Search Participant Email..."
            className={styles.search}
            shadow
          ></NavTextField> */}

          <div
            className={styles.searchBtn}
            onClick={searching ? undefined : () => setSearching(true)}
          >
            <i
              className={`bi bi-search ${searching ? styles.searching : ""}`}
            ></i>

            {searching && (
              <div className={styles.searchDiv}>
                <input
                  className={styles.search}
                  type="text"
                  value={searchValue}
                  placeholder={"Search..."}
                  onChange={(e) => setSearchValue(e.target.value)}
                  autoFocus={true}
                />
                <i
                  className={`bi bi-x-lg ${styles.close}`}
                  onClick={() => setSearching(false)}
                ></i>
              </div>
            )}
          </div>

          <div
            className={`${styles.filtersBtn} ${
              showFilters ? styles.filtersShowing : ""
            }`}
            onClick={setUpFilters}
          >
            <i className="bi bi-funnel"></i>
          </div>
          {showFilters && (
            <div className={styles.filter} ref={filtersRef}>
              <Filter
                updateChosenFilters={setFilters}
                chosenFilter={filters}
              ></Filter>
            </div>
          )}
          {/* {Object.keys(filters).length > 0 && (
            <Button onClick={downloadFiltersSeperately}>
              {window.innerWidth > 600 ? (
                "Download By Filters"
              ) : (
                <i className="bi bi-cloud-download"></i>
              )}
            </Button>
          )} */}

          {/* <CSVLink
            data={csvData ? csvData : ""}
            filename={csvName}
            style={{
              height: "40px",
              //border: "2px solid black",
              alignContent: "center",
              display: "flex",
            }}
          >
          </CSVLink> */}

          <Button
            shadow
            blue
            style={{ whiteSpace: "nowrap", gap: "10px" }}
            onClick={setUpOptions}
          >
            <i className="bi bi-cloud-download"></i> Download
          </Button>

          {showOptions && (
            <div className={styles.options} ref={optionsRef}>
              <Export
                survey={survey}
                onExport={onExport}
                exporting={exporting}
              />
            </div>
          )}
        </FlexRow>
      </div>

      <SimpleBar
        className={styles.scrollable}
        onScrollCapture={onScroll}
        autoHide={true}
      >
        <div className={styles.inside}>
          {right && (
            <div
              className={styles.scrollRight}
              onClick={() => {
                startScroll(true);
              }}
            >
              <i className="bi-chevron-right"></i>
            </div>
          )}
          {left && (
            <div
              className={styles.scrollLeft}
              onClick={() => {
                startScroll(false);
              }}
            >
              <i className="bi-chevron-left"></i>
            </div>
          )}

          <table className={`${styles.fulltable}`} ref={tableRef}>
            <thead>
              <tr style={{ boxShadow: shadow ? "" : "none" }}>
                <th>row</th>
                {headers
                  .filter((h) => h.show)
                  .map((col) => (
                    <th key={col.acc} onClick={() => sortBy(col.acc)}>
                      <div className={styles.columnHeader}>
                        <div className={styles.text}>
                          {normalizeHeader(col.label)}
                        </div>
                        {getSortCaret(col)}
                      </div>
                    </th>
                  ))}
                <th ref={dotsRef}>
                  <i
                    className={`bi bi-three-dots-vertical ${styles.threeDots}`}
                    onClick={setUpDots}
                  ></i>
                  {editHeaders && (
                    <div className={styles.editHeaders}>
                      {getSortedHeaders().map((head, ind) => (
                        <div className={styles.editableHeader} key={ind}>
                          <Checkbox
                            checked={head.show}
                            onChange={() => toggleHeader(head)}
                          ></Checkbox>
                          <div className={styles.colName}>{head.label}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {!data ? (
                <tr>
                  <td colSpan={3}>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <>
                  {topOfList?.map((rowdata, ind) => (
                    <tr
                      key={ind}
                      className={`${ind % 2 === 1 && styles.gray} ${
                        styles.rowData
                      }`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <td style={{ textAlign: "center" }}>{ind + 1}</td>
                      {headers
                        .filter((h) => h.show)
                        .map((column, i) => (
                          <td key={i} onClick={() => onRowClick(rowdata)}>
                            <div className={styles.text}>
                              {/* {column.cell_style &&
                                column.cell_style(rowdata[column.acc
                                ])} */}
                              {rowdata[column.acc]}
                              {(rowdata[column.acc] ||
                                rowdata[column.acc] == 0) && (
                                <div
                                  className={styles.overflow}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(
                                      `${rowdata[column.acc]}`
                                    );
                                  }}
                                >
                                  Copy Cell <i className="bi-layers"></i>
                                </div>
                              )}
                            </div>
                          </td>
                        ))}
                      <td></td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </SimpleBar>
    </div>
  );
}
