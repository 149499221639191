/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 ReactionData. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/

// External
import React, { useEffect, useState } from "react";
import styles from "./Surveys.module.scss";

// Internal
import { useFetchSurveys } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";

import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import SurveyProjects from "components/tables/BasicTable/SurveyWidgets/SurveyProjects/SurveyProjects";
import { Footer } from "App";
import Button from "components/Button/Button";
import { ErrorBanner } from "pages/error_page/ErrorPage";

export function Surveys({ roles, organization, user, updateRoute }) {
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [sort, setSort] = useState({
    item: "updatedAt",
    descend: true,
  });
  const recentProjects = useFetchSurveys(search, perPage, sort, pageSkip);

  const surveys = recentProjects?.data?.response?.surveys;
  const count = recentProjects?.data?.response?.count;

  useEffect(() => {
    localStorage.setItem("activepage", 1);
  }, []);

  useEffect(() => {
    recentProjects.refetch();
  }, [pageNum]);

  //to be able to navigate to the project. onClick in the row
  let navigate = useNavigate();
  const routeChange = (row) => {
    window.localStorage.removeItem("page1");
    window.localStorage.removeItem("page2");
    window.localStorage.removeItem("page3");
    window.localStorage.removeItem("page");
    let path = row.id;
    localStorage.setItem("activeprojecttab", 0);
    navigate("/project/" + path + "/survey-details");
  };
  return (
    <div className={styles.page}>
      <div className={styles.tableContainer}>

        <div className={`${styles.tableHeader}`}>
          <div
            className={styles.header_3}
            style={{ margin: "0", fontWeight: "400" }}
          >
            Surveys
          </div>
          <div
            style={{
              display: "flex",
              gap: "1em",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1em",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                shadow
                onClick={() => navigate("/surveys/create-project")}
              >
                + New Survey
              </Button>

              <div className={styles.searchBox}>
                <NavTextField
                  value={search}
                  setValue={(val) => {
                    setSearch(val);
                  }}
                  placeholder={"Search..."}
                  shadow
                />
              </div>
            </div>
          </div>
        </div>

        {recentProjects.isLoading && (
          <div style={{ height: "100vh" }}>
            <Loading></Loading>
          </div>
        )}
        {recentProjects.isError && (
          <ErrorBanner
            error={recentProjects.error}
            message={"Error pulling up your Surveys"}
          />
        )}
        {recentProjects.isSuccess && !recentProjects.isFetchingNextPage && (
          <SurveyProjects
            surveys={surveys}
            onClick={routeChange}
            draft
            sort={sort}
            setSort={setSort}
            roles={roles}
            refetch={recentProjects.refetch}
            count={count}
            perPage={perPage}
            setPerPage={setPerPage}
            setPageSkip={setPageSkip}
            pageNum={pageNum}
            setPageNum={setPageNum}
            length={surveys?.length}
            searchString={search}
            loading={recentProjects.isRefetching}
            chosen
          />
        )}
        <Footer></Footer>
      </div>
    </div>
  );
}

{
  /* <InteractiveSurveysTable
headers={headers}
currPage={pageNumber}
setCurrPage={setPageNumber}
maxItems={perPage}
onRowClick={routeChange}
sort={searchSort}
setSort={setSearchSort}
setPageSkip={setPageSkip}
perPage={perPage}
setPerPage={setPerpage}
searchString={searchString}
setSearchString={setSearchString}
actionButtonText={"+ New Survey"}
tableTitle={"Projects"}
tableDescription={`These are your survey projects and programs all in one place.`}
widgetType="survey"
searchPlaceholder={"Search Surveys..."}
onClickActionButton={() => navigate("/surveys/create-project")}
hideActionButton={!roles.canCreateEditOrgSurveys}
organization={organization}
roles={roles}
></InteractiveSurveysTable> */
}
