import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./DataInput.module.scss";
import { TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import FlexRow from "../FlexRow/FlexRow";
import FlexCol from "../FlexColumn/FlexCol";
import Icon from "components/Icon/Icon";
import {
  useCheckUniqueAttribute,
  useGetCustomFieldAttributes,
} from "api/resources/contacts/custom_field";
import { Loading } from "components/Loading/Loading";
import { formatDateToCustomString } from "assets/functions/DateFunctions";
import { useQueryClient } from "react-query";

function DataInput({
  dataType,
  value,
  onChange,
  onBlur,
  icon,
  label,
  min,
  max,
  falseValue,
  trueValue,
  placeholder,
  disabled,
  id,
  required,
  multiple,
  unique,
  onValidate,
}) {
  const isUnique = useCheckUniqueAttribute();
  const [invalidMessage, setInvalidMessage] = useState("");
  const [valid, setValid] = useState(undefined);
  const [finalLabel] = useState(required ? `${label}` : label);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  // Initialize date and time if dataType is "date/time"
  useEffect(() => {
    if (dataType === "date/time" && value) {
      const parsedDate = new Date(value);
      setDate(parsedDate.toISOString().split("T")[0]); // Extract date part
      setTime(parsedDate.toTimeString().slice(0, 5)); // Extract time part
    }
  }, [dataType, value]);

  const validateField = (newValue) => {
    if (required && (!newValue || (multiple && newValue.length === 0))) {
      setInvalidMessage("This field is required");
      setValid(false);
      onValidate?.(false);
    } else {
      setInvalidMessage("");
      setValid(true);
      onValidate?.(true);
    }

    if (unique && newValue !== value) {
      isUnique.mutate(
        { id, name: newValue },
        {
          onSuccess: (data) => {
            if (data?.exists) {
              setInvalidMessage("Value already exists");
              setValid(false);
              onValidate?.(false);
            } else {
              setInvalidMessage("");
              setValid(true);
              onValidate?.(true);
            }
          },
          onError: () => {
            setInvalidMessage("Error validating uniqueness");
            onValidate?.(false);
          },
        }
      );
    }
  };

  const handleBlur = (newValue) => {
    validateField(newValue);
    onBlur?.(newValue);
  };

  // Combine date and time into a single ISO string or custom format
  const getCombinedDateTime = (dateValue, timeValue) => {
    if (!dateValue || !timeValue) return "";
    const combinedDate = new Date(`${dateValue}T${timeValue}`);
    return formatDateToCustomString
      ? formatDateToCustomString(combinedDate)
      : combinedDate.toISOString();
  };

  // Handle date change
  const handleDateChange = (newDate) => {
    setDate(newDate);
    const combinedDateTime = getCombinedDateTime(newDate, time);
    onChange?.(combinedDateTime);
  };

  // Handle time change
  const handleTimeChange = (newTime) => {
    setTime(newTime);
    const combinedDateTime = getCombinedDateTime(date, newTime);
    onChange?.(combinedDateTime);
  };

  const commonProps = {
    label: finalLabel,
    value,
    onChange,
    onBlur: handleBlur,
    placeholder,
    icon,
    disable: disabled,
    valid,
    invalidMessage,
  };

  const renderInput = () => {
    switch (dataType) {
      case "boolean":
        return (
          <FlexCol className={styles.input}>
            <div className={styles.label6} style={{ padding: ".2rem .5rem" }}>
              {finalLabel}
            </div>
            <FlexRow className={styles.bool} onClick={() => onChange(!value)}>
              <Checkbox checked={value} onChange={() => {}} />
              <div className={styles.text_2}>
                {value ? trueValue || "Yes" : falseValue || "No"}
              </div>
            </FlexRow>
          </FlexCol>
        );
      case "email":
        return (
          <TextFieldSimple
            {...commonProps}
            type="email"
            validationRules={[...(required ? ["Required"] : [])]}
          />
        );
      case "currency":
        return (
          <TextFieldSimple
            {...commonProps}
            type="text"
            placeholder="0.00"
            icon="currency-dollar"
          />
        );
      case "number":
        return (
          <TextFieldSimple
            {...commonProps}
            type="number"
            min={min}
            max={max}
            icon="123"
          />
        );
      case "date":
      case "time":
        return (
          <TextFieldSimple
            {...commonProps}
            type={dataType}
            value={value ? new Date(value).toISOString().split("T")[0] : ""}
          />
        );
      case "date/time":
        return (
          <div className={styles.input}>
            <FlexRow align="center" gap=".5rem">
              {/* Date Input */}
              <TextFieldSimple
                label={finalLabel}
                value={date}
                onChange={handleDateChange}
                onBlur={() => handleBlur(getCombinedDateTime(date, time))}
                type="date"
                placeholder="Select Date"
                disable={disabled}
                valid={valid}
                invalidMessage={invalidMessage}
                validationRules={required ? ["This field is required"] : []}
              />
              <div className={styles.text_2} style={{ paddingTop: "1.5rem" }}>
                at
              </div>
              {/* Time Input */}
              <TextFieldSimple
                label="Time"
                value={time}
                onChange={handleTimeChange}
                onBlur={() => handleBlur(getCombinedDateTime(date, time))}
                type="time"
                placeholder="Select Time"
                disable={disabled}
                valid={valid}
                invalidMessage={invalidMessage}
                validationRules={required ? ["This field is required"] : []}
              />
            </FlexRow>
          </div>
        );
      case "list":
        return (
          <ListField
            {...commonProps}
            id={id}
            multiple={multiple}
            required={required}
            placeholder={placeholder}
          />
        );
      default:
        return <TextFieldSimple {...commonProps} />;
    }
  };

  return <div className={styles.input}>{renderInput()}</div>;
}

function ListField({
  value,
  label,
  icon,
  disable,
  onChange,
  id,
  required,
  multiple,
  placeholder,
  valid,
  onBlur,
}) {
  const [drop, setDrop] = useState(false);
  const dropdownRef = useRef(null);
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDrop(false);

      onBlur(valueRef.current, false);
    }
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setDrop(false);
  //     console.log(value)
  //     // onBlur(value,false)
  //     // let el = document.getElementById("data-input-value");
  //     // // console.log(el.innerHTML);
  //     // if (el.innerHTML === "") {
  //     //   console.log(el.innerHTML);
  //     //   onBlur(el.innerHTML, false);
  //     // }
  //   }
  // };

  useEffect(() => {
    if (drop) {
      // Use capture phase to ensure it catches the event before React's synthetic event system
      document.addEventListener("mousedown", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [drop]);

  function removeChosenOption(e, option) {
    e.stopPropagation();
    e.preventDefault();
    onChange(option, true);
  }

  function displayList(value) {
    if (value?.length > 0) {
      return (
        <>
          {value &&
            value?.length > 0 &&
            value?.map((option, i) => (
              <div
                className={`${styles.text_3} ${styles.chosenOption}`}
                key={i}
              >
                <div className={styles.chosenOptionName}>{option?.name}</div>
                <div
                  className={styles.chosenOptionRemove}
                  onClick={(e) => removeChosenOption(e, option)}
                >
                  <i className="bi-x"></i>
                </div>
              </div>
            ))}
        </>
      );
    } else {
      return undefined;
    }
  }

  const queryClient = useQueryClient();

  // Clears cache when drop is set to false
  useEffect(() => {
    if (!drop) {
      queryClient.removeQueries(["AttributesQuery", id]); // Ensure this matches query key format
    }
  }, [drop]);

  return (
    <div
      className={styles.input}
      ref={dropdownRef}
      style={{ position: "relative" }}
    >
      {/* Label */}
      <div className={styles.label6} style={{ padding: ".2rem .5rem" }}>
        {label}
      </div>
      {/* Clickable Dropdown Trigger */}
      <div
        className={`${styles.listField} ${disable ? styles.disableList : ""} ${
          valid === false && styles.invalid
        } `}
        onClick={() => !disable && setDrop((prev) => !prev)}
      >
        {icon && <i className={`bi-${icon} ${styles.icon}`} />}
        {multiple && placeholder && !value && <div>{placeholder}</div>}
        {!multiple && placeholder && value?.length > 0 && (
          <div
            className={`${!multiple && styles.listFieldInput} ${
              multiple && styles.listFieldInputMultiple
            } ${styles.text_2} ${styles.placeholder}`}
          >
            {placeholder}
          </div>
        )}
        <div
          className={`${!multiple && styles.listFieldInput} ${
            multiple && styles.listFieldInputMultiple
          } ${styles.text_2}`}
          // id="data-input-value"
        >
          {!multiple ? value : displayList(value)}
        </div>
        <i
          className={`bi-chevron-down ${disable ? styles.disableIcon : ""}`}
        ></i>
      </div>

      {/* Dropdown Content */}
      {drop && (
        <LoadListFieldOptions
          value={value}
          onChange={onChange}
          id={id}
          required={required}
          multiple={multiple}
          drop={drop}
        />
      )}
    </div>
  );
}

function LoadListFieldOptions({
  value,
  onChange,
  id,
  required,
  multiple,
  drop,
}) {
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState({ item: "name", descend: true });

  const getAttributes = useGetCustomFieldAttributes(search, perPage, sort, id);

  const data =
    getAttributes?.data?.pages.flatMap((page) =>
      page?.response?.attributes.map((a) => {
        return a.name;
      })
    ) || [];

  // const count =
  //   getAttributes?.data?.pages?.length > 0
  //     ? getAttributes?.data?.pages[0]?.response?.count
  //     : 0;
  const hasMore =
    getAttributes?.data?.pages?.length > 0
      ? getAttributes?.data?.pages[getAttributes?.data?.pages?.length - 1]
          ?.response?.hasMore
      : false;

  function onScroll(e) {
    e.stopPropagation();
    if (hasMore) {
      var scrollableContainer = e.target;

      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 1) {
        getAttributes.fetchNextPage();
      }
    }
  }
  useEffect(() => {
    if (!drop) {
      console.log("Clearing cache for:");
      getAttributes.remove();
    }
  }, [drop]);

  return multiple ? (
    <ListFieldOptionsMultiple
      options={data}
      value={value}
      onChange={onChange}
      loading={getAttributes.isLoading}
      loadingNext={getAttributes.isFetchingNextPage}
      required={required}
      onScroll={onScroll}
    ></ListFieldOptionsMultiple>
  ) : (
    <ListFieldOptions
      options={data}
      value={value}
      onChange={onChange}
      loading={getAttributes.isLoading}
      loadingNext={getAttributes.isFetchingNextPage}
      required={required}
      onScroll={onScroll}
    ></ListFieldOptions>
  );
}

function ListFieldOptionsMultiple({
  options,
  value,
  onChange,
  loading,
  loadingNext,
  onScroll,
}) {
  const scrollableRef = useRef(null);

  useEffect(() => {
    const container = scrollableRef.current;
    if (!container) return;

    const handleScroll = (e) => onScroll(e);

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={styles.dropdown}
      style={{
        height: "14rem",
      }}
      onScroll={onScroll}
      ref={scrollableRef}
    >
      {!loading && (
        <>
          {options &&
            options?.length > 0 &&
            options?.map((option, i) => (
              <MultipleOption
                option={option}
                key={i}
                onChange={onChange}
                chosen={
                  value?.length > 0 && value?.find((v) => v?.name === option)
                }
              ></MultipleOption>
            ))}
        </>
      )}
      {loadingNext && <Loading height={50} width={50}></Loading>}
    </div>
  );
}

function MultipleOption({ option, chosen, onChange }) {
  return (
    <div
      className={styles.option}
      onClick={() => onChange(option, chosen ? true : false)}
    >
      <span>{option}</span>
      {chosen && (
        <Icon iconName={"check"} blue style={{ fontSize: "1.3rem" }}></Icon>
      )}
    </div>
  );
}

function ListFieldOptions({
  options,
  value,
  onChange,
  loading,
  loadingNext,
  onScroll,
  required,
}) {

  const scrollableRef = useRef(null);

  useEffect(() => {
    const container = scrollableRef.current;
    if (!container) return;

    const handleScroll = (e) => onScroll(e);

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={styles.dropdown}
      style={{
        height: "14rem",
      }}
      onScroll={onScroll}
      ref={scrollableRef}
    >
      {loading && <Loading height={50} width={50}></Loading>}
      {!loading && (
        <>
          {!required && (
            <div className={styles.option} onClick={() => onChange("None")}>
              -- None --{" "}
            </div>
          )}
          {options &&
            options?.length > 0 &&
            options?.map((option, i) => (
              <div
                key={i}
                className={styles.option}
                onClick={() => onChange(option)}
              >
                <span>{option}</span>
                {option === value && (
                  <Icon
                    iconName={"check"}
                    blue
                    style={{ fontSize: "1.3rem" }}
                  ></Icon>
                )}
              </div>
            ))}
        </>
      )}
      {loadingNext && <Loading height={50} width={50}></Loading>}
    </div>
  );
}

export default DataInput;
