import { useState, useEffect } from "react";
import styles from "./TextEntry.module.scss";

export const TextEntry = ({
  onChange,
  onSave,
  value,
  placeholder,
  style,
  label,
  disable,
  valid,
  invalidMessage,
  autoFocus,
  blue,
  obscure,
}) => {
  // Made to be simple to not have to use TextFieldSimple

  const [val, setVal] = useState(value ? value : "");

  useEffect(() => {
    if (value != val) {
      setVal(value);
    }
  }, [value]);

  function handleOnChange(e) {
    if (!disable) {
      setVal(e.target.value);
      if (onChange) {
        onChange(e.target.value);
      }

      if (onSave && Math.abs(e.target.value.length - val.length) > 1) {
        onSave(e.target.value);
      }
    }
  }

  function handleFocusOut(e) {
    onSave(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      onSave(e.target.value);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      className={styles.container}
    >
      {(label || valid === false) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label6}>{label}</div>}
          {valid === false && (
            <div className={styles.invalidMessage}>
              {invalidMessage ? invalidMessage : "Invalid"}
            </div>
          )}
        </div>
      )}

      <input
        onChange={handleOnChange}
        onBlur={onSave ? handleFocusOut : null}
        onKeyDown={onSave ? handleKeyDown : null}
        value={val}
        placeholder={placeholder}
        disabled={disable}
        style={style}
        autoFocus={autoFocus}
        className={`${styles.textField} ${blue ? styles.blue : styles.plain}  ${
          disable && styles.disabled
        } ${valid === false && styles.invalidBorder}`}
        type={obscure ? "password" : "text"}
      ></input>
    </div>
  );
};
