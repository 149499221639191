import Modal from "components/ReactModal/ReactModal";
import styles from "./TestSend.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { useEffect, useRef, useState } from "react";
import {
  useFetchIsSuperAdmin,
  useGetCurrentUser,
} from "api/resources/organization/users";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import { useSearchContact } from "api/resources/contacts/contacts";
import { Loading } from "components/Loading/Loading";
import { useSendEmailTest } from "api/resources/projects/distributions";
import { ErrorBanner } from "pages/error_page/ErrorPage";
import { isValidEmail } from "assets/functions/StringFunctions";

export function TestSend({ onClose, email, onLoadTest }) {
  const [data, setData] = useState({
    toMe: true,
    random: true,
    randomCount: 3,
    contacts: [],
    toEmails: [],
  });

  const sendTest = useSendEmailTest();
  const fetchIsAdmin = useFetchIsSuperAdmin();

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState("");
  const [record, setRecord] = useState(false);

  function changeField(field, val) {
    let copy = { ...data };
    copy[field] = val;
    setData(copy);
  }

  const [to, setTo] = useState("");

  const [search, setSearch] = useState("");

  const searchContacts = useSearchContact(search, 15, {
    item: "firstName",
    descend: false,
  });

  const fetchUser = useGetCurrentUser();

  function addEmail(val) {
    if (val) {
      let copy = [...data.toEmails];
      copy.push(val);
      changeField("toEmails", copy);
      setTo("");
    }
  }

  function onKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      addEmail(e.target.value);
    }
  }

  function takeOutEmail(ind) {
    let copy = [...data.toEmails];
    copy.splice(ind, 1);
    changeField("toEmails", copy);
  }

  function onAddContact(contact) {
    let copy = [...data.contacts];
    copy.push(contact);
    changeField("contacts", copy);
  }

  function takeOutContact(ind) {
    let copy = [...data.contacts];
    copy.splice(ind, 1);
    changeField("contacts", copy);
  }

  function onSend() {
    if (!sending && !sent) {
      const emails = [...data.toEmails];
      if (to) emails.push(to);
      const validEmails = emails.filter((e) => isValidEmail(e));

      sendTest.mutate(
        {
          distId: email.id,
          random: data.random,
          randomCount: data.randomCount,
          contactIds: {
            strings: data.contacts.map((c) => c.id),
          },
          toMe: data.toMe,
          toEmails: {
            strings: validEmails,
          },
          record: record,
        },
        {
          onSuccess: (res) => {
            setSending(false);
            if (res.response.success) {
              setSent(true);
              setTimeout(() => setSent(false), 1500);
              if (error) setError("");
            } else {
              setError(res.response.error);
            }
          },
        }
      );

      setSending(true);
    }
  }

  return (
    <>
      {sendTest.isError && (
        <ErrorBanner
          error={sendTest.error}
          message="Error sending email test"
        />
      )}
      <Modal
        show
        onClose={onClose}
        dark
        rightStyle
        modalStyle={{ height: "100%" }}
      >
        <FlexCol gap={10} className={styles.container} align="center">
          <div className={styles.title}>Test Send</div>

          <i className={`bi-send-check ${styles.sendLogo}`}></i>

          <FlexRow fit>
            <ToggleSwitch startChecked={record} handleCheck={setRecord} />
            <div className={styles.label}>Record Answers</div>
          </FlexRow>

          {record ? (
            <div className={styles.warning}>
              <div className={styles.warningTitle}>Warning</div>
              <div>
                &nbsp;&nbsp;This <em>will</em> create participation data for
                these contacts. The survey link will be{" "}
                <span style={{ fontWeight: "600" }}>live</span>. You will need
                to <span style={{ textDecoration: "underline" }}>unanswer</span>{" "}
                any answers you give in the survey. This test is designed for
                testing results.
              </div>
            </div>
          ) : (
            <div className={styles.info}>
              <div className={styles.infoTitle}>Info</div>
              <div>
                &nbsp;&nbsp;This <em>will</em> create participation data for
                these contacts. The survey link will be a test link, containing
                all the necessary information to conduct the survey, but will{" "}
                <em>not</em> record any answers.
              </div>
            </div>
          )}

          <FlexCol gap={10} style={{ paddingTop: "10px" }}>
            <div className={styles.subTitle}>Send To</div>
            <FlexCol style={{ paddingLeft: "10px" }} gap={10}>
              <FlexRow fit>
                <ToggleSwitch
                  startChecked={data.toMe}
                  handleCheck={(val) => changeField("toMe", val)}
                />
                <FlexRow fit gap={10}>
                  <div className={styles.label}>Me</div>

                  {fetchUser.isSuccess && (
                    <div
                      className={styles.label}
                    >{`<${fetchUser.data.me.email}>`}</div>
                  )}
                </FlexRow>
              </FlexRow>

              {data.toEmails.length > 0 && (
                <FlexCol align={"flex-end"}>
                  {data.toEmails.map((e, i) => (
                    <FlexRow fit>
                      <div className={styles.label}>{e}</div>
                      <div
                        className={styles.minus}
                        onClick={() => takeOutEmail(i)}
                      >
                        <i className="bi-dash-circle"></i>
                      </div>
                    </FlexRow>
                  ))}
                </FlexCol>
              )}

              <FlexRow>
                <div className={styles.label}>and</div>
                <div className={styles.searchField}>
                  <input
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    type="text"
                    placeholder={"Add Email"}
                    className={styles.search}
                    onKeyDown={onKeyDown}
                  />
                </div>
              </FlexRow>
            </FlexCol>
          </FlexCol>

          <FlexCol gap={10} style={{ paddingTop: "15px" }}>
            <div className={styles.subTitle}>Contacts (As if to)</div>
            <FlexCol style={{ paddingLeft: "10px" }} gap={10}>
              <OneOrTheOther
                checked={!data.random}
                onCheck={(val) => changeField("random", !val)}
                left="Random"
                right="Pick"
                style={{ fontSize: ".85rem" }}
              />

              {data.random && (
                <FlexRow fit style={{ paddingLeft: "5px" }}>
                  <NumberInput
                    startNumber={data.randomCount}
                    handleNumberChange={(val) =>
                      changeField("randomCount", val)
                    }
                    min={1}
                    max={5}
                  />
                  <div className={styles.label}>Count</div>
                </FlexRow>
              )}

              {!data.random && (
                <>
                  {data.contacts.length > 0 && (
                    <FlexCol align={"flex-end"}>
                      {data.contacts.map((c, i) => (
                        <FlexRow fit>
                          <div
                            className={styles.label}
                          >{`${c.firstName} ${c.lastName}`}</div>
                          <div
                            className={styles.minus}
                            onClick={() => takeOutContact(i)}
                          >
                            <i className="bi-dash-circle"></i>
                          </div>
                        </FlexRow>
                      ))}
                    </FlexCol>
                  )}

                  <FlexCol>
                    <SearchSomething
                      search={search}
                      setSearch={setSearch}
                      data={
                        searchContacts.isSuccess
                          ? searchContacts.data.contacts
                          : null
                      }
                      loading={searchContacts.isLoading}
                      placeholder={"Search Contacts"}
                      onPick={onAddContact}
                    />
                  </FlexCol>
                </>
              )}
            </FlexCol>
          </FlexCol>

          <FlexCol style={{ paddingTop: "15px" }} align="center" gap={15}>
            {error && (
              <div className={`${styles.label} ${styles.red}`}>{error}</div>
            )}

            <Button blue style={{ gap: "10px" }} onClick={onSend}>
              {sending ? (
                <>
                  <Loading dim={25} /> Sending
                </>
              ) : sent ? (
                <>
                  <i className="bi-check-circle"></i> Sent
                </>
              ) : (
                <>
                  <i className={"bi-rocket-takeoff"}></i> Send Test
                </>
              )}
            </Button>

            {/* {fetchIsAdmin.isSuccess && fetchIsAdmin.data.response && (
              <Button
                seafoam
                style={{ gap: "10px", marginTop: "20px" }}
                onClick={onLoadTest}
              >
                Load Test <i className={"bi-cloud-lightning-rain-fill"}></i>
              </Button>
            )} */}
          </FlexCol>
        </FlexCol>
      </Modal>
    </>
  );
}

function SearchSomething({
  search,
  setSearch,
  data,
  placeholder,
  onPick,
  loading,
}) {
  const [active, setActive] = useState(false);
  const [val, setVal] = useState(search ? search : "");

  useEffect(() => {
    if (search != val) setVal(search);
  }, [search]);

  const ref = useRef(null);

  function setUp() {
    if (!active) {
      setActive(true);
      setTimeout(() => document.addEventListener("click", listener), 300);
    }
  }

  function listener(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setActive(false);
      document.removeEventListener("click", listener);
      setSearch("");
    }
  }

  const timeRef = useRef(0);

  function onChange(e) {
    setVal(e.target.value);
    if (timeRef.current) clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      setSearch(e.target.value);
      timeRef.current = 0;
    }, 400);
  }

  return (
    <div className={styles.searchField}>
      <input
        value={val}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        className={styles.search}
        onFocus={setUp}
        // autoComplete={false}
        ref={ref}
      />
      {active && val && (
        <div className={styles.anchor}>
          <div className={styles.results}>
            {loading ? (
              <Loading />
            ) : (
              <>
                {data && (
                  <>
                    {data.length > 0 ? (
                      data.map((d, i) => (
                        <div
                          className={styles.option}
                          onClick={() => onPick(d)}
                          key={i}
                        >
                          {d.firstName} {d.lastName}
                        </div>
                      ))
                    ) : (
                      <div className={`${styles.noData}`}>No data</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
