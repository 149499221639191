
// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation, useGqlInfiniteQuery } from "api/Api";
import { getScoreChartSurveyPullData } from "pages/results/Charts/ScoreChart";
import { getStatChartSurveyPullData } from "pages/results/Charts/StatChart/StatChart";

export const useFetchSurveys = (search, perPage, sort, skip) => {
  const query = gql`
    query Query(
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortInput!
    ) {
      response: searchSurveys(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
      ) {
        surveys {
          id
          name
          status
          type
          responseCount
          createdAt
          distributionCount
          questionCount
          lastDistributionDate
        }
        count
        hasMore
      }
    }
  `;

  return useGqlQuery(
    ["Get Surveys:", search],
    query,
    {
      search,
      perPage,
      skip,
      sort,
    },
  );
};

export const useFetchProject = (projId) => {
  const query = gql`
  query {
    project(id: "${projId}") {
      startedAt
      status
      type
      userId
      organizationId
      name
      id
      createdAt
      responseCount
      rawReportId
    }
  }
  `;

  return useGqlQuery(["project " + projId], query);
};

export const useFetchProjectLink = (projId) => {
  const query = gql`
  query {
    survey: project(id: "${projId}") {
      activeLink
      status
    }
  }
  `;

  return useGqlQuery(["project survey taker: " + projId], query);
};

export const useSearchSurveys = (searchString, page, perPage, sort, status) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelection!
    ) {
      surveys: searchProjects2(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        id
        name
        description
        status
        type
        responseCount
        createdAt
        updatedAt
        supportedLocales
        startedAt
        organizationId
        userId
      }
    }
  `;

  return useGqlQuery(["searchProjects", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchProjectsByIdsGql = (data) => {
  const query = gql`
    query GetProjectsByIds($data: ProjectIdsContainer!) {
      survey: getProjectsByIds(data: $data) {
        id
        name
        startedAt
        audienceSize
      }
    }
  `;

  return useGqlQuery(["projectsByIds" + data.projectIds.toString()], query, {
    data: data,
  });
};

export const useFetchPageProjects = (id) => {
  const query = gql`
    query {
      survey: getPageProjects(id: "${id}") {
        id
        name
        startedAt
      }
    }
  `;

  return useGqlQuery(["page projects: " + id], query);
};

export const useGetSurveyStats = (id, date) => {
  const query = gql`
    query ($id: String!, $date: DateTimeISO!) {
      stats: getSurveyStats(id: $id, date: $date) {
        started
        completed
        responses
        abandoned
        requests
        delivered
        opens
        bounced
        spam
        blocked
        surveyName
        participationCount
        avgResponseTime
        lastResponseTime
      }
    }
  `;
  return useGqlQuery(["GetSurveyStats:" + id], query, {
    id: id,
    date: date,
  });
};

export const useGetEngagementStats = (id) => {
  const query = gql`
    query {
      datasets: getEngagementStats(projectId: "${id}") {
        labels
        datasets {
          label
          data
          backgroundColor
          borderWidth
        }
      }
    }
  `;
  return useGqlQuery(["GetSurveyEngagementStats:" + id], query, {});
};

export const useFetchProjectsByIdsChart = (data) => {
  const query = gql`
    query GetProjectsByIds($data: ProjectIdsContainer!) {
      survey: getProjectsByIds(data: $data) {
        id
        name
        startedAt
        question {
          id
          questionText
          pageNumber
          pageOrderIndex
        }
      }
    }
  `;

  return useGqlQuery(["projectsByIdsChart" + data.projectIds], query, {
    data: data,
  });
};

export const useFetchProjectGql = (id) => {
  const query = gql`
    query {
      survey: project(id: "${id}") {
        id
        name
        description
        status
        type
        responseCount
        createdAt
        updatedAt
        startedAt
        closedAt
        organizationId
        rawReportId
        resultsUploading
        oldSurveyId
        userId
        designSettings
        isProgram
        sendInterval
        programId
        link
        activeLink
        numPages
        iterationCount
        useGPS
        openedResults
        audienceType
        finishedMessage
        projectType
        program {
          id
          mainProjectId
        }
        surveytag {
          label
          value
          id
          displayColor
        }
      }
    }
  `;

  return useGqlQuery(["project:" + id], query);
};

export const useFetchAllProjects = () => {
  const query = gql`
    query AllProjects {
      allProjects {
        id
        name
        startedAt
      }
    }
  `;

  return useGqlQuery("all projects", query);
};

export const useFetchProjectMaxPage = (id) => {
  const query = gql`
    query {
     project(id: "${id}") {
        numPages
      }
    }
  `;

  return useGqlQuery(["projectPages", id], query, {});
};

export const useFetchParticipationDates = (id) => {
  const query = gql`
    query {
     datasets: getParticipationDates(projectId: "${id}") {
       labels
       datasets {
          label
          backgroundColor
          borderWidth
          data
        }
      }
    }
  `;

  return useGqlQuery(["getpartdates", id], query, {});
};

export const useFetchPrograms = (ids) => {
  const query = gql`
    query Query($data: ProgramIds!) {
      programs: getPrograms(data: $data) {
        id
        mainProject {
          id
          name
        }
        project {
          id
          name
        }
      }
    }
  `;

  let data = {
    ids: ids,
  };

  return useGqlQuery(["programs by ids" + ids.toString()], query, {
    data: data,
  });
};

export const useFetchAllPrograms = () => {
  const query = gql`
    query GetPrograms {
      programs: getAllPrograms {
        id
        mainProject {
          id
          name
        }
        project {
          id
          name
        }
      }
    }
  `;

  return useGqlQuery(["all programs"], query);
};

export const useGetStatChartProjects = (viz) => {
  let data = getStatChartSurveyPullData(viz);

  const query = gql`
    query Query($data: SurveyPullData!) {
      survey: getStatChartProjects(data: $data) {
        id
        name
        startedAt
      }
    }
  `;

  return useGqlQuery(["statChartProjects: " + JSON.stringify(data)], query, {
    data: data,
  });
};

export const useGetComparableStatChartProjects = (viz) => {
  let data = {
    projectIds: [],
    dynamics: [],
  };

  if (viz.designSettings.useComparison && viz.comparison) {
    data = viz.comparison;
  }

  const query = gql`
    query Query($data: SurveyPullData!) {
      survey: getStatChartProjects(data: $data) {
        id
        name
        startedAt
      }
    }
  `;

  return useGqlQuery(["statChartProjects: " + JSON.stringify(data)], query, {
    data: data,
  });
};

export const useFetchScoreChartProjects = (viz) => {
  let data = getScoreChartSurveyPullData(viz);

  const query = gql`
    query Query($data: SurveyPullData!) {
      survey: getStatChartProjects(data: $data) {
        id
        name
        startedAt
      }
    }
  `;

  return useGqlQuery(["statChartProjects: " + JSON.stringify(data)], query, {
    data: data,
  });
};

export const useFetchProjectParticipationInfo = (
  projectIds,
  filters,
  constraints,
  columns
  // setData
) => {
  const data = {
    projectIDs: projectIds,
  };

  const fieldIds = {
    constraints: constraints,
  };

  const query = gql`
    query GetParticipationsWithStats(
      $userConstraints: UserConstraints!
      $filters: String!
      $data: projectIDArray!
      $columns: ColumnIds
    ) {
      info: getParticipationsWithStats(
        userConstraints: $userConstraints
        filters: $filters
        data: $data
        columns: $columns
      ) {
        distributions {
          id
          name
          projectId
          hasLink
          sentDate
        }
        deliveries {
          opens
          status
          distributionId
          unsubbed
          bounce_classification
          participationId
        }
        partStats {
          participation {
            id
            projectId
            startedAt
            contact {
              firstName
              email
              id
              lastName
              customField
              attribute {
                customFieldId
                name
              }
            }
            started
            completed
            abandoned
            distIdUsed
          }
          stats {
            blocked
            bounced
            participated
            received
            receivedInvite
            openedEmail
            unsubbed
          }
        }
      }
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    // onSettled: () => {
    //   // queryClient.invalidateQueries("answers");
    //   if (setData) {
    //     setData(null); // Causes it to recalculate the data
    //   }
    // },
  };

  return useGqlQuery(
    [
      "participations: " +
        projectIds.toString() +
        filters +
        JSON.stringify(constraints) +
        (columns && columns.ids ? columns.ids.toString() : ""),
    ],
    query,
    {
      data: data,
      filters: filters,
      userConstraints: fieldIds,
      columns: columns,
    },
    options
  );
};

export const useFetchSurveyAndUnsubLinks = () => {
  const query = gql`
    query Query {
      links: surveyAndUnsubLinks {
        survey
        unsub
      }
    }
  `;

  return useGqlQuery(["survey and unsub links for html email"], query);
};

export const useFetchStatChartData = () => {
  const mutation = gql`
    mutation GetStatChartData(
      $compData: SurveyPullData!
      $tagIds: IdStrings!
      $designSettings: String!
      $projData: SurveyPullData!
      $filters: String!
      $pivot: String
    ) {
      chart: getStatChartData(
        compData: $compData
        tagIds: $tagIds
        designSettings: $designSettings
        projData: $projData
        filters: $filters
        pivot: $pivot
      ) {
        N
        axisLabels
        datasets {
          data
          label
        }
        split
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useFetchStatChartDrillData = () => {
  const mutation = gql`
    mutation Mutation(
      $drill: Drill!
      $compData: SurveyPullData!
      $tagIds: IdStrings!
      $designSettings: String!
      $projData: SurveyPullData!
      $filters: String!
      $pivot: String
    ) {
      drillData: getStatChartDrillData(
        drill: $drill
        compData: $compData
        tagIds: $tagIds
        designSettings: $designSettings
        projData: $projData
        filters: $filters
        pivot: $pivot
      ) {
        columns {
          accessor
          name
        }
        rows
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useFetchProjectAndImage = () => {
  const mutation = gql`
    mutation GetProjectAndImageUrl($id: String!) {
      getProjectAndImageUrl(id: $id) {
        imageURL
        project {
          designSettings
          finishedMessage
          status
          numPages
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("projects");
    },
  };

  return useGqlMutation(mutation, [], options);
};

export const useUpdateProjectStatus = () => {
  const mutation = gql`
    mutation ChangeProjectStatus($status: String!, $projectId: String!) {
      changeProjectStatus(status: $status, id: $projectId) {
        rawReportId
        id
        status
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      if (data?.changeProjectStatus?.id) {
        queryClient.invalidateQueries("project:" + data.changeProjectStatus.id);
      }
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchProjectStatus = () => {
  const mutation = gql`
    mutation ($id: String!) {
      getProject(id: $id) {
        status
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("projects");
    },
  };

  return useGqlMutation(mutation, [], options);
};

export const useCreateProjectGql = () => {
  const mutation = gql`
    mutation CreateProject($data: ProjectInput!) {
      createProject(data: $data) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("projects");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCopyProject = () => {
  const mutation = gql`
    mutation CopyProject($data: CreateFromTemplateInput!) {
      copyProject(data: $data) {
        id
        status
        isProgram
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("projects");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateNumPages = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: ProjectInput!) {
      updateProject(id: $id, data: $data) {
        id
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteProjectGql = () => {
  const mutation = gql`
    mutation DeleteProject($projectId: String!) {
      deleteProject(ProjectId: $projectId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
      queryClient.invalidateQueries("recentProjects");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateProjectDesignSettingsGql = () => {
  const mutation = gql`
    mutation UpdateProject($data: ProjectInput!, $updateProjectId: String!) {
      updateProject(data: $data, id: $updateProjectId) {
        numPages
        designSettings
        finishedMessage
        updatedAt
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("projects");
    },
  };

  return useGqlMutation(mutation, [], options);
};

export const useUpdateProjectDashProperties = () => {
  const mutation = gql`
    mutation Mutation($data: ProjectInput!, $updateProjectId: String!) {
      updateProject(data: $data, id: $updateProjectId) {
        id
        scheduledToStartAt
        scheduledToCloseAt
        supportedLocales
        startedAt
        audience {
          id
        }
        surveytag {
          id
          label
          value
        }
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      console.log(err);
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("projects");
      if (data.updateProject.id) {
        queryClient.invalidateQueries("project:" + data.updateProject.id);
      }
    },
  };

  return useGqlMutation(mutation, options);
};

export const useSearchProjectsPages = (
  searchString,
  page,
  perPage,
  sort,
  place
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelection!
    ) {
      surveys: searchProjects(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        id
        name
        description
        status
        type
        responseCount
        createdAt
        updatedAt
        supportedLocales
        startedAt
        organizationId
        userId
        rawReportId
        isProgram
        sendInterval
        programId
        numPages
        iterationCount
        activeLink
        question {
          id
        }
        distribution {
          sentDate
          status
          messageType
          scheduledDate
        }
      }
    }
  `;

  return useGqlQuery(
    ["projects at " + place + searchString + page + perPage],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
    }
  );
};

export const useSearchProjectsPagesCount = (
  searchString,
  page,
  perPage,
  sort,
  place
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelection!
    ) {
      count: searchProjectsCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      )
    }
  `;

  return useGqlQuery(
    ["searchprojectscount:" + place, searchString, "recentProjects"],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
    }
  );
};

export const useFetchStartedSurveys = () => {
  const query = gql`
    query {
      surveys: fetchStartedSurveys {
        id
        name
        startedAt
      }
    }
  `;

  return useGqlQuery(["allProjects"], query);
};

export const useFetchStartedSurveysForMetricType = (code) => {
  const query = gql`
    query  {
      surveys: fetchStartedSurveysForMetricType(code: "${code}") {
        id
        name
        question {
          id
          questionText
        }
      }
    }
  `;

  return useGqlQuery(["allProjects for qType " + code], query);
};

export const useSearchProjectsForChart = (
  searchString,
  page,
  perPage,
  sort
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelection!
    ) {
      surveys: getPossibleSurveysForChart(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        id
        name
        status
        startedAt
        closedAt
        organizationId
        type
      }
    }
  `;

  return useGqlQuery(["searchProjectForChart", searchString], query, {
    search: searchString,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useDataUploader = () => {
  const mutation = gql`
    mutation Mutation($data: UploadDataArrayInput!) {
      startDataUpload(data: $data)
    }
  `;
  return useGqlMutation(mutation);
};

export const useFix = () => {
  const mutation = gql`
    mutation Mutation {
      fix
    }
  `;
  return useGqlMutation(mutation);
};

export const useFetchSharedBySurveyId = (surveyId) => {
  const query = gql`
    query Query($surveyId: String!) {
      users: sharedBySurveyId(surveyId: $surveyId) {
        status
        id
        projectId
        userId
        user {
          firstName
          lastName
          email
        }
      }
    }
  `;

  return useGqlQuery(["getPeopleWithAccess"], query, { surveyId: surveyId });
};

export const useCreateSurveyTemplate = () => {
  const mutation = gql`
    mutation Mutation($data: SurveyTemplateInput!) {
      createSurveyTemplate(data: $data) {
        description
        id
        name
        organizationId
        projectId
      }
    }
  `;

  const queryClient = useQueryClient();
  const option = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("surveyTemplates");
    },
  };
  return useGqlMutation(mutation, option);
};

export const useCreateSurveyFromTemplate = () => {
  const mutation = gql`
    mutation CreateSurveyFromTemplate($data: CreateFromTemplateInput!) {
      createSurveyFromTemplate(data: $data) {
        id
        name
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useGetDownloadResultsData = () => {
  const mutation = gql`
    mutation GetDownloadResultsData($filters: String, $projectId: String!) {
      participations: getDownloadResultsData(
        filters: $filters
        projectId: $projectId
      ) {
        id
        startedAt
        distIdUsed
        contact {
          id
          firstName
          lastName
          email
          phone
          country
          stateProvince
          city
          attribute {
            name
            customFieldId
          }
        }
        answers {
          choiceAnswer
          choiceAnswerIndex
          textAnswer
          scaleAnswer
          matrixAnswer
          questionId
          buckets
        }
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useGetProgramSurveys = (
  programId,
  surveyId,
  page,
  perPage,
  sort
) => {
  const query = gql`
    query Query(
      $programId: String!
      $mainSurveyId: String!
      $page: Float!
      $perPage: Float!
      $sort: programSort!
    ) {
      surveys: searchProgramSurveys(
        programId: $programId
        mainSurveyId: $mainSurveyId
        page: $page
        perPage: $perPage
        sort: $sort
      ) {
        id
        name
        description
        status
        type
        responseCount
        createdAt
        updatedAt
        supportedLocales
        startedAt
        organizationId
        userId
        rawReportId
        isProgram
        distribution {
          sentDate
          status
          messageType
        }
      }
    }
  `;

  return useGqlQuery(["ProgramSurveys:" + programId], query, {
    programId: programId,
    mainSurveyId: surveyId,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useGetProgramSurveysCount = (
  programId,
  surveyId,
  page,
  perPage,
  sort
) => {
  const query = gql`
    query Query(
      $programId: String!
      $mainSurveyId: String!
      $page: Float!
      $perPage: Float!
      $sort: programSort!
    ) {
      count: searchProgramSurveysCount(
        programId: $programId
        mainSurveyId: $mainSurveyId
        page: $page
        perPage: $perPage
        sort: $sort
      )
    }
  `;

  return useGqlQuery(["ProgramSurveysCount:" + programId], query, {
    programId: programId,
    mainSurveyId: surveyId,
    page: page,
    perPage: perPage,
    sort: sort,
  });
};

export const useFetchProgramRecent = (id, mainSurveyId, kind, trend) => {
  const query = gql`
    query {
      data: programMostRecent(programId: "${id}", mainSurveyId: "${mainSurveyId}", kind: "${kind}", trend: ${trend}) {
        newestSurvey {
          id
          responseCount
          name
          participations {
            id
          }
        }
        previousSurvey {
          id
          responseCount
          name  
          participations {
            id
          }
        }
        responses
        labels
        ids
        avgResponse 
        npsScores
        audiences
        programScore
      }
    }
  `;

  return useGqlQuery(["programRecent:" + id], query);
};

export const useSearchPrograms = (search) => {
  const query = gql`
    query {
      programs: searchPrograms(search: "${search}") {
        id
        mainProject {
          name
        }
        project {
          id
          name
          startedAt
        }
      }
    }
  `;

  return useGqlQuery(["searchPrograms: " + search], query);
};

export const useGetProjectsFromProgramInTimeFrame = (id, timeFrame) => {
  const query = gql`
    query {
      projects: getProjectsFromProgramInTimeFrame(timeFrame: "${timeFrame}", programId: "${id}") {
        id
        name
      }
    }
  `;

  return useGqlQuery(["projects from program : " + id + timeFrame], query);
};
