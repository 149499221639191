import { maintimezones } from "./Variables";

export function trimDate(date, giveTime) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);

    let string = month + " " + day + ", " + year;
    if (!giveTime) {
      return string;
    } else {
      let hours = d.getHours();
      let dayTime = "pm";
      if (hours > 0 && hours < 12) {
        hours = hours;
        dayTime = "am";
      } else if (hours > 12) {
        hours = hours - 12;
      } else if (hours === 0) {
        hours = 12;
        dayTime = "am";
      }

      return (
        string +
        " at " +
        hours +
        ":" +
        `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
        dayTime
      );
    }
  }
  return undefined;
}

export function trimTime(date) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    let hours = d.getHours();
    let dayTime = "pm";
    if (hours > 0 && hours < 12) {
      hours = hours;
      dayTime = "am";
    } else if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      hours = 12;
      dayTime = "am";
    }

    return (
      hours +
      ":" +
      `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
      dayTime
    );
  }
}

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export function trimTimeDay(date) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    let hours = d.getHours();
    let dayTime = "pm";
    if (hours > 0 && hours < 12) {
      hours = hours;
      dayTime = "am";
    } else if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      hours = 12;
      dayTime = "am";
    }

    let dayvar = days[date.getDay()];
    return (
      dayvar +
      " " +
      month +
      " " +
      day +
      ", " +
      hours +
      ":" +
      `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
      dayTime
    );
  }
}

export function findDay(dayNumber) {
  if (dayNumber == 0) {
    return "Sun";
  } else if (dayNumber == 1) {
    return "Mon";
  } else if (dayNumber == 2) {
    return "Tue";
  } else if (dayNumber == 3) {
    return "Wed";
  } else if (dayNumber == 4) {
    return "Thu";
  } else if (dayNumber == 5) {
    return "Fri";
  } else {
    return "Sat";
  }
}

export function findMonth(dayNumber) {
  if (dayNumber == 0) {
    return "Jan";
  } else if (dayNumber == 1) {
    return "Feb";
  } else if (dayNumber == 2) {
    return "Mar";
  } else if (dayNumber == 3) {
    return "Apr";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "Jun";
  } else if (dayNumber == 6) {
    return "Jul";
  } else if (dayNumber == 7) {
    return "Aug";
  } else if (dayNumber == 8) {
    return "Sep";
  } else if (dayNumber == 9) {
    return "Oct";
  } else if (dayNumber == 10) {
    return "Nov";
  } else {
    return "Dec";
  }
}

export function findFullMonth(dayNumber) {
  if (dayNumber == 0) {
    return "January";
  } else if (dayNumber == 1) {
    return "February";
  } else if (dayNumber == 2) {
    return "March";
  } else if (dayNumber == 3) {
    return "April";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "June";
  } else if (dayNumber == 6) {
    return "July";
  } else if (dayNumber == 7) {
    return "August";
  } else if (dayNumber == 8) {
    return "September";
  } else if (dayNumber == 9) {
    return "October";
  } else if (dayNumber == 10) {
    return "November";
  } else {
    return "December";
  }
}

export function calculateSurveyDuration(startDate, endDate) {
  // Convert both dates to milliseconds
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  // Calculate the difference in milliseconds
  const duration = end - start;

  // Convert milliseconds to seconds
  const totalSeconds = Math.floor(duration / 1000);

  // Calculate minutes and remaining seconds
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Return the result as minutes and seconds
  return `${minutes} minute(s) and ${seconds} second(s)`;
}

export function calculateDateDifference(datesArray) {
  // Convert the dates to JavaScript Date objects
  const dateObjects = datesArray.map((date) => new Date(date));

  // Find the earliest and latest dates
  const earliestDate = new Date(Math.min(...dateObjects));
  const latestDate = new Date(Math.max(...dateObjects));

  // Calculate the time difference in milliseconds
  const timeDifference = latestDate - earliestDate;

  // Convert the difference to minutes and seconds
  const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));
  const differenceInSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    earliestDate,
    latestDate,
    differenceInMilliseconds: timeDifference,
    differenceInMinutes,
    differenceInSeconds,
    differenceString: `${differenceInMinutes} minute(s) and ${differenceInSeconds} second(s)`,
  };
}

export function formatDateToCustomString(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Get milliseconds, then pad with extra zeros to match 6 decimal places
  const milliseconds =
    String(date.getMilliseconds()).padStart(3, "0") + "000".slice(0, 3);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function convertToStandardTime(militaryTime) {
  if (militaryTime) {
    const [hour, minute] = militaryTime.split(":").map(Number); // Split and convert to numbers

    // Determine AM/PM
    const period = hour >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    const standardHour = hour % 12 || 12; // Use 12 for 0 or 12 in 12-hour time

    // Format the result
    return `${standardHour}:${String(minute).padStart(2, "0")} ${period}`;
  }
}

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "June",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export function getNiceDate(when) {
  let day = findDay(when.getDay());
  let date =
    day +
    " " +
    months[when.getMonth()] +
    " " +
    when.getDate() +
    " " +
    when.getFullYear() +
    ", ";
  let hour = when.getHours();
  let am = true;
  if (hour == 0) {
    hour = 12;
  } else if (hour == 12) {
    am = false;
  } else if (hour > 12) {
    am = false;
    hour = hour % 12;
  }
  let min = when.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  date += hour + ":" + min + " " + (am ? "am" : "pm");
  return date;
}

export function isInSameZone(timezone) {
  let timeZone = maintimezones.find((tz) => tz.timezone === timezone);
  if (timeZone) {
    let localOffset = new Date().getTimezoneOffset() * 60;
    return localOffset === timeZone.offset;
  }

  return true;
}

function shortTimezone(timezone) {
  if (timezone.includes(",")) {
    let parts = timezone.split(",");
    return parts[0];
  }
  return timezone;
}

export function getLocalTime(date, timezone) {
  let localOffset = new Date().getTimezoneOffset() * 60;
  let localTZ = maintimezones.find((tz) => tz.offset === localOffset);
  let emailTZ = maintimezones.find((tz) => tz.timezone === timezone);
  if (localTZ && emailTZ) {
    let offset = emailTZ.offset - localOffset;
    let localDate = new Date(date);
    localDate.setSeconds(localDate.getSeconds() + offset);
    return (
      shortTimezone(localTZ.label) + ": " + getScheduledTime(localDate, true)
    );
  }
  return "";
}

export function getScheduledTime(forDate) {
  let date = new Date(forDate);

  let day = findDay(date.getDay());
  let month = findMonth(date.getMonth());
  let year = date.getFullYear();
  let thisYear = new Date().getFullYear();

  let string = day + " " + month + " " + date.getDate();
  if (year !== thisYear) {
    string += " " + year;
  }
  // let string = date.toDateString();
  string += ", at ";
  string += date.getHours() == 12 ? 12 : date.getHours() % 12;
  string += ":";
  if (date.getMinutes() < 10) {
    string += "0";
  }
  string += date.getMinutes();
  string += " ";
  string += date.getHours() < 12 ? "am" : "pm";
  return string;
}

export function appendTimezone(date, timezone) {
  let timeZone = maintimezones.find((tz) => tz.timezone === timezone);
  if (timeZone) {
    return shortTimezone(timeZone.label) + ": " + getScheduledTime(date);
  }
  return "Local to send: " + getScheduledTime(date);
}
