import React, { useEffect, useRef, useState } from "react";
import styles from "./EmailWidget.module.scss";
import Stat from "../Stat";
import Button from "components/Button/Button";
import { Player } from "@lottiefiles/react-lottie-player";

import { DISTRIBUTION } from "api/resources/subscription/subscribe";
import { useSubscription } from "@apollo/client";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import {
  emailString,
  secondString,
} from "pages/projects/project/delivery/distributions/EmailBuilder/Confirmation/Confirmation";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  appendTimezone,
  getLocalTime,
  getScheduledTime,
  isInSameZone,
} from "assets/functions/DateFunctions";

function trimDate(date, giveTime) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    //eslint-disable-next-line no-unused-expressions
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);

    let string = month + " " + day + ", " + year;
    if (!giveTime) {
      return string;
    } else {
      let hours = d.getHours();
      let dayTime = "pm";
      if (hours > 0 && hours <= 12) {
        hours = hours;
        dayTime = "am";
      } else if (hours > 12) {
        hours = hours - 12;
      } else if (hours == 0) {
        hours = 12;
      }

      return (
        string +
        " at " +
        hours +
        ":" +
        `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()}` +
        dayTime
      );
    }
  }
  return undefined;
}

function EmailWidget({
  email,
  onClick,
  copyClick,
  isProgram,
  iteration,
  user,
}) {
  const ref = useRef(null);
  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current?.play();

      setTimeout(() => {
        ref.current?.pause();
      }, 2000);
    }
  }, [email.scheduledDate]);

  const [viewStats, setViewStats] = useState(false);
  const [options, setOptions] = useState(false);

  const [check, setCheck] = useState(false);

  const [task, setTask] = useState();

  const { data, loading } = useSubscription(DISTRIBUTION, {
    variables: { userId: user?.id },
  });

  useEffect(() => {
    if (
      data &&
      data.distributionSubscription &&
      data.distributionSubscription?.itemId === email?.id
    ) {
      setTask({
        ...data.distributionSubscription,
      });
      if (
        data.distributionSubscription &&
        data.distributionSubscription?.progress === 100
      ) {
        //do somthing
      }
    }
  }, [data, loading]);

  function getNextSend() {
    if (email.sendInterval === "quarterly") {
      let date = new Date(email.scheduledDate);
      date.setMonth(date.getMonth() + 3);
      return date;
    }
    if (email.sendInterval === "daily") {
      let date = new Date(email.scheduledDate);
      date.setDate(date.getDate() + 1);
      return date;
    }
    if (email.sendInterval === "weekly") {
      let date = new Date(email.scheduledDate);
      date.setDate(date.getDate() + 7);
      return date;
    }
    if (email.sendInterval === "annually") {
      let date = new Date(email.scheduledDate);
      date.setMonth(date.getMonth() + 12);
      return date;
    }
    if (email.sendInterval === "semi-annually") {
      let date = new Date(email.scheduledDate);
      date.setMonth(date.getMonth() + 6);
      return date;
    }
  }

  return (
    <div
      className={styles.emailWidget}
      onClick={() => onClick(email)}
      style={{
        cursor: viewStats ? "auto" : "",
        // fontSize: window.innerWidth < 560 ? ".7em" : ".9em",
        minWidth: window.innerWidth < 560 ? "fit-content" : "",
        // paddingTop: window.innerWidth < 1000 ? "20px" : "",
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          bottom: ".5em",
          right: ".5em",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {iteration && email.status === "Scheduled" && (
          <div className={styles.startedTextTop}>
            <span>Schedule For: </span>
            <div>{trimDate(email?.scheduledDate, true)}</div>
          </div>
        )}
        <Player
          // autoplay={play}
          loop={true}
          ref={ref}
          src={require("assets/animations/checkmarkgreen.json")}
          style={{
            height: "20px",
            width: "20px",
          }}
        />
      </div> */}
      <div className={styles.container}>
        {!viewStats && (
          <>
            <div className={styles.titleContainer}>
              <FlexRow start gap={".5rem"} justify={"space-between"} fit>
                <div className={styles.emailTitle}>
                  {email?.index}: {email?.name}
                </div>
                <FlexRow gap=".5em" fit>
                  <div
                    className={`${styles.method} ${
                      email.messageType === "email" && styles.email
                    } `}
                  >
                    {email.messageType === "email" && (
                      <>
                        {email.messageType} <i className="bi-envelope"></i>{" "}
                      </>
                    )}
                  </div>

                  {email.status === "Draft" && (
                    <div className={`${styles.method} ${styles.draft}`}>
                      Draft
                    </div>
                  )}
                  {email.status === "Sent" && (
                    <>
                      {" "}
                      <div className={`${styles.method} ${styles.sent}`}>
                        Sent
                      </div>
                    </>
                  )}
                  {email.status === "Scheduled" && (
                    <>
                      <div className={`${styles.method} ${styles.scheduled}`}>
                        Scheduled
                      </div>
                      <div className={styles.player} style={{ width: "20px" }}>
                        <img
                          src={require("assets/images/Calendar.png")}
                          height="30px"
                        ></img>
                      </div>
                    </>
                  )}
                  {email.status === "Scheduling" && (
                    <>
                      <div className={`${styles.method} ${styles.scheduled}`}>
                        Scheduling...
                      </div>

                      {/* <div className={styles.player} style={{ width: "30px" }}>
                      {task?.progress && (
                        <Pie
                          percentage={task?.progress ? task?.progress : 0}
                          dim={25}
                          progress
                          colour={"#15bcc7"}
                          hideText
                          backColor={"#d8d9d9"}
                        />
                      )}
                    </div> */}
                    </>
                  )}
                  {email.status === "Canceled" && (
                    <>
                      <div className={`${styles.method} ${styles.cancelled}`}>
                        Canceled
                      </div>
                    </>
                  )}
                  {email.status === "Canceling" && (
                    <>
                      <div className={`${styles.method} ${styles.cancelled}`}>
                        Canceling...
                      </div>
                    </>
                  )}
                  {email.status === "Sending" && (
                    <>
                      {" "}
                      <div className={`${styles.method} ${styles.scheduled}`}>
                        Sending Messages{" "}
                      </div>
                      <div className={styles.player}>
                        <Player
                          autoplay
                          loop
                          src={require("assets/animations/EmailSending.json")}
                          style={{
                            height: "70px",
                            width: "70px",
                          }}
                        />
                      </div>
                    </>
                  )}
                  {/* {isProgram && !iteration && (
                  <div className={`${styles.method} ${styles.template}`}>
                    Template
                  </div>
                )}
                {isProgram && iteration && email.status === "Draft" && (
                  <div className={`${styles.method} ${styles.template}`}>
                    Template
                  </div>
                )}
                {isProgram && iteration && email.status === "Scheduled" && (
                  <div className={`${styles.method} ${styles.scheduled}`}>
                    Schedule
                  </div>
                )} */}
                </FlexRow>
              </FlexRow>
              <div className={styles.startedTextTop}>
                {email.status === "Draft" && (
                  <>
                    <span>Created: </span>
                    <div> {trimDate(email?.createdDate)}</div>
                  </>
                )}
                {email.status === "Sent" && (
                  <>
                    <FlexCol gap=".5em" fit>
                      <FlexRow gap={"5px"} align="center" fit>
                        <span>Sent: </span>{" "}
                        <div>
                          {!email?.audienceSchedule ? (
                            trimDate(email?.scheduledDate, true)
                          ) : (
                            <div className={styles.link}>
                              Mixed audience sent dates
                            </div>
                          )}
                        </div>
                      </FlexRow>

                      {/* <FlexRow gap="5px" fit>
                          <div className={styles.delivCount}>30</div> / 32 Delivered
                        </FlexRow> */}
                      <FlexRow gap={"10px"} align="center" fit>
                        <span>Delivered: </span>{" "}
                        <FlexRow fit gap={".2rem"}>
                          <div className={styles.deliveredCount}>
                            {email.delivered}
                          </div>{" "}
                          / <div>{email.requests}</div>
                        </FlexRow>
                      </FlexRow>
                    </FlexCol>
                  </>
                )}

                {email.status === "Cancelled" && (
                  <>
                    <span>Send Cancelled </span>{" "}
                    <div>
                      {!email?.audienceSchedule ? (
                        trimDate(email?.sentDate, true)
                      ) : (
                        <div className={styles.link}>
                          Mixed audience sent dates
                        </div>
                      )}
                    </div>
                  </>
                )}

                {email.status === "Scheduled" && (
                  <FlexCol gap={".5rem"}>
                    <FlexRow gap={".5rem"} align="center" fit>
                      {" "}
                      {!iteration && <span>Scheduled For: </span>}
                      {!iteration && (
                        <div>
                          {!email?.audienceSchedule ? (
                            // <>{trimDate(email?.scheduledDate, true)}</>
                            <>
                              {isInSameZone(email.timeZone) ? (
                                <>{getScheduledTime(email?.scheduledDate)}</>
                              ) : (
                                <FlexCol fit>
                                  <div>
                                    {appendTimezone(
                                      email.scheduledDate,
                                      email.timeZone
                                    )}
                                  </div>
                                  <div>
                                    {getLocalTime(
                                      email.scheduledDate,
                                      email.timeZone
                                    )}
                                  </div>
                                </FlexCol>
                              )}
                            </>
                          ) : (
                            <div className={styles.link}>
                              Mixed audience scheduled dates
                            </div>
                          )}
                        </div>
                      )}
                      {iteration && <span>Created: </span>}
                      {iteration && <div>{trimDate(email?.createdDate)}</div>}
                    </FlexRow>
                    {/* <FlexRow gap={"10px"} align="center" fit>
                      <span>Messages Scheduled: </span>{" "}
                      <div>{email.requests}</div>
                    </FlexRow> */}
                  </FlexCol>
                )}
                {email.status === "Sending" && (
                  <>
                    {!iteration && <span>Emails Started: </span>}
                    {!iteration && (
                      <div>
                        {!email?.audienceSchedule ? (
                          <>{trimDate(email?.scheduledDate, true)}</>
                        ) : (
                          <div className={styles.link}>
                            Mixed audience scheduled dates
                          </div>
                        )}
                      </div>
                    )}
                    {iteration && <span>Created: </span>}
                    {iteration && <div>{trimDate(email?.createdDate)}</div>}
                  </>
                )}
              </div>
              {email.status === "Sent" && email?.recurringSend && (
                <div className={styles.startedTextTop}>
                  <>
                    <span>Next Send: </span>{" "}
                    <div>
                      {!email?.audienceSchedule ? (
                        trimDate(getNextSend(), true)
                      ) : (
                        <div className={styles.link}>
                          Mixed audience sent dates
                        </div>
                      )}
                    </div>
                  </>
                </div>
              )}
            </div>
            <div className={styles.emailHeader}>
              <div className={styles.startedText}>
                <span>From: </span>
                <div className={styles.infotext}>
                  {email.distConfig ? email.distConfig.fromAddress : ""}
                </div>
              </div>
              <div className={styles.startedText}>
                <span>To:</span>{" "}
                <div className={styles.audiences}>
                  {email?.audience.length > 0 &&
                    email?.audience.map((aud) => (
                      <div
                        className={`${styles.audience} ${styles.infotext}`}
                        key={aud.id}
                      >
                        {aud.name}
                      </div>
                    ))}
                </div>
              </div>
              {/* <div className={styles.startedText}>
                <span>Drip: </span>
                <div
                  style={{ display: "flex", gap: ".5em" }}
                  className={styles.infotext}
                >
                  {email?.timeDelayCount
                    ? emailString(email?.timeDelayCount)
                    : "1 (email)"}{" "}
                  every{" "}
                  {email?.timeDelay
                    ? secondString(email?.timeDelay)
                    : "1 (second)"}
                </div>
              </div> */}
              <div className={styles.startedText}>
                <span>Subject:</span>{" "}
                <div className={styles.infotext} style={{}}>
                  {email?.subject}
                </div>
              </div>
            </div>
            {/* {email.status === "Draft" && window.innerWidth > 850 && (
              <div className={styles.stats2}>
                <div className={styles.send}>
                  {email.sender && email.audience.length > 0
                    ? "Ready to send"
                    : "Not Ready to send"}{" "}
                </div>
              </div>
            )} */}
            {/* {email.status != "Draft" && window.innerWidth > 850 && (
              <ViewStats email={email}></ViewStats>
            )} */}
            {/* {!view && <Button onClick={(e) => {e.stopPropagation(); setView(true);}}>Click</Button>} */}
            {/* {isProgram && !iteration && (
              <div className={styles.programVersion}>Program Template</div>
            )}
            {isProgram && iteration && (
              <div
                className={styles.programVersion}
                style={{ display: "flex", gap: "1em" }}
              >
                {!check && (
                  <Button
                    shadow
                    onClick={(e) => {
                      e.stopPropagation();
                      setCheck(true);
                      ref.current?.play();

                      setTimeout(() => {
                        ref.current?.pause();
                      }, 2000);
                    }}
                  >
                    Looks Good <i className="bi-hand-thumbs-up"></i>
                  </Button>
                )}

                {check && (
                  <Player
                    autoplay={true}
                    loop={false}
                    ref={ref}
                    onEvent={(e) => {
                      if (e === "play") {
                        setTimeout(() => {
                          ref?.current?.pause();
                        }, 2000);
                      }
                    }}
                    src={require("assets/animations/checkmarkgreen.json")}
                    style={{
                      height: "50px",
                      width: "50px",
                    }}
                  />
                )}

              </div>
            )} */}
          </>
        )}
      </div>
      <span
        className={`bi bi-three-dots ${styles.threeDots}`}
        onClick={(e) => {
          e.stopPropagation();
          setOptions(true);
        }}
        style={{
          position: "absolute",
          right: "15px",
          top: "10px",
          color: "#616565",
        }}
        // onMouseLeave={() => setOptions(false)}
      ></span>
      {options && (
        <div className={styles.settingsBox} ref={ref2}>
          <div
            className={styles.menuItem}
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
          >
            <i className="bi-pencil"></i> Edit Email
          </div>

          {/* {email?.audience?.length > 0 && email.sender && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                onClick(email, "schedule");
              }}
            >
              <i className="bi-calendar" style={{ color: "#7FCFD3" }}></i>{" "}
              Schedule
            </div>
          )} */}
          {/* {email.sender && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                onClick(email, "preview");
              }}
            >
              <i className="bi-eye" style={{ color: "#A7D5C5" }}></i> Send
              Preview
            </div>
          )} */}
          {email.status === "Draft" && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                onClick(email, "delete");
              }}
            >
              <i className="bi-trash" style={{ color: "#ff8878" }}></i> Delete
            </div>
          )}
          {!iteration && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                copyClick(email);
              }}
            >
              <i className="bi-layers" style={{ color: "#F2C85C" }}></i> Make a
              Copy
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmailWidget;

function ViewStats({ email }) {
  // const fetchEmailStats = useGetEmailStats(email?.id);
  // console.log(fetchEmailStats.data)

  // const handleGetStats = () => {
  //   let startDate = new Date("2024-03-27 20:53:20.656779");
  //   let endDate = new Date();
  //   let sMonth = `${startDate.month}`;
  //   let sDay = `${startDate.day}`;
  //   let eMonth = `${endDate.month}`;
  //   let eDay = `${endDate.day}`;
  //   if (startDate.month < 10) {
  //     sMonth = "0" + `${startDate.month}`;
  //   }
  //   if (endDate.month < 10) {
  //     eMonth = "0" + `${endDate.month}`;
  //   }
  //   if (startDate.day < 10) {
  //     sDay = "0" + `${startDate.day}`;
  //   }
  //   if (endDate.day < 10) {
  //     eDay = "0" + `${endDate.day}`;
  //   }
  //   let data = {
  //     startDate: `${startDate.year}` + "-" + sMonth + "-" + sDay,
  //     endDate: `${endDate.year}` + "-" + eMonth + "-" + eDay,
  //   };
  //   fetchEmailStats.mutate(
  //     { data: data },
  //     {
  //       onSuccess: (data) => {
  //         // emailInfoData.datasets.data = [
  //         //   data.getEmailStats.opens,
  //         //   data.getEmailStats.bounces,
  //         //   data.getEmailStats.unsubscribes,
  //         //   data.getEmailStats.reportedSpam,
  //         // ];
  //         // setBounces(data.getEmailStats.bounces);
  //         // setOpens(data.getEmailStats.opens);
  //         // setUnsubscribes(data.getEmailStats.unsubscribes);
  //         // setSpam(data.getEmailStats.reportedSpam);
  //         // setShowChart(true);
  //       },
  //     }
  //   );
  // };

  // useEffect(() => {
  //   if (email?.id === "18546e79-facf-4123-a179-0c82e7eaea4e") {
  //     handleGetStats();
  //   }
  // });
  return (
    <div className={styles.stats2}>
      <div className={styles.stats}>
        <div className={styles.flex}>
          {/* <Stat
            title={"Requests"}
            value={email?.requests}
            valueStyle={{ width: "35px", justifyContent: "end" }}
            style={{ width: "fit-content" }}
            small
          /> */}
          {email.status === "Scheduled" && (
            <Stat
              title={"Scheduled"}
              value={`${email?.scheduleCount} `}
              valueStyle={{
                // color: "#C1E08D",
                width: "35px",
                justifyContent: "end",
              }}
              style={{ width: "fit-content" }}
              small
            />
          )}
          <Stat
            title={"Delivered"}
            value={`${email?.delivered}/${email?.requests} `}
            valueStyle={{
              // color: "#C1E08D",
              width: "35px",
              justifyContent: "end",
            }}
            style={{ width: "fit-content" }}
            small
          />

          {/*  
                 <Stat

              title={"Delivered"}
              value={statsQuery?.data?.stats?.delivered}
              valueStyle={{
                color: "#C1E08D",
                width: "35px",
                justifyContent: "end",
              }}
              style={{ width: "fit-content" }}
              small
            />
             <Stat
              title={"Blocked"}
              value={statsQuery?.data?.stats?.blocked}
              valueStyle={{
                color: "#FF8878",
                width: "35px",
                justifyContent: "end",
              }}
              style={{ width: "fit-content" }}
              small
            />
            <Stat
              title={"Bounces"}
              value={statsQuery?.data?.stats?.bounced}
              valueStyle={{
                color: "#FF8878",
                width: "35px",
                justifyContent: "end",
              }}
              style={{ width: "fit-content" }}
              small
            />

            <Stat
              title={"Spam"}
              value={statsQuery?.data?.stats?.spam}
              valueStyle={{
                color: "#FF8878",
                width: "35px",
                justifyContent: "end",
              }}
              style={{ width: "fit-content" }}
              small
            /> */}
        </div>
      </div>
    </div>
  );
}
