import {
  useRetractAnswer,
  useSaveAnswer,
  useRetractAnswers,
  useFinishSurvey,
} from "api/resources/projects/answers";
import Survey from "components/Survey/Survey";
import { useParams } from "react-router-dom";
import styles from "./SurveyHandler.module.scss";
import { useSaveDistId } from "api/resources/projects/participations";

export default function SurveyHandler({
  participation,
  onFinished,
  noLongerActive,
  alreadyTaken,
  distId,
  testing,
}) {
  const { projectId } = useParams();
  const saveAnswerToDB = useSaveAnswer();
  const retractFromDB = useRetractAnswer();
  const retractMultFromDB = useRetractAnswers();
  const completeSurvey = useFinishSurvey();
  const saveDistId = useSaveDistId();

  function handleOnFinish() {
    completeSurvey.mutate(
      {
        participationId: participation?.id,
      },
      {
        onSuccess: (data) => {
          // console.log("answers saved", data);
        },
      }
    );

    if (onFinished) {
      onFinished();
    }
  }

  function saveAnswer(answerObj, increment) {
    saveAnswerToDB.mutate(
      { data: answerObj, increment, increment },
      {
        onSuccess: (data) => {
          // console.log("answer saved", data);
        },
      }
    );
  }

  function retractAnswer(questionId, decrement) {
    retractFromDB.mutate(
      {
        participationId: participation?.id,
        questionId: questionId,
        decrement: decrement,
        testing: testing ? true : false,
      },
      {
        onSuccess: (data) => {
          // console.log("retracted", data);
        },
      }
    );
  }

  function retractAnswers(qIdArray, decrement) {
    retractMultFromDB.mutate(
      {
        participationId: participation?.id,
        questionIds: {
          questionIds: qIdArray,
        },
        decrement: decrement,
        testing: testing ? true : false,
      },
      {
        onSuccess: (data) => {
          // console.log("retracted multiple", data);
        },
      }
    );
  }

  function startEntryTimer() {
    setTimeout(() => {
      saveDistId.mutate(
        {
          partId: participation?.id,
          distId: distId,
        },
        {
          onSuccess: (data) => {
            // console.log("saved dist id used", data);
          },
        }
      );
    }, 5000);
  }

  return (
    <div className={styles.container}>
      <Survey
        projectId={projectId}
        participationId={participation.id}
        preloadAnswers={participation?.answers}
        onFinish={handleOnFinish}
        saveAnswer={saveAnswer}
        retractAnswer={retractAnswer}
        retractAnswers={retractAnswers}
        alreadyTaken={alreadyTaken}
        noLongerActive={noLongerActive}
        startEntryTimer={distId && !testing ? startEntryTimer : undefined}
      />
    </div>
  );
}
