/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction Data. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/

import { useEffect, useRef, useState } from "react";
import styles from "./TextMessageEditor.module.scss";
import { shortId } from "components/tables/EditableTable/utils";
import { forEach } from "assets/functions/ArrayFunctions";
import { Menu } from "./Menu/Menu";

export default function TextMessageEditor({
  encoding,
  emailVersion,
  onSave,
  editable,
  bestPractices,
  tips,
  setTips,
  reDraw,
  incoming,
  customFields,
  conditions,
  setConditions,
  phoneRef,
}) {
  const [lastSelection, setLastSelection] = useState();
  const lastSelRef = useRef();
  const [runSave, setRunSave] = useState(false);
  const [edited, setEdited] = useState(false);

  const defaultBlack = "#050606";

  const [record, setRecord] = useState([]);

  const [menu, setMenu] = useState();
  const recordRef = useRef();

  function createDisplay(dataCopy, onComplete) {
    if (entry.current) {
      let images = [];
      for (let div of dataCopy) {
        if (div.image) {
          let img = document.getElementById(div.id + "_image");
          if (img) {
            images.push(img);
          }
        }
      }

      entry.current.innerHTML = "";

      let charCount = 0;
      let newTips = [];

      for (let k = 0; k < dataCopy.length; k++) {
        let div = dataCopy[k];

        const parentElem = document.createElement(div.list ? "li" : "div");
        parentElem.id = div.id;

        if (!div.text) {
          let span = document.createElement("span");
          span.appendChild(document.createTextNode("\n"));
          parentElem.appendChild(span);
        } else {
          let textBoxes = div.text.split("");
          let keys = textBoxes.map((c) => {
            return { char: c, styleIndeces: [] };
          });

          let styles = [];

          // let toBe = charCount + keys.length;
          // const littleLong = 425;
          // const tooLong = 525;

          // Best Practices
          // if (
          //   bestPractices &&
          //   !div.sig &&
          //   div.text.toLowerCase().trim() !== "{signature}" &&
          //   (toBe > littleLong || charCount > littleLong)
          // ) {
          //   if (toBe <= tooLong || charCount <= tooLong) {
          //     const code = "Email - Little Long";
          //     let existing = tips.find((t) => t.code === code);

          //     let from = charCount >= littleLong ? 0 : littleLong - charCount;
          //     let to =
          //       toBe < tooLong ? keys.length - 1 : tooLong - charCount - 1;

          //     if (!existing || !existing.ignore) {
          //       stylesInOrder.push({
          //         code: "color",
          //         value: "#fccb05", // Yellow",
          //         from: from,
          //         to: to,
          //         bp: true,
          //       });
          //     }

          //     if (!newTips.some((t) => t.code === code)) {
          //       newTips.push({
          //         code: code,
          //         title: (
          //           <>
          //             {"Email is getting a "}
          //             <span style={{ color: "#fccb05" }}>little long</span>
          //             {". Ideally it is below 425 characters."}
          //           </>
          //         ),
          //         ignore: existing ? existing.ignore : false,
          //       });
          //     }
          //   }

          //   if (charCount > tooLong || toBe > tooLong) {
          //     const code = "Email - Too Long";
          //     let existing = tips.find((t) => t.code === code);

          //     let from = charCount >= tooLong ? 0 : tooLong - charCount;

          //     if (!existing || !existing.ignore) {
          //       stylesInOrder.push({
          //         code: "color",
          //         value: "#ff391f", // Red",
          //         from: from,
          //         to: keys?.length - 1,
          //         bp: true,
          //       });
          //     }

          //     if (!newTips.some((t) => t.code === code)) {
          //       newTips.push({
          //         code: code,
          //         title: (
          //           <>
          //             {"Email is a "}
          //             <span style={{ color: "#ff391f" }}>too long</span>
          //             {". Ideally it is below 425 characters."}
          //           </>
          //         ),
          //         ignore: existing ? existing.ignore : false,
          //         red: true,
          //       });
          //     }
          //   }
          // }
          // if (!div.sig) {
          //   charCount += keys.length;
          // }

          // Custom Fields

          for (let key of ["{recipient", "{condition"]) {
            if (div.text.includes(key)) {
              let ind = div.text.indexOf(key);
              while (ind > -1) {
                // stylesInOrder.push({  // Make brackets always black?
                //   code: "color",
                //   value: defaultBlack,
                //   from: ind,
                //   to: ind,
                //   cf: true,
                // });

                styles.push({
                  code: "color",
                  value: "#a3a4a8", // Gray 100
                  from: ind + 1,
                  to: ind + "recipient".length, // 'same length as condition so doesn't matter'
                  cf: true,
                });

                if (div.text.includes("}", ind)) {
                  let closeInd = div.text.indexOf("}", ind);
                  let nextOpenInd = div.text.indexOf("{", ind + 1);
                  if (nextOpenInd === -1 || closeInd < nextOpenInd) {
                    // It closed the one that is open

                    const from = ind + "recipient".length + 2; // account for " " space;
                    const to = closeInd - 1;
                    let text = div.text.slice(from, to + 1);

                    if (replaceable(key, text)) {
                      styles.push({
                        code: "color",
                        value: "#7fcfd3", // Blue 60
                        from: from,
                        to: to,
                        cf: true,
                        con: key === "{condition",
                      });

                      // stylesInOrder.push({
                      //   code: "color",
                      //   value: defaultBlack,
                      //   from: closeInd,
                      //   to: closeInd,
                      //   cf: true,
                      // });
                    }
                  }
                }

                ind = div.text.indexOf(key, ind + 1);
              }
            }
          }

          if (div.text.toLowerCase().includes("{survey url}")) {
            let ind = div.text.toLowerCase().indexOf("{survey url}");

            while (ind > -1) {
              let end = div.text.indexOf("}", ind + 1);
              styles.push({
                code: "color",
                value: defaultBlack,
                from: ind,
                to: end,
                sig: true,
              });
              styles.push({
                code: "color",
                value: "#a3a4a8",
                from: ind + 1,
                to: end - 1,
                sig: true,
              });
              styles.push({
                code: "color",
                value: defaultBlack,
                from: end,
                to: end,
                sig: true,
              });

              ind = div.text.toLowerCase().indexOf("{survey url}", ind + 1);
            }
          }

          let words = div.text.split(" ");
          let spot = 0;
          for (let word of words) {
            if (isPotentialLink(word)) {
              styles.push({
                code: "color",
                value: "#15bcc7",
                from: spot,
                to: spot + word.length - 1,
              });
              styles.push({
                code: "textDecoration",
                value: "underline",
                from: spot,
                to: spot + word.length - 1,
              });
            }
            spot += word.length + 1;
          }

          forEach(styles, (style, ind) => {
            for (let i = style.from; i <= style.to; i++) {
              if (!keys[i]) {
                debugger;
                restartText();
                return;
              }
              keys[i].styleIndeces.push(ind);
            }
          });

          let blocks = [];
          let current = "";
          for (let key of keys) {
            let stringedStyle = JSON.stringify(key.styleIndeces);
            if (stringedStyle !== current) {
              // start a new span
              blocks.push({ styleIndeces: key.styleIndeces, text: key.char });
            } else {
              // add it
              blocks[blocks.length - 1].text += key.char;
            }
            current = stringedStyle;
          }

          let spans = [];
          for (let span of blocks) {
            let realSpan = document.createElement("span");

            for (let ind of span.styleIndeces) {
              let style = styles[ind];

              if (style.con) {
                realSpan.onclick = (e) =>
                  onConditionClick(span.text, style.from);
                realSpan.style.cursor = "pointer";
              }

              if (style.link) {
                continue;
              }

              if (
                style.bp &&
                span.styleIndeces.some(
                  (i) => styles[i].code === style.code && !styles[i].bp
                )
              ) {
                // has another color style - switch to a dashed underline
                let there = realSpan.style.textDecoration;
                let line = there.includes("line-through") ? "line-through" : "";
                let kind = there.includes("underline") ? "solid" : "dotted";
                let shouldBe =
                  line + " underline " + kind + " " + style.value + " ";
                realSpan.style.textDecoration = shouldBe;
              } else if (style.cf || style.sig) {
                realSpan.style[style.code] = style.value;
              } else if (realSpan.style[style.code]) {
                realSpan.style[style.code] += " " + style.value;
              } else {
                realSpan.style[style.code] = style.value;
              }
            }

            realSpan.appendChild(document.createTextNode(span.text));
            spans.push(realSpan);
          }

          for (let span of spans) {
            parentElem.appendChild(span);
          }
        }

        entry.current.appendChild(parentElem);
      }

      if (setTips) {
        setTips(newTips);
      }

      if (onComplete) {
        onComplete();
      }
    }
  }

  function replaceable(key, text) {
    if (key === "{recipient") {
      let lowered = text.toLowerCase();

      return (
        lowered === "full name" ||
        lowered === "last name" ||
        lowered === "first name" ||
        lowered === "doctor name" ||
        customFields.some((f) => f.name === text)
      );
    }
    if (conditions) {
      return conditions.some((c) => c.name === text);
    }
    return false;
  }

  function isPotentialLink(str) {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;;
    return urlPattern.test(str);
  }

  const initEncoding = (text) => {
    let given = text ? text : "";
    if (given) {
      let t = typeof text;
      if (t !== "string") {
        if (t === "number") {
          given = given.toString();
        } else {
          given = "";
        }
      }
    }

    let div = {
      id: shortId(),
      text: given,
    };

    return [div];
  };

  function decode() {
    if (!encoding) {
      if (emailVersion) {
        try {
          let emailData = JSON.parse(emailVersion);
          let divs = [];
          for (let div of emailData) {
            if (!div.sig && !div.divider && !div.image) {
              let text = div.text;
              if (div.links) {
                for (let link of div.links) {
                  if (link.surveyLink) {
                    text =
                      text.slice(0, link.from) +
                      "{survey url}" +
                      text.slice(link.to + 1);
                  } else if (link.custom) {
                    text =
                      text.slice(0, link.from) +
                      link.url +
                      text.slice(link.to + 1);
                  }
                }
              }
              divs.push({ id: shortId(), text: text });
            }
          }
          if (divs.length) return divs;
        } catch {
          // Just do blank
        }
      }
      return initEncoding("");
    }
    let decoded = null;
    try {
      decoded = JSON.parse(encoding);
    } catch (e) {
      return initEncoding(encoding);
    }

    if (Array.isArray(decoded)) {
      for (let div of decoded) {
        div.id = shortId();
      }
      return decoded;
    } else {
      return initEncoding("");
    }
  }

  function prepareEncoding() {
    let copy = getDataCopy();
    for (let div of copy) {
      delete div.id;
    }
    return JSON.stringify(copy);
  }

  const [data, setData] = useState(decode());

  useEffect(() => {
    createDisplay(data);
  }, []);

  useEffect(() => {
    if (runSave) {
      onBlur();
      setRunSave(false);
    }
  }, [runSave]);

  function saveData(copy) {
    let recordCopy = [...record];
    recordCopy.push({ data: data, selection: lastSelection });
    setRecord(recordCopy);
    setData(copy);
    setEdited(true);

    if (recordRef.current) {
      recordRef.current = copy;
    }
  }

  function saveSelection(selection) {
    setLastSelection(selection);
    lastSelRef.current = selection;
  }

  function restartText() {
    let copy = initEncoding();
    let selection = {
      divId: copy[0].id,
      offset: 0,
      isCollapsed: true,
    };
    if (editable) {
      createDisplayAndDoSelection(copy, selection);
      setRecord([]);
    } else {
      createDisplay(copy);
    }
    setData(copy);
  }

  function getDataCopy() {
    let copy = [...data];
    let trueCopy = JSON.parse(JSON.stringify(copy));
    return trueCopy;
  }

  function getDataCopyWithMenuUp() {
    if (recordRef.current) {
      if (Array.isArray(recordRef.current)) {
        return JSON.parse(JSON.stringify(recordRef.current));
      }
    }
    return getDataCopy(); // needed because the event handler in Menu.jsx can be out of scope
  }

  function onBlur() {
    if (onSave && edited) {
      let text = "";
      for (let i = 0; i < data.length; i++) {
        text += data[i].text;
        if (i < data.length - 1) {
          text += "\n";
        }
      }
      let code = prepareEncoding();
      let html = undefined;
      if (entry.current) {
        html = entry.current.innerHTML;
      }
      if (onSave) {
        onSave(text, code, html);
      }

      setEdited(false);
    }
  }

  function handleChange(e) {
    e.preventDefault();

    if (!lastSelection) {
      return;
    }

    if (lastSelection.isCollapsed) {
      if (
        e.nativeEvent.inputType === "deleteContentBackward" &&
        lastSelection.offset == 0
      ) {
        onDeleteBegginningOfDiv();
        return;
      }

      let copy = getDataCopy();
      let index = data.findIndex((d) => d.id === lastSelection.divId);
      let endOffset = lastSelection.offset;

      if (e.nativeEvent.inputType === "insertText") {
        if (endOffset === copy[index].text.length) {
          copy[index].text += e.nativeEvent.data;
        } else if (!endOffset) {
          // at the start
          copy[index].text = e.nativeEvent.data + copy[index].text;
        } else {
          let first = copy[index].text.slice(0, endOffset);
          let second = copy[index].text.slice(endOffset);
          copy[index].text = first + e.nativeEvent.data + second;
        }

        for (let char of e.nativeEvent.data) {
          endOffset++;
        }
      } else if (e.nativeEvent.inputType === "deleteContentBackward") {
        if (endOffset === copy[index].text.length) {
          copy[index].text = copy[index].text.slice(
            0,
            copy[index].text.length - 1
          );
        } else {
          let first = copy[index].text.slice(0, endOffset - 1);
          let second = copy[index].text.slice(endOffset);
          copy[index].text = first + second;
        }

        endOffset--;
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(copy[index].id);
        let subCount = 0;
        let count = 0;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            if (text.nodeValue) {
              subCount = 0;
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endOffset) {
                  count++;
                  subCount++;
                }
              }

              if (count == endOffset) {
                target = text;
                break;
              }
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, subCount);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      // Inserting or deleting on a selection
      let isADelete = e.nativeEvent.inputType === "deleteContentBackward";
      let isAnInsert = e.nativeEvent.inputType === "insertText";
      let isSpellCheck = e.nativeEvent.inputType === "insertReplacementText";
      if (isAnInsert || isADelete || isSpellCheck) {
        let newChar = isAnInsert || isSpellCheck ? e.nativeEvent.data : "";
        handleSelectionInsert(newChar);
      }
    }
  }

  function onDeleteBegginningOfDiv() {
    let copy = getDataCopy();
    // Merge a section back on to another one;
    let index = copy.findIndex((c) => c.id === lastSelection.divId);

    if (!index) {
      // it's at the top
      createDisplayAndDoSelection(data, lastSelection);
      return;
    }

    let selectionToBe = {
      isCollapsed: true,
      divId: copy[index - 1].id,
      offset: copy[index - 1].text.length,
    };

    if (copy[index].text) {
      copy[index - 1].text += copy[index].text;
    }

    copy.splice(index, 1);
    saveData(copy);
    createDisplayAndDoSelection(copy, selectionToBe);
  }

  function insertNewLine() {
    if (!lastSelection) {
      return;
    }

    if (lastSelection.isCollapsed) {
      // if it's got text, split the text and the styles.....
      let copy = getDataCopy();
      let index = copy.findIndex((d) => d.id === lastSelection.divId);

      if (copy[index].image) {
        let id = shortId();
        copy.splice(index + lastSelection.offset, 0, {
          text: "",
          id: id,
        });
        createDisplayAndDoSelection(copy, {
          divId: id,
          offset: 0,
          isCollapsed: true,
        });
        saveData(copy);
        return;
      }

      let firstPart = copy[index].text.substring(0, lastSelection.offset);
      let secondPart = copy[index].text.substring(lastSelection.offset);

      copy[index].text = firstPart;

      if (copy[index].list && !copy[index].text) {
        // Empty bullet point - take off the bullet point
        delete copy[index].list;
        createDisplayAndDoSelection(copy, {
          divId: copy[index].id,
          offset: 0,
          isCollapsed: true,
        });
        saveData(copy);
        return;
      }

      let newDiv = {
        id: shortId(),
        text: secondPart,
      };

      copy.splice(index + 1, 0, newDiv);
      saveData(copy);

      createDisplay(copy, () => {
        let targetNode = document.getElementById(newDiv.id);
        while (targetNode.nodeName !== "#text") {
          targetNode = targetNode.childNodes[0];
        }

        const newRange = document.createRange();
        newRange.setStart(targetNode, 0);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      let copy = getDataCopy();
      let divTrashCan = [];
      let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
      let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

      for (let i = startInd; i <= endInd; i++) {
        if (i === startInd && i === endInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          let end = copy[i].text.substring(lastSelection.end.offset + 1);
          copy[i].text = beginning;
          let newDiv = {
            text: end,
            id: shortId(),
          };

          copy.splice(i + 1, 0, newDiv);
        } else if (i == startInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          copy[i].text = beginning;

          let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
          copy[endInd].text = end;

          //add appended end text and styles
        } else if (startInd < i && i < endInd) {
          divTrashCan.push(i);
        }
      }

      if (divTrashCan.length) {
        let keeping = [];
        forEach(copy, (d, i) => {
          if (!divTrashCan.includes(i)) {
            keeping.push(d);
          }
        });
        copy = keeping;
      }

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        divId: copy[startInd + 1].id,
        offset: 0,
        isCollapsed: true,
      });
    }
  }

  function handlePaste(e) {
    e.preventDefault();
    if (lastSelection && e.clipboardData.types.includes("text/plain")) {
      let newText = e.clipboardData.getData("text");
      let paragraphs = newText.split("\n");
      if (paragraphs.length > 1) {
        handlePasteParagraphs(paragraphs);
        return;
      }

      if (lastSelection.isCollapsed) {
        let copy = getDataCopy();
        let div = copy.find((d) => d.id === lastSelection.divId);

        // add new text to this div
        let beginning = div.text.substring(0, lastSelection.offset);
        let end = div.text.substring(lastSelection.offset);
        div.text = beginning + newText + end;

        saveData(copy);
        createDisplay(copy, () => {
          let target = document.getElementById(lastSelection.divId);
          let count = 0;
          let targetOffset = 0;
          for (let node of target.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text) {
              let textOffset = 0;
              if (text.nodeValue) {
                for (let i = 0; i < text.nodeValue.length; i++) {
                  if (count < lastSelection.offset + newText.length) {
                    count++;
                    textOffset++;
                  }
                }
              }
              if (count == lastSelection.offset + newText.length) {
                target = text;
                targetOffset = textOffset;
                break;
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(target, targetOffset);
          newRange.collapse(true); // Collapse the range to the start position

          // Remove any existing selections and set the new range
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        });
      } else {
        handleSelectionInsert(newText);
      }
    }
  }

  function handleSelectionInsert(newText) {
    let copy = getDataCopy();
    let divTrashCan = [];
    let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
    let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

    for (let i = startInd; i <= endInd; i++) {
      if (i === startInd && i === endInd) {
        let beginning = copy[i].text.substring(0, lastSelection.start.offset);
        let end = copy[i].text.substring(lastSelection.end.offset + 1);
        copy[i].text = beginning + newText + end;
      } else if (i == startInd) {
        let beginning = copy[i].text.substring(0, lastSelection.start.offset);
        copy[i].text = beginning + newText;

        //add appended end text and styles
        let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
        if (end) {
          copy[i].text += end;
        }
      } else if (startInd < i && i < endInd) {
        divTrashCan.push(i);
      } else if (i == endInd) {
        divTrashCan.push(i);
      }
    }

    if (divTrashCan.length) {
      let keeping = [];
      forEach(copy, (d, i) => {
        if (!divTrashCan.includes(i)) {
          keeping.push(d);
        }
      });
      copy = keeping;
    }

    saveData(copy);
    createDisplay(copy, () => {
      let target = document.getElementById(lastSelection.start.divId);
      let count = 0;
      let targetOffset = 0;
      const endOfInsert = lastSelection.start.offset + newText.length;
      for (let node of target.childNodes) {
        let text = node.nodeName === "#text" ? node : node.childNodes[0];
        if (text) {
          let textOffset = 0;
          if (text.nodeValue) {
            for (let i = 0; i < text.nodeValue.length; i++) {
              if (count < endOfInsert) {
                count++;
                textOffset++;
              }
            }
          }
          if (count == endOfInsert) {
            target = text;
            targetOffset = textOffset;
            break;
          }
        }
      }

      const newRange = document.createRange();
      newRange.setStart(target, targetOffset);
      newRange.collapse(true); // Collapse the range to the start position

      // Remove any existing selections and set the new range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(newRange);
    });
  }

  function handlePasteParagraphs(paragraphs) {
    let copy = getDataCopy();
    if (lastSelection.isCollapsed) {
      let index = copy.findIndex((d) => d.id === lastSelection.divId);
      // break the styles in two.
      let beginning = copy[index].text.substring(0, lastSelection.offset);
      let end = copy[index].text.substring(lastSelection.offset);
      copy[index].text = beginning + paragraphs[0];

      // keep the old styles
      let endFirstPart = paragraphs[paragraphs.length - 1];

      let endDiv = {
        id: shortId(),
        text: endFirstPart + end,
      };
      copy.splice(index + 1, 0, endDiv);

      // Add the middle ones in
      for (let i = 1; i < paragraphs.length - 1; i++) {
        let newDiv = {
          text: paragraphs[i],
          id: shortId(),
        };
        copy.splice(index + i, 0, newDiv);
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(endDiv.id);
        let count = 0;
        let targetOffset = 0;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            let textOffset = 0;
            if (text.nodeValue) {
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endFirstPart.length) {
                  count++;
                  textOffset++;
                }
              }
            }
            if (count == endFirstPart.length) {
              target = text;
              targetOffset = textOffset;
              break;
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, targetOffset);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      let divTrashCan = [];
      let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
      let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);
      let endingId;

      for (let i = startInd; i <= endInd; i++) {
        if (i === startInd && i === endInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          let end = copy[i].text.substring(lastSelection.end.offset + 1);

          let newStartDiv = {
            text: beginning + paragraphs[0],
            id: shortId(),
          };
          let newEndDiv = {
            text: paragraphs[paragraphs.length - 1] + end,
            id: shortId(),
          };
          endingId = newEndDiv.id;

          copy.splice(startInd, 1, newStartDiv);
          copy.splice(startInd + 1, 0, newEndDiv);
        } else if (i == startInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          copy[i].text = beginning + paragraphs[0];
        } else if (startInd < i && i < endInd) {
          divTrashCan.push(i);
        } else if (i == endInd) {
          // append the last paragraph onto the end div
          let lastParagraphText = paragraphs[paragraphs.length - 1];
          let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
          copy[i].text = lastParagraphText + end;
          endingId = copy[i].id;
        }
      }

      if (divTrashCan.length) {
        let keeping = [];
        forEach(copy, (d, i) => {
          if (!divTrashCan.includes(i)) {
            keeping.push(d);
          }
        });
        copy = keeping;
      }

      // Add the middle ones in
      for (let j = 1; j < paragraphs.length - 1; j++) {
        let newDiv = {
          text: paragraphs[j],
          id: shortId(),
        };
        copy.splice(startInd + j, 0, newDiv);
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(endingId);
        let count = 0;
        let targetOffset = 0;
        let endOfPaste = paragraphs[paragraphs.length - 1].length;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            let textOffset = 0;
            if (text.nodeValue) {
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endOfPaste) {
                  count++;
                  textOffset++;
                }
              }
            }
            if (count == endOfPaste) {
              target = text;
              targetOffset = textOffset;
              break;
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, targetOffset);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    }
  }

  function handleCut(e) {
    if (lastSelection) {
      if (!lastSelection.isCollapsed) {
        if (navigator?.clipboard) {
          let startInd = data.findIndex(
            (div) => div.id === lastSelection.start.divId
          );
          let endInd = data.findIndex(
            (div) => div.id === lastSelection.end.divId
          );
          let string = "";

          for (let i = startInd; i <= endInd; i++) {
            if (i == startInd && i == endInd) {
              string = data[i].text.slice(
                lastSelection.start.offset,
                lastSelection.end.offset + 1
              );
            } else if (i == startInd) {
              string += data[i].text.slice(lastSelection.start.offset) + "\n";
            } else if (i > startInd && i < endInd) {
              string += data[i].text + "\n";
            } else if (i == endInd) {
              string += data[i].text.slice(0, lastSelection.end.offset + 1);
            }
          }

          if (document.hasFocus()) {
            navigator.clipboard.writeText(string);
          }
        }

        handleSelectionInsert("");
      }
    }
  }

  const container = useRef();
  const entry = useRef();

  function onSelect(e) {
    const selection = window.getSelection();

    if (!selection.rangeCount) {
      return;
    }
    const range = selection.getRangeAt(0);

    if (range.startContainer == entry.current) {
      saveSelection(null);
      return;
    }

    if (selection.isCollapsed) {
      let endOffset = range.endOffset;
      const endContainer = range.endContainer;

      let section = endContainer;

      if (!entry.current.contains(section)) {
        return;
      }

      while (
        section.parentElement !== entry.current &&
        !(section.id && data.some((div) => div.id === section.id))
      ) {
        section = section.parentElement;
      }

      if (
        endContainer.nodeName === "DIV" &&
        endContainer.parentElement === entry.current &&
        endOffset == 1
      ) {
        // its a blank div
        endOffset = 0;
      } else {
        // count the characters until the actual offset
        for (let node of section.childNodes) {
          if (node.nodeName === "BR") {
            continue;
          }
          let text = node.nodeName === "#text" ? node : node.childNodes[0]; // In case the text is outside of the span, which the browser will do on text entry on a blank line/div
          if (text === endContainer) {
            break;
          }
          if (text.nodeValue) {
            endOffset += text.nodeValue.length;
          }
        }
      }

      saveSelection({
        divId: section.id,
        offset: endOffset,
        isCollapsed: selection.isCollapsed,
      });
    } else {
      // use selection.focusNode && focusOffset to find cursor position
      let start = range.startContainer;
      let startOffset = range.startOffset;

      if (start.nodeName === "#text") {
        while (start.parentElement !== entry.current) {
          start = start.parentElement;
        }

        for (let node of start.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text === range.startContainer) {
            break;
          }
          if (text.nodeValue) {
            startOffset += text.nodeValue.length;
          }
        }
      }

      let end = range.endContainer;
      let endOffset = range.endOffset - 1;

      if (end.nodeName === "#text") {
        while (end.parentElement !== entry.current) {
          end = end.parentElement;
        }

        for (let node of end.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text === range.endContainer) {
            break;
          }
          if (text.nodeValue) {
            endOffset += text.nodeValue.length;
          }
        }
      }

      let focus = null;
      let focusOffset = 0;

      if (selection.focusNode === range.startContainer) {
        focus = start;
        focusOffset = startOffset;
      } else if (selection.focusNode === range.endContainer) {
        focus = end;
        focusOffset = endOffset;
      }

      saveSelection({
        start: {
          divId: start.id,
          offset: startOffset,
        },
        end: {
          divId: end.id,
          offset: endOffset,
        },
        focus: {
          divId: focus?.id,
          offset: focusOffset,
        },
        isCollapsed: selection.isCollapsed,
      });
    }

    if (incoming) {
      setTimeout(() => setShowContent(incoming), 100);
    }
  }

  function createDisplayAndDoSelection(dataCopy, selection) {
    createDisplay(dataCopy, () => {
      if (selection) {
        if (selection.isCollapsed) {
          let target = document.getElementById(selection.divId);
          let div = dataCopy.find((d) => d.id === selection.divId);
          let subCount = 0;

          if (div.image) {
            subCount = selection.offset;
          } else if (div.divider) {
            subCount = 0;
          } else {
            let count = 0;
            for (let node of target.childNodes) {
              let text = node.nodeName === "#text" ? node : node.childNodes[0];
              if (text) {
                if (text.nodeValue) {
                  subCount = 0;
                  for (let i = 0; i < text.nodeValue.length; i++) {
                    if (count < selection.offset) {
                      count++;
                      subCount++;
                    }
                  }

                  if (count == selection.offset) {
                    target = text;
                    break;
                  }
                }
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(target, subCount);
          newRange.collapse(true); // Collapse the range to the start position

          // Remove any existing selections and set the new range
          const windowSelection = window.getSelection();
          windowSelection.removeAllRanges();
          windowSelection.addRange(newRange);
        } else {
          let startTarget = document.getElementById(selection.start.divId);
          let subCount = 0;
          let count = 0;

          let startDiv = dataCopy.find((d) => d.id === selection.start.divId);
          if (startDiv.image) {
            subCount = selection.start.offset;
          } else if (startDiv.divider) {
            subCount = 0;
          } else {
            for (let node of startTarget.childNodes) {
              let text = node.nodeName === "#text" ? node : node.childNodes[0];
              if (text) {
                if (text.nodeValue) {
                  subCount = 0;
                  for (let i = 0; i < text.nodeValue.length; i++) {
                    if (count < selection.start.offset) {
                      count++;
                      subCount++;
                    }
                  }

                  if (count == selection.start.offset) {
                    startTarget = text;
                    break;
                  }
                }
              }
            }
          }

          let endTarget = document.getElementById(selection.end.divId);
          let endSubCount = 0;
          count = 0;

          let endDiv = dataCopy.find((d) => d.id === selection.end.divId);
          if (endDiv.image) {
            endSubCount = selection.end.offset;
          } else if (endDiv.divider) {
            endSubCount = 0;
          } else {
            for (let node of endTarget.childNodes) {
              let text = node.nodeName === "#text" ? node : node.childNodes[0];
              if (text) {
                if (text.nodeValue) {
                  endSubCount = 0;
                  for (let i = 0; i < text.nodeValue.length; i++) {
                    if (count < selection.end.offset + 1) {
                      count++;
                      endSubCount++;
                    }
                  }

                  if (count == selection.end.offset + 1) {
                    endTarget = text;
                    break;
                  }
                }
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(startTarget, subCount);
          newRange.setEnd(endTarget, endSubCount);

          // Remove any existing selections and set the new range
          const windowSelection = window.getSelection();
          windowSelection.removeAllRanges();
          windowSelection.addRange(newRange);
        }
      }
    });
  }

  const [showContent, setShowContent] = useState("");

  useEffect(() => {
    if (showContent) {
      setShowContent("");
      setUpIncoming(showContent);
    }
  }, [showContent]);

  function setUpIncoming(content) {
    let copy = getDataCopy();
    let index = -1;
    let offset = 0;
    if (lastSelection.isCollapsed) {
      index = copy.findIndex((d) => d.id === lastSelection.divId);
      offset = lastSelection.offset;
    } else {
      index = copy.findIndex((d) => d.id === lastSelection.start.divId);
      offset = lastSelection.start.offset;
    }

    if (index > -1) {
      if (content === "Fields") {
        menuPrep(copy, index, offset, "{recipient ");
      } else if (content === "Survey URL") {
        const sub = "{survey url} ";
        inputDropText(copy, index, offset, sub);
      }
    }
  }

  function menuPrep(copy, index, offset, input) {
    setUpMenu_FromIncomingClick(input);
    inputDropText(copy, index, offset, input);
  }

  function inputDropText(copy, index, offset, input) {
    if (lastSelection.isCollapsed) {
      copy[index].text =
        copy[index].text.slice(0, offset) +
        input +
        copy[index].text.slice(offset);

      offset += input.length;

      createDisplayAndDoSelection(copy, {
        divId: copy[index].id,
        offset: offset,
        isCollapsed: true,
      });
      saveData(copy);
    } else {
      handleSelectionInsert(input);
    }
  }

  function onFieldInput(string) {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      let endOffset = menu.offset;

      let first = copy[index].text.slice(0, endOffset);
      let second = copy[index].text.slice(endOffset);
      copy[index].text = first + string + second;

      endOffset += string.length;

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[index].id,
        offset: endOffset,
      });

      menu.offset = endOffset;
    }
  }

  function onFieldPrep() {
    onFieldInput("recipient ");
  }

  function onPickField(field) {
    onFieldInput(field.name + "} ");
    closeMenu();
  }

  function onConditionPrep() {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);
    if (index > -1) {
      // it will have 'recipient ',
      let endOffset = menu.offset - 1;

      let first = copy[index].text.slice(0, endOffset - "recipient".length);
      let second = copy[index].text.slice(endOffset);
      copy[index].text = first + "condition" + second;

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[index].id,
        offset: menu.offset,
      });
    }
  }

  function onConditionSwap(condition) {
    if (condition.name !== menu.init) {
      let copy = getDataCopyWithMenuUp();
      let index = copy.findIndex((d) => d.id === menu.divId);
      if (index > -1) {
        let to = copy[index].text.indexOf("}", menu.offset);
        let before = copy[index].text.slice(menu.offset, to);

        let offset = to;
        if (before.length > condition.name.length) {
          // remove text
          let amount = before.length - condition.name.length;
          offset -= amount;
        } else if (before.length < condition.name.length) {
          // add text
          let amount = condition.name.length - before.length;
          offset += amount;
        }

        copy[index].text =
          copy[index].text.slice(0, menu.offset) +
          condition.name +
          copy[index].text.slice(to);

        saveData(copy);
        createDisplay(copy);
      }
    }

    closeMenu();
  }

  function onSurveyUrl() {
    let copy = getDataCopyWithMenuUp();
    let index = copy.findIndex((d) => d.id === menu.divId);

    if (index > -1) {
      let before = copy[index].text.slice(0, menu.offset);
      const sub = "survey url} ";
      let after = copy[index].text.slice(menu.offset);

      copy[index].text = before + sub + after;

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        isCollapsed: true,
        divId: copy[index].id,
        offset: menu.offset + sub.length,
      });
    }

    closeMenu();
  }

  function clearSelections() {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      selection.removeAllRanges();
    }
  }

  function onConditionClick(name, from) {
    setUpMenu_FromConditionClick(name, from);
    clearSelections();
  }

  function undo() {
    let recordCopy = [...record];
    let last = recordCopy.pop();
    if (last) {
      if (last.data && last.selection) {
        createDisplayAndDoSelection(last.data, last.selection);
        setData(last.data);
        setEdited(true);
        setRunSave(true);
      }
      setRecord(recordCopy);
    }
    if (menu) {
      setMenu(null);
    }
  }

  function getMenuPosition() {
    const selection = window.getSelection();
    const rangeDim = selection.getRangeAt(0).getBoundingClientRect();
    // debugger;
    const containerDim = phoneRef.current.getBoundingClientRect();

    let style = {};

    const menuHeight = 225;
    const menuWidth = 380;

    let buffer = 0;
    if (rangeDim.left - containerDim.left > 15) {
      // accounts for non collapsed selections
      let widthLeft = window.innerWidth - rangeDim.right;
      while (widthLeft + buffer < menuWidth + 10) {
        buffer += 5;
      }
    }

    style.left = rangeDim.left - containerDim.left - buffer + "px";
    // style.left = "0px";

    const boxHeight = window.innerHeight - containerDim.bottom;
    const placementHeight = window.innerHeight - rangeDim.bottom;
    const heightLeft =
      boxHeight > 0 ? placementHeight - boxHeight : placementHeight;

    if (
      heightLeft < menuHeight &&
      rangeDim.top - containerDim.top > menuHeight
    ) {
      style.bottom = containerDim.bottom - rangeDim.bottom + 30 + "px";
    } else {
      style.top = rangeDim.top - containerDim.top + 25 + "px";
    }

    return style;
  }

  function setUpMenu_FromKeyClick() {
    let menuObj = {
      style: getMenuPosition(),
      divId: lastSelection.isCollapsed
        ? lastSelection.divId
        : lastSelection.start.divId,
      offset: lastSelection.isCollapsed
        ? lastSelection.offset + 1
        : lastSelection.start.offset + 1,
    };

    recordRef.current = true;
    setMenu(menuObj);
  }

  // const recordRef
  function setUpMenu_FromIncomingClick(input) {
    let bump = input.length;
    if (
      showContent === "Link" ||
      showContent === "Button" ||
      showContent === "Unsub Link"
    ) {
      bump = 0;
    }

    let menuObj = {
      style: getMenuPosition(),
      divId: lastSelection.isCollapsed
        ? lastSelection.divId
        : lastSelection.start.divId,
      offset: lastSelection.isCollapsed
        ? lastSelection.offset + bump
        : lastSelection.start.offset + bump,
      pre: showContent ? showContent : "",
    };

    recordRef.current = true;
    setMenu(menuObj);
  }

  function setUpMenu_FromConditionClick(name, offset) {
    const lastSel = lastSelRef.current;

    if (lastSel) {
      let menuObj = {
        style: getMenuPosition(),
        divId: lastSel.isCollapsed ? lastSel.divId : lastSel.start.divId,
        offset: offset,
        pre: "Conditions",
        init: name,
      };

      recordRef.current = true;
      setMenu(menuObj);
    }
  }

  function closeMenu() {
    recordRef.current = undefined;
    setMenu(null);
  }

  function onDragOver(e) {
    let node = getTextNodeAndOffset(e.clientX, e.clientY);
    if (node) {
      const range = document.createRange();
      range.setStart(node.node, node.offset);
      range.collapse(true);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
    e.preventDefault();
  }

  function getTextNodeAndOffset(x, y) {
    const walker = document.createTreeWalker(
      entry.current,
      NodeFilter.SHOW_TEXT,
      null
    );
    let node = null;

    while ((node = walker.nextNode())) {
      const range = document.createRange();
      range.selectNodeContents(node);
      const rects = range.getClientRects();

      for (let rect of rects) {
        if (
          x >= rect.left &&
          x <= rect.right &&
          y >= rect.top &&
          y <= rect.bottom
        ) {
          // Calculate offset within the text node
          const text = node.textContent;
          for (let i = 0; i < text.length; i++) {
            range.setStart(node, i);
            range.setEnd(node, i + 1);
            const charRect = range.getBoundingClientRect();
            if (
              x >= charRect.left &&
              x <= charRect.right &&
              y >= charRect.top &&
              y <= charRect.bottom
            ) {
              return { node, offset: i };
            }
          }
        }
      }
    }

    for (let div of data) {
      let realDiv = document.getElementById(div.id);
      if (realDiv) {
        let space = realDiv.getBoundingClientRect();
        if (
          x >= space.left &&
          x <= space.right &&
          y >= space.top &&
          y <= space.bottom
        ) {
          let end = getEndOfDiv(div, realDiv);
          if (end) {
            return end;
          }
        }
      }
    }

    let lastDiv = data[data.length - 1];
    let realDiv = document.getElementById(lastDiv.id);
    let end = getEndOfDiv(lastDiv, realDiv);
    return end;
  }

  function getEndOfDiv(div, realDiv) {
    let subCount = 0;
    let node = null;

    if (div.image || div.divider) {
      return null;
    } else if (div.divider) {
      return null;
    } else {
      let lastNode = realDiv.lastChild;
      let text =
        lastNode.nodeName === "#text" ? lastNode : lastNode.childNodes[0];
      if (text) {
        node = text;
        if (div.text && text.nodeValue) {
          subCount = text.nodeValue.length;
        }
      }
    }

    if (node) {
      return {
        node: node,
        offset: subCount,
      };
    }
    return null;
  }

  function onDragLeave() {
    clearSelections();
  }

  function onKeydown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!menu) {
        insertNewLine();
      }
    }

    if (e.ctrlKey || e.metaKey) {
      if (e.key === "z") {
        e.preventDefault();
        undo();
      }
    }

    if (e.key === "{" && !menu) {
      setUpMenu_FromKeyClick();
    }
  }

  // useEffect(() => {
  //   if (!editable) {
  //     let val = decode();
  //     setData(val);
  //     createDisplay(val);
  //   }
  // }, [encoding]);

  useEffect(() => {
    if (reDraw) {
      createDisplay(data);
    }
  }, [reDraw]);

  useEffect(() => {
    if (entry.current && data.length == 1 && !data[0].text) {
      entry.current.focus();
    }
  });

  return (
    <>
      <div className={`${styles.container}`} ref={container} onBlur={onBlur}>
        <div
          className={`${styles.entry} ${incoming ? styles.poised : ""}`}
          id="entry"
          contentEditable={editable}
          onInput={handleChange}
          onSelect={editable ? onSelect : null}
          ref={entry}
          onKeyDown={editable ? onKeydown : null}
          onPaste={editable ? handlePaste : null}
          onCut={editable ? handleCut : null}
          placeholder="Type Name"
          // spellCheck={editable}
          suppressContentEditableWarning={true}
          onDragOver={incoming ? onDragOver : undefined}
          onDragLeave={incoming ? onDragLeave : undefined}
        ></div>
      </div>
      {menu && (
        <Menu
          onClose={closeMenu}
          menu={menu}
          onFieldPrep={onFieldPrep}
          onPickField={onPickField}
          onSurveyUrl={onSurveyUrl}
          conditions={conditions}
          setConditions={setConditions}
          onConditionPrep={onConditionPrep}
          onConditionSwap={onConditionSwap}
          customFields={customFields}
          forText
        />
      )}
    </>
  );
}
